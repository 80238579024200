import React from 'react';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import { composer } from '../../../../component-decorators/composer';
import { Text } from '@wix/design-system';
import { useSolution } from '../contexts/solution';

export interface IAppDeveloperProps extends WithTranslationProps {}

export const AppDeveloper = composer()
  .withTranslation()
  .compose(({ t }: IAppDeveloperProps) => {
    const solution = useSolution();
    const { company } = solution;
    return (
      <Text secondary size="tiny" weight="thin">
        {t('solutionBox.solutionDetails.companyName', {
          company: company?.name,
        })}
      </Text>
    );
  });
