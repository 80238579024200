import React from 'react';
import { Box, Divider } from '@wix/design-system';
import type { TextHyperlinkType } from '../../components/text-hyperlink';
import { TextHyperlink } from '../../components/text-hyperlink';
import type { CompanyInfoHeaderProps } from '../../components/company-info-header-section';
import { CompanyInfoHeaderSection as HeaderSection } from '../../components/company-info-header-section';
import {
  withEssentialsContext,
  useEssentials,
} from '../../contexts/essentials-context';
import type { ExternalComponentProps } from '../types';
import {
  EmailSmall,
  AdminAccessSmall,
  PublicSmall,
} from '@wix/wix-ui-icons-common';

export interface CompanyInfoProps extends ExternalComponentProps {
  appInfo: CompanyInfoHeaderProps;
  website?: TextHyperlinkType;
  contactSupport?: TextHyperlinkType;
  privacyPolicy?: TextHyperlinkType;
}

const CompanyInfoComponent = ({
  appInfo,
  contactSupport,
  privacyPolicy,
  website,
}: CompanyInfoProps) => {
  const { t } = useEssentials();

  return (
    <Box
      dataHook="company-info"
      boxSizing="border-box"
      border="1px solid #DFE5EB"
      direction="vertical"
      borderRadius="8px"
    >
      <HeaderSection name={appInfo.name} imageURL={appInfo.imageURL} />
      <Divider />
      <Box direction="vertical" padding="18px 24px" gap="12px">
        {website?.href && (
          <TextHyperlink
            text={t('app.companyInfo.websiteLink')}
            href={website.href}
            onClick={website.onClick}
            icon={<PublicSmall />}
          />
        )}
        {contactSupport?.href && (
          <TextHyperlink
            text={t('app.companyInfo.contactSupportLink')}
            href={contactSupport.href}
            onClick={contactSupport.onClick}
            icon={<EmailSmall />}
          />
        )}
        {privacyPolicy?.href && (
          <TextHyperlink
            text={t('app.companyInfo.privacyPolicyLink')}
            href={privacyPolicy.href}
            onClick={privacyPolicy.onClick}
            icon={<AdminAccessSmall />}
          />
        )}
      </Box>
    </Box>
  );
};

export const CompanyInfo = withEssentialsContext(CompanyInfoComponent);
