import React from 'react';
import { SkeletonLine, SkeletonCircle, Box } from '@wix/design-system';

interface RegularAppBoxSkeletonProps {
  className?: string;
}

export function RegularAppBoxSkeleton({
  className,
}: RegularAppBoxSkeletonProps) {
  return (
    <Box className={className} gap="12px">
      <SkeletonCircle diameter="42px" />
      <Box direction="vertical">
        <SkeletonLine width="168px" marginTop="18px" />
        <SkeletonLine width="342px" marginTop="15px" />
        <SkeletonLine width="117px" marginTop="6px" />
        <SkeletonLine width="30px" marginTop="12px" />
      </Box>
    </Box>
  );
}
