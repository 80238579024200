import type { i18n, Resource } from 'i18next';
import i18next from 'i18next';

type GetI18nInstanceType = (
  resources: Resource,
  locale: string,
) => Promise<{ i18n: i18n; i18nResources: Resource }>;

export const getI18nInstance: GetI18nInstanceType = async (
  messages: Resource,
  locale: string,
) => {
  await i18next.init({
    lng: locale,
    resources: messages,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

  return {
    i18n: i18next,
    i18nResources: messages,
  };
};
