import * as React from 'react';
import { WithFetcher } from '../../component-decorators/with-fetcher';
import { CenteredLoader } from '../centered-loader';
import { useConfigContext } from '../../config-context';
import type { GetDynamicSectionsResponseNonNullableFields } from '@wix/ambassador-appmarket-v1-app-market-client-aggregator/types';
import { getHomePageDynamicSections } from './home-page.utils';
import { FluidContainer } from '../FluidContainer';
import { HomeHeader } from '../home/partials/home-header/home-header';
import { DynamicSectionComponent } from './dynamic-section';
import { config } from '../../config';
import { Origin } from '../../enums/marketplace-enums';
import { Box, TextButton } from '@wix/design-system';
import { ChevronLeft } from '@wix/wix-ui-icons-common';
import type { TranslateFunction } from '../../component-decorators/with-translation';
import s from './home-page.scss';
import { useEffect } from 'react';
import { AppMarketPage } from '../../types/common/marketplace';
import type { MarketplaceStore } from '../../component-decorators/with-marketplace-store';
import { useEssentials, useSiteContext } from '@wix/app-market-core';
import { getPageViewBiData } from './bi-events-data-parser';
import { appMarketPageView } from '@wix/bi-logger-market/v2';

interface HomePageProps {
  t: TranslateFunction;
  marketplaceStore: MarketplaceStore;
}

export function HomePage({ t, marketplaceStore }: HomePageProps) {
  const { locale } = useConfigContext();
  const { i18n, biLogger, httpClient, rpcClient } = useEssentials();
  const { isPremium } = useSiteContext();

  useEffect(() => {
    const urlParams = location.pathname.split('/')[3];
    biLogger.report(
      appMarketPageView(getPageViewBiData(marketplaceStore, urlParams)),
    );
  }, []);

  return (
    <WithFetcher
      initialFetch={() => getHomePageDynamicSections({ httpClient, rpcClient })}
      fetchMemoizationId={`home-${locale}`}
      loader={<CenteredLoader />}
    >
      {({
        fetchData: sections,
      }: {
        fetchData: GetDynamicSectionsResponseNonNullableFields['sections'];
      }) => (
        <div data-hook="homepage" data-testit="homepage">
          {config.origin === Origin.BIZMGR && config.goBackToReferrer && (
            <FluidContainer className={s.backToReferrer}>
              <TextButton
                onClick={() => {
                  config.goBackToReferrer.navigation();
                }}
                underline="always"
                dataHook="back-to-referrer-button"
                size="medium"
                prefixIcon={<ChevronLeft />}
              >
                {t('homepage.back.to.referrer', {
                  translatedPageName: t(`${config.goBackToReferrer.labelKey}`),
                })}
              </TextButton>
            </FluidContainer>
          )}
          <FluidContainer>
            <HomeHeader />
          </FluidContainer>
          <FluidContainer>
            {sections.map((section, index) => (
              <Box direction="vertical" marginBottom="30px">
                <DynamicSectionComponent
                  section={section}
                  i18n={i18n}
                  marketplaceStore={marketplaceStore}
                  index={index}
                  page={AppMarketPage.HOMEPAGE}
                  biLogger={biLogger}
                  isPremiumSite={isPremium}
                />
              </Box>
            ))}
          </FluidContainer>
        </div>
      )}
    </WithFetcher>
  );
}
