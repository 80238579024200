import React from 'react';
import {
  Box,
  CounterBadge,
  Divider,
  SidebarItemNext,
} from '@wix/design-system';
import { useMarketplaceStore } from '../../component-decorators/with-marketplace-store';
import { config } from '../../config';
import { Origin } from '../../enums/marketplace-enums';
import { biLogger } from '../../bi';
import { appMarketMenuNavigation } from '@wix/bi-logger-market/v2';
import { SettingsSmall } from '@wix/wix-ui-icons-common';
import {
  filterBusinessSolutionApps,
  getCompleteSetupApps,
} from '@wix/manage-installed-apps';
import { composer } from '../../component-decorators/composer';
import type { TFunction } from 'i18next';

export function SidebarFooter({ experiments }: { experiments: any }) {
  const {
    hideBusinessSolutionsInManageApps,
    installedApps,
    currentBundleApps,
    siteIsPremium,
  } = useMarketplaceStore();

  function shouldShowManagedAppsEntry() {
    return config.origin === Origin.BIZMGR || config.origin === Origin.EDITOR;
  }

  function calculatePendingAppsCount(): number {
    const installedAppsWithoutBusinessSolutions = filterBusinessSolutionApps(
      installedApps || [],
    );
    const completeSetupApps = getCompleteSetupApps(
      hideBusinessSolutionsInManageApps
        ? installedAppsWithoutBusinessSolutions
        : installedApps || [],
      currentBundleApps || [],
      siteIsPremium as boolean,
      experiments,
      false,
    );
    return completeSetupApps?.length || 0;
  }

  function navigateToManagedApps() {
    biLogger.report(
      appMarketMenuNavigation({ menu_item_label: 'manage_apps' }),
    );
    config.goToManageApps?.();
  }

  if (!shouldShowManagedAppsEntry()) {
    return null;
  }

  return (
    <>
      <Divider direction="horizontal" />

      <SidebarItemNext
        itemKey="managed-apps"
        onClick={navigateToManagedApps}
        dataHook="sidebar-footer.manage-apps"
        suffix={
          calculatePendingAppsCount() ? (
            <CounterBadge skin="danger">
              {calculatePendingAppsCount()}
            </CounterBadge>
          ) : null
        }
      >
        <Box verticalAlign="middle" gap="20px">
          <SettingsSmall />
          <ManagedAppsLabel />
        </Box>
      </SidebarItemNext>
    </>
  );
}

export const ManagedAppsLabel = composer()
  .withTranslation()
  .withExperiments()
  .compose(({ t, experiments }: { t?: TFunction; experiments: any }) =>
    experiments.enabled('specs.appMarket.manageAppsTextTest')
      ? t?.('menu.manageApps.test')
      : t?.('menu.manageApps'),
  );
