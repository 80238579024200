import type { ManagedAppsRequest } from '@wix/ambassador-devcenter-managedapps-v1-managed-app/types';
import type { QueryMarketListingRequest } from '@wix/ambassador-devcenter-app-market-listing-v1-market-listing/types';
import type { QueryAppRequest } from '@wix/ambassador-devcenter-apps-v1-app/types';
import type { QueryTagRequest } from '@wix/ambassador-prime-auto-v1-tag/types';
import type {
  GetAppsByTagRequest,
  QueryTagAppRequest,
} from '@wix/ambassador-devcenter-appmarket-v1-tag-app/types';
import type {
  GetMarketAppRequest,
  QueryMarketAppRequest,
} from '@wix/ambassador-devcenter-market-apps-v1-market-app/types';
import type { GetWebSolutionsRequest } from '@wix/ambassador-devcenter-marketplace-v1-web-solution/types';
import type {
  GetAppsByAppIdsRequest,
  GetDynamicSectionsRequest,
  GetTagSectionsRequest,
} from '@wix/ambassador-appmarket-v1-app-market-client-aggregator/types';
import type { ListAppPlansByAppIdRequest } from '@wix/ambassador-appmarket-v1-app-plans/types';
import type { CompleteInstallationRequest } from '@wix/ambassador-devcenter-pendingapps-v1-pending-installation/types';
import type { AmbassadorBootstrap } from '@wix/ambassador-bootstrap-plugin';
import type { QueryAppReviewsSummaryRequest } from '@wix/ambassador-appreviewssummaries-v1-app-reviews-summary/types';
import type {
  AppreviewsCreateReviewRequest,
  GetUserReviewRequest,
  QueryReviewsRequest,
  UpdateReviewRequest,
} from '@wix/ambassador-devcenter-v1-review/types';
import type { GetPricingModelRequest } from '@wix/ambassador-devcenter-pricing-v1-pricing-entity/types';
import type {
  GetSitesPremiumStatusRequest,
  GetSitesPremiumStatusResponse,
} from '@wix/ambassador-premium-v1-asset/types';
import type { GetAppComponentsByAppIdsRequest } from '@wix/ambassador-devcenter-app-components-v1-app-components/types';
import type {
  AutocompleteRequest,
  SearchRequest,
} from '@wix/ambassador-devcenter-ams-v1-app-market-search/types';
/**
 * Rpc services implementation, based on rpcClient/ambassador-bootstrap.
 * @class RpcServices
 */
export class RpcServices {
  private readonly rpcClient: NonNullable<AmbassadorBootstrap>;
  constructor(rpcClient: AmbassadorBootstrap) {
    if (!rpcClient) {
      throw new Error('RpcClient is required but provided as undefined.');
    }
    this.rpcClient = rpcClient;
  }

  async getManagedApps(payload: ManagedAppsRequest) {
    const managedAppsRpc = (
      await import('@wix/ambassador-devcenter-managedapps-v1-managed-app/rpc')
    ).managedApps;
    return this.rpcClient.request(managedAppsRpc(payload));
  }

  async getMarketApp(payload: GetMarketAppRequest) {
    const getMarketAppRpc = (
      await import('@wix/ambassador-devcenter-market-apps-v1-market-app/rpc')
    ).getMarketApp;
    return this.rpcClient.request(getMarketAppRpc(payload));
  }

  async queryMarketApp(payload: QueryMarketAppRequest) {
    const queryMarketAppRpc = (
      await import('@wix/ambassador-devcenter-market-apps-v1-market-app/rpc')
    ).queryMarketApp;
    return this.rpcClient.request(queryMarketAppRpc(payload));
  }

  async queryMarketListing(payload: QueryMarketListingRequest) {
    const queryMarketListingRpc = (
      await import(
        '@wix/ambassador-devcenter-app-market-listing-v1-market-listing/rpc'
      )
    ).queryMarketListing;
    return this.rpcClient.request(queryMarketListingRpc(payload));
  }

  async queryApp(payload: QueryAppRequest) {
    const queryAppRpc = (
      await import('@wix/ambassador-devcenter-apps-v1-app/rpc')
    ).queryApp;
    return this.rpcClient.request(queryAppRpc(payload));
  }

  async queryTag(payload: QueryTagRequest) {
    const queryTagRpc = (await import('@wix/ambassador-prime-auto-v1-tag/rpc'))
      .queryTag;
    return this.rpcClient.request(queryTagRpc(payload));
  }

  async queryTagApp(payload: QueryTagAppRequest) {
    const queryTagAppRpc = (
      await import('@wix/ambassador-devcenter-appmarket-v1-tag-app/rpc')
    ).queryTagApp;
    return this.rpcClient.request(queryTagAppRpc(payload));
  }
  async getAppsByTag(payload: GetAppsByTagRequest) {
    const getAppsByTagRpc = (
      await import('@wix/ambassador-devcenter-appmarket-v1-tag-app/rpc')
    ).getAppsByTag;
    return this.rpcClient.request(getAppsByTagRpc(payload));
  }

  async getAppsByAppIds(payload: GetAppsByAppIdsRequest) {
    const getAppsByAppIdsRpc = (
      await import(
        '@wix/ambassador-appmarket-v1-app-market-client-aggregator/rpc'
      )
    ).getAppsByAppIds;
    return this.rpcClient.request(getAppsByAppIdsRpc(payload));
  }

  async getDynamicSections(payload: GetDynamicSectionsRequest) {
    const getDynamicSectionsRpc = (
      await import(
        '@wix/ambassador-appmarket-v1-app-market-client-aggregator/rpc'
      )
    ).getDynamicSections;
    return this.rpcClient.request(getDynamicSectionsRpc(payload));
  }

  async getWebSolutionsBase(payload: GetWebSolutionsRequest) {
    const getWebSolutionsRpc = (
      await import('@wix/ambassador-devcenter-marketplace-v1-web-solution/rpc')
    ).getWebSolutionsBase;
    return this.rpcClient.request(getWebSolutionsRpc(payload));
  }

  async queryAppPlans(payload: ListAppPlansByAppIdRequest) {
    const queryAppPlansRpc = (
      await import('@wix/ambassador-appmarket-v1-app-plans/rpc')
    ).listAppPlansByAppId;
    return this.rpcClient.request(queryAppPlansRpc(payload));
  }

  async completePendingInstallation(payload: CompleteInstallationRequest) {
    const completeInstallationRpc = (
      await import(
        '@wix/ambassador-devcenter-pendingapps-v1-pending-installation/rpc'
      )
    ).completeInstallation;
    return this.rpcClient.request(completeInstallationRpc(payload));
  }

  async queryAppReviewsSummary(payload: QueryAppReviewsSummaryRequest) {
    const queryAppReviewsSummaryRpc = (
      await import(
        '@wix/ambassador-appreviewssummaries-v1-app-reviews-summary/rpc'
      )
    ).queryAppReviewsSummary;
    return this.rpcClient.request(queryAppReviewsSummaryRpc(payload));
  }

  async getUserReview(payload: GetUserReviewRequest) {
    const getUserReviewRpc = (
      await import('@wix/ambassador-devcenter-v1-review/rpc')
    ).getUserReview;
    return this.rpcClient.request(getUserReviewRpc(payload));
  }

  async queryReviews(payload: QueryReviewsRequest) {
    const queryReviewsRpc = (
      await import('@wix/ambassador-devcenter-v1-review/rpc')
    ).queryReviews;
    return this.rpcClient.request(queryReviewsRpc(payload));
  }

  async updateReview(payload: UpdateReviewRequest) {
    const updateReviewRpc = (
      await import('@wix/ambassador-devcenter-v1-review/rpc')
    ).updateReview;
    return this.rpcClient.request(updateReviewRpc(payload));
  }

  async createReview(payload: AppreviewsCreateReviewRequest) {
    const createReviewRpc = (
      await import('@wix/ambassador-devcenter-v1-review/rpc')
    ).appReviewsNamespaceCreateReview;
    return this.rpcClient.request(createReviewRpc(payload));
  }

  async getTagSections(payload: GetTagSectionsRequest) {
    const getTagSectionsRpc = (
      await import(
        '@wix/ambassador-appmarket-v1-app-market-client-aggregator/rpc'
      )
    ).getTagSections;
    return this.rpcClient.request(getTagSectionsRpc(payload));
  }

  async getPricingModel(payload: GetPricingModelRequest) {
    const getPricingModelRpc = (
      await import('@wix/ambassador-devcenter-pricing-v1-pricing-entity/rpc')
    ).getPricingModel;
    return this.rpcClient.request(getPricingModelRpc(payload));
  }

  async getSitesPremiumStatus(
    payload: GetSitesPremiumStatusRequest,
  ): Promise<GetSitesPremiumStatusResponse> {
    const getSitesPremiumStatusRpc = (
      await import('@wix/ambassador-premium-v1-asset/rpc')
    ).getSitesPremiumStatus;
    return this.rpcClient.request(getSitesPremiumStatusRpc(payload));
  }

  async searchApps(payload: SearchRequest) {
    const searchAppsRpc = (
      await import('@wix/ambassador-devcenter-ams-v1-app-market-search/rpc')
    ).search;
    return this.rpcClient.request(searchAppsRpc(payload));
  }

  async getAppComponentsByAppIds(payload: GetAppComponentsByAppIdsRequest) {
    const getAppComponentsByAppIds = (
      await import(
        '@wix/ambassador-devcenter-app-components-v1-app-components/rpc'
      )
    ).getAppComponentsByAppIds;
    return this.rpcClient.request(getAppComponentsByAppIds(payload));
  }

  async getAutoCompleteSuggestions(payload: AutocompleteRequest) {
    const autocompleteSuggestions = (
      await import('@wix/ambassador-devcenter-ams-v1-app-market-search/rpc')
    ).autocomplete;
    return this.rpcClient.request(autocompleteSuggestions(payload));
  }
}
