import React, { useContext } from 'react';
import { CenteredLoader } from '../components/centered-loader';

export interface Resources {
  [resourceId: string]: {
    status: 'pre-fetch' | 'fetch-fulfilled';
    data?: unknown;
  };
}

export const ResourcesCTX = React.createContext<Resources>({});

export const suspendable = <P extends unknown>(
  Component: React.FunctionComponent<P>,
) => {
  return (props: P) => (
    <React.Suspense fallback={<CenteredLoader />}>
      {/* @ts-expect-error */}
      <Component {...props} />
    </React.Suspense>
  );
};

export const useResource = <D extends unknown>(
  resourceFetcher: () => Promise<D>,
  resourceId: string,
) => {
  const resources = useContext(ResourcesCTX);

  if (!resources[resourceId]) {
    resources[resourceId] = { status: 'pre-fetch' };
  }

  if (resources[resourceId].status === 'fetch-fulfilled') {
    return resources[resourceId].data as D;
  }

  const promise = resourceFetcher()
    .then((res) => {
      resources[resourceId].status = 'fetch-fulfilled';
      resources[resourceId].data = res;
    })
    .catch(() => {
      resources[resourceId].status = 'fetch-fulfilled';
    });
  throw promise;
};
