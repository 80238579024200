import React from 'react';
import style from './installed-badge.scss';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import { composer } from '../../../../component-decorators/composer';
import { Text } from '@wix/design-system';
import { StatusCompleteFilledSmall } from '@wix/wix-ui-icons-common';
import { useSolution } from '../contexts/solution';

export interface IInstalledBadgeProps extends WithTranslationProps {}

export const InstalledBadge = composer()
  .withTranslation()
  .compose(({ t }: IInstalledBadgeProps) => {
    const solution = useSolution();
    const { isBundleAppInstalled } = solution;
    const textWeight = 'thin';
    const textSize = 'tiny';

    return (
      <span
        className={
          isBundleAppInstalled ? style.bundleBadge : style.centeredView
        }
      >
        <StatusCompleteFilledSmall className={style.installedIcon} />
        <span>
          <Text
            size={textSize}
            weight={textWeight}
            skin="success"
            dataHook="installed-text"
          >
            {isBundleAppInstalled
              ? t('solutionBox.solutionBottomActionBar.BundleInstalled')
              : t('solutionBox.solutionBottomActionBar.installedSolution')}
          </Text>
        </span>
      </span>
    );
  });
