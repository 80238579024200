import React from 'react';
import { SkeletonLine, SkeletonCircle, Box } from '@wix/design-system';

interface WideAppBoxSkeletonProps {
  className?: string;
}

export function WideAppBoxSkeleton({ className }: WideAppBoxSkeletonProps) {
  return (
    <Box className={className} gap="12px">
      <SkeletonCircle diameter="42px" />
      <Box flex={1} direction="vertical">
        <SkeletonLine width="168px" marginTop="18px" />
        <Box align="space-between" marginTop="15px">
          <Box direction="vertical">
            <SkeletonLine width="342px" />
            <SkeletonLine width="117px" marginTop="6px" />
          </Box>
          <SkeletonLine width="30px" />
        </Box>
      </Box>
    </Box>
  );
}
