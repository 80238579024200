import type { Path, AppPageMode } from '../../enums/marketplace-enums';
import type { GUID } from '@wix/bi-logger-market/v2/types';
import type { WebSolutionBase } from '@wix/ambassador-marketplace/types';

export interface IShareAppData {
  appDefId: string;
  shareId: string;
  version: string;
}

export interface IManageAppsDeepLinkData {
  appId: string;
  modal: string;
}
export interface IRoute {
  path: Path;
  slug?: string;
  subCategories?: string[];
  query?: string;
  referral?: string;
  referralTag?: string;
  shareAppData?: IShareAppData;
  manageAppsDeepLinkData?: IManageAppsDeepLinkData;
  appPageMode?: AppPageMode;
  version?: string;
  platformName?: string;
  currentUrl?: string;
  hideMenu?: boolean;
  languageCode?: string;
  appIndex?: number;
  collimp_id?: GUID;
  referralSectionName?: string;
  openAppOptions?: any;
  section?: 'discovery' | null;
  searchLocation?: string;
  utm_id?: string;
  entryPointToAppMarket?: string;
}

export interface IAppMarketParams {
  etpa?: IRoute;
  route: string;
  appDefId?: string;
  shareId?: string;
}

export interface SearchResponse {
  autocomplete?: string[];
  suggestions?: WebSolutionBase[];
}

export interface WebSolutionSuggestion extends WebSolutionBase {
  suggestionType?: SuggestionType;
  selected?: boolean;
}

export enum SuggestionType {
  SEARCH = 'SEARCH',
  CATEGORY = 'CATEGORY',
  APP = 'APP',
  NONE_TYPE = 'NONE_TYPE',
  WIX_OFFERING = 'WIX_OFFERING',
}

export enum AppMarketPage {
  MANAGE_APPS = 'manage-apps',
  APP_PAGE = 'app_page',
  CATEGORY = 'category',
  COLLECTION = 'collection',
  HOMEPAGE = 'homepage',
  SEARCH_RESULT = 'search-result',
}

export enum AppMarketEntryPoint {
  DASHBOARD_HEADER_EXPLORE = 'explore',
  DASHBOARD_HEADER_SEARCH = 'search',
  DASHBOARD_SIDEBAR = 'dashboard_sidebar_menu',
  DASHBOARD_TOOLS_FOR_YOUR_BUSINESS = 'dashboard_more_than_a_website',
}

export interface ISolutionReviewsDTO {
  body: {
    totalReviews: number;
    averageRating: number;
    ratingHistogram: {
      rating1: number;
      rating2: number;
      rating3: number;
      rating4: number;
      rating5: number;
    };
    sort: string;
    userReview: any;
    reviews: ISolutionReview[];
  };
}
export interface ISolutionReview {
  id: string;
  author: {
    userName: string;
  };
  title: string;
  description: string;
  rating: number;
  replies: ISolutionReviewReply[];
  totalLikes: number;
  userOpinion: ISolutionReviewUserOpinion;
  publishedAt: string;
  createdAt: string;
}
export interface ISolutionReviewReply {
  id: string;
  description: string;
  createdBy: { username: string };
  createdAt: string;
}

export interface ISolutionReviewUserOpinion {
  id: string;
}

export interface INotificationsOptions {
  message: string;
  biName: string;
  type?: any;
  action?: any;
  link?: any;
}

export interface IBiData {
  appId?: string;
  impressionType?: string;
  index?: number;
  market?: string;
  msid?: GUID;
  tagName?: string;
  tagType?: string;
  subCategory?: string;
  section?: string;
  referralInfo?: string;
  searchTerm?: string;
  widgetReferral?: string;
  sectionReferral?: string;
  isBundleAppInstalled?: boolean;
  isBundleAppNotInstalled?: boolean;
  collimp_id?: GUID;
  referralSectionName?: string;
  referralTag?: string;
  referralTagType?: string;
  referralSubTag?: string;
  hasAppRequirements?: boolean;
}
