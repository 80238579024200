export const nameFormatter = (name: string): string =>
  name
    .toLowerCase()
    .split(' ')
    .map((n) => n.charAt(0).toUpperCase() + n.slice(1))
    .join(' ');

export const dateFormatter = (dateToFormat: string): string => {
  const date = new Date(dateToFormat);
  return date
    ? date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      })
    : '';
};
