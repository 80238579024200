import React from 'react';
import { Box, Loader } from '@wix/design-system';

export function CenteredLoader() {
  return (
    <Box height="100%" verticalAlign="middle" align="center">
      <Loader size="medium" />
    </Box>
  );
}
