import React from 'react';
import type {
  BadgeSkin,
  BadgeProps as DesignSystemBadgeProps,
} from '@wix/design-system';
import { Badge as DesignSystemBadge, Box, Tooltip } from '@wix/design-system';
import s from '../../sass/badge.scss';

interface BadgeProps {
  skin: BadgeSkin;
  uppercase?: boolean;
  tooltipContent?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  dataHook?: string;
  showBorder?: boolean;
  type?: 'solid' | 'outlined';
  size?: 'tiny' | 'small';
}

export function Badge(props: BadgeProps) {
  const {
    tooltipContent,
    className,
    dataHook,
    showBorder,
    ...DesignSystemBadgePartialProps
  } = props;
  const designSystemBadgeProps: DesignSystemBadgeProps = {
    dataHook,
    size: 'tiny',
    type: 'solid',
    ...DesignSystemBadgePartialProps,
  };

  return (
    <Box dataHook="tooltip-badge-box" className={s.badgeHeight}>
      {tooltipContent ? (
        <Tooltip
          placement="right"
          inline
          content={tooltipContent}
          size="medium"
        >
          <DesignSystemBadge
            className={showBorder ? s.badgeBorder : ''}
            {...designSystemBadgeProps}
          />
        </Tooltip>
      ) : (
        <DesignSystemBadge {...designSystemBadgeProps} />
      )}
    </Box>
  );
}
