import React from 'react';
import { SectionHelper, Text } from '@wix/design-system';
import {
  appMarketAppPageOneDependencyImpression,
  appMarketAppPageOneDependencyClick,
} from '@wix/bi-logger-market/v2';
import { useTranslation } from '../../../../../contexts/translations-context';
import { DependencyPendingEditorNotificationDataHooks } from './data-hooks';
import { useEssentials, useRouterContext } from '../../../../../contexts';
import { RoutePath } from '../../../../../models';

export interface DependencyPendingEditorNotificationProps {
  app: {
    id: string;
    name: string;
  };
  appDependency: {
    id: string;
    name: string;
  };
}

export function DependencyPendingEditorNotification({
  app,
  appDependency,
}: DependencyPendingEditorNotificationProps) {
  const { biLogger } = useEssentials();
  const { t } = useTranslation();
  const { router } = useRouterContext();

  React.useEffect(() => {
    biLogger.report(
      appMarketAppPageOneDependencyImpression({
        app_id: app.id,
        dependency_type: 'app',
        app_dependency: appDependency.id,
        type: 'pending',
      }),
    );
  }, []);

  return (
    <SectionHelper
      dataHook={DependencyPendingEditorNotificationDataHooks.container}
      appearance="danger"
      fullWidth={true}
      actionText={t('solution.page.appDependencyPending.cta')}
      onAction={() => {
        biLogger.report(
          appMarketAppPageOneDependencyClick({
            app_id: app.id,
            dependency_type: 'app',
            click_type: 'go to editor',
            app_dependency: appDependency.id,
            type: 'pending',
          }),
        );
        router.navigateTo({
          path: RoutePath.EDITOR_COMPLETE_APP_SETUP,
          payload: {
            appId: appDependency.id,
          },
        });
      }}
      title={t('solution.page.appDependencyPending.title', {
        appRequirement: appDependency.name,
      })}
    >
      <Text
        dataHook={DependencyPendingEditorNotificationDataHooks.description}
        size="small"
        weight="thin"
      >
        {t('solution.page.appDependencyPending.subtitle', {
          appDependency: app.name,
          appRequirement: appDependency.name,
          interpolation: { escapeValue: false },
        })}
      </Text>
    </SectionHelper>
  );
}
