import React from 'react';
import s from './fluid-container.scss';

interface FluidContainerProps {
  children: any;
  className?: string;
  dataHook?: string;
  [s: string]: any;
  isWide?: boolean;
}

export const FluidContainer = ({
  children,
  className = '',
  dataHook,
  isWide,
  ...extraProps
}: FluidContainerProps) => {
  return (
    <div
      {...extraProps}
      data-hook={dataHook}
      className={`${className}
        ${isWide ? '' : s.fluidContainer}`}
    >
      {children}
    </div>
  );
};
