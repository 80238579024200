import React from 'react';
import { TextButton } from '@wix/design-system';

export interface TextHyperlinkType {
  href: string;
  onClick?: () => void;
}

export interface TextHyperlinkProps extends TextHyperlinkType {
  text: string;
  icon?: React.JSX.Element | undefined;
}

export const TextHyperlink = ({
  text,
  href,
  onClick,
  icon,
}: TextHyperlinkProps) => {
  return (
    <TextButton
      as="a"
      size="small"
      prefixIcon={icon}
      href={href}
      target="_blank"
      onClick={onClick}
    >
      {text}
    </TextButton>
  );
};
