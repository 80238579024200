import React from 'react';
import { AppIcon } from '../../../app-icon';
import style from './app-promotion-image.scss';
import { Box, Proportion, Image } from '@wix/design-system';
import type {
  AppBundle,
  BadgeType,
} from '@wix/ambassador-appmarket-v1-app-market-client-aggregator/types';

import { Badges } from '../../../badges/badges';

export function AppPromotionImage({
  iconUrl,
  appName,
  promotionalImage,
  isInstalled,
  bundle,
  badges,
}: {
  iconUrl: string;
  appName: string;
  promotionalImage: string;
  isInstalled: boolean;
  bundle?: AppBundle;
  badges?: BadgeType[];
}) {
  return (
    <div className={style.imagesStack}>
      <Proportion aspectRatio="1.5">
        <Box position="absolute" top="10px" right="12px" gap="6px">
          <Badges
            badges={badges}
            bundle={bundle}
            isInstalled={isInstalled}
            appName={appName}
            showBorder={true}
          />
        </Box>
        <Image
          alt={`${appName} icon`}
          src={promotionalImage}
          borderRadius="8px 8px 0px 0px"
          style={{ height: 'inherit' }}
          className={style.img}
        />
      </Proportion>
      <Box
        height="42px"
        width="42px"
        position="absolute"
        bottom="-15px"
        left="15px"
        borderRadius="6px"
        background="#ffffff"
        dataHook="boxi"
      >
        <AppIcon appName={appName} iconUrl={iconUrl} className={style.icon} />
      </Box>
    </div>
  );
}
