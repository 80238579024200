import type { TFunction } from '@wix/wix-i18n-config';

export function getBundleBadgeTooltipContent({
  t,
  isInstalled,
  isCouponTypeBundle,
  appName,
}: {
  t: TFunction;
  isInstalled: boolean;
  isCouponTypeBundle: boolean;
  appName: string;
}) {
  if (!isInstalled && isCouponTypeBundle) {
    return t('solutionBox.badge.bundle-tooltip-voucher-not-installed', {
      appName,
    });
  }
  if (isInstalled && isCouponTypeBundle) {
    return t('solutionBox.badge.bundle-tooltip-installed-voucher', { appName });
  }

  return t('solutionBox.badge.bundle-tooltip', { appName });
}
