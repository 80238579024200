import * as React from 'react';
import { Box, Text, Image, IconButton, TextButton } from '@wix/design-system';
import { ArrowLeftSmall } from '@wix/wix-ui-icons-common';
import { AppInfoBadge } from '../../components/app-info-badge';
import type { PricingBadgeProps } from '../../components/pricing-badge';
import type {
  ExternalComponentProps,
  ComponentsWithSiteContextProps,
} from '../types';
import { type AppBoxData } from '@wix/ambassador-appmarket-v1-app-market-client-aggregator/types';
import {
  useEssentials,
  withEssentialsContext,
} from '../../contexts/essentials-context';
import { withSiteContext } from '../../contexts/site-context';
import { shouldShowPremiumRequired } from '../../components/app-info-badge/app-info-badge.util';
import { Badges } from '../../components/badges/badges';
import type { AppPageStickyOptions } from './types';
import { appPageStickyHeaderDataHooks } from './data-hooks';
import s from './app-page-sticky.scss';

export interface AppPageStickyProps
  extends Required<
      Pick<
        AppBoxData,
        | 'icon'
        | 'company'
        | 'installation'
        | 'bundle'
        | 'pricing'
        | 'name'
        | 'badges'
      >
    >,
    ExternalComponentProps,
    ComponentsWithSiteContextProps {
  onBack?: () => void;
  onCompanyClick: (companyName: string, companySlug: string) => void;
  mainActionButton: React.ReactNode;
  isVisible: boolean;
  options?: AppPageStickyOptions;
}

function AppPageStickyComp({
  isVisible,
  icon,
  onBack,
  onCompanyClick,
  company,
  installation,
  bundle,
  badges,
  pricing,
  name,
  mainActionButton,
  experiments,
  isPremiumSite,
  options = {},
}: AppPageStickyProps) {
  const { t } = useEssentials();
  const shouldBeClickableCompanyName =
    experiments &&
    experiments?.enabled('specs.marketplace.AppPageOnNewInfraStandalone');
  const {
    showCTA = true,
    showAppInfoBadge = true,
    showBackButton = true,
  } = options;
  return (
    <Box
      {...(!isVisible && { visibility: 'hidden' })}
      verticalAlign="middle"
      backgroundColor="white"
      borderBottom="1px solid #dcdcdc"
      position="sticky"
      zIndex="8"
      align="space-between"
      padding="8px 48px"
      dataHook={appPageStickyHeaderDataHooks.APP_PAGE_STICKY}
      className={s.appPageStickyHeader}
    >
      <Box>
        {showBackButton && onBack && (
          <Box
            align="center"
            justifyItems="center"
            verticalAlign="middle"
            marginRight="12px"
            dataHook={
              appPageStickyHeaderDataHooks.APP_STICKY_HEADER_BACK_BUTTON
            }
          >
            <IconButton
              skin="dark"
              priority="tertiary"
              size="tiny"
              onClick={onBack}
            >
              <ArrowLeftSmall />
            </IconButton>
          </Box>
        )}
        <Box marginRight="12px">
          <Image width="42px" height="42px" src={icon} alt="" />
        </Box>
        <Box direction="vertical">
          <Box>
            <Box>
              <Text size="medium" weight="bold">
                {name}
              </Text>
            </Box>
            <Box className={s.hideOnMobile} paddingLeft="12px" gap="6px">
              <Badges
                badges={badges}
                bundle={bundle}
                isInstalled={Boolean(installation?.isInstalled)}
                appName={name ?? ''}
              />
            </Box>
          </Box>
          <Box>
            {company?.name && (
              <Box gap="2px">
                <Text size="small">{t('solution.page.developerName.by')}</Text>
                {shouldBeClickableCompanyName ? (
                  <TextButton
                    onClick={() =>
                      onCompanyClick(company?.name ?? '', company?.slug ?? '')
                    }
                    size="small"
                  >
                    {company?.name}
                  </TextButton>
                ) : (
                  <Text size="small">{company?.name}</Text>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box className={s.hideOnMobile} gap="12px">
        {showAppInfoBadge && (
          <Box verticalAlign="middle">
            <AppInfoBadge
              isAppInstalled={!!installation?.isInstalled}
              isAppBuiltIn={!!installation?.isBuiltIn}
              isAppSetupIncomplete={!!installation?.isSetupIncomplete}
              isBundleApp={!!bundle?.isBundleApp}
              pricing={pricing as PricingBadgeProps}
              options={{
                showPremiumRequired: shouldShowPremiumRequired(
                  !!installation?.requirements?.premiumSite,
                  isPremiumSite,
                ),
              }}
              dataHook={
                appPageStickyHeaderDataHooks.APP_STICKY_HEADER_INFO_BADGE
              }
            />
          </Box>
        )}
        {showCTA && mainActionButton}
      </Box>
    </Box>
  );
}
export const AppPageSticky = withSiteContext(
  withEssentialsContext(AppPageStickyComp),
);
