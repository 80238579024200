import type { TagSection as TagSectionType } from '@wix/ambassador-appmarket-v1-app-market-client-aggregator/types';
import type { AppGroupSection } from './types';

export class TagSection {
  constructor(private readonly tagSection: TagSectionType | undefined) {}

  get appGroup(): AppGroupSection {
    return this.tagSection?.appGroup as AppGroupSection;
  }
}
