import React from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from '../../contexts/translations-context';
import type { AppReviewsSummaryType } from '@wix/app-market-services';

export interface AppPageSEOProps {
  description: string;
  metaTagsAggregator: string[];
  appName: string;
  reviewsSummary: AppReviewsSummaryType;
  icon: string;
}

export function AppPageSEO({
  description,
  metaTagsAggregator,
  appName,
  reviewsSummary,
  icon,
}: AppPageSEOProps) {
  const { t } = useTranslation();

  function trimQuotes(value: string): string {
    return value ? value.replace(/"/g, '') : '';
  }
  if (metaTagsAggregator) {
    metaTagsAggregator.push(
      `<title data-meta-tags-aggregator="true">${t('solution-page.seo.title', {
        appName,
      })}</title>`,
    );
    metaTagsAggregator.push(
      `<meta data-meta-tags-aggregator="true" property="og:title" content="${t(
        'solution-page.seo.title',
        {
          appName,
        },
      )}" />`,
    );
    metaTagsAggregator.push(
      `<meta data-meta-tags-aggregator="true" property="description" content="${description}" />`,
    );
    metaTagsAggregator.push(
      `<meta data-meta-tags-aggregator="true" property="og:description" content="${description}" />`,
    );
  }
  return (
    <Helmet>
      <title>
        {t('solution-page.seo.title', {
          appName,
        })}
      </title>
      <meta name="description" content={description} />
      <meta
        property="og:title"
        content={t('solution-page.seo.title', {
          appName,
        })}
      />
      <meta property="og:description" content={description} />
      <script type="application/ld+json">{`{
              "@context": "https://schema.org/",
              "@type": "SoftwareApplication",
              "name": "${trimQuotes(appName)}",
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "${reviewsSummary.averageRating}",
                "ratingCount": "${reviewsSummary.totalReviews}"
              },
              "applicationCategory": "DeveloperApplication",
              "description": "${trimQuotes(description)}",
              "image": "${icon}",
            }`}</script>
    </Helmet>
  );
}
