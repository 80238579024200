import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { i18nInit } from './i18nInit-browser';
import { useConfigContext } from '../config-context';
import { useExperiments } from '@wix/wix-experiments-react';

export const TranslationsProvider = ({
  children,
  locale,
}: {
  children: any;
  locale?: string;
}) => {
  const { experiments } = useExperiments();
  const configContext = useConfigContext();

  return (
    <I18nextProvider
      i18n={i18nInit({ locale: configContext.locale || locale, experiments })}
    >
      {children}
    </I18nextProvider>
  );
};
