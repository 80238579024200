import s from '../pages.scss';
import {
  SkeletonLine,
  SkeletonRectangle,
  Box,
  SkeletonGroup,
  Page,
} from '@wix/design-system';
import React from 'react';
import { AppGridSkeleton } from '@wix/app-market-components';
import { useUserContext } from '../../contexts';

export function CollectionSkeleton() {
  const { isMobile } = useUserContext();

  return (
    <Page className={`${s.appMarketPage} ${s.appMarketPageBackgroundColor}`}>
      <Page.Content>
        <SkeletonGroup skin="light">
          <Box dataHook="skeleton-header" marginTop="24px" direction="vertical">
            <Box dataHook="back-button-skeleton" marginLeft="4px">
              <SkeletonLine width="50px" />
            </Box>
            <Box
              dataHook="skeleton-titles"
              marginTop="20px"
              direction="vertical"
            >
              <SkeletonRectangle width="175px" height="18px" />
              <SkeletonRectangle width="638px" height="30px" marginTop="6px" />
              <SkeletonLine width="540px" marginTop="12px" />
              <SkeletonLine width="264px" marginTop="4px" />
            </Box>
            <Box dataHook="skeleton-apps-count" marginTop="22px">
              <SkeletonLine width="48px" />
            </Box>
          </Box>
          <Box marginTop="18px">
            <AppGridSkeleton boxCount={24} minColumns={isMobile ? 1 : 3} />
          </Box>
        </SkeletonGroup>
      </Page.Content>
    </Page>
  );
}
