import React from 'react';
import { Text, Box, Popover } from '@wix/design-system';
import { StatusWarningFilledSmall } from '@wix/wix-ui-icons-common';
import { useEssentials } from '../../contexts/essentials-context';

export function SetupIncompleteBadge() {
  const [isPopoverShown, setIsPopoverShown] = React.useState(false);

  const { t } = useEssentials();

  return (
    <Popover
      showArrow={true}
      appendTo="window"
      placement="right"
      theme="dark"
      shown={isPopoverShown}
      onMouseEnter={() => {
        setIsPopoverShown(true);
      }}
      onMouseLeave={() => {
        setIsPopoverShown(false);
      }}
    >
      <Popover.Element>
        <Box verticalAlign="middle">
          <StatusWarningFilledSmall color="#fdb10c" />
          <Box marginLeft="4px">
            <Text skin="standard" secondary={true} weight="thin" size="tiny">
              {t('solutionBox.solutionBottomActionBar.IncompleteSetup')}
            </Text>
          </Box>
        </Box>
      </Popover.Element>
      <Popover.Content>
        <Box padding="12px 24px" maxWidth="102px">
          <Text skin="standard" light weight="thin" size="small">
            {t('solutionBox.solutionBottomActionBar.IncompleteSetupTooltip')}
          </Text>
        </Box>
      </Popover.Content>
    </Popover>
  );
}
