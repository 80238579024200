import React from 'react';
import { Box, Divider, Text } from '@wix/design-system';
import style from './app-promo-box.scss';
import { AppPromotionImage } from './app-promo-box-elements/app-promotion-image';
import type { AppBoxData } from '@wix/ambassador-appmarket-v1-app-market-client-aggregator/types';
import { AppInfoBadge } from '../app-info-badge';
import type { PricingBadgeProps } from '../pricing-badge';
import { appPromoBoxDataHooks } from './data-hooks';
import { useSiteContext } from '../../contexts/site-context';
import { shouldShowPremiumRequired } from '../app-info-badge/app-info-badge.util';

export interface AppPromoBoxProps {
  dataHook: string;
  appData: AppBoxData;
  onClick?(): void;
  href?: string;
}

export function AppPromoBox({
  dataHook,
  appData,
  onClick,
  href,
}: AppPromoBoxProps) {
  const { isPremiumSite } = useSiteContext();
  const {
    icon,
    name,
    shortDescription,
    installation,
    promotionalImage,
    bundle,
    pricing,
    badges,
  } = appData;

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onClick?.();
    }
  };

  return (
    <div data-hook={appPromoBoxDataHooks.container}>
      <div
        onClick={(e) => {
          onClick?.();
          e.preventDefault();
        }}
        className={
          bundle?.isBundleApp || Boolean(badges?.length)
            ? style.promoWithBadge
            : style.promoBox
        }
        data-hook={dataHook}
        tabIndex={0}
        onKeyDown={handleKeyDown}
      >
        <a className={style.link} href={href}>
          <Box direction="vertical">
            <AppPromotionImage
              iconUrl={icon ?? ''}
              appName={name ?? ''}
              promotionalImage={promotionalImage ?? ''}
              isInstalled={installation?.isInstalled ?? false}
              bundle={bundle}
              badges={badges}
            />
            <Divider />
          </Box>
          <Box
            direction="vertical"
            verticalAlign="space-between"
            dataHook="app-details-box"
            padding="18px"
          >
            <Box direction="vertical" paddingTop="6px" height="84px">
              <Text weight="bold" dataHook="app-name">
                {name}
              </Text>
              <Box maxWidth="270px" marginTop="6px">
                <Text size="small" weight="thin">
                  {shortDescription}
                </Text>
              </Box>
            </Box>
            <Box marginTop="6px">
              <AppInfoBadge
                isAppInstalled={!!installation?.isInstalled}
                isAppBuiltIn={!!installation?.isBuiltIn}
                isAppSetupIncomplete={!!installation?.isSetupIncomplete}
                isBundleApp={!!bundle?.isBundleApp}
                pricing={pricing as PricingBadgeProps}
                options={{
                  showPremiumRequired: shouldShowPremiumRequired(
                    !!installation?.requirements?.premiumSite,
                    isPremiumSite,
                  ),
                }}
              />
            </Box>
          </Box>
        </a>
      </div>
    </div>
  );
}
