import React from 'react';
import { SectionHelper, Text } from '@wix/design-system';
import { useTranslation } from '../../../../../contexts/translations-context';
import { HeadlessAppInstalledNotificationDataHooks } from './data-hooks';

export interface HeadlessAppInstalledNotificationProps {
  appName: string;
}

export function HeadlessAppInstalledNotification({
  appName,
}: HeadlessAppInstalledNotificationProps) {
  const { t } = useTranslation();

  return (
    <SectionHelper
      dataHook={HeadlessAppInstalledNotificationDataHooks.container}
      appearance="danger"
      title={t('app.page.installedHeadlessAppHelper.title', {
        appName,
      })}
      size="small"
    >
      <Text
        dataHook={HeadlessAppInstalledNotificationDataHooks.description}
        size="tiny"
        weight="thin"
      >
        {t('app.page.installedHeadlessAppHelper.content')}
      </Text>
    </SectionHelper>
  );
}
