import * as React from 'react';
import { Origin } from '../..';
import s from './marketplace-menu.scss';
import { getMenuDataByPlatform } from '../../api/api';
import { config } from '../../config';
import { composer } from '../../component-decorators/composer';
import { SearchBoxContainer } from '../search-box';
import { WithFetcher } from '../../component-decorators/with-fetcher';
import { Box, Sidebar } from '@wix/design-system';
import { useConfigContext } from '../../config-context';
import type { InjectedExperimentsProps } from '@wix/wix-experiments-react';
import { ManageAppsMenuItem, BackMenuItem } from '../menu-item';
import { MenuSections } from './menu-sections';
import type {
  ContainerResponse,
  MarketApiSection,
} from '@wix/ambassador-marketplace/types';
import type { MarketplaceStore } from '../../component-decorators/with-marketplace-store';
import { SidebarVisibilityToggle } from '../sidebar-visibility-toggle';

export const dtoToMenuProps = (res: ContainerResponse): MarketApiSection[] => {
  return res?.container?.sections;
};

export interface IMarketplaceMenuProps
  extends Partial<InjectedExperimentsProps> {
  marketplaceStore?: MarketplaceStore;
  hideSearchInMenu: boolean;
}

export const MarketplaceMenu = composer()
  .withMarketplaceStore()
  .withExperiments()
  .compose(
    ({
      hideSearchInMenu,
      marketplaceStore,
      experiments,
    }: IMarketplaceMenuProps) => {
      const marketplaceMenuClassName =
        config.origin === Origin.STANDALONE
          ? s.marketplaceMenuSA
          : s.marketplaceMenu;
      const { locale, rpcClient } = useConfigContext();

      return (
        <WithFetcher
          loader={<div></div>}
          initialFetch={() =>
            getMenuDataByPlatform(marketplaceStore.route, locale, rpcClient)
          }
          fetchMemoizationId={`menu-${locale}`}
        >
          {({ fetchData }) => {
            const sections = dtoToMenuProps(fetchData);
            const showManageAppsMenuItem =
              config.origin === Origin.BIZMGR ||
              config.origin === Origin.EDITOR;
            return (
              <div
                data-testid="marketplace-menu"
                className={marketplaceMenuClassName}
              >
                <Sidebar dataHook="side-bar" skin="light">
                  <Sidebar.PersistentHeader>
                    {config.origin === Origin.BIZMGR ? (
                      <Box marginTop="9px">
                        <SidebarVisibilityToggle />
                      </Box>
                    ) : config.goBackToReferrer ? (
                      <BackMenuItem />
                    ) : null}
                    {!hideSearchInMenu && (
                      <Box padding="18px">
                        <SearchBoxContainer
                          isInMenu={true}
                          locale={locale}
                          searchLocation="menu"
                        />
                      </Box>
                    )}
                  </Sidebar.PersistentHeader>
                  <Sidebar.Item>
                    <MenuSections sections={sections} />
                  </Sidebar.Item>
                  {showManageAppsMenuItem && (
                    <Sidebar.PersistentFooter>
                      <ManageAppsMenuItem />
                    </Sidebar.PersistentFooter>
                  )}
                </Sidebar>
              </div>
            );
          }}
        </WithFetcher>
      );
    },
  );
