import React from 'react';
import type { IModalProps } from '../../../../modals/modals';
import { composer } from '../../../../../component-decorators/composer';
import {
  Box,
  Button,
  Divider,
  Modal,
  ModalMobileLayout,
  Text,
  TextButton,
} from '@wix/design-system';
import s from './other-countries.scss';
import type { TFunction } from 'i18next';

interface OtherCountriesModalProps extends IModalProps {
  t?: TFunction;
  subtitle: string;
  closeModal(): void;
  countries: any;
  footerTwo?: string;
  onSupportClick?: any;
}

export const OtherCountriesModal = composer()
  .withTranslation()
  .compose(
    ({
      subtitle,
      closeModal,
      countries,
      footerTwo,
      t,
      onSupportClick,
    }: OtherCountriesModalProps) => {
      return (
        <Modal isOpen={true} zIndex={100000}>
          <ModalMobileLayout
            stickyTitle={true}
            stickyFooter={true}
            fullscreen
            title={
              <Text className={s.otherCountriesTitle} weight="bold">
                {t('solution.page.tagsBoxAvailabilityTitle')}
              </Text>
            }
            content={
              <Box direction="vertical">
                <Text
                  size="small"
                  weight="bold"
                  className={s.countryListSubtitle}
                >
                  {subtitle}
                </Text>
                {countries.map((country) => (
                  <Text size="small" weight="thin" className={s.countryList}>
                    - &nbsp;{country}
                  </Text>
                ))}
                <Divider />
                <Box
                  direction="horizontal"
                  marginTop="18px"
                  verticalAlign="middle"
                >
                  <Text size="tiny">
                    {t('solution.page.tagsBoxAvailabilityPopoverFooter1')}
                  </Text>
                  <TextButton as="a" size="tiny" onClick={onSupportClick}>
                    &nbsp;{footerTwo}
                  </TextButton>
                </Box>
              </Box>
            }
            footer={
              <Box align="right" marginTop="16px">
                <Box marginRight="12px">
                  <Button onClick={closeModal} priority="secondary">
                    {t('solution.page.tagsBoxAvailabilitySecondaryCta')}
                  </Button>
                </Box>
                <Button onClick={closeModal}>
                  {t('solution.page.tagsBoxAvailabilityCta')}
                </Button>
              </Box>
            }
            onCloseButtonClick={closeModal}
          />
        </Modal>
      );
    },
  );
