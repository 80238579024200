import { useState } from 'react';
import type { i18n, TFunction } from 'i18next';

const TRANSLATIONS_NAMESPACE = 'app-market-core';
/**
 *
 * @param i18n i8n instance
 * @returns {{t: TFunction; ready: boolean }}
 * this logic is identical to the logic in translation file in app-market-components,
 * the difference is the namespace
 */

export const useTranslationsLoader = (
  i18n: i18n,
): { t: TFunction; ready: boolean } => {
  const language = i18n.options.lng ?? i18n.language;
  const [ready, setReady] = useState(() =>
    i18n.hasResourceBundle(language, TRANSLATIONS_NAMESPACE),
  );

  if (!i18n.hasResourceBundle(language, TRANSLATIONS_NAMESPACE)) {
    try {
      const messages = require(`../assets/locale/messages_${language}.json`);
      i18n.addResources(language, TRANSLATIONS_NAMESPACE, messages);
      setReady(true);
    } catch (error) {
      console.error('Error loading translations:', error);
    }
  }

  return {
    t: (key, options: any) =>
      i18n.t(key, { ...(options ?? {}), ns: TRANSLATIONS_NAMESPACE }),
    ready,
  };
};
