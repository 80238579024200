import type { AppreviewsReview as AppreviewsReviewType } from '@wix/ambassador-devcenter-v1-review/types';
import { Review } from '../review/review';
import type { ReviewType } from '../types';

export class Reviews {
  constructor(private readonly Reviews: AppreviewsReviewType[]) {}

  findByAppId(appId: string): Review {
    const review = this.Reviews?.find((_review) => _review.appId === appId);
    return new Review(review ?? {});
  }

  toJSON(): ReviewType[] {
    return this.Reviews?.map((_review) => new Review(_review ?? {}).toJSON());
  }
}
