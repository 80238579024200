import type { WebSolutionBase as WebSolutionBaseType } from '@wix/ambassador-devcenter-marketplace-v1-web-solution/types';
import { WebSolutionBase } from './web-solution-base';

export class WebSolutionsBase {
  constructor(private readonly webSolutionsBase: WebSolutionBaseType[]) {}

  get appIds(): string[] {
    return this.webSolutionsBase.map(
      (webSolutionBase) => new WebSolutionBase(webSolutionBase).appId,
    );
  }

  findByAppId(appId: string): WebSolutionBase {
    const webSolutionBase = this.webSolutionsBase.find(
      (_webSolutionBase) => _webSolutionBase.id === appId,
    );
    return new WebSolutionBase(webSolutionBase);
  }
}
