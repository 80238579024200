import React from 'react';
import { Box } from '@wix/design-system';
import { useResizeDetector } from 'react-resize-detector';
import style from './app-box.scss';
import { NarrowAppBox } from '../narrow-app-box';
import { RegularAppBox } from '../regular-app-box';
import { WideAppBox } from '../wide-app-box';
import { useConsumerCallbacks } from '../contexts/consumer-callbacks';
import { useSolution } from '../contexts/solution';
import { useConsumerPreferences } from '../contexts/consumer-preferences';

const appBoxStyles = {
  Grid: style.gridAppBox,
  List: style.listAppBox,
};

export function AppBox() {
  const { width, ref } = useResizeDetector();
  const { onClick, onInstalledAppClick } = useConsumerCallbacks();
  const { id: appId, isInstalled } = useSolution();
  const { skin } = useConsumerPreferences();

  const shouldRenderNarrowLayout = width <= 468;
  const shouldRenderRegularLayout = width > 468 && width <= 828;
  const shouldRenderWideLayout = width > 828;

  function onBoxClick() {
    return isInstalled ? onInstalledAppClick?.(appId) : onClick?.(appId);
  }

  return (
    <div
      data-hook="app-box-container"
      ref={ref}
      onClick={() => onBoxClick()}
      className={style.appBoxContainer}
    >
      <Box className={appBoxStyles[skin]} direction="vertical">
        {shouldRenderNarrowLayout && <NarrowAppBox />}
        {shouldRenderRegularLayout && <RegularAppBox />}
        {shouldRenderWideLayout && <WideAppBox />}
      </Box>
      {skin === 'List' ? <Box className={style.listAppBoxDivider} /> : null}
    </div>
  );
}
