import * as React from 'react';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import { Translation } from 'react-i18next';
import { getI18nInstance } from '../i18next/translations-provider';

export const withTranslation: any = (
  component: React.ComponentType<WithTranslationProps>,
) => {
  return (props) => {
    const i18n = getI18nInstance();
    if (i18n) {
      return React.createElement(component, {
        ...props,
        t: (k, o) => i18n.t(k, o),
      });
    }
    return (
      <Translation>
        {(t) => React.createElement(component, { ...props, t })}
      </Translation>
    );
  };
};
