import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import { composer } from '../../../component-decorators/composer';
import {
  Box,
  Button,
  EmptyState,
  Heading,
  TextButton,
} from '@wix/design-system';
import React from 'react';
import { config } from '../../../config';
import s from '../error-page.scss';
import { ErrorPageType } from '../../..';
import type { MarketplaceStore } from '../../../component-decorators/with-marketplace-store';

export interface INotAvailableAppHeaderProps
  extends Partial<WithTranslationProps> {
  marketplaceStore?: MarketplaceStore;
  appName?: string;
  errorPageType: ErrorPageType;
}

export const errorPageTypeMessagesMap = {
  [ErrorPageType.NOT_FOUND]: {
    titleKey: 'error-page.not-found.title',
    subtitleKey: 'error-page.not-found.subtitle',
  },
  [ErrorPageType.INTERNAL_SERVER]: {
    titleKey: 'error-page.internal-server.title',
    subtitleKey: 'error-page.internal-server.subtitle',
  },
  [ErrorPageType.NOT_AVAILABLE_APP]: {
    titleKey: 'not-available-app.title',
    subtitleKey: 'not-available-app.subtitle',
  },
};

export const ErrorPageHeader = composer()
  .withTranslation()
  .withMarketplaceStore()
  .compose(({ appName, errorPageType, t }: INotAvailableAppHeaderProps) => {
    const { titleKey, subtitleKey } = errorPageTypeMessagesMap[errorPageType];
    return (
      <div data-testid={`${errorPageType}-error-page-header`}>
        <ErrorPageHeaderTitle appName={appName} errorPageType={errorPageType} />
        <EmptyState
          theme="page"
          title={t(titleKey)}
          subtitle={t(subtitleKey, {
            appName,
          })}
          dataHook="error-page-empty-state"
          image={
            <img
              className={s.logo}
              src={`${config.marketplaceStaticsUrl}assets/images/empty-state-generic-no-search-results.svg`}
            />
          }
        >
          <ErrorPageEmptyStateButtons errorPageType={errorPageType} />
        </EmptyState>
      </div>
    );
  });

const ErrorPageHeaderTitle = ({
  appName,
  errorPageType,
}: INotAvailableAppHeaderProps) =>
  appName && errorPageType === ErrorPageType.NOT_AVAILABLE_APP ? (
    <div className={s.appName} data-testid="error-page-app-name.">
      <Heading appearance="H1" dataHook="error-page-app-name">
        {appName}
      </Heading>
    </div>
  ) : (
    <Box height="48px" />
  );

const ErrorPageEmptyStateButtons = composer()
  .withTranslation()
  .compose(({ errorPageType, t }: INotAvailableAppHeaderProps) => {
    return (
      <div className={s.emptyStateButtons}>
        <Button as="a" href="/app-market" dataHook="error-page-button-explore">
          {t('not-available-app.button.explore')}
        </Button>
        {errorPageType === ErrorPageType.INTERNAL_SERVER && (
          <TextButton
            dataHook="error-page-button-contact"
            className={s.contactButton}
            as="a"
            href="https://www.wix.com/support/conversations/category/contact"
          >
            {t('error-page.button.contact')}
          </TextButton>
        )}
      </div>
    );
  });
