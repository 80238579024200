export const getCategoriesByQuery = (catTerm) => {
  const t = escapeRegExp(catTerm);
  const pattern = new RegExp(
    '^' + (t.toLowerCase() as string) + '| ' + (t.toLowerCase() as string),
    'i',
  );
  return allcategories
    .filter((keyword) => {
      return keyword.name.search(pattern) > -1;
    })
    .slice(0, 2);
};

const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

export interface IcategorySearchResult {
  name: string;
  slug: string;
  desc: string;
  index: number;
  svg: string;
}

const allcategories: IcategorySearchResult[] = [
  {
    name: 'Site Design',
    slug: 'site-design',
    desc: 'Visual tools to enrich site design',
    index: 0,
    svg: 'https://static.wixstatic.com/shapes/5305c5_b51338ed0adc47a98045705a55cae5de.svg',
  },
  {
    name: 'Booking & Events',
    slug: 'booking--events',
    desc: 'Apps that help users sell and manage services and events',
    index: 1,
    svg: 'https://static.wixstatic.com/shapes/d24377_50d00aba327f4265ab673bbd386d3d3d.svg',
  },
  {
    name: 'Media',
    slug: 'media',
    desc: 'Apps that let users upload / import and manage media',
    index: 2,
    svg: 'https://static.wixstatic.com/shapes/d24377_bde9e4f42e0d445ba7c6648fee762a4f.svg',
  },
  {
    name: 'Utilities',
    slug: 'utilities',
    desc: "Apps that extend the site's functionality)",
    index: 3,
    svg: 'https://static.wixstatic.com/shapes/d24377_87fc8800ef9341b2b09b2f73788f4e9e.svg',
  },
  {
    name: 'Communication',
    slug: 'communication',
    desc: 'Apps that encourage visitor communication',
    index: 4,
    svg: 'https://static.wixstatic.com/shapes/d24377_52bf439ac9004419a956c17151fdccee.svg',
  },
  {
    name: 'Marketing',
    slug: 'marketing',
    desc: 'Apps that help site owners grow their online presence ',
    index: 5,
    svg: 'https://static.wixstatic.com/shapes/d24377_d1bd9151de72436892fec7737df802da.svg',
  },
  {
    name: 'eCommerce',
    slug: 'ecommerce',
    desc: 'Apps that help users sell products online ',
    index: 6,
    svg: 'https://static.wixstatic.com/shapes/d24377_604057347b38463480db1fdfe1133274.svg',
  },
];
