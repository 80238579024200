import React, { useEffect, useState } from 'react';
import { AppType, RateAndReviewsSection } from '@wix/app-market-components';
import type { ReviewType as Review } from '@wix/app-market-services';
import type { getReviewsSectionResponse } from './reviews-api';
import {
  useEssentials,
  useJunkYard,
  useUserContext,
} from '../../../../contexts';
import { getMarketApp } from '@wix/ambassador-devcenter-market-apps-v1-market-app/http';
import { useReviewsActions } from './reviews-actions';
import { useTranslation } from '../../../../contexts/translations-context';
import type { getAppPageDataResponse } from '../../app-page-api';

interface ReviewsWrapperProps {
  app: getAppPageDataResponse['app'];
  company: getAppPageDataResponse['companyInfo'];
  reviews: getReviewsSectionResponse;
  onChange: (reviews: getReviewsSectionResponse) => void;
}

export function ReviewsSection({
  app,
  company,
  reviews,
  onChange,
}: ReviewsWrapperProps) {
  const [reviewsArray, setReviewsArray] = useState<Review[]>(reviews.reviews);
  const [supportEmails, setSupportEmails] = useState<string[]>([]);
  const [isEligibleForReview, setIsEligibleForReview] =
    useState<boolean>(false);
  const { httpClient, i18n, experiments } = useEssentials();
  const { showUserActionNotification } = useJunkYard();
  const { t } = useTranslation();
  const user = useUserContext();
  const {
    onReFetchReviews,
    onLike,
    onReviewsLoad,
    onReviewLoad,
    onUnlike,
    onSubmit,
    onClose,
    onUserAction,
    onSortChange,
    onReadMoreOrLessClick,
    onContactSupport,
  } = useReviewsActions();

  useEffect(() => {
    const getIsEligibleForReview = async () => {
      if (app.id) {
        httpClient
          .get<{ eligibleForReview: boolean }>(
            `/_serverless/app-market-rpc-proxy/eligible-for-review/${app.id}`,
            { withCredentials: true },
          )
          .then(({ data }) => {
            setIsEligibleForReview(data.eligibleForReview);
          });
      }
    };
    const getContactInfo = async () => {
      httpClient
        .request(
          getMarketApp({
            appId: app.id,
          }),
        )
        .then((res) =>
          setSupportEmails(
            res.data.marketApp?.contactEmails?.supportEmails ?? [],
          ),
        );
    };
    getIsEligibleForReview();
    getContactInfo();
  }, []);

  return (
    <RateAndReviewsSection
      userReview={reviews.userReview}
      reviewsSummary={reviews.reviewsSummary}
      isEligibleForReview={isEligibleForReview}
      isMobile={user.isMobile}
      app={{
        ...app,
        company: { name: company.name, icon: company.image },
      }}
      shouldShowUserActionButton={user?.isLoggedIn ?? false}
      reviews={reviewsArray}
      onSortChange={async (sort: string) => {
        const { reviews: newReviews } = await onReFetchReviews(app.id, sort);
        setReviewsArray(newReviews);
        onSortChange(app.id, sort);
      }}
      onLike={async (reviewId: string, sort: string, limit: number) => {
        await onLike(app.id, reviewId);
        const { reviews: newReviews } = await onReFetchReviews(
          app.id,
          sort,
          limit,
        );
        setReviewsArray(newReviews);
      }}
      onReviewsLoad={async (selectedSort: string, readMoreClicks: number) => {
        const { reviews: newReviews } = await onReviewsLoad(
          app.id,
          selectedSort,
          readMoreClicks,
        );
        setReviewsArray(reviewsArray.concat(newReviews));
      }}
      onUnlike={async (
        reviewId: string,
        opinionId: string,
        sort: string,
        limit: number,
      ) => {
        await onUnlike(app.id, reviewId, opinionId);
        const { reviews: newReviews } = await onReFetchReviews(
          app.id,
          sort,
          limit,
        );
        setReviewsArray(newReviews);
      }}
      onReviewLoad={onReviewLoad}
      onSubmit={async (review: Review) => {
        const { userReview } = await onSubmit(app.id, review);
        const { reviews: newReviews } = await onReFetchReviews(
          app.id,
          'publishedAt',
        );
        showUserActionNotification({
          message: t('review-app.successful-submission-toast'),
          biName: 'review-app',
          type: 'SUCCESS',
        });
        onChange({
          reviews: newReviews,
          userReview,
          reviewsSummary: reviews.reviewsSummary,
        });
      }}
      onClose={onClose}
      onUserAction={onUserAction}
      onReadMoreOrLessClick={(clickType: string) =>
        onReadMoreOrLessClick(app.id, clickType)
      }
      onContactSupport={(location: string) => {
        const supportMails = supportEmails.join(';');
        onContactSupport(
          app.id,
          app.type === AppType.WIX_APP,
          location,
          supportMails,
        );
      }}
      i18n={i18n}
      experiments={experiments}
    />
  );
}
