import React from 'react';
import type { IConfigContext } from '../config-context';
import { ConfigContextConsumer } from '../config-context';

interface IFetcher {
  initialFetch(experiments?): Promise<any>;
  fetchMemoizationId?: string;
  children: any;
}

export interface IWithFetcherProps extends IFetcher {
  loader: React.ReactElement;
}

const getMemo = (fetchMemoizationId, configContext: IConfigContext) =>
  configContext.ssrMemo ? configContext.ssrMemo[fetchMemoizationId] : null;

const setMemo = (
  fetchMemoizationId,
  fetchData,
  configContext: IConfigContext,
) => {
  if (configContext.ssrMemo) {
    configContext.ssrMemo[fetchMemoizationId] = fetchData;
  }
};

const isBrowser = () => typeof window !== 'undefined';

const getFetcher = () => {
  let data: any;
  let promise: Promise<any>;
  let ssrCount = 0;
  return ({ initialFetch }: IFetcher) => {
    if (ssrCount++ > 10) {
      return {};
    }

    if (data) {
      ssrCount = 0;
      return data;
    }

    if (!promise) {
      promise = initialFetch().then((res) => {
        data = res;
      });
    }

    throw promise;
  };
};

interface IFetcherHOCProps extends IFetcher {
  fetcher: any;
  configContext: IConfigContext;
}

class FetcherHOC extends React.Component<IFetcherHOCProps, { fetchData: any }> {
  constructor(props) {
    super(props);
    const { fetcher, initialFetch, fetchMemoizationId, configContext } =
      this.props;
    if (isBrowser()) {
      const memo = getMemo(fetchMemoizationId, configContext);
      this.state = memo
        ? { fetchData: memo }
        : { fetchData: fetcher({ initialFetch }) };
    }
  }

  reFetch(fetch: () => Promise<any>) {
    fetch().then((res) => this.setState({ fetchData: res }));
  }

  render() {
    const {
      fetcher,
      initialFetch,
      fetchMemoizationId,
      children,
      configContext,
    } = this.props;
    const fetchData = isBrowser()
      ? this.state.fetchData
      : fetcher({ initialFetch });
    !isBrowser() && setMemo(fetchMemoizationId, fetchData, configContext);
    return (
      <>
        {children({
          fetchData,
          reFetch: isBrowser() ? this.reFetch.bind(this) : () => {},
        })}
      </>
    );
  }
}

export interface IFetcherInjected {
  fetchData: any;
  reFetch?(fetch: () => Promise<any>): {};
}

export class WithFetcher extends React.Component<IWithFetcherProps> {
  fetcher: any;

  constructor(props) {
    super(props);
    this.fetcher = getFetcher();
  }

  render() {
    const {
      initialFetch,
      fetchMemoizationId = '',
      children,
      loader,
    } = this.props;
    return (
      <ConfigContextConsumer>
        {(configContext) => (
          <React.Suspense fallback={loader}>
            <FetcherHOC
              fetcher={this.fetcher}
              initialFetch={initialFetch}
              fetchMemoizationId={fetchMemoizationId}
              configContext={configContext}
            >
              {children}
            </FetcherHOC>
          </React.Suspense>
        )}
      </ConfigContextConsumer>
    );
  }
}
