import React from 'react';
import { Box, Card, Carousel, Heading, Image, Text } from '@wix/design-system';
import s from './category-carousel.scss';
import type { AppCategory } from '@wix/ambassador-appmarket-v1-app-market-client-aggregator/types';

export interface CategoryCarouselProps {
  title: string;
  categories: Required<AppCategory>[];
  onCategoryClick({ slug }: { slug: string }): void;
  onChange?(currentSlide: number, nextSlide: number): void;
  getHrefByCategory({ slug }: { slug: string }): string | undefined;
}

export function CategoryCarousel({
  categories,
  onCategoryClick,
  title,
  onChange,
  getHrefByCategory,
}: CategoryCarouselProps) {
  return (
    <Box
      gap="24px"
      direction="vertical"
      padding="24px 18px"
      backgroundColor="#f0f4f7"
    >
      <Heading size="large" dataHook="category-carousel-title">
        {title}
      </Heading>
      <Carousel
        className={s.categoryCarousel}
        controlsSize="small"
        variableWidth={true}
        beforeChange={onChange}
        dataHook="category-carousel"
      >
        {categories.map((category) => {
          return (
            <a
              key={category.slug}
              // data-testid={`category-${category.slug}`}
              href={getHrefByCategory({ slug: category.slug })}
              onClick={(e) => {
                onCategoryClick({ slug: category.slug });
                e.preventDefault();
              }}
              className={s.link}
            >
              <Card className={s.categoryCard} dataHook="carousel-card">
                <Card.Content>
                  <Box align="space-between" height="114px">
                    <Box gap="6px" direction="vertical" width="196px">
                      <Text
                        size="medium"
                        weight="bold"
                        dataHook="category-name"
                      >
                        {category.name}
                      </Text>
                      <Text
                        size="small"
                        weight="thin"
                        dataHook="category-description"
                      >
                        {category.description}
                      </Text>
                    </Box>
                    <Box height="96px" width="96px">
                      <Image
                        alt={`${category.name} category icon`}
                        src={category.iconUrl}
                        transparent
                      />
                    </Box>
                  </Box>
                </Card.Content>
              </Card>
            </a>
          );
        })}
      </Carousel>
    </Box>
  );
}
