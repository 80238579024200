import React from 'react';
import { Text } from '@wix/design-system';
import { useSolution } from '../contexts/solution';

export function AppName() {
  const solution = useSolution();
  const { name } = solution;

  return (
    <Text size="medium" weight="bold">
      {name}
    </Text>
  );
}
