import * as React from 'react';
import { config } from '../../config';
import { ErrorPageContainer } from '../error-page/error-page-container';
import { ErrorPageType } from '../..';

export interface IErrorBoundryProps {
  fallback?: React.ReactNode;
  handleClick?(): void;
}

export class ErrorBoundry extends React.Component<IErrorBoundryProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      isError: false,
    };
  }

  componentDidCatch(error) {
    this.setState({ isError: true });
    config.sentryClient && config.sentryClient.captureException(error);
  }
  componentDidUpdate(prevProps) {
    prevProps !== this.props && this.setState({ isError: false });
  }
  render() {
    if (this.state.isError) {
      return (
        this.props.fallback || (
          <ErrorPageContainer errorPageType={ErrorPageType.INTERNAL_SERVER} />
        )
      );
    }
    return this.props.children;
  }
}
