import * as React from 'react';
import style from './solution-details.scss';
import { composer } from '../../../../../component-decorators/composer';
import { Text, Box } from '@wix/design-system';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';

interface ISolutionDetailsProps extends Partial<WithTranslationProps> {
  teaser: string;
  name: string;
  isWixOffering: boolean;
  companyName?: string;
  experiments?: any;
}

export const SolutionDetails = composer()
  .withTranslation()
  .compose(
    ({
      teaser,
      name,
      isWixOffering,
      t,
      companyName,
    }: ISolutionDetailsProps) => {
      const subtitle =
        !teaser && isWixOffering
          ? t('solutionBox.wixOffering.description')
          : teaser;

      return (
        <div className={style.solutionDetail}>
          <Box className={style.titleCompany} direction="vertical">
            <Text weight="bold">{name}</Text>
          </Box>
          <Box maxWidth="260px" marginTop="6px">
            <Text
              dataHook="solution-teaser"
              size="small"
              weight="thin"
              className={style.solutionTeaser}
            >
              {subtitle}
            </Text>
          </Box>
        </div>
      );
    },
  );
