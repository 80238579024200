import React, { useState, useEffect } from 'react';
import type { IConfigContext } from './config-context';
import { ConfigContextProvider, useConfigContext } from './config-context';
import { TranslationsProvider } from './i18next';
import type { IRoute } from './types/common/marketplace';
import type { StatusCode } from './enums/marketplace-enums';
import { Origin, Path } from './enums/marketplace-enums';
import { ExperimentsProvider } from '@wix/wix-experiments-react';
import { BiProvider, biLogger } from './bi';
import { MarketplaceLayout } from './components/marketplace-layout';
import { setConfig } from './config';
import s from './marketplace.scss';
import { configureScope } from '@sentry/browser';
import type { AppMarketRpcClient } from './types/app-market-rpc-client';
import type { ExperimentsBag } from '@wix/wix-experiments';
import type { MarketplaceStore } from './component-decorators/with-marketplace-store';
import {
  MarketplaceStoreContext,
  MarketplaceStoreProvider,
} from './component-decorators/with-marketplace-store';
import type { BundleWebSolution } from '@wix/ambassador-marketplace/types';
import type { ManagedApp } from '@wix/ambassador-devcenter-managedapps-v1-managed-app/types';

import {
  fetchBundleApps,
  fetchCurrentBundleApps,
  fetchSiteData,
  getPrivateAppsTagId,
} from './api/api';
import type { IFetcherInjected } from './component-decorators/with-fetcher';
import { WithFetcher } from './component-decorators/with-fetcher';
import { CenteredLoader } from './components/centered-loader';
import { StandaloneHeader } from './components/standalone-header';
import { Modals } from './components/modals/modals';
import { MobileMenu } from './components/mobile-menu';
import { MobileSearchBox } from './components/search-box/mobile-search-box';
import type { i18n as i18nType } from 'i18next';
import { getPathForBi, getReferralInfo } from './common/bi-events';
import { composer } from './component-decorators/composer';
import { getInstalledApps } from './common/utils';
import { WixDesignSystemProvider } from '@wix/design-system';
import type { IAppInstallParams } from '@wix/installation-manager-client-sdk';
import { marketOpened } from '@wix/bi-logger-market/v2';
import type { BiLogger } from '@wix/app-market-core';
import { JunkYardContextProvider } from '@wix/app-market-core';

export interface IMarketplaceProps {
  metaSiteId?: string;
  route: IRoute;
  locale: string;
  sendAppLoadedEvent?(): void;
  goto(IRoute): {} | void;

  baseApiUrl: string;
  origin: Origin;
  marketplaceStaticsUrl: string;
  manageAppsStaticsUrl?: string;
  baseUrl?: string;

  goToHomePage?(): {} | void;

  goToManageApps?(): {} | void;

  goToWixOffering?(
    pageComponentId: string,
    appState: string,
    text: string,
    buttonText: string,
  ): {} | void;
  goToExploreHeadlessApps?(): {} | void;
  openBMInEditor?(path: string): {} | void;
  openHelpCenter?(): void;

  goBackToReferrer?: {
    navigation(): {} | void;
    labelKey: string;
    referrerNameForBI: string;
  } | null;

  finishAppInstall?(appDefId: string, appVersion: string, options?: any): void;

  installApp(IAppInstallParams): {} | void;

  openApp?(app: any, options?: any): Promise<{} | void>;

  manageAppsMainButtonAction?(
    app: ManagedApp | BundleWebSolution,
    bundleApps: BundleWebSolution[],
    onInstallationSuccessCb: IAppInstallParams['onInstallationSuccess'],
  ): Promise<{} | void>;

  experimentsBag?: ExperimentsBag;

  getAppInstance?(appDefID: string): string;

  closeMarket?(): {} | void;

  sentryClient?: any;
  ssrMemo?: any;
  editorUrl?: string;
  showHeader?: boolean;
  hideSearchInMenu?: boolean;

  isAppOnStage?(appDefinitionId: string): Promise<boolean>;

  editorType?: string;

  saveSite?(): Promise<string>;

  isMobile?: boolean;
  isBusinessFirstSite?: boolean;
  i18n?: i18nType;
  metaTagsAggregator?: string[];
  countryCode?: string;

  showUserActionNotification?(INotificationsOptions);

  shouldUseSupportModal?: boolean;
  userName?: string;
  userImage?: string;

  unInstallApp?(appDefinitionId: string): Promise<any>;

  responseCode?(status: StatusCode): void;

  openPackagePicker?(
    app: ManagedApp,
    ppOrigin?: string,
    isAppInBundle?: boolean,
  ): void;

  hideBusinessSolutionsInManageApps?: boolean;
  rpcClient?: AppMarketRpcClient;
  showSidebar?(): void;
  hideSidebar?(): void;
  // The props below is part of new market infra:
  biLogger?: BiLogger;
}

const dataFetcher = async (metaSiteId, signedInstance, rpcClient) => {
  const [siteData, bundleApps, currentBundleApps, privateAppsTagId] =
    await Promise.all([
      fetchSiteData(metaSiteId),
      fetchBundleApps(signedInstance),
      fetchCurrentBundleApps(signedInstance),
      getPrivateAppsTagId(rpcClient),
    ]);

  return {
    siteData,
    bundleApps,
    currentBundleApps,
    privateAppsTagId,
  };
};

export class Marketplace extends React.Component<IMarketplaceProps, any> {
  constructor(props) {
    super(props);

    const {
      goto,
      baseApiUrl,
      origin,
      marketplaceStaticsUrl,
      goToHomePage,
      goToManageApps,
      metaSiteId,
      goBackToReferrer,
      installApp,
      editorUrl,
      editorType,
      manageAppsMainButtonAction,
      manageAppsStaticsUrl,
      sentryClient,
    } = props;

    biLogger.updateDefaults({
      msid: metaSiteId,
      market:
        this.props.origin === Origin.BIZMGR ? 'dashboard' : this.props.origin,
    });

    configureScope((scope) => {
      scope.setTag('component', 'marketplace-component');
    });

    setConfig({
      goto,
      baseApiUrl,
      origin,
      marketplaceStaticsUrl,
      goToHomePage,
      goToManageApps,
      goBackToReferrer,
      installApp,
      editorUrl,
      editorType,
      manageAppsMainButtonAction,
      manageAppsStaticsUrl,
      sentryClient,
    });
  }

  componentDidMount() {
    const { route } = this.props;

    this.props.sendAppLoadedEvent?.();

    if (this.props.goToExploreHeadlessApps) {
      this.props.goToExploreHeadlessApps();
    }

    biLogger.report(
      marketOpened({
        tag_name: route.slug || route.query || 'homepage',
        tag_type: getPathForBi(route.path),
        referral_info: getReferralInfo(route.referral),
      }),
    );
  }

  render() {
    const {
      showHeader,
      hideSearchInMenu,
      experimentsBag,
      metaSiteId,
      locale,
      i18n,
      ssrMemo,
      baseUrl,
      route,
      metaTagsAggregator,
      isMobile,
      isAppOnStage,
      closeMarket,
      finishAppInstall,
      openApp,
      getAppInstance,
      countryCode,
      showUserActionNotification,
      shouldUseSupportModal,
      unInstallApp,
      responseCode,
      openPackagePicker,
      userName,
      hideBusinessSolutionsInManageApps,
      userImage,
      origin,
      rpcClient,
      showSidebar,
      hideSidebar,
      goToWixOffering,
      openBMInEditor,
      openHelpCenter,
      isBusinessFirstSite,
      goto,
      goBackToReferrer,
    } = this.props;
    const shouldHideMenu = route.path === Path.SHARE;
    const currentRoute = shouldHideMenu ? { ...route, hideMenu: true } : route;
    const configContext: IConfigContext = {
      locale,
      i18n,
      ssrMemo,
      baseUrl,
      route,
      metaTagsAggregator,
      isMobile,
      isBusinessFirstSite,
      isAppOnStage,
      closeMarket,
      finishAppInstall,
      openApp,
      getAppInstance,
      goToWixOffering,
      countryCode,
      showUserActionNotification,
      shouldUseSupportModal,
      unInstallApp,
      responseCode,
      openPackagePicker,
      rpcClient,
      showSidebar,
      hideSidebar,
      openBMInEditor,
      openHelpCenter,
    };

    return (
      <div className={s.marketplaceRoot} data-hook="marketplace">
        <ConfigContextProvider value={configContext}>
          <TranslationsProvider>
            <ExperimentsProvider options={{ experiments: experimentsBag }}>
              <MarketplaceStoreProvider
                hideBusinessSolutionsInManageApps={
                  hideBusinessSolutionsInManageApps
                }
                userImage={userImage}
                userName={userName}
                metaSiteId={metaSiteId}
                prevRoute={null}
                route={currentRoute}
              >
                <BiProvider value={biLogger}>
                  <MarketplaceStoreContext.Consumer>
                    {(marketplaceStore) => (
                      <JunkYardContextProvider
                        junkYard={{
                          goto,
                          routerData: {
                            route: marketplaceStore.route,
                            prevRoute: marketplaceStore.prevRoute,
                          },
                          goBackToReferrer,
                          showUserActionNotification,
                          ssrMemo,
                          openApp,
                        }}
                      >
                        <MarketplaceComponent
                          key="marketplaceComponent"
                          showHeader={showHeader}
                          hideSearchInMenu={hideSearchInMenu}
                          msId={metaSiteId}
                          route={route}
                          origin={origin}
                          rpcClient={rpcClient}
                        />
                      </JunkYardContextProvider>
                    )}
                  </MarketplaceStoreContext.Consumer>
                </BiProvider>
              </MarketplaceStoreProvider>
            </ExperimentsProvider>
          </TranslationsProvider>
        </ConfigContextProvider>
      </div>
    );
  }
}

export interface IMarketplaceComponentProps extends IFetcherInjected {
  marketplaceStore: MarketplaceStore;
  showHeader?: boolean;
  hideSearchInMenu?: boolean;
  msId?: string;
  t?: any;
  signedInstance: string;
  locale: string;
}

export const MarketplaceComponentBase = composer()
  .withMarketplaceStore()
  .compose(
    ({
      marketplaceStore,
      fetchData,
      showHeader,
      hideSearchInMenu,
      signedInstance,
      locale,
    }: IMarketplaceComponentProps) => {
      const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
      const [isSearchMenuOpen, setIsSearchMenuOpen] = useState(false);

      const shouldHaveOverlay = () => isMobileMenuOpen || isSearchMenuOpen;

      useEffect(() => {
        marketplaceStore.setSiteIsPremium(fetchData?.siteData?.siteIsPremium);
        marketplaceStore.setSiteHasDomain(fetchData?.siteData?.siteIsPremium);
        marketplaceStore.setBundleApps(fetchData?.bundleApps || []);
        marketplaceStore.setCurrentBundleApps(
          fetchData?.currentBundleApps || [],
        );
        marketplaceStore.setPrivateAppsTagId(fetchData?.privateAppsTagId);
        getInstalledApps(signedInstance, locale).then((apps) => {
          marketplaceStore.setInstalledApps(apps);
        });
      }, []);

      return (
        <>
          <Modals />
          {showHeader && (
            <StandaloneHeader
              toggleSearchBox={() => setIsSearchMenuOpen(!isSearchMenuOpen)}
              toggleMenu={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            />
          )}
          {showHeader && (
            <>
              <MobileMenu
                toggleMenu={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                isMenuOpen={isMobileMenuOpen}
              />
              <MobileSearchBox
                toggleSearchBox={() => setIsSearchMenuOpen(!isSearchMenuOpen)}
                isSearchMenuOpen={isSearchMenuOpen}
              />
              {shouldHaveOverlay() && (
                <div
                  className={s.overlay}
                  onClick={() => {
                    isMobileMenuOpen && setIsMobileMenuOpen(false);
                    isSearchMenuOpen && setIsSearchMenuOpen(false);
                  }}
                />
              )}
            </>
          )}
          <MarketplaceLayout hideSearchInMenu={hideSearchInMenu} />
        </>
      );
    },
  );

export const MarketplaceComponent = composer()
  .withExperiments()
  .withMarketplaceStore()
  .compose(
    ({
      showHeader,
      hideSearchInMenu,
      msId,
      experiments,
      route,
      origin,
      marketplaceStore,
      rpcClient,
      locale,
    }) => {
      const { getAppInstance } = useConfigContext();
      const signedInstance = getAppInstance
        ? getAppInstance('22bef345-3c5b-4c18-b782-74d4085112ff')
        : undefined;

      useEffect(() => {
        marketplaceStore.setMetaSiteId(msId);
        marketplaceStore.setRoute(route);
        biLogger.updateDefaults({
          msid: msId,
          market: origin === Origin.BIZMGR ? 'dashboard' : origin,
        });
      }, [route, msId, origin]);

      return (
        <WithFetcher
          loader={<CenteredLoader />}
          initialFetch={() => dataFetcher(msId, signedInstance, rpcClient)}
          fetchMemoizationId="marketplaceComponent"
        >
          {({ fetchData, reFetch }) => (
            <WixDesignSystemProvider
              features={{ newColorsBranding: true }}
              as="span"
            >
              <MarketplaceComponentBase
                key="marketplaceComponent"
                showHeader={showHeader}
                hideSearchInMenu={hideSearchInMenu}
                msId={msId}
                fetchData={fetchData}
                reFetch={reFetch}
                signedInstance={signedInstance}
                locale={locale}
              />
            </WixDesignSystemProvider>
          )}
        </WithFetcher>
      );
    },
  );
