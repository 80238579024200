import React from 'react';

export default function HomePageHeaderImage() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="300"
      height="200"
      viewBox="0 0 300 200"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#192C55"
          d="M105,90 L63,90 C56.373,90 51,84.627 51,78 L51,36 C51,29.373 56.373,24 63,24 L105,24 C111.627,24 117,29.373 117,36 L117,78 C117,84.627 111.627,90 105,90"
        />
        <path
          fill="#FFD527"
          d="M223.3355,108 C230.189703,108 235.525882,113.519469 235.330712,120.326852 L235.3185,120.628 L232.5955,172.628 C232.272706,178.783714 227.04443,183.837269 220.909064,183.996148 L220.6115,184 L168.6445,184 C161.790297,184 156.454118,178.480531 156.649288,171.673148 L156.6615,171.372 L159.3845,119.372 C159.707294,113.216286 164.934601,108.162731 171.07089,108.003852 L171.3685,108 L223.3355,108 Z M83.5728,39.9996 C92.2408,39.9996 99.2688,47.0276 99.2688,55.6956 C99.2688,64.2588805 92.4111694,71.2199656 83.8891805,71.3884746 L83.5728,71.3916 C74.9038,71.3916 67.8768,64.3646 67.8768,55.6956 C67.8768,47.1333073 74.7334548,40.1712585 83.2563838,40.0027259 L83.5728,39.9996 Z"
        />
        <path
          fill="#28BF9A"
          d="M237.2764,93 L180.2914,93 C173.4564,93 168.0594,87.412 168.2984,80.581 L170.2884,23.581 C170.5114,17.211 175.9074,12 182.2814,12 L239.2674,12 C246.1014,12 251.4984,17.588 251.2594,24.419 L249.2694,81.419 C249.0464,87.789 243.6504,93 237.2764,93"
        />
        <polygon
          fill="#192C55"
          points="223 23 223 24 233.798 24 179.229 59.08 179.771 59.92 234.577 24.687 231.026 35.342 231.974 35.658 236.194 23"
        />
        <path
          fill="#FFF"
          d="M222,160 L222,164 L167.982,164 L167.982,160 L222,160 Z M195.2399,125.559122 C200.4499,125.5502 205.2999,127.0992 209.4399,129.7402 L202.1299,144.5702 C201.8399,144.3302 201.5399,144.0992 201.2299,143.9002 C199.4999,142.7402 197.4399,142.0802 195.2399,142.0802 C189.0999,142.0992 184.1199,147.3092 184.1199,153.7192 C184.1199,153.8002 184.1199,153.8892 184.1299,153.9692 L168.0599,154.0002 C168.0599,146.1602 171.0999,139.0592 176.0199,133.9102 C177.5299,132.3402 179.2299,130.9692 181.0499,129.8002 C185.1899,127.1392 190.0299,125.5702 195.2399,125.559122 Z M231.7341,47 L230.2981,83 L216.1231,83 L202.0441,79.818 L201.9191,83 L187.7311,83 L188.2831,69 L202.4711,69 L202.2201,75.356 L216.6131,70.729 L217.5601,47 L231.7341,47 Z M97.262,50.902 C100.564,50.902 103.701,51.604 106.522,52.883 C109.177059,54.0877059 111.563716,55.779609 113.556083,57.8561634 L113.925,58.25 C115.520625,59.9903333 117.697599,61.0482934 120.044018,61.0612642 L120.351,61.057 L120.901,61.057 C125.745,61.057 130.134,63.024 133.299,66.189 C135.086294,67.9631176 136.482945,70.1269931 137.363896,72.5438984 L137.523,73 L57,73 C59.3012742,66.1630806 65.6788052,61.2186054 73.2487585,61.0608848 L73.622,61.057 L74.172,61.057 C76.532375,61.1355833 78.7540712,60.1534115 80.3882192,58.4731004 L80.598,58.25 C84.726,53.736 90.657,50.902 97.262,50.902 Z"
        />
        <path
          fill="#28BF9A"
          d="M206.3599,153.9297 L206.3599,153.6697 C206.3599,149.9807 204.7099,146.6907 202.1299,144.5707 C201.8399,144.3297 201.5399,144.0997 201.2299,143.9007 L209.4399,129.7407 C211.2599,130.9097 212.9599,132.2697 214.4599,133.8397 C219.3799,138.9597 222.4299,146.0497 222.4299,153.8897 L206.3599,153.9297 Z"
        />
        <path
          fill="#C2E2FF"
          d="M61.6256,130 C65.0426,130 67.7416,132.794 67.6216,136.209 L66.9936,154.209 C66.8816,157.395 64.1836,160 60.9966,160 L43.0036,160 C39.5866,160 36.8886,157.206 37.0076,153.791 L37.6366,135.791 C37.7476,132.605 40.4466,130 43.6326,130 L61.6256,130 Z M167.0811,47.9189 C174.708614,47.9189 180.911427,54.0186392 181.077678,61.6062544 L181.0811,61.9189 L181.0811,129.9999 C181.0811,137.627414 174.981361,143.830227 167.393746,143.996478 L167.0811,143.9999 L99.0001,143.9999 C91.3725865,143.9999 85.1697728,137.900161 85.003522,130.312546 L85.0001,129.9999 L85.0001,61.9189 C85.0001,54.2913865 91.0998392,48.0885728 98.6874544,47.922322 L99.0001,47.9189 L167.0811,47.9189 Z M268.0004,116.0001 C270.8474,116.0001 273.0964,118.3281 272.9974,121.1751 L272.6074,132.3341 C272.5144,134.9871 270.2654,137.1591 267.6104,137.1591 L256.4574,137.1591 C253.6094,137.1591 251.3604,134.8311 251.4604,131.9841 L251.8494,120.8251 C251.9424,118.1721 254.1914,116.0001 256.8464,116.0001 L268.0004,116.0001 Z"
        />
        <path
          fill="#FFF"
          d="M91.7129,88.0215 L109.7089,60.8985 L138.0589,79.6405 L157.9749,107.0255 L168.0069,120.5975 C170.0399,123.3355 170.1499,127.2315 168.2909,130.4485 L166.7579,133.0965 L91.7129,88.0215 Z"
        />
        <path
          fill="#192C55"
          d="M166.7583,133.0966 L148.6483,121.0796 L157.9743,107.0256 L168.0073,120.5976 C170.0393,123.3356 170.1503,127.2316 168.2913,130.4486 L166.7583,133.0966 Z"
        />
        <path
          fill="#192C55"
          d="M166.3745,134.1982 C156.1275,129.6402 146.2975,124.2392 136.9695,118.0502 L91.7125,88.0212 L94.0675,84.4722 L139.3245,114.5012 C148.5175,120.6002 158.2025,125.9282 168.2915,130.4482 L166.3745,134.1982 Z"
        />
        <path
          fill="#FFF"
          d="M168.0864,130.9043 C157.9874,126.3803 148.2174,121.0013 139.0474,114.9183 L93.7914,84.8893 L94.3434,84.0563 L139.6004,114.0843 C148.7244,120.1373 158.4454,125.4903 168.4954,129.9923 L168.0864,130.9043 Z"
        />
        <path
          fill="#116DFF"
          d="M115.8693,76.2848 L127.8583,87.8128 L128.3413,116.4658 L104.6083,116.9348 L104.1243,88.2808 L115.8693,76.2848 Z M217.1535,57 L216.1235,83 L201.9185,83 L202.9485,57 L217.1535,57 Z"
        />
        <path
          fill="#FFF"
          d="M115.8696,83.3676 L115.8696,83.3676 C117.6666,83.3316 119.1526,84.7606 119.1876,86.5566 C119.2236,88.3536 117.7956,89.8396 115.9986,89.8746 C114.2016,89.9096 112.7156,88.4826 112.6806,86.6856 C112.6446,84.8886 114.0726,83.4026 115.8696,83.3676"
        />
        <path
          fill="#192C55"
          d="M196.7527,149.4908 L213.9547,141.1628 L199.5117,153.0488 L199.5317,153.1408 C199.8237,155.6128 198.1417,157.8688 195.7727,158.1798 C193.4047,158.4888 191.2467,156.7368 190.9547,154.2628 C190.6617,151.7898 192.3447,149.5338 194.7137,149.2248 C195.341075,149.141675 195.947012,149.218566 196.512755,149.404558 L196.7527,149.4908 Z M153.1902,99.3656 L153.5322,100.3046 L146.1982,102.9716 L145.8562,102.0326 L153.1902,99.3656 Z M141.1793,92.6576 L148.7283,92.7446 L148.7163,93.7446 L141.1673,93.6576 L141.1793,92.6576 Z M136.4921,83.344 L144.2551,86.184 L143.9111,87.123 L136.1481,84.283 L136.4921,83.344 Z M112.3707,56.8871 L135.2627,73.1841 L137.3367,77.9741 L139.7307,79.6536 L139.1567,80.4716 L137.5267,79.3281 L116.9127,67.2721 L116.7329,86.4262 L115.7329,86.4162 L115.9187,66.6901 L108.6767,62.4551 L112.3707,56.8871 Z"
        />
      </g>
    </svg>
  );
}
