import { Box, Heading } from '@wix/design-system';
import * as React from 'react';
import HomePageHeaderImage from '../../../assets/images/home-page-header';
import { SearchBox } from '@wix/app-market-components';
import { useEssentials } from '../../../contexts/essentials-context';
import { useSearchBoxActions } from '../../../components/search-box/hooks/search-box-actions';
import { AppMarketPage } from '../../../enums';
import { useTranslation } from '../../../contexts/translations-context';
import s from './header.scss';

export function HomePageHeader() {
  const { i18n, experiments } = useEssentials();
  const { t } = useTranslation();
  const { onSelect, onChange, onFocus, onEnterPressed } = useSearchBoxActions(
    AppMarketPage.HOMEPAGE,
    'home',
  );

  return (
    <Box marginTop="24px" align="space-between">
      <Box className={s.header} direction="vertical" gap="6px" maxWidth="718px">
        <Box maxWidth="570px" marginTop="18px">
          <Heading size="extraLarge" dataHook="page-title">
            {t('home.page.title')}
          </Heading>
        </Box>
        <Box marginTop="12px">
          <SearchBox
            onChange={onChange}
            onSelect={onSelect}
            onEnterPressed={onEnterPressed}
            onFocus={onFocus}
            i18n={i18n}
            experiments={experiments}
            size="large"
          />
        </Box>
      </Box>
      <HomePageHeaderImage />
    </Box>
  );
}
