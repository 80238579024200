import React from 'react';
import type { IUserContext } from '../models/user-context';
import type { FunctionComponent, ReactNode } from 'react';

const UserContext = React.createContext<IUserContext | null>(null);

export const UserContextProvider: FunctionComponent<{
  user: IUserContext;
  children: ReactNode;
}> = ({ user, children }) => (
  <UserContext.Provider value={user}>{children}</UserContext.Provider>
);

export const useUserContext = () => {
  const userContext = React.useContext(UserContext);
  if (userContext === null) {
    throw new Error('useUserContext must be used within a UserContextProvider');
  }
  return userContext;
};
