import type { SitePremiumStatus as SitePremiumStatusType } from '@wix/ambassador-premium-v1-asset/types';
import { SitePremiumStatus } from './site-premium-status';

export class SitesPremiumStatus {
  constructor(private readonly sitesPremiumStatus: SitePremiumStatusType[]) {}

  findByMetaSiteId(metaSiteId: string): SitePremiumStatus {
    const sitePremiumStatus = this.sitesPremiumStatus.find(
      (_sitePremiumStatus) => _sitePremiumStatus.metaSiteId === metaSiteId,
    );
    return new SitePremiumStatus(sitePremiumStatus);
  }
}
