import React from 'react';
import { Heading, Box } from '@wix/design-system';

export interface SubHeaderProps {
  title: string;
  suffix?: any;
}

export function SubHeader({ title, suffix = null }: SubHeaderProps) {
  return (
    <Box align="space-between" gap="10px" paddingBottom="12px">
      <Heading size="extraTiny">{title}</Heading>
      {suffix}
    </Box>
  );
}
