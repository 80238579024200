import { Path } from '../../enums/marketplace-enums';
import { composer } from '../../component-decorators/composer';
import { SidebarDivider, SidebarSectionTitle } from '@wix/design-system';
import { MenuItem } from '../menu-item';
import * as React from 'react';
import type { MarketApiSection } from '@wix/ambassador-marketplace/types';
import type { InjectedExperimentsProps } from '@wix/wix-experiments-react';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';

interface IMenuSectionProps
  extends MarketApiSection,
    Partial<InjectedExperimentsProps>,
    Partial<WithTranslationProps> {
  menuIndex?: number;
}

function getPathByType(sectionType: string): Path {
  switch (sectionType) {
    case 'categoriesList':
      return Path.CATEGORY;
    case 'collectionsList':
      return Path.COLLECTION;
    default:
      return Path.HOME;
  }
}

export const MenuSection = composer().compose(
  ({ id, type, sectionData, menuIndex }: IMenuSectionProps) => {
    const { displayName, tagsToWebSolutions } = sectionData?.tagData;
    return (
      <>
        <SidebarDivider />
        <SidebarSectionTitle dataHook={`${id}-menu-section`}>
          {displayName}
        </SidebarSectionTitle>
        {tagsToWebSolutions?.map((menuItemData, itemIndex) => {
          const { name, slug } = menuItemData.tag;
          const path = getPathByType(type);
          const props = {
            itemIndex,
            // index: itemIndex + menuIndex,
            name,
            slug,
            path,
          };
          return <MenuItem {...props} />;
        })}
      </>
    );
  },
);
