import {  APPS_LIMIT, SortType, AppGroupType } from '@wix/app-market-services';
import type { AppGroupSection, IServices } from '@wix/app-market-services';


export interface SubCategoryPageData {
  tagId: string;
  tagType: string;
  slug: string;
  parentSlug: string;
  parentName: string;
  primaryTitle: string;
  secondaryTitle?: string;
  description?: string;
  appGroup: AppGroupSection;
  paging: {
    total: number;
    hasNext: boolean;
  };
}

export async function getApps({
  services,
  tagId,
  offset,
}: {
  services: IServices;
  tagId: string;
  offset: number;
}) {
  const tagApps = await services.getAppsByTag({
    tagId,
    sortTypes: [SortType.COUNTRY_CODE, SortType.APP_MARKET_SCORE],
    paging: {
      limit: APPS_LIMIT,
      offset,
    },
  });
  const appsData = await services.getAppsByAppIds({
    appIds: tagApps.appIds,
    status: 'PUBLISHED',
  });

  return {
    apps: appsData.toJSON(),
    hasNext: tagApps.paging.hasNext,
  };
}

export async function getSubCategoryPageData({
  slug,
  parentSlug,
  services,
  languageCode,
}: {
  services: IServices;
  slug: string;
  parentSlug: string;
  languageCode: string;
}): Promise<SubCategoryPageData> {
  const [tags, parentTags] = await Promise.all([
    services.queryTag({ slugs: [slug], languageCode }),
    services.queryTag({ slugs: [parentSlug], languageCode }),
  ]);
  const tag = tags.findBySlug(slug);
  const parentTag = parentTags.findBySlug(parentSlug);
  const tagId = tag?.id;

  const tagApps = await services.getAppsByTag({
    tagId,
    sortTypes: [SortType.COUNTRY_CODE, SortType.APP_MARKET_SCORE],
    paging: {
      limit: APPS_LIMIT,
      offset: 0,
    },
  });
  const appsData = await services.getAppsByAppIds({
    appIds: tagApps.appIds,
    status: 'PUBLISHED',
  });
  const apps = appsData.toJSON();

  return {
    tagType: tag.type,
    tagId,
    slug,
    parentSlug,
    parentName: parentTag.name,
    primaryTitle: tag.name,
    secondaryTitle: tag.collectionTeaser,
    description: tag.description,
    appGroup: {
      type: AppGroupType.COLLECTION,
      tagSlug: slug,
      apps: apps as AppGroupSection['apps'],
      title: '',
      totalAppsCount: 0,
    },
    paging: {
      total: tagApps.paging.total,
      hasNext: tagApps.paging.hasNext,
    },
  };
}
