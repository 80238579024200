import type { InputStatusError, starRatingBarValue } from '@wix/design-system';
import { useState } from 'react';
import type { ReviewType as Review } from '@wix/app-market-services';
import { useEssentials } from '../../../contexts/essentials-context';

const EMPTY_REVIEW_DATA: Review = {
  id: '',
  rate: 5,
  title: '',
  description: '',
  revision: '',
  userName: '',
  replies: [],
  totalLikes: 0,
  isDeleted: false,
};

interface IReviewFormInstance {
  errorKey: string;
  rate: number;
  setRate(rate: starRatingBarValue): void;
  title: string;
  setTitle(title: string): void;
  titleStatus?: InputStatusError;
  updateTitleStatus(inputValue: string): void;
  description: string;
  setDescription(description: string): void;
  updateError(
    errorKey: string,
    titleStatus: InputStatusError | undefined,
  ): void;
  submit(): Promise<void>;
}

function useReviewForm(
  review: Review = EMPTY_REVIEW_DATA,
  onSubmitAction: (review: Review) => Promise<void>,
): IReviewFormInstance {
  const {
    id,
    rate: initialRate,
    title: initialTitle,
    description: initialDescription,
    revision,
  } = review;

  const [rate, setRate] = useState<number>(initialRate ?? 5);
  const [title, setTitle] = useState<string>(initialTitle ?? '');
  const [description, setDescription] = useState<string>(
    initialDescription ?? '',
  );
  const [errorKey, setErrorKey] = useState<string>('');
  const [titleStatus, setTitleStatus] = useState<InputStatusError | undefined>(
    undefined,
  );

  const { experiments } = useEssentials();
  const enableOptionalReviewTitle = experiments?.enabled(
    'specs.marketplace.OptionalTitleReviewModal',
  );

  function updateTitleStatus(inputValue: string) {
    if (inputValue) {
      setTitleStatus(undefined);
    } else {
      setTitleStatus('error');
    }
  }

  function updateError(
    errorKey: string,
    titleStatus: InputStatusError | undefined,
  ) {
    setErrorKey(errorKey);
    setTitleStatus(titleStatus);
  }

  async function submit() {
    const reviewData: Review = {
      ...review,
      id,
      rate,
      title: enableOptionalReviewTitle && title === '' ? ' ' : title,
      description,
      revision,
    };

    if (
      !enableOptionalReviewTitle &&
      (!reviewData.title || reviewData.title === ' ')
    ) {
      updateError('review-app.error-notification-missing-fields', 'error');
      return;
    }
    updateError('', undefined);

    try {
      await onSubmitAction(reviewData);
    } catch (error: any) {
      if (error?.response?.status === 412) {
        setErrorKey(
          'review-app.error-notification-user-is-ineligible-to-review',
        );
      } else {
        setErrorKey('review-app.error-notification-server-error');
      }
    }
  }

  return {
    errorKey,
    rate,
    setRate,
    title,
    setTitle,
    titleStatus,
    updateTitleStatus,
    description,
    setDescription,
    updateError,
    submit,
  };
}

export { IReviewFormInstance, useReviewForm };
