import * as React from 'react';
import { SearchInput } from './search-input';
import { config } from '../../config';
import { Path } from '../../enums/marketplace-enums';
import type { IWithFocusIndicationInjectedProps } from '../../component-decorators/with-focus-indication';
import { WithFocusIndication } from '../../component-decorators/with-focus-indication';
import type { MarketplaceStore } from '../../component-decorators/with-marketplace-store';
import { withMarketplaceStore } from '../../component-decorators/with-marketplace-store';
import { withBiLogger } from '../../component-decorators/with-bi-logger';
import type { BiInjectedProps as InjectedBiLoggerProps } from '../../bi';
import { biLogger } from '../../bi';
import ReactDOM from 'react-dom';
import { getAutocompleteAppsByQuery } from '../../api/api';
import { getCategoriesByQuery } from './search-suggestions-container/static-data/categories-searches';
import { defaultSuggestions as defaultSuggestionsData } from './search-suggestions-container/static-data/default-suggestions';
import { getPathForBi } from '../../common/bi-events';
import { withTranslation } from '../../component-decorators/with-translation';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import { SuggestionType } from '../../types/common/marketplace';
import {
  appMarketAppImpression,
  searchSearchFocus,
  searchSearchClick,
} from '@wix/bi-logger-market/v2';

export interface IMobileSearchContainerProps
  extends Partial<IWithFocusIndicationInjectedProps>,
    Partial<InjectedBiLoggerProps>,
    Partial<WithTranslationProps> {
  marketplaceStore?: MarketplaceStore;
  isInMenu: boolean;
  isExpandable?: boolean;
  updateSuggestions(suggestions: any): void;
  upadteSearchValue(v: string): void;
  locale: string;
  toggleSearchBox(): void;
}

interface IMobileSearchContainerState {
  value: string;
  isLoading: boolean;
  suggestionsList: any;
  fetchedData: boolean;
  clearedSearchAtDate: number;
}

@withTranslation
@withBiLogger
@withMarketplaceStore
@WithFocusIndication({ init: false })
export class MobileSearchContainer extends React.Component<
  IMobileSearchContainerProps,
  IMobileSearchContainerState
> {
  constructor(props) {
    super(props);
    this.state = {
      fetchedData: false,
      value: '',
      isLoading: false,
      suggestionsList: this.fetchDefaultSuggestions(),
      clearedSearchAtDate: Date.now(),
    };
    this.onSearchInputValueChange = this.onSearchInputValueChange.bind(this);
    this.addAndRemoveSelectedNode = this.addAndRemoveSelectedNode.bind(this);
    this.searchInputOnclickHandler = this.searchInputOnclickHandler.bind(this);
    this.clearSearchInput = this.clearSearchInput.bind(this);
    this.sendSearchSearchClickBi = this.sendSearchSearchClickBi.bind(this);
    this.onSearchInputEnter = this.onSearchInputEnter.bind(this);
  }

  componentDidUpdate(
    prevProps: Readonly<IMobileSearchContainerProps>,
    prevState: Readonly<IMobileSearchContainerState>,
  ) {
    const { marketplaceStore } = this.props;

    if (
      prevProps.marketplaceStore.clearSearchBeforeDate !==
      marketplaceStore.clearSearchBeforeDate
    ) {
      this.clearSearchInput();
    }

    const { suggestionsList } = this.state;
    if (prevState.suggestionsList !== suggestionsList) {
      this.setScrollPositions(suggestionsList);
    }
  }

  fetchDefaultSuggestions() {
    const defaultSuggestions = defaultSuggestionsData.map((item, index) => {
      return { ...item, selected: false, index };
    });
    this.props.updateSuggestions(defaultSuggestions);
    return defaultSuggestions;
  }

  clearSearchInput() {
    this.setState({
      value: '',
      clearedSearchAtDate: Date.now(),
      suggestionsList: this.fetchDefaultSuggestions(),
    });
  }

  setScrollPositions(suggestionsList) {
    const node = ReactDOM.findDOMNode(this) as HTMLElement;
    const suggestionsElements = node.querySelectorAll('.suggestion');
    suggestionsElements.forEach((el: HTMLElement, index) => {
      const offset =
        el.offsetParent.clientHeight - (el.offsetTop + el.clientHeight);
      const scrollPosition = offset >= 0 ? 0 : offset * -1;
      suggestionsList[index] = { ...suggestionsList[index], scrollPosition };
    });
    return suggestionsList;
  }

  async onSearchInputValueChange(value) {
    const { marketplaceStore, biLogger, setFocus } = this.props;
    setFocus(true);
    this.props.upadteSearchValue(value);
    this.setState({ isLoading: true, value });
    let suggestionsList;
    if (value === '') {
      suggestionsList = this.fetchDefaultSuggestions();
    } else {
      suggestionsList = await this.fetchData(this.props.t(value));
      suggestionsList.forEach((item) => {
        if (item.suggestionType === SuggestionType.APP) {
          biLogger.report(
            appMarketAppImpression({
              app_id: item.id,
              market: config.originForBI,
              msid: marketplaceStore.metaSiteId,
              impression_type: 'search',
              section: 'app_suggestion',
              tag_type: 'search_bar',
              search_term: value,
            }),
          );
        }
      });
    }
    this.props.updateSuggestions(suggestionsList);
    this.setState({
      suggestionsList,
      fetchedData: true,
      value,
      isLoading: false,
    });
  }

  addAndRemoveSelectedNode(newIndex) {
    const { suggestionsList } = this.state;
    const updatedList = suggestionsList.map((item, index) => {
      if (item.selected) {
        return { ...item, selected: false };
      }

      return item;
    });
    if (newIndex !== null && newIndex !== undefined) {
      updatedList[newIndex] = { ...updatedList[newIndex], selected: true };
    }
    this.props.updateSuggestions(updatedList);
    this.setState({ suggestionsList: updatedList });
  }

  setScrollPosition(suggestionsList, index) {
    const node = ReactDOM.findDOMNode(this) as HTMLElement;
    const suggestionsContainer = node.querySelector('.suggestionsContainer');
    if (suggestionsContainer) {
      suggestionsContainer.scrollTop = suggestionsList[index].scrollPosition;
    }
  }

  async fetchData(value) {
    const autocompleteSuggestions = await getAutocompleteAppsByQuery(
      value,
      this.props.locale,
    );
    let apps = autocompleteSuggestions.suggestions;
    apps = apps.map((app) => {
      return { ...app, suggestionType: SuggestionType.APP, selected: false };
    });
    let categories = getCategoriesByQuery(value);
    categories = categories.map((category) => {
      return {
        ...category,
        suggestionType: SuggestionType.CATEGORY,
        selected: false,
      };
    });
    const popularSearchesResponse = autocompleteSuggestions.autocomplete;
    const popularSearches = popularSearchesResponse.map((popular) => ({
      name: popular,
      suggestionType: SuggestionType.SEARCH,
      selected: false,
    }));
    return [...popularSearches, ...apps, ...categories].map(
      (suggestion, index) => {
        return { ...suggestion, index };
      },
    );
  }

  searchInputOnclickHandler() {
    const { setFocus, biLogger, marketplaceStore } = this.props;
    biLogger.report(
      searchSearchFocus({
        market: config.originForBI,
        msid: marketplaceStore.metaSiteId,
        search_location: this.props.isInMenu ? 'sidebar_search' : 'main_search',
      }),
    );
    setFocus(true);
  }

  sendSearchSearchClickBi(
    search_term: string,
    search_type: string,
    index?: number,
  ) {
    const { marketplaceStore, isInMenu } = this.props;
    biLogger.report(
      searchSearchClick({
        market: config.originForBI,
        search_term,
        msid: marketplaceStore.metaSiteId,
        search_type,
        search_location: isInMenu ? 'sidebar_search' : 'main_search',
        index,
        tag_type: getPathForBi(this.props.marketplaceStore.route.path),
      }),
    );
  }

  onSearchInputEnter(value) {
    const node = ReactDOM.findDOMNode(this) as HTMLElement;
    const selectedSuggestion = node.querySelector('.selected') as HTMLElement;
    if (selectedSuggestion) {
      selectedSuggestion.click();
    } else {
      this.sendSearchSearchClickBi(value, 'direct');
      this.props.setFocus(false);
      this.props.toggleSearchBox();
      config.goto({ path: Path.SEARCH, query: value });
    }
  }

  render() {
    const { value } = this.state;
    return (
      <div data-testid="search-box-container" style={{ width: 'fit-content' }}>
        <div onClick={this.searchInputOnclickHandler}>
          <SearchInput
            searchValue={value}
            onSearchValueChange={this.onSearchInputValueChange}
            isLoading={this.state.isLoading}
            isInMenu={this.props.isInMenu}
            isExpandable={this.props.isExpandable}
            onSearchInputEnter={this.onSearchInputEnter}
          />
        </div>
      </div>
    );
  }
}
