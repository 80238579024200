import * as React from 'react';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import { composer } from '../../../../../component-decorators/composer';
import { SolutionBadge } from '../../../../solution-badge/index';
import { Box } from '@wix/design-system';
import type { InjectedExperimentsProps } from '@wix/wix-experiments-react';
import { SolutionReviewsInfo } from '../solution-reviews-info';
import type { Pricing } from '@wix/ambassador-marketplace/types';

interface ISolutionBottomActionBarProps
  extends Partial<WithTranslationProps>,
    Partial<InjectedExperimentsProps> {
  isInstalled?: boolean;
  pricing: Pricing;
  showPremiumLabel?: boolean;
  reviews?: { numberOfReviews: number; reviewStars: number };
  isBundleAppInstalled?: boolean;
  isBundleAppNotInstalled?: boolean;
  isAppSetupIncomplete?: boolean;
  savedPrice?: number;
  hideRate?: boolean;
  companyId?: string;
  isPreInstalled?: boolean;
}

export const SolutionBottomAction = composer()
  .withTranslation()
  .withExperiments()
  .compose(
    ({
      t,
      isInstalled,
      pricing,
      showPremiumLabel,
      reviews,
      isBundleAppInstalled,
      isBundleAppNotInstalled,
      savedPrice,
      hideRate,
      isAppSetupIncomplete,
      companyId,
      isPreInstalled,
    }: ISolutionBottomActionBarProps) => {
      return (
        <Box align="space-between" verticalAlign="bottom">
          <Box marginRight="6px">
            <SolutionBadge
              t={(k, o) => t(k, o)}
              isInstalled={isInstalled}
              showPremiumLabel={showPremiumLabel}
              pricing={pricing}
              isBundleAppInstalled={isBundleAppInstalled}
              isBundleAppNotInstalled={isBundleAppNotInstalled}
              isAppSetupIncomplete={isAppSetupIncomplete}
              savedPrice={savedPrice}
              companyId={companyId}
              isPreInstalled={isPreInstalled}
            />
          </Box>

          {!hideRate && <SolutionReviewsInfo reviews={reviews} />}
        </Box>
      );
    },
  );
