import React from 'react';
import { Box, Text, Heading } from '@wix/design-system';
import s from './styles/pricing-plans.scss';

interface IPlanPriceProps {
  decodedSign: string;
  price: string;
  period?: string;
  isMobile: boolean;
  index: number;
}

const getPriceParts = (
  price: string,
): { amountPart1: string; amountPart2: string } => {
  const divider = price?.includes('.') ? '.' : ',';
  const priceParts = price?.split(divider);
  const part1 = priceParts?.[0] || '0';
  const part2 = priceParts?.[1] || '00';
  return { amountPart1: part1, amountPart2: part2 };
};

export function PlanPrice({
  decodedSign,
  price,
  period,
  isMobile,
  index,
}: IPlanPriceProps) {
  const { amountPart1, amountPart2 } = getPriceParts(price);
  return (
    <Box marginTop={isMobile ? '2px' : '6px'} height="23px">
      <Box marginRight="4px">
        <Text size="medium">{decodedSign}</Text>
      </Box>
      <Heading
        size="large"
        className={s.planPricingBoxSmallDynamicNew}
        dataHook={`plan-${index}-price-number-part-1`}
      >
        {amountPart1}
      </Heading>
      <Box marginTop={isMobile ? '3px' : '1px'} marginLeft="2px">
        <Text
          className={s.planPricingBoxBigDynamicSecondPart}
          weight={isMobile ? 'bold' : 'normal'}
          size="tiny"
          dataHook={`plan-${index}-price-number-part-2`}
        >
          {amountPart2}
        </Text>
      </Box>
      <Box
        marginBottom="3px"
        marginTop={isMobile ? '0' : '2px'}
        marginRight="10px"
        marginLeft="2px"
        align="center"
      >
        {period && (
          <Text
            weight={isMobile ? 'bold' : 'normal'}
            size={isMobile ? 'small' : 'medium'}
          >
            {` /${period}`}
          </Text>
        )}
      </Box>
    </Box>
  );
}
