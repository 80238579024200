import {
  AppGroup,
  IncompleteSetupReason,
} from '@wix/ambassador-marketplace/types';
import type { ManagedApp } from '@wix/ambassador-devcenter-managedapps-v1-managed-app/types';

function isAppPendingConfiguration(app?: ManagedApp): boolean {
  if (!app) {
    return false;
  }
  const isHybridAppPending = app.installationDetails.group === AppGroup.PENDING;

  const isSetupIncomplete =
    app.installationDetails.incompleteSetupReason ===
    IncompleteSetupReason.PENDING_CONFIGURATION;

  return isSetupIncomplete && !isHybridAppPending;
}

export { isAppPendingConfiguration };
