import React, { useEffect, useState } from 'react';
import style from './solution-grid.scss';
import { SolutionBox } from './solution-box';
import { TranslationsProvider } from '../../../i18next';
import type { i18n as i18nType } from 'i18next';
import { composer } from '../../../component-decorators/composer';
import { Layout, Cell } from '@wix/design-system';
import { SolutionBoxCompact } from './solution-box-compact';
import { useMediaQuery } from 'react-responsive';
import type { InjectedExperimentsProps } from '@wix/wix-experiments-react';
import type { ISolutionBiData, WebSolutionExtended } from '../../../types';
import { SolutionGridLayout } from '../../../types';

export interface ISolutionGridProps extends Partial<InjectedExperimentsProps> {
  solutions: WebSolutionExtended[];
  solutionsBiData?: ISolutionBiData[];
  isCompany?: boolean;
  layout?: SolutionGridLayout;
  locale?: string;
  colSpan?: number;

  onBoxClick?(solution: WebSolutionExtended): void;

  onButtonClick?(solution: WebSolutionExtended): void;

  onBoxRender?(): void;

  as?: 'link';
  i18n?: i18nType;
  hideCTA?: boolean;
  numberOfLines?: number;
  hideRate?: boolean;
  hideTagsBadge?: boolean;
  editorType?: string;
}

export const SolutionGrid = composer()
  .withExperiments()
  .compose(
    ({
      solutions,
      solutionsBiData,
      layout,
      locale,
      onBoxClick,
      onButtonClick,
      onBoxRender,
      as,
      i18n,
      hideCTA,
      numberOfLines,
      hideRate,
      experiments,
      hideTagsBadge,
      editorType,
    }: ISolutionGridProps) => {
      const rowHeight = 238;
      const widgetHeight = rowHeight * numberOfLines;
      const bigScreen = useMediaQuery({
        query: `(min-width: ${
          editorType === 'EDITORX' || editorType === 'STUDIO'
            ? '1634'
            : editorType === 'EDITOR'
            ? '1800'
            : '1484'
        }px)`,
      });
      const inMobile = useMediaQuery({ query: '(max-width: 600px)' });

      const [isBigScreen, setIsBigScreen] = useState(true);
      const [isInMobile, setIsInMobile] = useState(false);
      const [shouldSetHeight, setShouldSetHeight] = useState(false);
      const [isLoading, setIsLoading] = useState(true);

      useEffect(() => {
        setIsBigScreen(bigScreen);
        setIsInMobile(inMobile);
        setShouldSetHeight(numberOfLines ? numberOfLines && !inMobile : false);
        setIsLoading(false);
      }, [bigScreen, inMobile, numberOfLines]);
      return (
        <TranslationsProvider i18n={i18n} locale={locale}>
          <div
            style={{
              height: shouldSetHeight ? widgetHeight : 'auto',
              overflow: 'hidden',
              paddingTop: '15px',
              visibility: isLoading ? 'hidden' : 'unset',
              marginRight: '-12px',
              marginLeft: '-12px',
              paddingLeft: '12px',
              paddingRight: '12px',
            }}
          >
            <Layout gap="24px" cols={12}>
              {solutions.map((solution: WebSolutionExtended, index: number) => (
                <Cell
                  span={isInMobile ? 12 : isBigScreen ? 3 : 4}
                  rows={1}
                  key={solution.id}
                >
                  <SolutionBoxCompact
                    as={as}
                    to={solution.to}
                    solution={solution}
                    solutionBiData={solutionsBiData && solutionsBiData[index]}
                    layout={layout || SolutionGridLayout.MARKETPLACE}
                    onBoxClick={onBoxClick}
                    onButtonClick={onButtonClick}
                    onBoxRender={onBoxRender}
                    isInstalled={solution.isInstalled}
                    showPremiumLabel={solution.showPremiumLabel}
                    isBundleAppNotInstalled={solution.isBundleAppNotInstalled}
                    isBundleAppInstalled={solution.isBundleAppInstalled}
                    isAppSetupIncomplete={solution.isAppSetupIncomplete}
                    savedPrice={solution.savedPrice}
                    isVoucherApp={solution.isVoucherApp}
                    hideCTA={hideCTA}
                    hideRate={hideRate}
                    hideTagsBadge={hideTagsBadge}
                  />
                </Cell>
              ))}
            </Layout>
          </div>
        </TranslationsProvider>
      );
    },
  );
