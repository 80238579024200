export const pricingPlansSectionDataHooks = {
  sectionContainer: 'pricing-plans-section-container',
  sectionTitle: 'pricing-plans-section-title',
  sectionSubTitle: 'pricing-plans-section-subtitle',
  externalPricingLinkButton: 'external-pricing-link-button',
  pricingPlansToggleContainer: 'pricing-plans-toggle-container',
  pricingToggleYearly: 'pricing-toggle-yearly',
  pricingToggleMonthly: 'pricing-toggle-monthly',
  planBox: 'plan-box-container',
  planBoxTitle: 'plan-box-title',
  planBoxPriceTitle: 'plan-box-price-title',
  planPriceNumber: 'plan-price-number',
  pricingPlansDisclaimer: 'pricing-plans-disclaimer',
};

export const getPlanBoxPriceTitleDataHook = (index: number) => {
  return `plan-box-${index}-price-title`;
};

export const getPlanBoxPriceNumberDataHook = (index: number, part: 1 | 2) => {
  return `plan-${index}-price-number-part-${part}`;
};
