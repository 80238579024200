import type { Plan as PlanType } from '@wix/ambassador-appmarket-v1-app-plans/types';
import type { IAppPlan, MeterdBilling } from './types';
import { CycleType, DurationUnit } from './types';

/**
 *
 * Represents a facade for Plan type on the app-plans service.
 *
 * @class AppPlan
 */

export class AppPlan {
  constructor(
    private readonly plan: PlanType,
    private readonly showPriceWithTax: boolean,
  ) {}
  get vendorId(): string {
    return this.plan.vendorId ?? '';
  }

  get meterdBilling(): MeterdBilling | undefined {
    let meterdBilling;
    const usageBaseOptions =
      this.plan?.prices?.find((price) => !!price.usageBaseOptions)
        ?.usageBaseOptions ?? '';
    if (usageBaseOptions) {
      meterdBilling = {
        outline: usageBaseOptions.customChargeDescription ?? '',
        baseFee: usageBaseOptions.monthlyBaseFee ?? '',
      };
    }
    return meterdBilling;
  }

  get yearlyPrice(): string | undefined {
    const price = this.plan?.prices?.find(
      (_price) =>
        _price.billingCycle?.cycleDuration?.unit === DurationUnit.YEAR &&
        _price.billingCycle?.cycleType === CycleType.RECURRING,
    );
    return this.showPriceWithTax ? price?.totalPrice : price?.priceBeforeTax;
  }

  get monthlyPrice(): string | undefined {
    const price = this.plan?.prices?.find(
      (_price) =>
        _price.billingCycle?.cycleDuration?.unit === DurationUnit.MONTH &&
        _price.billingCycle?.cycleType === CycleType.RECURRING,
    );
    return this.showPriceWithTax ? price?.totalPrice : price?.priceBeforeTax;
  }

  get oneTimePrice(): string | undefined {
    const price = this.plan?.prices?.find(
      (_price) => _price.billingCycle?.cycleType === CycleType.ONE_TIME,
    );
    return this.showPriceWithTax ? price?.totalPrice : price?.priceBeforeTax;
  }

  toJSON(): IAppPlan {
    return {
      meterdBilling: this.meterdBilling,
      yearlyPrice: this.yearlyPrice,
      monthlyPrice: this.monthlyPrice,
      oneTimePrice: this.oneTimePrice,
      vendorId: this.vendorId,
    };
  }
}
