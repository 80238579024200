import { useEffect } from 'react';
import { firstAnonymous, pageView } from '@wix/bi-logger-pblc/v2';
import { useMarketplaceStore } from '../component-decorators/with-marketplace-store';
import { config } from '../config';
import { Origin } from '../enums/marketplace-enums';
import { v4 as uuidv4 } from 'uuid';
import webBiLogger from '@wix/web-bi-logger';

const biLogger = webBiLogger.factory().logger();

export function usePageViewEvent() {
  const marketplaceStore = useMarketplaceStore();

  useEffect(() => {
    if (config.origin === Origin.STANDALONE && isBrowser()) {
      if (!isWixClientIdCookieSet()) {
        biLogger.report(
          firstAnonymous({
            app_url: window.location.origin + window.location.pathname,
            referral:
              location.href === marketplaceStore.prevRoute?.currentUrl
                ? encodeURIComponent(document.referrer) || undefined
                : marketplaceStore.prevRoute?.currentUrl,
            // @ts-expect-error
            _artifact_name: 'app_market',
            _referer: location.href,
            utm_campaign: new URL(window.location.href).searchParams.get(
              'utm_campaign',
            ),
            experiment_id: new URL(window.location.href).searchParams.get(
              'experiment_id',
            ),
          }),
        );
      } else {
        biLogger.report(
          pageView({
            app_url: window.location.origin + window.location.pathname,
            referral:
              location.href === marketplaceStore.prevRoute?.currentUrl
                ? encodeURIComponent(document.referrer) || undefined
                : marketplaceStore.prevRoute?.currentUrl,
            // @ts-expect-error
            _artifact_name: 'app_market',
            _referer: location.href,
            utm_campaign: new URL(window.location.href).searchParams.get(
              'utm_campaign',
            ),
            experiment_id: new URL(window.location.href).searchParams.get(
              'experiment_id',
            ),
          }),
        );
      }

      if (!isWixClientIdCookieSet()) {
        setWixClientIdCookie();
      }
    }
  }, [
    marketplaceStore.route?.path,
    marketplaceStore.route?.slug,
    marketplaceStore.route?.subCategories?.toString(),
    marketplaceStore.route?.query,
  ]);
}

// ------------------------------HELPER-FUNCTIONS------------------------------

function isBrowser() {
  return typeof window !== 'undefined';
}

function isWixClientIdCookieSet() {
  const cookies = document.cookie.split('; ');
  const wixClientIdCookie =
    cookies.find((cookie) => cookie.startsWith('_wixCIDX=')) ?? '_wixCIDX=';
  const wixClientIdCookieValue = wixClientIdCookie.replace('_wixCIDX=', '');
  return wixClientIdCookieValue.length > 0;
}

function setWixClientIdCookie() {
  const HALF_YEAR_IN_MILLISECONDS = 6 * 30 * 24 * 60 * 60 * 1000;
  const expirationDate = new Date(Date.now() + HALF_YEAR_IN_MILLISECONDS);
  const wixCIDXCookie = `_wixCIDX=${uuidv4()}; expires=${expirationDate.toUTCString()}; path=/; domain=.wix.com`;
  document.cookie = wixCIDXCookie;
}
