import i18next from 'i18next';
import abTranslate from 'ab-translate/dist/src/abTranslate-runtime';

interface II18nInitProps {
  locale: string;
  experiments?: any;
  baseUrl?: string;
}

export const i18nInit = ({ locale, experiments = {} }: II18nInitProps) => {
  const instance = i18next.createInstance();
  instance.use({
    type: 'backend',
    read: (
      language: string,
      _namespace: string,
      callback: (
        err: any | null,
        translations?: Record<string, string>,
      ) => void,
    ) => {
      return import(`../locales/messages_${language}.json`)
        .then((translation: Record<string, string>) =>
          callback(null, abTranslate(experiments.experiments, translation)),
        )
        .catch((error) => callback(error));
    },
  });
  instance
    .init({
      lng: locale,
      fallbackLng: 'en',
      keySeparator: false,
      react: {
        wait: true,
      },
      interpolation: { escapeValue: false },
    })
    .catch(console.error);
  return instance;
};
