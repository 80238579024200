import type { AppreviewsReview as AppreviewsReviewType } from '@wix/ambassador-devcenter-v1-review/types';
import type { ReviewType } from '../types';

export class Review {
  constructor(private readonly Review: AppreviewsReviewType) {}
  toJSON(): ReviewType {
    return {
      id: this.Review.id ?? '',
      title: this.Review.title ?? ' ',
      description: this.Review.description ?? '',
      userName: this.Review.author?.userName ?? '',
      createdAt: this.Review.createdAt,
      rate: this.Review.rating ?? 5,
      replies:
        this.Review.replies?.map((reply) => {
          return {
            id: reply.id ?? '',
            description: reply.description ?? '',
            userName: reply.createdBy?.userName ?? '',
          };
        }) ?? [],
      totalLikes: this.Review.totalLikes ?? 0,
      userOpinion: {
        id: this.Review.userOpinion?.id ?? '',
      },
      revision: this.Review.revision ?? '0',
      isDeleted: this.Review.unpublishedAt ? true : false,
    };
  }
}
