import type {
  TagApp as TagAppType,
  PagingMetadata as pagingData,
} from '@wix/ambassador-devcenter-appmarket-v1-tag-app/types';
import { TagApp } from './tag-app';
import { PagingMetadata } from '../common/paging-metadata';

export class TagApps {
  constructor(
    private readonly tagApps: TagAppType[] | undefined,
    private readonly pagingMetadata?: pagingData | undefined,
  ) {}

  get tagIds(): string[] {
    return this.tagApps?.map((tagApp) => new TagApp(tagApp).tagId) || [];
  }
  get appIds(): string[] {
    return this.tagApps?.map((tagApp) => new TagApp(tagApp).appId) || [];
  }

  get paging(): PagingMetadata {
    return new PagingMetadata(this.pagingMetadata);
  }
}
