import React from 'react';
import { Box } from '@wix/design-system';
import { ReviewsInfo } from '../reviews-info';
import { CTAButton } from '../cta-button';
import { AppIcon } from '../app-icon';
import { AppName } from '../app-name';
import { AppDeveloper } from '../app-developer';
import { AppTeaser } from '../app-teaser';
import { TopBadge } from '../top-badge';
import { useConsumerPreferences } from '../contexts/consumer-preferences';
import { PricingBadge } from '../pricing-badge';
import { InstalledBadge } from '../installed-badge';
import { useSolution } from '../contexts/solution';

export function NarrowAppBox() {
  const {
    showViewAppButton,
    showDeveloperName,
    showRating,
    showPricing,
    showTopBadge,
    skin,
  } = useConsumerPreferences();

  const { isBundleAppInstalled, isInstalled } = useSolution();
  const appTeaserMarginBottom = skin === 'List' ? '12px' : '24px';

  return (
    <Box width="100%" height="100%" direction="vertical" align="space-between">
      <Box direction="vertical">
        <Box marginBottom="6px" align="space-between">
          <AppIcon />
          {showTopBadge ? <TopBadge /> : null}
        </Box>
        <Box direction="vertical">
          <Box direction="vertical">
            <AppName />
            {showDeveloperName && <AppDeveloper />}
          </Box>
          <Box marginTop="6px" marginBottom={appTeaserMarginBottom}>
            <AppTeaser />
          </Box>
        </Box>
      </Box>
      <Box
        align="space-between"
        verticalAlign={showViewAppButton ? 'bottom' : 'middle'}
      >
        <Box direction="vertical" gap="6px">
          {showRating && showViewAppButton && <ReviewsInfo />}
          {isBundleAppInstalled || isInstalled ? (
            <InstalledBadge />
          ) : (
            showPricing && <PricingBadge />
          )}
        </Box>

        {!showViewAppButton ? showRating && <ReviewsInfo /> : <CTAButton />}
      </Box>
    </Box>
  );
}
