import type { AppComponents as AppComponentsType } from '@wix/ambassador-devcenter-app-components-v1-app-components/types';
import type { IComponent, ComponentType } from './types';

/**
 * Represents a facade for GetAppComponentsByAppIdsResponse type on the app-components service.
 *
 * @class AppComponents
 */
export class AppComponents {
  constructor(private readonly appComponents: AppComponentsType | undefined) {}

  get id(): string {
    return this.appComponents?.appId ?? '';
  }

  get components(): IComponent[] {
    return (
      this.appComponents?.components?.map((component) => ({
        compType: component.compType as ComponentType,
      })) ?? []
    );
  }
}
