import React from 'react';
import { FavoriteFilledSmall } from '@wix/wix-ui-icons-common';
import { Text, Box } from '@wix/design-system';
import type { AppReviews } from '@wix/ambassador-appmarket-v1-app-market-client-aggregator/types';

export function AppReviewsInfo({ reviews }: { reviews: AppReviews }) {
  return (
    <Box dataHook="reviews-info" verticalAlign="middle" height="18px" gap="4px">
      <FavoriteFilledSmall
        color={reviews.totalCount === 0 ? 'rgba(22, 45, 61, 0.2)' : '#FDB10C'}
      />
      <Box gap="5px" verticalAlign="middle">
        <Text data-hook="average-rating" size="tiny" weight="bold">
          {Number(reviews.averageRating).toFixed(1)}
        </Text>
        <Text
          data-hook="total-reviews"
          size="tiny"
          weight="thin"
          secondary
        >{`(${reviews.totalCount})`}</Text>
      </Box>
    </Box>
  );
}
