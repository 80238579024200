import * as React from 'react';
import style from './solution-box.scss';
import { SolutionTopSection } from './solution-top-section';
import { SolutionDetails } from './solution-details';
import { SolutionBottomAction } from './solution-bottom-action-bar';
import {
  sendSolutionImpressionBi,
  sendOpenSolutionPageBi,
  sendAddClick,
  sendOpenClick,
} from '../../../../bi/events';
import {
  getAppBadgeTheme,
  getAppBadgeTitle,
  getAppBadgeTooltipContent,
} from '../../../common';
import { VisibilityDetector } from '../../../../utils/visibility-detector';
import { getClientImageURL } from '../../../common/getClientImageURL';
import { Box } from '@wix/design-system';
import type { InjectedExperimentsProps } from '@wix/wix-experiments-react';
import { withExperiments } from '../../../../component-decorators/with-experiments';
import type {
  ISolutionBiData,
  SolutionGridLayout,
  WebSolutionExtended,
} from '../../../../types';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';

export interface ISolutionBoxProps
  extends Partial<InjectedExperimentsProps>,
    Partial<WithTranslationProps> {
  as?: 'link';
  to?: string;
  solution: WebSolutionExtended;
  solutionBiData?: ISolutionBiData;
  isCompany?: boolean;
  layout?: SolutionGridLayout;

  onBoxClick?(solution: WebSolutionExtended): void;

  onButtonClick?(solution: WebSolutionExtended): void;

  onBoxRender?(): void;

  isInstalled?: boolean;
  showPremiumLabel?: boolean;
  isBundleAppInstalled?: boolean;
  isBundleAppNotInstalled?: boolean;
  isAppSetupIncomplete?: boolean;
  savedPrice?: number;
  isVoucherApp?: boolean;
  hideCTA?: boolean;
  hideRate?: boolean;
  hideTagsBadge?: boolean;
  isWixOffering?: boolean;
}

@withExperiments
export class SolutionBox extends React.Component<ISolutionBoxProps, any> {
  componentDidMount(): void {
    if (this.props.onBoxRender) {
      this.props.onBoxRender();
    }
  }

  constructor(props) {
    super(props);
    this.state = { isImpression: false };
  }

  getLinkProps = () => {
    const { to, as } = this.props;
    return {
      to,
      as,
    };
  };

  onImpression = () => {
    if (!this.state.isImpression) {
      sendSolutionImpressionBi(this.props.solutionBiData);
      this.setState({ isImpression: true });
    }
  };

  render() {
    const {
      solution,
      solutionBiData,
      onButtonClick,
      onBoxClick,
      isInstalled,
      showPremiumLabel,
      as,
      isCompany,
      experiments,
      isBundleAppInstalled,
      isBundleAppNotInstalled,
      isAppSetupIncomplete,
      savedPrice,
      isVoucherApp,
      hideCTA,
      hideTagsBadge,
    } = this.props;
    const {
      icon,
      teaser,
      numberOfReviews,
      reviewStars,
      name,
      company,
      pricing,
      Tags,
    } = solution;
    let areReviews = true;
    if (
      experiments &&
      experiments.enabled('specs.marketplace.solutionBox.removeReviewRating')
    ) {
      areReviews = false;
    }

    const badgeTitle = getAppBadgeTitle(
      isBundleAppInstalled,
      isBundleAppNotInstalled,
      Tags,
      experiments,
      hideTagsBadge,
      solution?.id,
    );
    return (
      <VisibilityDetector onVisible={this.onImpression}>
        <Box
          className={style.solutionBox}
          dataHook="solution-box"
          height={
            isCompany && areReviews ? '291px' : isCompany ? '267px' : '276px'
          }
          marginTop="15px"
          marginBottom="15px"
        >
          <div
            data-appid={solution.id}
            onClick={() => {
              sendOpenSolutionPageBi(solutionBiData);
              onBoxClick(solution);
            }}
            className={
              this.props.layout && this.props.layout !== 'marketplace'
                ? style.solutionBoxWrapperIntegrationsLayout
                : style.solutionBoxWrapperMarketplaceLayout
            }
          >
            <ConditionalLinkRenderer {...this.getLinkProps()}>
              <SolutionTopSection
                alt={`${name} logo`}
                solutionIcon={getClientImageURL(icon)}
                badgeTitle={badgeTitle}
                badgeTheme={getAppBadgeTheme(badgeTitle)}
                showTooltip={isBundleAppInstalled || isBundleAppNotInstalled}
                tooltipContent={getAppBadgeTooltipContent(
                  isBundleAppInstalled,
                  isBundleAppNotInstalled,
                  isVoucherApp,
                )}
                solutionName={name}
              />

              <SolutionDetails
                teaser={teaser}
                name={name}
                isCompany={isCompany}
                company={company.name}
                reviews={{ numberOfReviews, reviewStars }}
                layout={this.props.layout}
              />
            </ConditionalLinkRenderer>
            <Box height={isCompany ? '24px' : '25px'} />
            <SolutionBottomAction
              isInstalled={isInstalled}
              showPremiumLabel={showPremiumLabel}
              pricing={pricing}
              reviews={areReviews ? { numberOfReviews, reviewStars } : null}
              onButtonClick={() => {
                isInstalled
                  ? sendOpenClick(solutionBiData)
                  : sendAddClick(solutionBiData);
                onButtonClick(solution);
              }}
              isBundleAppInstalled={isBundleAppInstalled}
              isBundleAppNotInstalled={isBundleAppNotInstalled}
              isAppSetupIncomplete={isAppSetupIncomplete}
              savedPrice={savedPrice}
              isVoucherApp={isVoucherApp}
              hideCTA={hideCTA}
            />
          </div>
        </Box>
      </VisibilityDetector>
    );
  }
}

interface IConditionalLinkRenderer {
  as?: 'link';
  to?: string;
  className?: string;
  children: any;
}

const FakeLink = ({ to, className, children }) => {
  return (
    <a href={to} className={className} onClick={(e) => e.preventDefault()}>
      {children}
    </a>
  );
};
export const ConditionalLinkRenderer = ({
  as,
  to,
  className,
  children,
}: IConditionalLinkRenderer) => {
  if (as === 'link') {
    if (to === undefined) {
      console.warn(
        "trying to render component as 'link' without passing 'to' prop",
      );
    }
    return (
      <FakeLink to={to} className={`${style.boxLink} ${className}`}>
        {children}
      </FakeLink>
    );
  }
  return children;
};
