import * as React from 'react';
import style from './solution-details.scss';
import { SolutionReviewsInfo } from '../solution-reviews-info';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import { composer } from '../../../../../component-decorators/composer';

import { Text, Box } from '@wix/design-system';
import type { InjectedExperimentsProps } from '@wix/wix-experiments-react';

interface ISolutionDetailsProps
  extends Partial<WithTranslationProps>,
    Partial<InjectedExperimentsProps> {
  teaser: string;
  name: string;
  company: any;
  layout?: string;
  isCompany?: boolean;
  reviews: { numberOfReviews: number; reviewStars: number };
}

export const SolutionDetails = composer()
  .withTranslation()
  .withExperiments()
  .compose(
    ({
      t,
      teaser,
      name,
      reviews,
      company,
      experiments,
      isCompany,
    }: ISolutionDetailsProps) => {
      company = company || t('solutionBox.solutionDetails.defaultCompanyName');

      let areReviews = true;
      if (
        experiments &&
        experiments.enabled('specs.marketplace.solutionBox.removeReviewRating')
      ) {
        areReviews = false;
      }
      return (
        <div className={style.heightSolutionDetail}>
          <Box
            className={style.titleCompany}
            height={isCompany ? '63px' : '48px'}
            direction="vertical"
          >
            <Text weight="bold" className={style.madeforSolutionTitle}>
              {name}
            </Text>
            {isCompany && (
              <Box marginBottom="6px">
                <Text
                  dataHook="company-name"
                  size="tiny"
                  weight="thin"
                  secondary
                  light
                >
                  {t('solutionBox.solutionDetails.companyName', { company })}
                </Text>
              </Box>
            )}
          </Box>

          <Box
            maxWidth="280px"
            marginTop="6px"
            // marginBottom={isPrice ? '4px' : '0'}
          >
            <Text
              dataHook="solution-teaser"
              size="small"
              weight="normal"
              skin="standard"
              className={style.solutionTeaser}
            >
              {teaser}
            </Text>
          </Box>
          {areReviews && <SolutionReviewsInfo reviews={reviews} />}
        </div>
      );
    },
  );
