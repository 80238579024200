import { composer } from '../../../../component-decorators/composer';
import s from '../../style.scss';
import { Box, Heading } from '@wix/design-system';
import * as React from 'react';
import { config } from '../../../../config';
import { SearchBoxContainer } from '../../../search-box';
import { EditorType } from '../../../..';
import { useConfigContext } from '../../../../config-context';

export const HomeHeader = composer()
  .withTranslation()
  .withExperiments()
  .compose(() => {
    const { locale } = useConfigContext();
    return (
      <>
        <div className={s.homeHead}>
          <div>
            <HomeTitle />
            <Box height="12px" />
            <SearchBoxContainer
              isInMenu={false}
              locale={locale}
              searchLocation="home"
              isBig={true}
            />
          </div>
          <img
            className={s.homeWithSearchImage}
            src={`${config.marketplaceStaticsUrl}assets/images/home-illustrations/title-illustration-new.svg`}
          />
        </div>
      </>
    );
  });

const getHomePageTitle = (experiments, t) => {
  return config.editorType === EditorType.EDITORX ||
    config.editorType === EditorType.STUDIO
    ? t('home.page.editorX.title')
    : t('home.page.title');
};

const HomeTitle = composer()
  .withExperiments()
  .withTranslation()
  .compose(({ t, experiments }) => {
    return (
      <div className={`${s.homeTitleWrapper}`}>
        <div className={s.homeTitle}>
          <Heading
            appearance="H1"
            dataHook="page-title"
            className={s.homeHeading}
          >
            {getHomePageTitle(experiments, t)}
          </Heading>
        </div>
        <Box width={60} />
      </div>
    );
  });
