import React from 'react';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import type { IconElement } from '@wix/design-system';
import { TextButton } from '@wix/design-system';
import { Add } from '@wix/wix-ui-icons-common';
import { composer } from '../../../../component-decorators/composer';
import { useConsumerCallbacks } from '../contexts/consumer-callbacks';
import { useSolution } from '../contexts/solution';
import style from '../app-box/app-box.scss';
import { useConsumerPreferences } from '../contexts/consumer-preferences';

interface CTAButtonSkinMap {
  [skin: string]: {
    installedKey: string;
    addKey: string;
    addPrefix: IconElement | null;
  };
}

const ctaButtonSkinMap: CTAButtonSkinMap = {
  Grid: {
    installedKey: 'apps.grid.cta.button.installed',
    addKey: 'apps.grid.cta.button',
    addPrefix: null,
  },
  List: {
    installedKey: 'apps.grid.cta.button.installed',
    addKey: 'apps.grid.cta.button.add',
    addPrefix: <Add />,
  },
};

export interface ICTAButtonProps extends WithTranslationProps {}

export const CTAButton = composer()
  .withTranslation()
  .compose(({ t }: ICTAButtonProps) => {
    const { onClick, onInstalledAppClick } = useConsumerCallbacks();
    const { id: appId, isBundleAppInstalled, isInstalled } = useSolution();
    const { skin } = useConsumerPreferences();
    const { installedKey, addKey, addPrefix } = ctaButtonSkinMap[skin];
    const prefixIcon = !isInstalled ? addPrefix : null;
    const ctaText =
      isBundleAppInstalled || isInstalled ? t(installedKey) : t(addKey);

    return (
      <TextButton
        className={style.ctaButton}
        size="tiny"
        weight="normal"
        prefixIcon={prefixIcon}
        onClick={(e) => {
          e.stopPropagation();
          isInstalled ? onInstalledAppClick?.(appId) : onClick?.(appId);
        }}
      >
        {ctaText}
      </TextButton>
    );
  });
