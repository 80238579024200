import * as React from 'react';
import { ActionsCtx } from '../components/solution-page/actions-context/ActionCtx';

export const withPermissions: any = (
  component: React.ComponentType<{ permissions: string[] }>,
) => {
  return (props) => {
    return (
      <ActionsCtx.Consumer>
        {(permissions) =>
          React.createElement(component, { ...props, permissions })
        }
      </ActionsCtx.Consumer>
    );
  };
};
