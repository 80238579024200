import * as React from 'react';
import { composer } from '../../../component-decorators/composer';
import type { InjectedUserDetailsProps } from '../../../component-decorators/with-user-details';
import type { MarketplaceStore } from '../../../component-decorators/with-marketplace-store';

interface IHeaderUserImageProps extends Partial<InjectedUserDetailsProps> {
  marketplaceStore?: MarketplaceStore;
  marginRight: string;
  marginLeft: string;
  size: string;
}

export const HeaderUserImage = composer()
  .withMarketplaceStore()
  .compose(
    ({
      marketplaceStore,
      marginRight = '12px',
      marginLeft = '12px',
      size = '30px',
    }: IHeaderUserImageProps) => {
      return (
        <img
          src={marketplaceStore.userImage}
          alt="profile-image"
          data-hook="profile-image"
          style={{
            height: size,
            width: size,
            marginRight,
            marginLeft,
            borderRadius: '50%',
          }}
        />
      );
    },
  );
