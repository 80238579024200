import type { ReactNode } from 'react';
import React, { useContext, useMemo, useState } from 'react';
import type { IRoute } from '..';
import type { IModalProps, ModalNames } from '../components/modals/modals';

export interface MarketplaceStoreData {
  metaSiteId: string;
  route: IRoute;
  prevRoute: IRoute;
  experimentOptions: { [key: string]: string };
  installedApps: any;
  clearSearchBeforeDate: number;
  siteIsPremium: boolean;
  saleModalMobileSeen: boolean;
  siteHasDomain: boolean;
  userName: string;
  userImage: string;
  openedModalName: ModalNames;
  openedModalProps: IModalProps;
  bundleApps: any;
  currentBundleApps: any;
  hideBusinessSolutionsInManageApps?: boolean;
  permissions: string[];
  privateAppsTagId: string;
  privateAppsTagName: string;
  privateAppsTagSlug: string;
}

export interface MarketplaceStoreSetters {
  setMetaSiteId(metaSiteId: string): void;
  setRoute(route: IRoute): void;
  setInstalledApps(apps: any): void;
  setBundleApps(apps: any): void;
  setCurrentBundleApps(apps: any): void;
  setUserName(userName: string): void;
  setUserImage(userImage: string): void;
  setSiteIsPremium(isPremium: boolean): void;
  setSiteHasDomain(hasDomain: boolean): void;
  setSaleMobileModalSeen(opened: boolean): void;
  setHideBusinessSolutionsInManageApps(
    hideBusinessSolutionsInManageApps: boolean,
  ): void;
  setPrivateAppsTagId(tagId: string): void;
  setPrivateAppsTagName(tagName: string): void;
  setPrivateAppsTagSlug(tagSlug: string): void;
  resetClearSearchBeforeDate(): void;
  setPermissions(permissions): void;
  openModal(modalName: ModalNames, modalProps: IModalProps): void;
  closeAllModals(): void;
}

export type MarketplaceStore = Partial<MarketplaceStoreData> &
  MarketplaceStoreSetters;

export const MarketplaceStoreContext =
  React.createContext<MarketplaceStore>(undefined);

export type MarketplaceStoreProviderProps = Partial<MarketplaceStoreData> & {
  children: ReactNode;
};

export function MarketplaceStoreProvider(props: MarketplaceStoreProviderProps) {
  const { children, ...initialMarketplaceStore } = props;

  const [metaSiteId, updateMetaSiteId] = useState<string>(
    initialMarketplaceStore.metaSiteId,
  );
  const [prevRoute, updatePrevRoute] = useState<IRoute>(
    initialMarketplaceStore.prevRoute,
  );
  const [route, updateRoute] = useState<IRoute>(initialMarketplaceStore.route);
  const [installedApps, updateInstalledApps] = useState<any>(
    initialMarketplaceStore.installedApps,
  );
  const [bundleApps, updateBundleApps] = useState<any>(
    initialMarketplaceStore.bundleApps,
  );
  const [currentBundleApps, updateCurrentBundleApps] = useState<any>(
    initialMarketplaceStore.currentBundleApps,
  );
  const [userName, updateUserName] = useState<string>(
    initialMarketplaceStore.userName,
  );
  const [userImage, updateUserImage] = useState<string>(
    initialMarketplaceStore.userImage,
  );
  const [siteIsPremium, updateSiteIsPremium] = useState<boolean>(
    initialMarketplaceStore.siteIsPremium,
  );
  const [saleModalMobileSeen, updateSaleModalMobileSeen] = useState<boolean>(
    initialMarketplaceStore.saleModalMobileSeen,
  );
  const [siteHasDomain, updateSiteHasDomain] = useState<boolean>(
    initialMarketplaceStore.siteHasDomain,
  );
  const [
    hideBusinessSolutionsInManageApps,
    updateHideBusinessSolutionsInManageApps,
  ] = useState<boolean>(
    initialMarketplaceStore.hideBusinessSolutionsInManageApps,
  );
  const [clearSearchBeforeDate, updateClearSearchBeforeDate] = useState<number>(
    initialMarketplaceStore.clearSearchBeforeDate ?? Date.now(),
  );
  const [permissions, updatePermissions] = useState<string[]>(
    initialMarketplaceStore.permissions,
  );
  const [openedModalName, updateOpenedModalName] = useState<ModalNames>(
    initialMarketplaceStore.openedModalName,
  );
  const [openedModalProps, updateOpenedModalProps] = useState<IModalProps>(
    initialMarketplaceStore.openedModalProps,
  );
  const [privateAppsTagId, updatePrivateAppsTagId] = useState<
    string | undefined
  >(initialMarketplaceStore.privateAppsTagId);
  const [privateAppsTagName, updatePrivateAppsTagName] = useState<
    string | undefined
  >(initialMarketplaceStore.privateAppsTagName);
  const [privateAppsTagSlug, updatePrivateAppsTagSlug] = useState<
    string | undefined
  >(initialMarketplaceStore.privateAppsTagSlug);

  function setMetaSiteId(newMetaSiteId: string) {
    updateMetaSiteId(newMetaSiteId);
  }
  function setRoute(newRoute: IRoute) {
    updatePrevRoute(route);
    updateRoute(newRoute);
  }
  function setInstalledApps(newApps: any) {
    updateInstalledApps([...newApps]);
  }
  function setBundleApps(newApps: any) {
    updateBundleApps(newApps);
  }
  function setCurrentBundleApps(newApps: any) {
    updateCurrentBundleApps(newApps);
  }
  function setUserName(newUserName: string) {
    updateUserName(newUserName);
  }
  function setUserImage(newUserImage: string) {
    updateUserImage(newUserImage);
  }
  function setSiteIsPremium(newSiteIsPremium: boolean) {
    updateSiteIsPremium(newSiteIsPremium);
  }
  function setSaleMobileModalSeen(opened: boolean) {
    updateSaleModalMobileSeen(opened);
  }
  function setSiteHasDomain(newSiteHasDomain: boolean) {
    updateSiteHasDomain(newSiteHasDomain);
  }
  function setHideBusinessSolutionsInManageApps(
    newHideBusinessSolutionsInManageApps: boolean,
  ) {
    updateHideBusinessSolutionsInManageApps(
      newHideBusinessSolutionsInManageApps,
    );
  }
  function resetClearSearchBeforeDate() {
    updateClearSearchBeforeDate(Date.now());
  }
  function setPermissions(newPermissions) {
    updatePermissions(newPermissions);
  }
  function setPrivateAppsTagName(newTagName) {
    updatePrivateAppsTagName(newTagName);
  }
  function setPrivateAppsTagSlug(newTagSlug) {
    updatePrivateAppsTagSlug(newTagSlug);
  }
  function setPrivateAppsTagId(newTagId) {
    updatePrivateAppsTagId(newTagId);
  }

  function openModal(newModalName: ModalNames, newModalProps: IModalProps) {
    updateOpenedModalName(newModalName);
    updateOpenedModalProps(newModalProps);
  }
  function closeAllModals() {
    updateOpenedModalName('none' as ModalNames);
    updateOpenedModalProps({});
  }

  const marketplaceStore: Partial<MarketplaceStore> & MarketplaceStoreSetters =
    useMemo(
      () => ({
        metaSiteId,
        route,
        prevRoute,
        installedApps,
        clearSearchBeforeDate,
        siteIsPremium,
        siteHasDomain,
        userName,
        userImage,
        saleModalMobileSeen,
        openedModalName,
        openedModalProps,
        bundleApps,
        currentBundleApps,
        hideBusinessSolutionsInManageApps,
        permissions,
        privateAppsTagId,
        privateAppsTagName,
        privateAppsTagSlug,
        setMetaSiteId,
        setRoute,
        setInstalledApps,
        setBundleApps,
        setCurrentBundleApps,
        setUserName,
        setUserImage,
        setSiteIsPremium,
        setSaleMobileModalSeen,
        setSiteHasDomain,
        setHideBusinessSolutionsInManageApps,
        resetClearSearchBeforeDate,
        setPermissions,
        openModal,
        closeAllModals,
        setPrivateAppsTagId,
        setPrivateAppsTagName,
        setPrivateAppsTagSlug,
      }),
      [
        metaSiteId,
        JSON.stringify(route),
        JSON.stringify(prevRoute),
        JSON.stringify(installedApps),
        // clearSearchBeforeDate,
        siteIsPremium,
        saleModalMobileSeen,
        siteHasDomain,
        userName,
        userImage,
        openedModalName,
        // openedModalProps,
        JSON.stringify(bundleApps),
        JSON.stringify(currentBundleApps),
        hideBusinessSolutionsInManageApps,
        JSON.stringify(permissions),
        privateAppsTagSlug,
      ],
    );

  return (
    <MarketplaceStoreContext.Provider value={marketplaceStore}>
      {children}
    </MarketplaceStoreContext.Provider>
  );
}

export function useMarketplaceStore(): MarketplaceStore {
  const context = useContext(MarketplaceStoreContext);
  if (context === undefined) {
    throw new Error(
      'useMarketplaceStore must be used within a MarketplaceStoreProvider',
    );
  }

  return context;
}

export function withMarketplaceStore(BaseComponent: any): any {
  return (props) => (
    <MarketplaceStoreContext.Consumer>
      {(marketplaceStore) => (
        <BaseComponent {...props} marketplaceStore={marketplaceStore} />
      )}
    </MarketplaceStoreContext.Consumer>
  );
}
