import * as React from 'react';
import style from './solution-reviews-info.scss';
import { FavoriteFilledSmall } from '@wix/wix-ui-icons-common';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import { composer } from '../../../../../component-decorators/composer';
import { Text, Box } from '@wix/design-system';

interface ISolutionReviewsInfoProps extends Partial<WithTranslationProps> {
  reviews: { numberOfReviews: number; reviewStars: number };
}

export const SolutionReviewsInfo = composer()
  .withTranslation()
  .compose(({ t, reviews }: ISolutionReviewsInfoProps) => {
    const noReviews = !reviews.numberOfReviews || reviews.numberOfReviews === 0;
    return (
      <Box dataHook="reviews-info" verticalAlign="middle">
        <FavoriteFilledSmall
          color={noReviews ? 'rgba(22, 45, 61, 0.2)' : '#FDB10C'}
          className={style.starIcon}
        />
        {noReviews ? (
          <Text
            data-hook="no-reviews"
            size="tiny"
            weight="thin"
            secondary
            light
          >
            0
          </Text>
        ) : (
          <Box>
            <Text
              data-hook="average-rating"
              className={style.rating}
              size="tiny"
              weight="bold"
            >
              {Number(reviews.reviewStars).toFixed(1)}
            </Text>
            <Text
              data-hook="total-reviews"
              size="tiny"
              weight="thin"
              secondary
            >{`(${reviews.numberOfReviews})`}</Text>
          </Box>
        )}
      </Box>
    );
  });
