import React from 'react';
import Helmet from 'react-helmet';
import { Path } from '../..';
import type { TFunction } from 'i18next';
import { composer } from '../../component-decorators/composer';
import { useConfigContext } from '../../config-context';

interface IHomeSEOProps {
  url: string;
  data: any;
  t?: TFunction;
}

export const HomeSEO = composer()
  .withTranslation()
  .compose(({ t, url }: IHomeSEOProps) => {
    const { metaTagsAggregator } = useConfigContext();
    if (metaTagsAggregator) {
      metaTagsAggregator.push(
        `<title data-meta-tags-aggregator="true" >${t(
          'home-page.seo.title',
        )}</title>`,
      );
      metaTagsAggregator.push(
        `<meta data-meta-tags-aggregator="true" name="description" content="${t(
          'home-page.seo.desc',
        )}" />`,
      );
      metaTagsAggregator.push(
        `<meta data-meta-tags-aggregator="true" property="og:type" content="website" />`,
      );
      metaTagsAggregator.push(
        `<meta data-meta-tags-aggregator="true" property="og:title" content="${t(
          'home-page.seo.title',
        )}" />`,
      );
      metaTagsAggregator.push(
        `<meta data-meta-tags-aggregator="true" property="og:description" content="${t(
          'home-page.seo.desc',
        )}" />`,
      );
      metaTagsAggregator.push(
        `<meta data-meta-tags-aggregator="true" property="og:image" content="https://static.parastorage.com/services/one-app-market/1.940.0/images/share-app-market.jpg"/>`,
      );
      metaTagsAggregator.push(
        `<meta data-meta-tags-aggregator="true" name="fb:app_id" content="${t(
          'seo.fb-app-id',
        )}"></meta>`,
      );
      metaTagsAggregator.push(
        `<meta data-meta-tags-aggregator="true" name="twitter:account_id" content="${t(
          'seo.twitter-account-id',
        )}"></meta>`,
      );
      metaTagsAggregator.push(
        `<meta data-meta-tags-aggregator="true" name="twitter:card" content="https://static.parastorage.com/services/one-app-market/1.940.0/images/share-app-market.jpg"></meta>`,
      );
      metaTagsAggregator.push(
        `<meta data-meta-tags-aggregator="true" name="twitter:site" content="@Wix"></meta>`,
      );
      metaTagsAggregator.push(
        `<meta data-meta-tags-aggregator="true" name="twitter:title" content="${t(
          'home-page.seo.title',
        )}" />`,
      );
      metaTagsAggregator.push(
        `<meta data-meta-tags-aggregator="true" name="twitter:description" content="${t(
          'home-page.seo.desc',
        )}" />`,
      );
      metaTagsAggregator.push(
        `<meta data-meta-tags-aggregator="true" name="twitter:image:src" content="https://static.parastorage.com/services/one-app-market/1.940.0/images/share-app-market.jpg"/>`,
      );
      metaTagsAggregator.push(
        `<link rel="alternate" hrefLang="x-default" href="https://www.wix.com/app-market"/>`,
      );
      metaTagsAggregator.push(
        `<link rel="alternate" hreflang="cs" href="https://cs.wix.com/app-market"/>`,
      );
      metaTagsAggregator.push(
        `<link rel="alternate" hreflang="da" href="https://da.wix.com/app-market"/>`,
      );
      metaTagsAggregator.push(
        `<link rel="alternate" hreflang="de" href="https://de.wix.com/app-market"/>`,
      );
      metaTagsAggregator.push(
        `<link rel="alternate" hreflang="en"href="https://www.wix.com/app-market"/>`,
      );
      metaTagsAggregator.push(
        `<link rel="alternate" hreflang="es" href="https://es.wix.com/app-market"/>`,
      );
      metaTagsAggregator.push(
        `<link rel="alternate" hreflang="fr" href="https://fr.wix.com/app-market"/>`,
      );
      metaTagsAggregator.push(
        `<link rel="alternate" hreflang="hi" href="https://hi.wix.com/app-market"/>`,
      );
      metaTagsAggregator.push(
        `<link rel="alternate" hreflang="it" href="https://it.wix.com/app-market"/>`,
      );
      metaTagsAggregator.push(
        `<link rel="alternate" hreflang="ja" href="https://ja.wix.com/app-market"/>`,
      );
      metaTagsAggregator.push(
        `<link rel="alternate" hreflang="ko" href="https://ko.wix.com/app-market"/>`,
      );
      metaTagsAggregator.push(
        `<link rel="alternate" hreflang="nl" href="https://nl.wix.com/app-market"/>`,
      );
      metaTagsAggregator.push(
        `<link rel="alternate" hreflang="no" href="https://no.wix.com/app-market"/>`,
      );
      metaTagsAggregator.push(
        `<link rel="alternate" hreflang="pt" href="https://pt.wix.com/app-market"/>`,
      );
      metaTagsAggregator.push(
        `<link rel="alternate" hreflang="ru" href="https://ru.wix.com/app-market"/>`,
      );
      metaTagsAggregator.push(
        `<link rel="alternate" hreflang="sv" href="https://sv.wix.com/app-market"/>`,
      );
      metaTagsAggregator.push(
        `<link rel="alternate" hreflang="th" href="https://th.wix.com/app-market"/>`,
      );
      metaTagsAggregator.push(
        `<link rel="alternate" hreflang="tr" href="https://tr.wix.com/app-market"/>`,
      );
      metaTagsAggregator.push(
        `<link rel="alternate" hreflang="uk" href="https://uk.wix.com/app-market"/>`,
      );
      metaTagsAggregator.push(
        `<link rel="alternate" hreflang="zh" href="https://zh.wix.com/app-market"/>`,
      );
    }

    return (
      <Helmet>
        <title>{t('home-page.seo.title')}</title>
        <meta name="description" content={t('home-page.seo.desc')} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={t('home-page.seo.title')} />
        <meta property="og:description" content={t('home-page.seo.desc')} />
        <meta
          property="og:image"
          content="https://static.parastorage.com/services/one-app-market/1.940.0/images/share-app-market.jpg"
        />
        <meta name="fb:app_id" content={t('seo.fb-app-id')}></meta>
        <meta
          name="twitter:account_id"
          content={t('seo.twitter-account-id')}
        ></meta>
        <meta
          name="twitter:card"
          content="https://static.parastorage.com/services/one-app-market/1.940.0/images/share-app-market.jpg"
        ></meta>
        <meta name="twitter:site" content="@Wix"></meta>
        <meta name="twitter:title" content={t('home-page.seo.title')} />
        <meta name="twitter:description" content={t('home-page.seo.desc')} />
        <meta
          name="twitter:image:src"
          content="https://static.parastorage.com/services/one-app-market/1.940.0/images/share-app-market.jpg"
        />

        <script type="application/ld+json">
          {`
  {
  "@context": "https://schema.org",
  "@type": "WebSite",
  "url": "${url}",
  "potentialAction": {
    "@type": "SearchAction",
    "target": "${url}/${Path.SEARCH}?query={search_term_string}",
    "query-input": "required name=search_term_string"
  }
  }
`}
        </script>
      </Helmet>
    );
  });
