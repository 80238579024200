import { HttpClient } from '@wix/http-client';
import { calculateApiHostname } from './api.utils';

const httpClient = new HttpClient();

function getAppDataById(appDefId: string) {
  return httpClient
    .get(
      `https://${calculateApiHostname()}/_api/marketplace-api/v1/web-solutions/${appDefId}`,
    )
    .then(({ data }) => data);
}

export { getAppDataById };
