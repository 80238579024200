import type { DropdownLayoutValueOption } from '@wix/design-system';
import type { OnSelectParams, OptionIdParams } from './index';
import { OptionIdKeys } from './index';

/**
 * Return the string value from a DropdownLayoutValueOption id based on a specific key.
 * Assumes that values were injected into the id in query-params format using createOptionIdWithParams.
 *
 * @function getValueFromOptionId
 */

export function getValueFromOptionId(
  field: string,
  id: string,
): string | undefined {
  const regex = new RegExp(`${field}=([^&]+)`);
  const match = id.match(regex);
  return match?.[1];
}

/**
 * Return an optionId string from the params input values in query-params format,
 * for use in the SearchBox component as DropdownLayoutOption id.
 *
 * @function createOptionIdWithParams
 */
export function createOptionIdWithParams(params: OptionIdParams): string {
  let optionId = '';
  Object.keys(params).forEach((key, index) => {
    optionId = optionId.concat(
      `${index === 0 ? '' : '&'}${key}=${params[key as keyof OptionIdParams]}`,
    );
  });
  return optionId;
}

export function parseOnSelectParams(
  option: DropdownLayoutValueOption,
  searchTerm: string,
): OnSelectParams {
  const { APP_ID, INDEX, TYPE, SLUG } = OptionIdKeys;
  const optionId = option.id.toString();
  return {
    optionId,
    searchTerm,
    label: option.label,
    appId: getValueFromOptionId(APP_ID, optionId),
    type: getValueFromOptionId(TYPE, optionId),
    slug: getValueFromOptionId(SLUG, optionId),
    index: getValueFromOptionId(INDEX, optionId)
      ? Number(getValueFromOptionId(INDEX, optionId))
      : undefined,
  };
}
