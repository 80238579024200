import React, { useEffect, useState } from 'react';
import { Button, Loader, type ButtonSize, Tooltip } from '@wix/design-system';
import { openAppClick } from '@wix/bi-logger-market/v2';
import { useTranslation } from '../../../../contexts/translations-context';
import {
  useEssentials,
  useInstaller,
  useJunkYard,
  useRouterContext,
  useServices,
  useSiteContext,
  useUserContext,
} from '../../../../contexts';
import type { ManagedApp } from '@wix/app-market-services';
import { getOpenAppClickBiData } from '../../../../bi-services/events-data-parser';
import { RoutePath } from '../../../../models';

export interface IOpenAppButtonProps {
  isDisabled: boolean;
  tooltipContent: string;
  app: {
    id: string;
    isBuiltIn: boolean;
  };
  size: ButtonSize;
  openMobileInstallationBlockedModal(): void;
}

export function OpenAppButton({
  isDisabled,
  tooltipContent,
  app,
  size,
  openMobileInstallationBlockedModal,
}: IOpenAppButtonProps) {
  const { t } = useTranslation();
  const { metaSiteId } = useSiteContext();
  const { isMobile } = useUserContext();
  const services = useServices();
  const [isLoading, setIsLoading] = useState(true);
  const [managedApp, setManagedApp] = useState<ManagedApp | undefined>();
  const { biLogger } = useEssentials();
  const { router } = useRouterContext();
  const { routerData, goBackToReferrer, openApp } = useJunkYard();
  const installer = useInstaller();
  const buttonProps = {
    disabled: isDisabled,
    size,
  };

  useEffect(() => {
    async function getManagedApp() {
      const managedApps = await services.getManagedApps({});
      return managedApps.findByAppId(app.id);
    }
    if (metaSiteId) {
      getManagedApp().then((ma) => {
        setManagedApp(ma);
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return (
      <Button {...buttonProps}>
        <Loader size="tiny" />
      </Button>
    );
  }

  const isAddAgainMode =
    !!managedApp?.isAddAgainAllowed && !!installer.addAgain;

  return (
    <Tooltip inline disabled={!tooltipContent} content={tooltipContent}>
      <Button
        {...buttonProps}
        onClick={() => {
          biLogger.report(
            openAppClick(
              getOpenAppClickBiData({
                appId: app.id,
                ctaType: isAddAgainMode ? 'add_again' : 'open_app',
                tagType: 'app_page',
                tagName: app.id,
                routerData,
                referrerNameForBI: goBackToReferrer?.referrerNameForBI,
              }),
            ),
          );

          if (isMobile) {
            return openMobileInstallationBlockedModal();
          }

          if (app.isBuiltIn) {
            return router.navigateTo({
              path: RoutePath.WIX_OFFERING,
              payload: {
                appId: app.id,
              },
            });
          }

          if (isAddAgainMode) {
            return installer.addAgain?.({
              appId: app.id,
              appVersion: managedApp.installedVersion,
            });
          }

          const managedAppJson = managedApp?.toJSON();
          managedAppJson && openApp(managedAppJson);
        }}
      >
        {isAddAgainMode ? t('solution.page.addAgain') : t('solution.page.open')}
      </Button>
    </Tooltip>
  );
}
