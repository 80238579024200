import * as React from 'react';
import type {
  WebSolutionExtended,
  TagExtended,
} from '@wix/app-market-collection-widget';
import {
  CategoriesCarouselWidget,
  getWebSolutionData,
  extractTagsDefinition,
} from '@wix/app-market-collection-widget';
import s from './style.scss';
import { config } from '../../../../config';
import { Path } from '../../../..';
import { getTagBiData } from '../../biData';
import { composer } from '../../../../component-decorators/composer';
import { biLogger } from '../../../../bi';
import { categoryClick } from '@wix/bi-logger-market/v2';
import { Heading } from '@wix/design-system';
import { addUrl } from '../../../../common/addUrl';
import { shouldRenderAsLink } from '../../../../common/utils';
import { useConfigContext } from '../../../../config-context';
import type { WebSolution } from '@wix/ambassador-marketplace/types';
import type { MarketplaceStore } from '../../../../component-decorators/with-marketplace-store';

export interface ICategoriesCarouselComponentProps {
  marketplaceStore?: MarketplaceStore;
  displayName: string;
  webSolutions?: WebSolutionExtended[];
  innerTagsDefinitions: TagExtended[];
  className?: string;
}

type ICategoriesCarouselMapper = (
  sectionDTO: any,
) => ICategoriesCarouselComponentProps;

export const categoriesCarouselSectionDTOtoProps: ICategoriesCarouselMapper = (
  sectionDTO: any,
) => {
  const { tagData } = sectionDTO;
  const { tagsToWebSolutions } = tagData;
  const webSolutions: WebSolution[] =
    tagsToWebSolutions?.webSolutions?.webSolutions;

  const innerTagsDefinitions = extractTagsDefinition(tagsToWebSolutions) || [];
  const webSolutionsData: WebSolutionExtended[] =
    (webSolutions &&
      webSolutions.map((solution: WebSolution) =>
        getWebSolutionData(solution),
      )) ||
    [];
  return {
    displayName: tagData.displayName,
    webSolutions: webSolutionsData,
    innerTagsDefinitions,
  };
};

export const CategoriesCarousel = composer()
  .withMarketplaceStore()
  .compose(
    ({
      displayName,
      innerTagsDefinitions,
      marketplaceStore,
      className = '',
    }: ICategoriesCarouselComponentProps) => {
      const biData = getTagBiData(
        innerTagsDefinitions,
        'explore_by_category',
        'category',
        'categories_carousel',
        marketplaceStore.metaSiteId,
        marketplaceStore.route,
        marketplaceStore.prevRoute,
      );
      return (
        <div
          data-testid="categories-carousel"
          className={`${s.wrapper} ${className}`}
        >
          <div className={s.contentWrapper}>
            <Heading
              appearance="H2"
              dataHook="widget-title"
              className={s.widgetTitle}
            >
              {displayName}
            </Heading>
            <CategoriesCarouselWrapper
              innerTagsDefinitions={innerTagsDefinitions}
              metaSiteId={marketplaceStore.metaSiteId}
              biData={biData}
            />
          </div>
        </div>
      );
    },
  );

const CategoriesCarouselWrapper = ({
  innerTagsDefinitions,
  metaSiteId,
  biData,
}) => {
  const { locale, i18n, baseUrl } = useConfigContext();
  return (
    <span className={s.categoriesCarouselWrapper}>
      <CategoriesCarouselWidget
        i18n={i18n}
        as={shouldRenderAsLink()}
        categories={addUrl(
          baseUrl,
          innerTagsDefinitions,
          (c) => `${baseUrl}${Path.CATEGORY}/${c.slug}`,
        )}
        onCategoryClickHandler={(category: TagExtended) => {
          biLogger.report(
            categoryClick({
              category: category.slug.replace(/-/g, '_'),
              msid: metaSiteId,
              origin: 'categories_carousel',
              tag_name: category.slug.replace(/-/g, '_'),
              tag_type: 'category',
            }),
          );
          config.goto({ path: Path.CATEGORY, slug: category.slug });
        }}
        biData={biData}
        locale={locale}
      />
    </span>
  );
};
