import * as React from 'react';
import s from './app-basic-info.scss';
import { Box, Image, Heading, Text, TextButton } from '@wix/design-system';
import { AppInfoBadge } from '../../app-info-badge';
import { FavoriteFilled } from '@wix/wix-ui-icons-common';
import type { PricingBadgeProps } from '../../pricing-badge';
import { type AppBoxData } from '@wix/ambassador-appmarket-v1-app-market-client-aggregator/types';
import { useEssentials } from '../../../contexts/essentials-context';
import { useSiteContext } from '../../../contexts/site-context';
import { shouldShowPremiumRequired } from '../../app-info-badge/app-info-badge.util';
import { Badges } from '../../badges/badges';

export interface AppBasicInfoProps {
  appData: AppBoxData;
  onCompanyClick: (companyName: string, companySlug: string) => void;
  onReviewsClick: (reviewsAmount: number) => void;
  mainActionButton: React.ReactNode;
}

export function AppBasicInfo({
  appData,
  onCompanyClick,
  onReviewsClick,
  mainActionButton,
}: AppBasicInfoProps) {
  const {
    name,
    company,
    icon,
    shortDescription,
    reviews,
    pricing,
    installation,
    bundle,
    badges,
  } = appData;
  const { t, experiments } = useEssentials();
  const { isPremiumSite } = useSiteContext();
  const shouldBeClickableCompanyName =
    experiments &&
    experiments?.enabled('specs.marketplace.AppPageOnNewInfraStandalone');
  function getReviewsLabel() {
    if (reviews?.totalCount === 1) {
      return t('app.page.reviews.one.review.subtitle');
    }
    return t('app.page.reviews.subtitle', {
      totalReviews: reviews?.totalCount,
    });
  }

  return (
    <Box align="space-between" marginTop="12px">
      <Box gap="12px">
        <Box
          className={s.iconWrapper}
          marginTop={badges?.length === 0 ? '6px' : '3px'}
        >
          <Image
            dataHook="logo"
            width="54px"
            height="54px"
            src={icon}
            alt={`${name} logo`}
          />
        </Box>
        <Box direction="vertical">
          {Boolean(badges?.length) && (
            <Box gap="6px" marginBottom="2px">
              <Badges
                badges={badges}
                size="small"
                bundle={bundle}
                isInstalled={!!installation?.isInstalled}
                appName={name ?? ''}
              />
            </Box>
          )}
          <Box
            className={s.basicInfoTitle}
            direction="vertical"
            width="206px"
            marginBottom="30px"
          >
            <Heading dataHook="app-name-heading" size="large">
              {name}
            </Heading>
            {company?.name && (
              <Box gap="2px">
                <Text size="small">{t('solution.page.developerName.by')}</Text>
                {shouldBeClickableCompanyName ? (
                  <TextButton
                    onClick={() =>
                      onCompanyClick(company?.name ?? '', company?.slug ?? '')
                    }
                    size="small"
                  >
                    {company?.name}
                  </TextButton>
                ) : (
                  <Text dataHook="company-name" size="small">
                    {company?.name}
                  </Text>
                )}
              </Box>
            )}
          </Box>
          <Box direction="vertical" width="258px" dataHook="boxchecking">
            <Box marginBottom="12px">
              <Text>{shortDescription}</Text>
            </Box>

            <Box gap="8px" marginBottom="30px">
              <Box>
                <FavoriteFilled
                  color={reviews?.totalCount ? '#FDB10C' : '#ACAFC4'}
                />
                {Boolean(reviews?.totalCount) && (
                  <Text weight="bold">
                    {reviews?.averageRating?.toFixed(1)}
                  </Text>
                )}
              </Box>

              {appData.reviews?.totalCount === 0 ? (
                <Text secondary light>
                  {t('solution.page.details.review.empty')}
                </Text>
              ) : (
                <TextButton
                  dataHook="reviews-count-button"
                  onClick={() =>
                    onReviewsClick(appData.reviews?.totalCount ?? 0)
                  }
                >
                  {getReviewsLabel()}
                </TextButton>
              )}
            </Box>
          </Box>
          <Box>
            <Box direction="vertical">
              {mainActionButton}
              <Box align="center" marginTop="12px">
                <AppInfoBadge
                  isAppInstalled={!!installation?.isInstalled}
                  isAppBuiltIn={!!installation?.isBuiltIn}
                  isAppSetupIncomplete={!!installation?.isSetupIncomplete}
                  isBundleApp={!!bundle?.isBundleApp}
                  pricing={pricing as PricingBadgeProps}
                  options={{
                    showPremiumRequired: shouldShowPremiumRequired(
                      !!installation?.requirements?.premiumSite,
                      isPremiumSite,
                    ),
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
