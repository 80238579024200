import * as React from 'react';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import { composer } from '../../../../component-decorators/composer';
import type { BiInjectedProps as InjectedBiLoggerProps } from '../../../../bi';
import { biLogger } from '../../../../bi';
import { Box, Divider, ListItemSelect, Text } from '@wix/design-system';
import { ExternalLink, Hint } from '@wix/wix-ui-icons-common';
import { useConfigContext } from '../../../../config-context';
import { getWixOfferingId } from '../../../../api/api';
import { config } from '../../../../config';
import { Origin } from '../../../../enums/marketplace-enums';
import s from './wix-offering-suggestion.scss';
import { wixOfferingBoxAction } from '@wix/bi-logger-market/v2';
import type { MarketplaceStore } from '../../../../component-decorators/with-marketplace-store';

export interface IWixOfferingSuggestionsProps
  extends Partial<WithTranslationProps>,
    Partial<InjectedBiLoggerProps> {
  wixOffering: any;
  searchValue: string;
  onSearchValueChange(value: string): Promise<void>;
  sendSearchSearchClickBi(value: string, type: string, index?: number): void;
  marketplaceStore: MarketplaceStore;
}

export const WixOfferingSuggestion = composer()
  .withTranslation()
  .withBiLogger()
  .withMarketplaceStore()
  .compose(
    ({
      wixOffering,
      sendSearchSearchClickBi,
      searchValue,
      t,
      marketplaceStore,
    }: IWixOfferingSuggestionsProps) => {
      const { goToWixOffering, openBMInEditor } = useConfigContext();

      const navigateToWixOffering = () => {
        return async () => {
          sendSearchSearchClickBi(
            searchValue,
            'wix_offering',
            wixOffering.index,
          );
          biLogger.report(
            wixOfferingBoxAction({
              app_id: wixOffering.id,
              market: config.originForBI,
              action_type: 'click',
              msid: marketplaceStore.metaSiteId,
              offering_name: wixOffering.name,
              search_term: searchValue,
              referral_info: 'search_bar',
            }),
          );
          if (config.origin === Origin.STANDALONE) {
            const dest = `https://manage.wix.com/dashboard/{metaSiteId}/wix-offering/app/${wixOffering.id}`;
            const encodedDestination = encodeURIComponent(dest);
            window.open(
              `https://manage.wix.com/account/site-selector?actionUrl=${encodedDestination}`,
            );
          }
          const idResponse = await getWixOfferingId(wixOffering.id);
          const id =
            idResponse.data.appComponents[0].components[0].compData.wixOffering;
          const isPageComponent = 'businessManagerPage' in id;
          if (isPageComponent) {
            const pageComponentId = id.businessManagerPage?.componentId ?? '';
            const appStatus = id.businessManagerPage?.appState ?? '';
            if (config.origin === Origin.BIZMGR) {
              const text = t('wix-offering.toast-text');
              const buttonText = t('wix-offering.toast-cta');
              goToWixOffering(pageComponentId, appStatus, text, buttonText);
            } else {
              const path = appStatus.length
                ? `wix-offering/compId/${pageComponentId}/appState/${appStatus}`
                : `wix-offering/compId/${pageComponentId}`;
              openBMInEditor(path);
            }
          }
        };
      };

      return (
        <React.Fragment>
          <Box>
            <Divider />
          </Box>
          <ListItemSelect
            className={s.wixOfferingStyle}
            prefix={
              <Box height="24px" color="B10">
                <Hint />
              </Box>
            }
            suffix={
              <Box height="24px" color="D20">
                <ExternalLink />
              </Box>
            }
            title={
              <Text size="small" weight="thin" skin="standard">
                {t('searchBox.wixOffering.title', {
                  wixOffering: wixOffering.name,
                })}
              </Text>
            }
            onClick={navigateToWixOffering()}
          />
        </React.Fragment>
      );
    },
  );
