import * as React from 'react';
import {
  Box,
  FloatingNotification,
  FormField,
  Input,
  InputArea,
  StarsRatingBar,
} from '@wix/design-system';
import { StatusAlert } from '@wix/wix-ui-icons-common';
import type { IReviewFormInstance } from '../../hooks/useReviewForm';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';

interface IReviewAppFormProps extends Partial<WithTranslationProps> {
  appName: string;
  reviewForm: IReviewFormInstance;
  editorSdkVariant?: boolean;
}

function ReviewAppForm({
  appName,
  reviewForm,
  editorSdkVariant = false,
  t,
}: IReviewAppFormProps) {
  const rateLabel = t('review-app.rate-label');
  const rateStarsDescription: [string, string, string, string, string] = [
    t('review-app.rate-one-star-description'),
    t('review-app.rate-two-stars-description'),
    t('review-app.rate-three-stars-description'),
    t('review-app.rate-four-stars-description'),
    t('review-app.rate-five-stars-description'),
  ];

  const reviewTitleLabel = t('review-app.review-title-label');
  const reviewTitlePlaceholder = t('review-app.review-title-placeholder');
  const reviewTitleStatusMessage = reviewForm.titleStatus
    ? t('review-app.review-title-error-tooltip')
    : undefined;

  const reviewDescriptionLabel = t('review-app.review-description-label');
  const reviewDescriptionPlaceholder = t(
    'review-app.review-description-placeholder',
    { appName },
  );
  const reviewDescriptionInputHeight =
    editorSdkVariant && !reviewForm.errorKey ? '149px' : '101px';

  return (
    <Box direction="vertical">
      {Boolean(reviewForm.errorKey) && (
        <Box direction="vertical" marginBottom="12px">
          <FloatingNotification
            type="destructive"
            prefixIcon={<StatusAlert />}
            text={t(reviewForm.errorKey)}
            showCloseButton={false}
          />
        </Box>
      )}

      <Box direction="vertical" marginBottom="24px">
        <FormField label={rateLabel} required>
          <StarsRatingBar
            dataHook="review-rating-stars"
            value={reviewForm.rating}
            descriptionValues={rateStarsDescription}
            onChange={reviewForm.setRating}
          />
        </FormField>
      </Box>

      <Box direction="vertical" marginBottom="24px">
        <FormField
          label={reviewTitleLabel}
          required
          charCount={100 - reviewForm.title.length}
        >
          <Input
            dataHook="review-title-input"
            value={reviewForm.title}
            placeholder={reviewTitlePlaceholder}
            status={reviewForm.titleStatus}
            statusMessage={reviewTitleStatusMessage}
            onChange={(event) => reviewForm.setTitle(event.target.value)}
            onBlur={() => reviewForm.updateTitleStatus(reviewForm.title)}
            maxLength={100}
          />
        </FormField>
      </Box>

      <Box direction="vertical" marginBottom="2px">
        <FormField
          label={reviewDescriptionLabel}
          charCount={1000 - reviewForm.description.length}
        >
          <InputArea
            dataHook="review-description-input-area"
            value={reviewForm.description}
            placeholder={reviewDescriptionPlaceholder}
            onChange={(event) => reviewForm.setDescription(event.target.value)}
            maxLength={1000}
            minHeight={reviewDescriptionInputHeight}
          />
        </FormField>
      </Box>
    </Box>
  );
}

export { IReviewAppFormProps, ReviewAppForm };
