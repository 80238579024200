import * as React from 'react';
import { composer } from '../../../component-decorators/composer';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import { SolutionBadge } from '../../solution-badge/index';
import type { Pricing } from '@wix/ambassador-marketplace/types';

interface IPricingPlanOrInstalledBadgeProps
  extends Partial<WithTranslationProps> {
  isInstalled: boolean;
  pricing: Pricing;
  showPremiumLabel?: boolean;
  isBundleAppInstalled?: boolean;
  isBundleAppNotInstalled?: boolean;
  savedPrice?: number;
}

export const PricingPlanOrInstalledBadge = composer()
  .withTranslation()
  .compose(
    ({
      isInstalled,
      pricing,
      t,
      showPremiumLabel,
      isBundleAppInstalled,
      isBundleAppNotInstalled,
      savedPrice,
    }: IPricingPlanOrInstalledBadgeProps) => {
      return (
        <SolutionBadge
          t={(k, o) => t(k, o)}
          isInstalled={isInstalled}
          showPremiumLabel={showPremiumLabel}
          pricing={pricing}
          isBundleAppInstalled={isBundleAppInstalled}
          isBundleAppNotInstalled={isBundleAppNotInstalled}
          savedPrice={savedPrice}
        />
      );
    },
  );
