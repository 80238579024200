import React from 'react';
import type { JunkYard } from '../models/junk-yard';
import type { FunctionComponent, ReactNode } from 'react';

const JunkYardContext = React.createContext<JunkYard | null>(null);

export const JunkYardContextProvider: FunctionComponent<{
  junkYard: JunkYard;
  children: ReactNode;
}> = ({ junkYard, children }) => (
  <JunkYardContext.Provider value={junkYard}>
    {children}
  </JunkYardContext.Provider>
);

export const useJunkYard = () => {
  const context = React.useContext(JunkYardContext);

  if (context === null) {
    throw new Error(
      'useJunkYard must be used within a JunkYardContextProvider',
    );
  }

  return context;
};
