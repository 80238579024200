import * as React from 'react';
import { TextButton } from '@wix/design-system';
import { biLogger } from '../../../bi';
import { config } from '../../../config';
import { AppMarketLink } from '../../app-market-link';
import { ArrowRightSmall } from '@wix/wix-ui-icons-common';
import { composer } from '../../../component-decorators/composer';
import type { TFunction } from 'i18next';
import s from './see-more-apps.scss';
import { Path } from '../../../enums/marketplace-enums';
import type { MarketplaceStore } from '../../../component-decorators/with-marketplace-store';
import { categoryClick } from '@wix/bi-logger-market/v2';

interface ISeeMoreAppsProps {
  marketplaceStore?: MarketplaceStore;
  slug: string;
  gridType?: string;
  positionBottom?: boolean;
}

export const SeeMoreApps = composer()
  .withMarketplaceStore()
  .compose(
    ({
      slug,
      gridType,
      marketplaceStore,
      positionBottom,
    }: ISeeMoreAppsProps) => {
      const origin = config.origin;
      const gridTypeIs = gridType;
      const pageType =
        gridTypeIs === 'COLLECTION' ? Path.COLLECTION : Path.CATEGORY;

      return (
        <div
          className={positionBottom ? s.buttonWrapperBottom : s.buttonWrapper}
        >
          {origin === 'bizmgr' ? (
            <>
              <SeeMoreAppsButton
                slug={slug}
                gridType={gridType}
                positionBottom={positionBottom}
              />
            </>
          ) : (
            <AppMarketLink to={`${pageType}/${slug}`}>
              <SeeMoreAppsButton
                slug={slug}
                gridType={gridType}
                positionBottom={positionBottom}
              />
            </AppMarketLink>
          )}
        </div>
      );
    },
  );

const SeeMoreAppsButton = composer()
  .withMarketplaceStore()
  .compose(
    ({
      slug,
      gridType,
      marketplaceStore,
      positionBottom,
    }: ISeeMoreAppsProps) => {
      const gridTypeIs = gridType;
      const pageType =
        gridTypeIs === 'COLLECTION' ? Path.COLLECTION : Path.CATEGORY;

      return (
        <TextButton
          size="small"
          weight="normal"
          className={s.seeAllCta}
          dataHook={`${slug}-see-all-button`}
          onClick={() => {
            biLogger.report(
              categoryClick({
                category: slug.replace(/-/g, '_'),
                msid: marketplaceStore.metaSiteId,
                origin: 'see_all',
                tag_name: slug.replace(/-/g, '_'),
                tag_type: 'collection',
              }),
            );
            config.goto({
              path: pageType,
              slug,
            });
          }}
        >
          <>
            <SeeAllAppsText />
            <ArrowRightSmall />
          </>
        </TextButton>
      );
    },
  );

const SeeAllAppsText = composer()
  .withTranslation()
  .compose(({ t }: { t: TFunction }) => t('solution.see.all.cta'));
