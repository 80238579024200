import React from 'react';

export default function MobileInstallBlockedImage() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="120"
      viewBox="0 0 120 120"
    >
      <g fill="none" fill-rule="evenodd">
        <g>
          <g>
            <path
              d="M0 120L120 120 120 0 0 0z"
              transform="translate(-128.000000, -127.000000) translate(128.000000, 127.000000)"
            />
            <path
              fill="#192C55"
              d="M74 108L74 85.5 58 85.5 58 108 36 108 36 109 96 109 96 108z"
              transform="translate(-128.000000, -127.000000) translate(128.000000, 127.000000)"
            />
            <path
              fill="#C2E2FF"
              d="M65 90L117 90 117 22.679 65 22.679z"
              transform="translate(-128.000000, -127.000000) translate(128.000000, 127.000000)"
            />
            <path
              fill="#28BF9A"
              d="M15 90L66 90 66 23 15 23z"
              transform="translate(-128.000000, -127.000000) translate(128.000000, 127.000000)"
            />
            <path
              fill="#116DFF"
              d="M103.5 62c1.381 0 2.5 1.119 2.5 2.5 0 1.326-1.031 2.41-2.336 2.495L103.5 67h-10c-1.381 0-2.5-1.119-2.5-2.5 0-1.326 1.031-2.41 2.336-2.495L93.5 62h10zM117 17v7H15v-7h102z"
              transform="translate(-128.000000, -127.000000) translate(128.000000, 127.000000)"
            />
            <path
              fill="#FFF"
              d="M30 68h21c1.657 0 3-1.343 3-3V44c0-1.657-1.343-3-3-3H30c-1.657 0-3 1.343-3 3v21c0 1.657 1.343 3 3 3"
              transform="translate(-128.000000, -127.000000) translate(128.000000, 127.000000)"
            />
            <path
              fill="#192C55"
              d="M26 83H5c-1.657 0-3-1.343-3-3V35c0-1.657 1.343-3 3-3h21c1.657 0 3 1.343 3 3v45c0 1.657-1.343 3-3 3"
              transform="translate(-128.000000, -127.000000) translate(128.000000, 127.000000)"
            />
            <path
              fill="#FFF"
              d="M28 77L3 77 3 49.889 28 40.852z"
              transform="translate(-128.000000, -127.000000) translate(128.000000, 127.000000)"
            />
            <path
              fill="#C2E2FF"
              d="M19 68c1.104 0 2 .896 2 2 0 1.054-.816 1.918-1.85 1.995L19 72h-7c-1.104 0-2-.896-2-2 0-1.054.816-1.918 1.85-1.995L12 68h7zm9-31v5.63L3 52V37h25z"
              transform="translate(-128.000000, -127.000000) translate(128.000000, 127.000000)"
            />
            <path
              fill="#116DFF"
              d="M9 53L38 53 38 40 9 40z"
              transform="translate(-128.000000, -127.000000) translate(128.000000, 127.000000)"
            />
            <path
              fill="#192C55"
              d="M20 63v1h-9v-1h9zm2-4v1H9v-1h13zm84-4v1H77v-1h29zM40.5 39l.243.004C44.773 39.132 48 42.439 48 46.5c0 4.142-3.358 7.5-7.5 7.5-4.142 0-7.5-3.358-7.5-7.5 0-4.06 3.228-7.368 7.257-7.496L40.5 39zM106 49v1H77v-1h29zm-13.001-6.001V44H77v-1.001h15.999z"
              transform="translate(-128.000000, -127.000000) translate(128.000000, 127.000000)"
            />
            <path
              fill="#FFF"
              d="M41 42v4h4v1h-4v4h-1v-4h-4v-1h4v-4h1zm-15.177 1.185l5.878 6.813L15.799 50l4.802-5.349 1.914 2.22 3.308-3.686zM15.5 43c.829 0 1.5.671 1.5 1.5 0 .78-.594 1.42-1.355 1.493L15.5 46c-.829 0-1.5-.671-1.5-1.5s.671-1.5 1.5-1.5z"
              transform="translate(-128.000000, -127.000000) translate(128.000000, 127.000000)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
