import * as React from 'react';
import s from './developerPageTopSection.scss';
import { StarFilled } from '@wix/wix-ui-icons-common';
import { Heading, Text, Avatar, Box } from '@wix/design-system';
import { BackButton } from '../../back-button';
import { composer } from '../../../component-decorators/composer';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import type { InjectedExperimentsProps } from '@wix/wix-experiments-react';
import { getClientImageURL } from '../../../common/getClientImageURL';
import type { MarketplaceStore } from '../../../component-decorators/with-marketplace-store';

export interface IDeveloperPageTopProps
  extends Partial<InjectedExperimentsProps>,
    Partial<WithTranslationProps> {
  marketplaceStore: MarketplaceStore;
  icon: string;
  appsCount: number;
  name: string;
  averageRating: number;
}

export const DeveloperPageTopSection = composer()
  .withTranslation()
  .withMarketplaceStore()
  .withExperiments()
  .compose(
    ({
      icon,
      appsCount,
      name,
      averageRating,
      marketplaceStore,
      t,
    }: IDeveloperPageTopProps) => {
      const shouldShowBackButton =
        marketplaceStore?.prevRoute &&
        marketplaceStore.prevRoute.currentUrl !==
          marketplaceStore.route.currentUrl &&
        marketplaceStore?.route?.referral !== 'back_button' &&
        marketplaceStore?.route?.referral !== 'menu_navigation';
      return (
        <>
          {shouldShowBackButton && <BackButton skinColor="dark" />}
          <section className={s.companyHeader}>
            <Box marginTop="6px">
              <Avatar
                size="size60"
                className={s.avatar}
                imgProps={{
                  className: s.logo,
                  src: getClientImageURL(icon, {
                    toHeight: 60,
                    toWidth: 60,
                  }),
                  alt: `${name} company logo`,
                }}
              />
            </Box>

            <span className={s.infoContainer}>
              <Heading appearance="H1" className={s.appName}>
                {name}
              </Heading>
              <div className={s.rating}>
                <StarFilled className={s.star} />
                <Text weight="bold" size="medium" className={s.ratingText}>
                  <span className={s.ratingNumber}>{`${averageRating} `}</span>
                  {appsCount === 1
                    ? t('developer.page.rating.info.single.app', {
                        appsCount,
                      })
                    : t('developer.page.rating.info', {
                        appsCount,
                      })}
                </Text>
              </div>
            </span>
          </section>
        </>
      );
    },
  );
