import React from 'react';
import { Image } from '@wix/design-system';
import { getClientImageURL } from './app-icon.utils';

export function AppIcon({
  iconUrl,
  appName,
  className,
}: {
  iconUrl: string;
  appName: string;
  className: string;
}) {
  return (
    <Image
      height="42px"
      width="42px"
      alt={`${appName} icon`}
      src={getClientImageURL(iconUrl, {
        toHeight: 36,
        toWidth: 36,
      })}
      className={className}
    />
  );
}
