import React, { useContext } from 'react';

export type Skin = 'Grid' | 'List';

export interface IConsumerPreferences {
  showViewAppButton?: boolean;
  showDeveloperName?: boolean;
  showRating?: boolean;
  showPricing?: boolean;
  showTopBadge?: boolean;
  skin?: Skin;
}

const consumerPreferencesContext = React.createContext<IConsumerPreferences>(
  {} as IConsumerPreferences,
);

export function ConsumerPreferencesProvider({
  children,
  preferences,
}: {
  children: React.ReactNode;
  preferences: IConsumerPreferences;
}) {
  return (
    <consumerPreferencesContext.Provider value={preferences}>
      {children}
    </consumerPreferencesContext.Provider>
  );
}

export const useConsumerPreferences = () =>
  useContext(consumerPreferencesContext);
