import type { AppsPlans } from './apps-plans';

export type queryAppsPlans = (payload: {
  appIds: string[];
}) => Promise<AppsPlans>;

export enum DurationUnit {
  UNKNOWN_UNIT = 'UNKNOWN_UNIT',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export enum CycleType {
  UNKNOWN_TYPE = 'UNKNOWN_TYPE',
  ONE_TIME = 'ONE_TIME',
  RECURRING = 'RECURRING',
}

export type IAppPlans = {
  taxSettings: TaxDetails;
  currency: CurrencySettings;
  appId: string;
  plans: IAppPlan[];
};

export type IAppPlan = {
  meterdBilling?: MeterdBilling;
  yearlyPrice?: string;
  monthlyPrice?: string;
  oneTimePrice?: string;
  vendorId: string;
};

export type CurrencySettings = {
  code: string;
  symbol: string;
};

export enum EDisplayTaxType {
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  VAT = 'VAT',
  GST = 'GST',
}

export type TaxDetails = {
  showPriceWithTax: boolean;
  displayTaxType: EDisplayTaxType;
};

export type MeterdBilling = {
  outline?: string;
  baseFee: string;
};
