import type { Origin } from '../enums/marketplace-enums';
import { EditorType } from '../enums/marketplace-enums';
import type { IRoute } from '..';
import type { IAppInstallParams } from '@wix/installation-manager-client-sdk';
import type { BundleWebSolution } from '@wix/ambassador-marketplace/types';
import type { ManagedApp } from '@wix/ambassador-devcenter-managedapps-v1-managed-app/types';

export interface IConfig {
  goto?(route: IRoute): {} | void;
  sentryClient?: any;
  baseApiUrl?: string;
  origin?: Origin;
  originForBI?: string;
  esi?: string;
  marketplaceStaticsUrl?: string;
  manageAppsStaticsUrl?: string;
  editorUrl?: string;
  editorType?: string;

  goToHomePage?(): {} | void;

  goToManageApps?(): {} | void;
  goBackToReferrer?: {
    navigation(): {} | void;
    labelKey: string;
    referrerNameForBI: string;
  };
  installApp?(IAppInstallParams): {} | void;

  manageAppsMainButtonAction?(
    app: ManagedApp | BundleWebSolution,
    bundleApps: BundleWebSolution[],
    onInstallationSuccessCb: IAppInstallParams['onInstallationSuccess'],
  ): Promise<{} | void>;

  // PLEASE do not add anything to this deprecated file!!!
}

const clientConfig: IConfig = {};

export const setConfig = (conf: IConfig) => {
  // PLEASE do not add anything to this deprecated file!!!

  clientConfig.goto = conf.goto;
  clientConfig.baseApiUrl = conf.baseApiUrl;
  clientConfig.origin = conf.origin;
  clientConfig.sentryClient = conf.sentryClient;
  clientConfig.marketplaceStaticsUrl = conf.marketplaceStaticsUrl;
  clientConfig.goToHomePage = conf.goToHomePage;
  clientConfig.goToManageApps = conf.goToManageApps;
  clientConfig.goBackToReferrer = conf.goBackToReferrer;
  clientConfig.installApp = conf.installApp;
  clientConfig.editorUrl = conf.editorUrl;
  clientConfig.editorType = conf.editorType;
  clientConfig.manageAppsMainButtonAction = conf.manageAppsMainButtonAction;
  clientConfig.originForBI =
    conf.origin === 'bizmgr'
      ? `${conf.editorType === EditorType.ADI ? 'adi_' : ''}dashboard`
      : conf.origin;
  clientConfig.manageAppsStaticsUrl = conf.manageAppsStaticsUrl;

  // PLEASE do not add anything to this deprecated file!!!
};

export const config = {
  get goto() {
    return clientConfig.goto;
  },
  get baseApiUrl() {
    return clientConfig.baseApiUrl;
  },
  get origin() {
    return clientConfig.origin;
  },
  get marketplaceStaticsUrl() {
    return clientConfig.marketplaceStaticsUrl;
  },
  get goToHomePage() {
    return clientConfig.goToHomePage;
  },
  get goToManageApps() {
    return clientConfig.goToManageApps;
  },
  get goBackToReferrer() {
    return clientConfig.goBackToReferrer;
  },
  get sentryClient() {
    return clientConfig.sentryClient;
  },
  get esi() {
    return clientConfig.esi;
  },
  get installApp() {
    return clientConfig.installApp;
  },
  get originForBI() {
    return clientConfig.originForBI;
  },
  get editorUrl() {
    return clientConfig.editorUrl;
  },
  get editorType() {
    return clientConfig.editorType;
  },
  get manageAppsMainButtonAction() {
    return clientConfig.manageAppsMainButtonAction;
  },
  get manageAppsStaticsUrl() {
    return clientConfig.manageAppsStaticsUrl;
  },
};
