import type { IAppPricingPlan } from './types';
import { AppBoxPlanType } from './types';
import type { MeterdBilling, IAppPlan } from '../apps-plans';
import type { PlanDescription, IPricingModelPlan } from '../pricing-model';
import type { SupportedAppStatuses } from '../../types';

/**
 * Represents a unified class for managing app pricing plan .
 * Retrieves app plans from the app-plans service and pricing models from the pricing-proxy service.
 * @class
 */
export class AppPricingPlan {
  constructor(
    private readonly status: SupportedAppStatuses,
    private readonly pricingModelPlan: IPricingModelPlan,
    private readonly appPlan?: IAppPlan,
  ) {}

  get vendorId(): string {
    return this.pricingModelPlan.vendorId;
  }
  get name(): string {
    return this.pricingModelPlan.name;
  }

  get title(): string | undefined {
    return this.pricingModelPlan.title;
  }

  get isVisible(): boolean {
    return this.pricingModelPlan.isVisible;
  }

  get isFree(): boolean {
    return this.pricingModelPlan.isFree;
  }

  get description(): PlanDescription {
    return this.pricingModelPlan.description;
  }
  get meterdBilling(): MeterdBilling | undefined {
    if (this.status === 'DRAFT') {
      return this.pricingModelPlan.meterdBilling;
    }
    return this.appPlan?.meterdBilling;
  }
  get yearlyPrice(): string | undefined {
    if (this.status === 'DRAFT') {
      return this.pricingModelPlan.yearlyPrice;
    }
    return this.appPlan?.yearlyPrice;
  }
  get monthlyPrice(): string | undefined {
    if (this.status === 'DRAFT') {
      return this.pricingModelPlan.monthlyPrice;
    }
    return this.appPlan?.monthlyPrice;
  }

  get oneTimePrice(): string | undefined {
    if (this.status === 'DRAFT') {
      return this.pricingModelPlan.oneTimePrice;
    }
    return this.appPlan?.oneTimePrice;
  }

  get type(): AppBoxPlanType {
    let planType = AppBoxPlanType.FREE;

    if (this.meterdBilling) {
      planType = AppBoxPlanType.METERED;
    } else if (this.oneTimePrice && this.oneTimePrice !== '0') {
      planType = AppBoxPlanType.ONE_TIME;
    } else if (
      (this.yearlyPrice && this.yearlyPrice !== '0') ||
      (this.monthlyPrice && this.monthlyPrice !== '0')
    ) {
      planType = AppBoxPlanType.RECURRING;
    }
    return planType;
  }

  get appBoxPrice(): number | undefined {
    switch (this.type) {
      case AppBoxPlanType.METERED:
        return Number(this.meterdBilling?.baseFee);
      case AppBoxPlanType.ONE_TIME:
        return Number(this.oneTimePrice);
      case AppBoxPlanType.RECURRING:
        if (!this.yearlyPrice) {
          return Number(this.monthlyPrice);
        }
        if (!this.monthlyPrice) {
          return Number(this.yearlyPrice);
        }
        return Math.min(Number(this.monthlyPrice), Number(this.yearlyPrice));
      default:
        return undefined;
    }
  }

  toJSON(): IAppPricingPlan {
    return {
      vendorId: this.vendorId,
      name: this.name,
      title: this.title,
      isVisible: this.isVisible,
      isFree: this.isFree,
      description: this.description,
      meterdBilling: this.meterdBilling,
      yearlyPrice: this.yearlyPrice,
      monthlyPrice: this.monthlyPrice,
      oneTimePrice: this.oneTimePrice,
      type: this.type,
      appBoxPrice: this.appBoxPrice,
    };
  }
}
