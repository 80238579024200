import * as React from 'react';
import { Box, Heading, Image, Text } from '@wix/design-system';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';

interface IReviewAppHeaderProps extends Partial<WithTranslationProps> {
  appName: string;
  appIconSrc: string;
}

function ReviewAppHeader({ appName, appIconSrc, t }: IReviewAppHeaderProps) {
  const headerTitle = t('review-app.header-title');
  const headerDescription = t('review-app.header-description', { appName });

  return (
    <Box>
      <Box minWidth="60px" width="60px" height="60px">
        <Image src={appIconSrc} alt={`${appName} icon`} />
      </Box>
      <Box maxWidth="450px" marginLeft="18px" direction="vertical">
        <Heading appearance="H2">{headerTitle}</Heading>
        <Text secondary>{headerDescription}</Text>
      </Box>
    </Box>
  );
}

export { IReviewAppHeaderProps, ReviewAppHeader };
