import React from 'react';
import { Box, Tag, Text, TextButton } from '@wix/design-system';
import { categoryClick } from '@wix/bi-logger-market/v2';
import { useTranslation } from '../../../../contexts/translations-context';
import {
  useEssentials,
  useJunkYard,
  useRouterContext,
  useSiteContext,
} from '../../../../contexts';
import { Path } from '../../../../models/junk-yard';
import { AppPropertiesDataHooks } from './data-hooks';
import type { RoutePayloadMap } from '../../../../models';
import { Route, RoutePath } from '../../../../models';

export interface AppCollectionsProps {
  appId: string;
  collections: {
    slug: string;
    name: string;
  }[];
  subCategories?: boolean;
}

export const AppCollections = ({
  appId,
  collections,
  subCategories,
}: AppCollectionsProps) => {
  const { t } = useTranslation();
  const { goto } = useJunkYard();
  const { biLogger, experiments } = useEssentials();
  const { router } = useRouterContext();
  const { baseURL } = useSiteContext();

  function getButtonHref(path: RoutePath, payload: RoutePayloadMap[RoutePath]) {
    const hasHref = router.hasHref();
    if (
      !experiments.enabled('specs.marketplace.routerOnNewInfra') ||
      !hasHref
    ) {
      return undefined;
    }
    const route = new Route({
      path,
      payload,
      baseURL,
    });
    return route.toHref();
  }
  function onCollectionClick(collection: { slug: string; name: string }) {
    biLogger.report(
      categoryClick({
        app_id: appId,
        tag_name: collection.name,
        tag_type: 'collection',
        origin: 'more_apps_like_this',
      }),
    );
    if (experiments.enabled('specs.marketplace.routerOnNewInfra')) {
      router.navigateTo({
        path: RoutePath.COLLECTION,
        payload: {
          slug: collection.slug,
          referral: 'app_tag',
        },
      });
    } else {
      goto({
        path: Path.COLLECTION,
        slug: collection.slug,
        referral: 'app_tag',
      });
    }
  }

  const sharedProps = ({
    collection,
    index,
  }: {
    collection: { slug: string; name: string };
    index: number;
  }): {
    href: string | undefined;
    dataHook: string;
    key: string;
    id: string;
    removable: boolean;
  } => {
    return {
      href: getButtonHref(RoutePath.COLLECTION, {
        slug: collection.slug,
        referral: 'app_tag',
      }),
      dataHook: `${AppPropertiesDataHooks.appCollection}-${index}`,
      key: collection.slug,
      id: collection.slug,
      removable: false,
    };
  };

  return (
    <Box
      dataHook={AppPropertiesDataHooks.featuredInContainer}
      direction="vertical"
    >
      <Text size="small" weight="bold">
        {t('solution.page.tagsBoxTitle')}
      </Text>
      <Box
        gap={subCategories ? '0px' : '6px'}
        flexWrap="wrap"
        marginTop={subCategories ? '2px' : '6px'}
      >
        {subCategories
          ? collections.map((collection, index) => {
              return (
                <Box>
                  <TextButton
                    as="a"
                    {...sharedProps({ collection, index })}
                    size="small"
                    weight="thin"
                    onClick={(e) => {
                      onCollectionClick(collection);
                      e.preventDefault();
                    }}
                  >
                    {collection.name}
                  </TextButton>
                  {index < collections.length - 1 && (
                    <span style={{ display: 'inline-block', color: '#116DFF' }}>
                      ,&nbsp;
                    </span>
                  )}
                </Box>
              );
            })
          : collections.map((collection, index) => {
              return (
                <Tag
                  {...sharedProps({ collection, index })}
                  onClick={(_id, e) => {
                    onCollectionClick(collection);
                    e.preventDefault();
                  }}
                  size="tiny"
                >
                  {collection.name}
                </Tag>
              );
            })}
      </Box>
    </Box>
  );
};
