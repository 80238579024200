import type { ManagedApp as ManagedAppType } from '@wix/ambassador-devcenter-managedapps-v1-managed-app/types';
import { ManagedApp } from './managed-app';

export class ManagedApps {
  constructor(private readonly managedApps: ManagedAppType[]) {}

  findByAppId(appId: string): ManagedApp {
    const managedApp = this.managedApps.find((_app) => _app.appId === appId);
    return new ManagedApp(managedApp);
  }

  isAppInstalled(appId: string): boolean {
    return this.managedApps.some((managedApp) => managedApp.appId === appId);
  }
}
