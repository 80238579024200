import React, { useEffect, useState } from 'react';
import { Box, Text, TextButton } from '@wix/design-system';
import { ThumbsUp, ThumbsUpFilled } from '@wix/wix-ui-icons-common';
import type { Opinion } from '../../../../exported-components/rate-and-reviews-section/types';
import type { ReviewType as Review } from '@wix/app-market-services';
import { useEssentials } from '../../../../contexts/essentials-context';

interface ReviewLikeProps {
  review: Review;
  onUnlikeReview: () => Promise<void>;
  onLikeReview: () => Promise<void>;
}

export function ReviewLike({
  review,
  onLikeReview,
  onUnlikeReview,
}: ReviewLikeProps) {
  const { t } = useEssentials();
  const [showTextHelpfulReview, updateShowTextHelpfulReview] =
    useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => updateShowTextHelpfulReview(false), 5000);
  }, [showTextHelpfulReview]);

  const clickLikeOrUnlikeReview = async (userOpinion?: Opinion) => {
    if (userOpinion?.id) {
      await onUnlikeReview();
    } else {
      onLikeReview().then(() => {
        updateShowTextHelpfulReview(true);
      });
    }
  };

  const isLiked = (opinion: boolean) => {
    return opinion ? <ThumbsUpFilled /> : <ThumbsUp />;
  };

  return (
    <Box align="right" marginBottom="9px" direction="vertical">
      <Box>
        <TextButton
          weight="thin"
          prefixIcon={isLiked(!!review.userOpinion?.id)}
          dataHook="like-button"
          style={{ marginRight: '6px' }}
          as="button"
          onClick={() => clickLikeOrUnlikeReview(review.userOpinion)}
        >
          {t('solution.page.helpfulReviews')}
        </TextButton>
        <Text
          skin="standard"
          secondary
          weight="thin"
          style={{ marginBottom: '6px' }}
          dataHook="total-likes"
        >
          {`(${review.totalLikes})`}
        </Text>
      </Box>
      <Box
        opacity={Number(showTextHelpfulReview)}
        transitionDuration="0.35s"
        transitionProperty="opacity"
        transitionTimingFunction="ease"
      >
        <Text
          secondary={false}
          skin="success"
          weight="thin"
          size="tiny"
          dataHook="success-message-like-review"
        >
          {t('solution.page.afterLikeOnReview')}
        </Text>
      </Box>
    </Box>
  );
}
