import * as React from 'react';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import { composer } from '../../../../../component-decorators/composer';
import { SolutionBadge } from '../../../../solution-badge/index';
import { Box } from '@wix/design-system';
import type { InjectedExperimentsProps } from '@wix/wix-experiments-react';
import type { Pricing } from '@wix/ambassador-marketplace/types';

interface ISolutionBottomActionBarProps
  extends Partial<WithTranslationProps>,
    Partial<InjectedExperimentsProps> {
  isInstalled?: boolean;
  pricing: Pricing;
  showPremiumLabel?: boolean;
  reviews?: { numberOfReviews: number; reviewStars: number };
  isBundleAppInstalled?: boolean;
  isBundleAppNotInstalled?: boolean;
  isAppSetupIncomplete?: boolean;
  savedPrice?: number;
  isVoucherApp?: boolean;
  onButtonClick(): void;
  hideCTA?: boolean;
}

export const SolutionBottomAction = composer()
  .withTranslation()
  .withExperiments()
  .compose(
    ({
      t,
      isInstalled,
      onButtonClick,
      pricing,
      showPremiumLabel,
      reviews,
      isBundleAppInstalled,
      isBundleAppNotInstalled,
      isAppSetupIncomplete,
      savedPrice,
      isVoucherApp,
      hideCTA,
      experiments,
    }: ISolutionBottomActionBarProps) => {
      const getLeftSideView = () => (
        <SolutionBadge
          t={(k, o) => t(k, o)}
          isInstalled={isInstalled}
          showPremiumLabel={showPremiumLabel}
          pricing={pricing}
          isBundleAppInstalled={isBundleAppInstalled}
          isBundleAppNotInstalled={isBundleAppNotInstalled}
          isAppSetupIncomplete={isAppSetupIncomplete}
          savedPrice={savedPrice}
        />
      );
      const handleCtaClick = (e) => {
        e.stopPropagation();

        onButtonClick();
      };

      return (
        <Box align="space-between" verticalAlign="middle">
          {getLeftSideView()}
        </Box>
      );
    },
  );
