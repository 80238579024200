import type { i18n } from '@wix/wix-i18n-config';
import type { Essentials } from '../contexts/essentials-context';
import { useTranslationsLoader } from './translations';

export const useInitEssentials = ({
  i18n,
  experiments,
}: {
  i18n: i18n;
  experiments?: Essentials['experiments'];
}): { essentials: Essentials; ready: boolean } => {
  const { t, ready } = useTranslationsLoader(i18n);
  return { essentials: { t, experiments }, ready };
};
