import React from 'react';
import {
  Box,
  Button,
  FloatingHelper,
  Heading,
  Modal,
  ModalMobileLayout,
  Text,
  TextButton,
} from '@wix/design-system';
import { useRateAndReviewSectionContext } from '../../../exported-components/rate-and-reviews-section/rate-and-reviews-section-context';
import { useEssentials } from '../../../contexts/essentials-context';
import { AppType } from '../../../exported-components/rate-and-reviews-section/types';

export function ReviewBlockedNotification({
  isOpen,
  onClose,
  triggerElement,
}: {
  isOpen: boolean;
  onClose: () => void;
  triggerElement: React.ReactNode;
}) {
  const { t } = useEssentials();
  const { isMobile, onContactSupport, app } = useRateAndReviewSectionContext();

  const notificationContent = {
    title: t('solution.page.reviews.block-helper-title'),
    description:
      app.type === AppType.TPA
        ? t('solution.page.reviews.block-helper-description', {
            appName: app.name,
          })
        : t('solution.page.reviews.block-helper-description-wix', {
            appName: app.name,
          }),
    actionText:
      app.type === AppType.TPA
        ? t('solution.page.reviews.block-helper-cta1')
        : t('solution.page.reviews.block-helper-cta2'),
  };

  if (isMobile) {
    return (
      <>
        <Modal isOpen={isOpen} zIndex={100000} screen="mobile">
          <ModalMobileLayout
            onCloseButtonClick={onClose}
            content={
              <Box
                direction="vertical"
                align="center"
                textAlign="center"
                marginTop="18px"
                marginLeft="36px"
                marginRight="36px"
              >
                <Heading size="large">{notificationContent.title}</Heading>
                <Box marginTop="18px" marginBottom="42px">
                  <Text skin="standard" secondary weight="thin">
                    {notificationContent.description}
                  </Text>
                </Box>
                <Button
                  priority="secondary"
                  onClick={() => onContactSupport('review_section')}
                >
                  {notificationContent.actionText}
                </Button>
                <Box marginBottom="18px" />
                <TextButton onClick={onClose}>
                  {t('modals-installationNotSupportedMobile-cta')}
                </TextButton>
              </Box>
            }
          />
        </Modal>
        {triggerElement}
      </>
    );
  }

  return (
    <FloatingHelper
      dataHook="write-review-floating-helper"
      onClose={onClose}
      appearance="light"
      opened={isOpen}
      target={triggerElement}
      content={
        <FloatingHelper.Content
          title={notificationContent.title}
          body={notificationContent.description}
          actionText={notificationContent.actionText}
          actionTheme="standard"
          onActionClick={() => onContactSupport('review_section')}
        />
      }
      placement="top-end"
      zIndex={6000}
    />
  );
}
