import React from 'react';
import type { IServices } from '@wix/app-market-services';
import type { FunctionComponent, ReactNode } from 'react';

const ServicesContext = React.createContext<IServices | null>(null);

export const ServicesContextProvider: FunctionComponent<{
  services: IServices;
  children: ReactNode;
}> = ({ services, children }) => (
  <ServicesContext.Provider value={services}>
    {children}
  </ServicesContext.Provider>
);

export const useServices = () => {
  const context = React.useContext(ServicesContext);

  if (context === null) {
    throw new Error(
      'useServices must be used within a ServicesContextProvider',
    );
  }

  return context;
};
