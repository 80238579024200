import React from 'react';
import ReactDOM from 'react-dom';

export interface IWithFocusIndicationInjectedProps {
  isFocused: boolean;
  setFocus(value): {};
}

interface IWithFocusIndicationState {
  isFocused: boolean;
}

export const WithFocusIndicationHOC = <
  P extends IWithFocusIndicationInjectedProps,
>(
  init: boolean,
  WrappedComponent: React.ComponentType<P>,
): React.ComponentType<Omit<P, keyof IWithFocusIndicationInjectedProps>> =>
  class extends React.Component<
    Omit<P, keyof IWithFocusIndicationInjectedProps>,
    IWithFocusIndicationState
  > {
    constructor(props) {
      super(props);
      this.state = { isFocused: init };
      this.onFocus = this.onFocus.bind(this);
      this.onBlur = this.onBlur.bind(this);
      this.setFocus = this.setFocus.bind(this);
    }

    blurTimeoutId: NodeJS.Timeout = null;

    onFocus() {
      if (this.blurTimeoutId) {
        clearTimeout(this.blurTimeoutId);
        this.blurTimeoutId = null;
      }
      this.setState({ isFocused: true });
    }

    onBlur() {
      this.blurTimeoutId = setTimeout(() => {
        this.setState({ isFocused: false });
      });
    }

    setFocus(value: boolean) {
      this.setState({ isFocused: value });
      if (value === false) {
        const node = ReactDOM.findDOMNode(this) as HTMLElement;
        const inputBox = node.querySelector('.searchInput') as HTMLElement;
        if (inputBox) {
          inputBox.blur();
        }
      }
    }

    render() {
      const newProps = {
        ...this.props,
        isFocused: this.state.isFocused,
        setFocus: this.setFocus,
      };
      return (
        <div
          tabIndex={0}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          style={{ width: 'fit-content', outline: 'none' }}
        >
          {React.createElement(WrappedComponent, newProps as P)}
        </div>
      );
    }
  };

export const WithFocusIndication: any =
  ({ init }: { init: boolean } = { init: false }) =>
  (component: React.ComponentType<IWithFocusIndicationInjectedProps>) =>
    WithFocusIndicationHOC(init, component);
