import React, { useEffect } from 'react';
import { Box, Page } from '@wix/design-system';
import { HomePageHeader } from './home-page-header';
import s from '../pages.scss';
import type { AppGroupSection, Section } from '@wix/app-market-services';
import { useAppsSectionSharedPropsMapper } from '../../hooks/apps-section-props-mapper';
import { AppsSection, CategoryCarousel } from '@wix/app-market-components';
import { useCategoryCarouselSharedPropsMapper } from '../../hooks/category-carousel-props-mapper';
import { Path } from '../../models/junk-yard';
import {
  useJunkYard,
  useEssentials,
  useServices,
  useRouterContext,
} from '../../contexts';
import { CenteredLoader } from '../../components/centered-loader';
import { QueryDecorator } from '../../decorators';
import { appMarketPageView } from '@wix/bi-logger-market/v2';
import { getPageViewBiData } from '../../bi-services/events-data-parser';
import { AppMarketPage } from '../../enums';
import { RoutePath } from '../../models';

export function HomePage() {
  const { goto, routerData } = useJunkYard();
  const { biLogger, experiments } = useEssentials();
  const { router } = useRouterContext();
  const services = useServices();
  const appsSectionSharedPropsMapper = useAppsSectionSharedPropsMapper();
  const categoryCarouselSharedPropsMapper =
    useCategoryCarouselSharedPropsMapper();
  const page = AppMarketPage.HOMEPAGE;

  function onButtonClick(slug: string) {
    if (experiments.enabled('specs.marketplace.routerOnNewInfra')) {
      router.navigateTo({
        path: RoutePath.COLLECTION,
        payload: {
          slug,
        },
      });
    } else {
      goto({ path: Path.COLLECTION, slug });
    }
  }

  useEffect(() => {
    const urlParams = location.pathname.split('/')[3];
    biLogger.report(
      appMarketPageView(
        getPageViewBiData({
          routerData,
          urlParams,
          pageType: page,
          pageName: page,
        }),
      ),
    );
  }, []);

  function renderSection(section: Section, index: number) {
    if (section.appGroup) {
      return (
        <AppsSection
          {...appsSectionSharedPropsMapper(
            section?.appGroup as AppGroupSection,
            page,
            page,
            index,
            undefined,
            undefined,
            {
              path: RoutePath.COLLECTION,
              payload: {
                slug: section?.appGroup?.tagSlug,
                referral: 'view_more_apps',
              },
            },
          )}
          onButtonClick={() => onButtonClick(section?.appGroup?.tagSlug ?? '')}
        />
      );
    }
    if (section.categoryCarousel) {
      return (
        <CategoryCarousel
          {...categoryCarouselSharedPropsMapper(section.categoryCarousel, page)}
        />
      );
    }
    return null;
  }

  return (
    <QueryDecorator
      initialFetch={() =>
        services.getDynamicSections({ placement: 'HOME_PAGE' })
      }
      loader={<CenteredLoader />}
      memoId="home-page"
    >
      {({ data: { sections } }) => (
        <Page
          className={`${s.appMarketPage} ${s.appMarketPageBackgroundColor}`}
          dataHook="homepage"
        >
          <Page.Content>
            <HomePageHeader />
            {sections.map((section, index) => (
              <Box direction="vertical" marginBottom="30px">
                {renderSection(section, index)}
              </Box>
            ))}
          </Page.Content>
        </Page>
      )}
    </QueryDecorator>
  );
}
