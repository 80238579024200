import * as React from 'react';
import type { IFinishSetupProps } from './finish-setup';
import { dtoToFinishSetupProps, FinishSetup } from './finish-setup';
import { FetchStatus } from '../view-rapper';
import { getSolutionPreviewBySlugOrId } from '../../api/api';
import { WithFetcher } from '../../component-decorators/with-fetcher';
import { CenteredLoader } from '../centered-loader';
import type { MarketplaceStore } from '../../component-decorators/with-marketplace-store';
import { withMarketplaceStore } from '../../component-decorators/with-marketplace-store';
import type { IConfigContext } from '../../config-context';
import { useConfigContext } from '../../config-context';
import { withExperiments } from '../../component-decorators/with-experiments';
import type { InjectedExperimentsProps } from '@wix/wix-experiments-react';
import { getInstalledApps } from '../../common/utils';

export interface IFinishSetupContainerProps
  extends Partial<InjectedExperimentsProps> {
  marketplaceStore?: MarketplaceStore;
  slug: string;
  appVersion: string;
}

interface IFinishSetupConnectedContainerState {
  isFetchingData: FetchStatus;
  finishSetupProps: IFinishSetupProps;
}

interface IFinishSetupConnectedContainerProps
  extends IFinishSetupContainerProps {
  configContext: IConfigContext;
}

export const FinishSetupContainer = (props: IFinishSetupContainerProps) => {
  const configContext = useConfigContext();
  return (
    <FinishSetupConnectedContainer {...props} configContext={configContext} />
  );
};

@withMarketplaceStore
@withExperiments
export class FinishSetupConnectedContainer extends React.Component<
  IFinishSetupConnectedContainerProps,
  IFinishSetupConnectedContainerState
> {
  constructor(props: IFinishSetupConnectedContainerProps) {
    super(props);
    this.state = {
      isFetchingData: FetchStatus.FETCHING,
      finishSetupProps: null,
    };
  }

  dataFetcher = () => {
    const { getAppInstance, locale } = this.props.configContext;
    const signedInstance = getAppInstance
      ? getAppInstance('22bef345-3c5b-4c18-b782-74d4085112ff')
      : undefined;

    return getInstalledApps(signedInstance, locale).then((apps) => {
      const appFromInstalled = apps?.find(
        (installedApp) => installedApp.appId === this.props.slug,
      );
      if (appFromInstalled) {
        return appFromInstalled;
      }
      return getSolutionPreviewBySlugOrId(
        true,
        this.props.slug,
        this.props.configContext.locale,
        this.props.experiments,
      ).then((webSolution) => {
        return webSolution;
      });
    });
  };

  render() {
    return (
      <WithFetcher
        loader={<CenteredLoader />}
        initialFetch={this.dataFetcher}
        fetchMemoizationId="finish-setup"
      >
        {({ fetchData }) => (
          <FinishSetup
            {...dtoToFinishSetupProps(fetchData, this.props.appVersion)}
          />
        )}
      </WithFetcher>
    );
  }
}
