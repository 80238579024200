import React from 'react';
import { Text, Box } from '@wix/design-system';
import { StatusCompleteFilledSmall } from '@wix/wix-ui-icons-common';

export interface InstalledBadgeProps {
  children: string;
  size?: 'tiny' | 'small';
}

export function InstalledBadge({
  children,
  size = 'tiny',
}: InstalledBadgeProps) {
  return (
    <Box gap="4px" verticalAlign="middle">
      <StatusCompleteFilledSmall color="#60bc57" />
      <Text size={size} weight="thin" skin="success" dataHook="installed-text">
        {children}
      </Text>
    </Box>
  );
}
