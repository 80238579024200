import type { AppBoxData } from '@wix/ambassador-appmarket-v1-app-market-client-aggregator/types';
import type { AppDataType } from './types';

export class AppData {
  constructor(private readonly appData: AppBoxData) {}

  toJSON(): AppDataType {
    return {
      id: this.appData.id ?? '',
      slug: this.appData.slug ?? '',
      name: this.appData.name ?? '',
      icon: this.appData.icon ?? '',
      promotionalImage: this.appData.promotionalImage ?? '',
      shortDescription: this.appData.shortDescription ?? '',
      installation: this.appData.installation! ?? {
        isInstalled: false,
        isSetupIncomplete: false,
        requirements: { premiumSite: false },
        isBuiltIn: false,
      },
      reviews: this.appData.reviews! ?? {
        averageRating: undefined,
        totalCount: undefined,
      },
      company: {
        name: this.appData.company?.name ?? '',
        privacyPolicyUrl: this.appData.company?.privacyPolicyUrl ?? '',
        website: this.appData.company?.website ?? '',
        icon: this.appData.company?.icon ?? '',
        slug: this.appData.company?.slug ?? '',
      },
      pricing: this.appData.pricing ?? { currencySettings: undefined },
      freeTrialDays:
        this.appData.pricing?.label?.freeTrialDaysLabelData?.freeTrialDays ?? 0,
      bundle: this.appData.bundle! ?? {
        isBundleApp: undefined,
        type: undefined,
      },
      badges: this.appData.badges ?? [],
    };
  }
}
