import type { AppComponents as AppComponentsType } from '@wix/ambassador-devcenter-app-components-v1-app-components/types';
import { AppComponents } from './app-components';

/**
 * Represents facade entity for GetAppComponentsByAppIdsResponse type on the app-plans service.
 *
 * @class AppsComponents
 */
export class AppsComponents {
  constructor(
    private readonly appComponents: AppComponentsType[] | undefined,
  ) {}

  findByAppId(appId: string): AppComponents {
    return new AppComponents(
      this.appComponents?.find((appComponent) => appComponent.appId === appId),
    );
  }
}
