import type { AppReviewsSummary as AppReviewsSummaryType } from '@wix/ambassador-appreviewssummaries-v1-app-reviews-summary/types';
import { AppReviewsSummary } from './appReviewSummary';

export class AppReviewsSummaries {
  constructor(private readonly appsReviewSummaries: AppReviewsSummaryType[]) {}

  findByAppId(appId: string): AppReviewsSummary {
    const appReviewsSummary = this.appsReviewSummaries?.find(
      (_reviewSummary) => _reviewSummary.appId === appId,
    );
    return new AppReviewsSummary(appReviewsSummary ?? {});
  }
}
