import { biLogger } from './index';
import type { ISolutionBiData, ITagBiData } from '../types';
import { AppMarketWidget } from '../types';
import {
  openAppClick,
  appModalOpenAppModalClick,
  appMarketAppImpression,
  addAppClick,
  appMarketWidgetsClickAction,
  appMarketWidgetsNavigationClick,
  manageAppsAddApp,
  wixOfferingBoxAction,
} from '@wix/bi-logger-market/v2';

export const getSolutionBiData = (
  solutionsBiData: ISolutionBiData[],
  index: number,
) => {
  if (solutionsBiData && solutionsBiData.length > index) {
    return solutionsBiData[index];
  }
};

export const sendOpenSolutionPageBi = (biData: ISolutionBiData) => {
  if (biData) {
    biLogger
      .report(
        appModalOpenAppModalClick({
          app_id: biData.appId,
          index: biData.index,
          market: biData.market,
          tag_name: biData.tagName,
          sub_tag_name: biData.subCategory,
          tag_type: biData.tagType,
          msid: biData.msid,
          section: biData.section,
          referral_info: biData.referralInfo,
          search_term: biData.searchTerm,
          widget_referral: biData.widgetReferral,
          collimp_id: biData.collimp_id,
          slug_id: biData.referralSectionName || biData.section,
          referral_tag_name: biData?.referralTag,
          referral_tag_type: biData?.referralTagType,
          referral_sub_tag_name: biData?.referralSubTag,
        }),
      )
      .catch((e) => console.log(e));
  }
};

export const sendOpenWixOfferingBi = (
  biData: ISolutionBiData,
  name: string,
  actionType: string,
) => {
  if (biData) {
    biLogger
      .report(
        wixOfferingBoxAction({
          app_id: biData.appId,
          market: biData.market,
          action_type: actionType,
          msid: biData.msid,
          offering_name: name,
          search_term: biData.searchTerm,
          referral_info: biData.referralInfo,
        }),
      )
      .catch((e) => console.log(e));
  }
};

export const sendSolutionImpressionBi = (biData: ISolutionBiData) => {
  if (biData) {
    biLogger
      .report(
        appMarketAppImpression({
          app_id: biData.appId,
          index: biData.index,
          market: biData.market,
          tag_name: biData.tagName,
          tag_type: biData.tagType,
          msid: biData.msid,
          impression_type: biData.impressionType,
          section: biData.section,
          search_term: biData.searchTerm,
          badge_name:
            biData.isBundleAppInstalled || biData.isBundleAppNotInstalled
              ? 'premium_benefit'
              : '',
          slug_id: biData.referralSectionName || biData.section,
        }),
      )
      .catch((e) => console.log(e));
  }
};

export const sendAddClick = (biData: ISolutionBiData) => {
  if (biData) {
    biLogger
      .report(
        addAppClick({
          app_id: biData.appId,
          index: biData.index,
          market: biData.market,
          tag_name: biData.tagName,
          tag_type: biData.tagType,
          msid: biData.msid,
          section: biData.section,
          referral_info: biData.referralInfo,
          search_term: biData.searchTerm,
          widget_referral: biData.widgetReferral,
          slug_id: biData.referralSectionName || biData.section,
        }),
      )
      .catch((e) => console.log(e));
  }
};

export const sendOpenClick = (biData: ISolutionBiData) => {
  if (biData) {
    biLogger
      .report(
        openAppClick({
          app_id: biData.appId,
          index: biData.index,
          market: biData.market,
          tag_name: biData.tagName,
          tag_type: biData.tagType,
          msid: biData.msid,
          section: biData.section,
          search_term: biData.searchTerm,
        }),
      )
      .catch((e) => console.log(e));
  }
};

export const sendWidgetClick = (biData: ITagBiData) => {
  if (biData) {
    biLogger
      .report(
        appMarketWidgetsClickAction({
          // index: biData.index,
          market: biData.market,
          tag_name: biData.tagName,
          tag_name_clicked: biData.tagNameClicked,
          tag_type: biData.tagType,
          tag_type_clicked: biData.tagTypeClicked,
          msid: biData.msid,
          section: biData.section,
        }),
      )
      .catch((e) => console.log(e));
  }
};

export const sendWidgetNavigation = (biData: ITagBiData, direction: string) => {
  if (biData) {
    biLogger
      .report(
        appMarketWidgetsNavigationClick({
          // index: biData.index,
          market: biData.market,
          tag_name: biData.tagName,
          tag_type: biData.tagType,
          msid: biData.msid,
          section: biData.section,
          direction,
        }),
      )
      .catch((e) => console.log(e));
  }
};

export const sendFinanceAddMoreAppsClickBi = (msid) => {
  if (msid) {
    biLogger
      .report(
        manageAppsAddApp({
          // index: biData.index,
          market: 'dashboard',
          msid,
          referral_info: AppMarketWidget.FINANCE_INTEGRATIONS,
        }),
      )
      .catch((e) => console.log(e));
  }
};
