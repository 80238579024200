import React, { useEffect, useRef, useState } from 'react';
import { Box, Text } from '@wix/design-system';
import { AppsSection } from '@wix/app-market-components';
import { useServices } from '../../contexts';
import { getApps } from './search-results-page-data';
import { useAppsSectionSharedPropsMapper } from '../../hooks/apps-section-props-mapper';
import { AppMarketPage } from '../../enums';
import { useTranslation } from '../../contexts/translations-context';
import type { AppGroupSection, AppDataType } from '@wix/app-market-services';
import { SearchResultsEmptyState } from './empty-search-result-state';
import { CenteredLoader } from '../../components/centered-loader';

interface ResultsProps {
  isAppsFound: boolean;
  searchTerm: string;
  paging: { hasNext: boolean; total: number };
  appGroup: AppGroupSection;
}

export function Results({
  isAppsFound,
  searchTerm,
  paging,
  appGroup,
}: ResultsProps) {
  const pageName = AppMarketPage.SEARCH_RESULT;
  const appsSectionSharedPropsMapper = useAppsSectionSharedPropsMapper();
  const [apps, setApps] = useState<AppDataType[]>(
    appGroup.apps as AppDataType[],
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [moreAppsToLoad, setMoreAppsToLoad] = useState<boolean>(paging.hasNext);

  const services = useServices();
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  async function fetchMoreApps() {
    setIsLoading(true);
    const { apps: nextApps, hasNext } = await getApps({
      services,
      searchTerm,
      offset: apps.length,
    });
    setIsLoading(false);

    setMoreAppsToLoad(nextApps.length > 0 && hasNext);
    setApps((prevApps) => [...prevApps, ...nextApps]);
  }

  function onIntersection(entries: IntersectionObserverEntry[]) {
    const isIntersecting = entries.some((entry) => entry.isIntersecting);
    if (isIntersecting && moreAppsToLoad) {
      fetchMoreApps();
    }
  }
  useEffect(() => {
    const observer = new IntersectionObserver(onIntersection);
    if (moreAppsToLoad) {
      observer.observe(ref.current!);
    }
    return () => {
      observer.disconnect();
    };
  }, [apps]);

  return (
    <>
      {isAppsFound ? (
        <>
          <Box marginBottom="-6px">
            <Text weight="normal" size="small" secondary>
              {t('collection.apps-counter', { appsCount: paging.total })}
            </Text>
          </Box>
          <Box
            ref={ref}
            dataHook="search-results-page-apps-section-box"
            direction="vertical"
          >
            <AppsSection
              showBorder={false}
              {...appsSectionSharedPropsMapper(
                { ...appGroup, apps: apps as AppGroupSection['apps'] },
                pageName,
                searchTerm,
                0,
              )}
            ></AppsSection>
          </Box>
        </>
      ) : (
        <SearchResultsEmptyState searchTerm={searchTerm} />
      )}
      {isLoading && (
        <Box marginTop="24px" direction="vertical">
          <CenteredLoader />
        </Box>
      )}
    </>
  );
}
