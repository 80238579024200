import * as React from 'react';
import type { IModalProps } from '../modals';
import { biLogger } from '../../../bi';
import {
  appMarketCloseDialogBoxOfUpgradeWixX,
  appMarketUpgradeSiteConnectDomainDialogBox,
} from '@wix/bi-logger-market/v2';
import { config } from '../../../config';
import s from '../modals.scss';

import { Modal, MessageBoxMarketerialLayout } from '@wix/design-system';
import { composer } from '../../../component-decorators/composer';
import type { TFunction } from 'i18next';

interface IAdiEditorModalProps extends IModalProps {
  t?: TFunction;
}
export const AdiEditorModal = composer()
  .withTranslation()
  .withMarketplaceStore()
  .compose(
    ({
      t,
      appId,
      msid,
      marketplaceStore,
      appIcon,
      appName,
    }: IAdiEditorModalProps) => {
      const handleClose = () => {
        biLogger.report(
          appMarketCloseDialogBoxOfUpgradeWixX({
            app_id: appId,
            msid,
          }),
        );
        marketplaceStore.closeAllModals();
      };

      const handleConnect = () => {
        window.open(`${config.editorUrl}etpa/add-app?${appId}`);
      };

      biLogger.report(
        appMarketUpgradeSiteConnectDomainDialogBox({
          appId,
          msid,
          stepName: 'adi_editor',
        }),
      );

      return (
        <Modal isOpen={true}>
          <MessageBoxMarketerialLayout
            title={
              <>
                <img className={s.appIcon} src={appIcon} />
                <div>{t('modals-adiEditor-header', { appName })}</div>
              </>
            }
            content={t('modals-adiEditor-content', { appName })}
            theme="white"
            primaryButtonLabel={t('modals-adiEditor-cta')}
            primaryButtonTheme="blue"
            onClose={() => handleClose()}
            onPrimaryButtonClick={() => handleConnect()}
          />
        </Modal>
      );
    },
  );
