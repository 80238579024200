import React, { useEffect } from 'react';
import { Page } from '@wix/design-system';
import { useEssentials, useJunkYard, useServices } from '../../contexts';
import { QueryDecorator } from '../../decorators';
import { getSearchResultsPageData } from './search-results-page-data';
import type { SearchResultsPageData } from './search-results-page-data';
import { AppMarketPage } from '../../enums';
import { getPageViewBiData } from '../../bi-services/events-data-parser';
import { appMarketPageView } from '@wix/bi-logger-market/v2';
import { appMarketPageStartLoadedSrc24Evid163 } from '@wix/bi-logger-app-market-data/v2';
import { SearchResultsSkeleton } from './search-results-skeleton';
import { Header } from './header';
import { Results } from './results';
import s from '../pages.scss';

function SearchResultsPageComponent({
  searchTerm,
  appGroup,
  paging,
}: SearchResultsPageData) {
  const pageName = AppMarketPage.SEARCH_RESULT;

  const { routerData } = useJunkYard();
  const { biLogger } = useEssentials();

  const isAppsFound = paging.total > 0;

  useEffect(() => {
    const urlParams = location.pathname.split('/')[3];
    biLogger.report(
      appMarketPageView(
        getPageViewBiData({
          routerData,
          urlParams,
          pageType: pageName,
          pageName: searchTerm,
        }),
      ),
    );
  }, []);

  return (
    <Page className={s.appMarketPage}>
      <Page.Content>
        <Header {...{ isAppsFound, searchTerm }} />

        <Results {...{ isAppsFound, searchTerm, appGroup, paging }} />
      </Page.Content>
    </Page>
  );
}

export function SearchResultsPage({ searchTerm }: { searchTerm: string }) {
  const { biLogger, i18n } = useEssentials();
  const services = useServices();

  useEffect(() => {
    biLogger.report(
      appMarketPageStartLoadedSrc24Evid163({
        tag_type: AppMarketPage.SEARCH_RESULT,
      }),
    );
  }, [searchTerm]);

  return (
    <QueryDecorator
      initialFetch={() =>
        getSearchResultsPageData({
          searchTerm,
          services,
        })
      }
      loader={<SearchResultsSkeleton />}
      memoId={`search-result-${searchTerm}-${i18n.language}`}
    >
      {({ data: searchResultPageData }) => (
        <SearchResultsPageComponent {...searchResultPageData} />
      )}
    </QueryDecorator>
  );
}
