import React from 'react';
import { Box, Text, Avatar } from '@wix/design-system';
import { useEssentials } from '../../contexts/essentials-context';

export interface CompanyInfoHeaderProps {
  name: string;
  imageURL?: string;
}

export const CompanyInfoHeaderSection = ({
  name,
  imageURL,
}: CompanyInfoHeaderProps) => {
  const { t } = useEssentials();

  return (
    <Box padding="24px 24px 18px" gap="12px" verticalAlign="middle">
      <Avatar
        shape="circle"
        size="size36"
        name={name}
        color="A1"
        imgProps={{
          src: imageURL,
          width: '36px',
          height: '36px',
          alt: name,
        }}
      />
      <Box direction="vertical">
        <Text size="small" weight="thin" secondary>
          {t('app.companyInfo.appDevelopedBy')}
        </Text>
        <Text dataHook="company-info-name" size="medium" weight="bold">
          {name}
        </Text>
      </Box>
    </Box>
  );
};
