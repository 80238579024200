import * as React from 'react';
import { httpClient } from '../http-client';

export interface InjectedUserDetailsProps {
  userImage: string;
  username: string;
  links?: IUserDetailsLinks;
}

interface IUserDetails {
  userImage: string;
  username: string;
}

interface IUserDetailsApiResponse {
  user: {
    first: string | null;
    last: string | null;
    userName: string;
    photo?: string;
  };
}

interface IUserDetailsLinks {
  signin: string;
  logout: string;
}

const links: IUserDetailsLinks = {
  signin:
    'https://users.wix.com/signin?loginDialogContext=login&referralInfo=HEADER&postLogin=https:%2F%2Fwix.com/app-market&postSignUp=https:%2F%2Fwix.com/app-market',
  logout: 'https://www.wix.com/signout?redirectTo=https://wix.com/app-market',
};
const defaultUserDetails = {
  photo:
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMzYiIGhlaWdodD0iMzYiIHZpZXdCb3g9IjAgMCAzNiAzNiI+CiAgICA8ZGVmcz4KICAgICAgICA8cmVjdCBpZD0iYSIgd2lkdGg9IjM2IiBoZWlnaHQ9IjM2IiByeD0iMTgiLz4KICAgIDwvZGVmcz4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPG1hc2sgaWQ9ImIiIGZpbGw9IiNmZmYiPgogICAgICAgICAgICA8dXNlIHhsaW5rOmhyZWY9IiNhIi8+CiAgICAgICAgPC9tYXNrPgogICAgICAgIDx1c2UgZmlsbD0iI0RBRUZGRSIgZmlsbC1ydWxlPSJub256ZXJvIiB4bGluazpocmVmPSIjYSIvPgogICAgICAgIDxwYXRoIGZpbGw9IiNCMUQ5RjciIGZpbGwtcnVsZT0ibm9uemVybyIgZD0iTTMwIDM1YzAtNS4xMy0zLjIxOC05LjUwNy03Ljc0NS0xMS4yMjRhOCA4IDAgMSAwLTguNTEgMEM5LjIxOCAyNS40OTMgNiAyOS44NzEgNiAzNXYxaDI0di0xeiIgbWFzaz0idXJsKCNiKSIvPgogICAgPC9nPgo8L3N2Zz4K',
};

const getUserDetailsFromResponse = (
  response: Partial<IUserDetailsApiResponse>,
): Partial<IUserDetails> => {
  if (response && response.user) {
    const { user } = response;
    const username =
      user.first !== null && user.last !== null
        ? `${user.first} ${user.last}`
        : user.userName;
    return {
      userImage: user.photo ? user.photo : defaultUserDetails.photo,
      username,
    };
  }
  return {};
};

const fetchUserDetails = async (): Promise<Partial<IUserDetails> | void> => {
  const getUserDetailsEndpoint = `https://${
    window.location.host === 'localhost:5000' ||
    window.location.host === 'local.wix.com:5000'
      ? 'www.wix.com'
      : window.location.host
  }/_api/dev-center/user-details`;
  return httpClient
    .get(getUserDetailsEndpoint)
    .then(({ data }: { data: IUserDetailsApiResponse }) => data)
    .then(getUserDetailsFromResponse)
    .catch((error) => console.error('error:', error));
};

export const UserDetailsContext = React.createContext<InjectedUserDetailsProps>(
  {
    userImage: undefined,
    username: undefined,
  },
);

export const UserDetailsConsumer = UserDetailsContext.Consumer;

export const UserDetailsProvider = ({
  children,
  marketplaceStore,
  shouldFetchData,
}) => {
  const [username, setUsername] = React.useState(undefined);
  const [userImage, setUserImage] = React.useState(undefined);

  React.useEffect(() => {
    async function asyncFetchUserDetails(): Promise<Partial<IUserDetails> | void> {
      return fetchUserDetails();
    }
    if (shouldFetchData) {
      asyncFetchUserDetails().then(
        (userDetailsResponse: Partial<IUserDetails>) => {
          if (userDetailsResponse) {
            const { username, userImage } = userDetailsResponse;
            username && setUsername(username);
            userImage && setUserImage(userImage);
          }
        },
      );
    }
  }, []);

  React.useEffect(() => {
    username && marketplaceStore.setUserName(username);
  }, [username]);

  return (
    <UserDetailsContext.Provider value={{ username, userImage, links }}>
      {children}
    </UserDetailsContext.Provider>
  );
};

export const withUserDetails = (Component) => {
  return (props) => (
    <UserDetailsConsumer>
      {({ username, userImage, links: userDetailsLinks }) => {
        return (
          <Component
            links={userDetailsLinks}
            username={username}
            userImage={userImage}
            {...props}
          />
        );
      }}
    </UserDetailsConsumer>
  );
};
