import { SectionHelper, Text } from '@wix/design-system';
import React from 'react';
import { useTranslation } from '../../../../../contexts/translations-context';
import { SetupIncompleteNotificationDataHooks } from './data-hooks';

export interface SetupIncompleteNotificationProps {
  onCompleteClick(): void;
}

export function SetupIncompleteNotification({
  onCompleteClick,
}: SetupIncompleteNotificationProps) {
  const { t } = useTranslation();

  return (
    <SectionHelper
      dataHook={SetupIncompleteNotificationDataHooks.container}
      appearance="warning"
      actionText={t('solution.page.incompleteSetupHelper.actionButtonText')}
      onAction={onCompleteClick}
      title={t('solution.page.incompleteSetupHelper.title')}
      size="small"
    >
      <Text
        dataHook={SetupIncompleteNotificationDataHooks.description}
        size="tiny"
        weight="thin"
      >
        {t('solution.page.incompleteSetupHelper.content')}
      </Text>
    </SectionHelper>
  );
}
