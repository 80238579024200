import { APPS_LIMIT, SortType, AppGroupType } from '@wix/app-market-services';
import type { AppGroupSection, IServices } from '@wix/app-market-services';

export interface CollectionPageData {
  tagId: string;
  tagType: string;
  slug: string;
  primaryTitle: string;
  secondaryTitle: string;
  description: string;
  appGroup: AppGroupSection;
  paging: {
    total: number;
    hasNext: boolean;
  };
}

export async function getApps({
  services,
  tagId,
  offset,
}: {
  services: IServices;
  tagId: string;
  offset: number;
}) {
  const { apps, hasNext } = await services.getAppsDataByTagId({
    tagId,
    sortTypes: [SortType.COUNTRY_CODE, SortType.APP_MARKET_SCORE],
    limit: APPS_LIMIT,
    offset,
    status: 'PUBLISHED',
  });

  return {
    apps,
    hasNext,
  };
}

export async function getCollectionPageData({
  services,
  slug,
  languageCode,
}: {
  services: IServices;
  slug: string;
  languageCode: string;
}): Promise<CollectionPageData> {
  const tags = await services.queryTag({ slugs: [slug], languageCode });
  const tag = tags.findBySlug(slug);
  const tagId = tag?.id;

  const { apps, hasNext, total } = await services.getAppsDataByTagId({
    tagId,
    sortTypes: [SortType.COUNTRY_CODE, SortType.APP_MARKET_SCORE],
    limit: APPS_LIMIT,
    offset: 0,
    status: 'PUBLISHED',
  });

  return {
    tagType: tag.type,
    tagId,
    slug,
    primaryTitle: tag.name,
    secondaryTitle: tag.collectionTeaser,
    description: tag.description,
    appGroup: {
      type: AppGroupType.COLLECTION,
      tagSlug: slug,
      apps: apps as AppGroupSection['apps'],
      title: '',
      totalAppsCount: 0,
    },
    paging: {
      total,
      hasNext,
    },
  };
}
