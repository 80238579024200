import i18next from 'i18next';

export const getI18nSSRInitator = () => {
  let promise;
  let loaded = false;
  let instance;

  return (locale: string) => {
    if (loaded) {
      return instance;
    }
    if (!promise) {
      instance = i18next.createInstance();
      instance.use({
        type: 'backend',
        read: (
          language: string,
          _namespace: string,
          callback: (
            err: any | null,
            translations?: Record<string, string>,
          ) => void,
        ) => {
          return import(`../locales/messages_${language}.json`)
            .then((translation: Record<string, string>) =>
              callback(null, translation),
            )
            .catch((error) => callback(error));
        },
      });
      promise = instance
        .init({
          lng: locale,
          fallbackLng: 'en',
          keySeparator: false,
          react: {
            wait: true,
          },
          interpolation: { escapeValue: false },
        })
        .then(() => (loaded = true));
    }

    throw promise;
  };
};
