import * as React from 'react';
import type { starRatingBarValue } from '@wix/design-system';
import {
  Box,
  FloatingNotification,
  FormField,
  Input,
  InputArea,
  StarsRatingBar,
} from '@wix/design-system';
import { StatusAlert } from '@wix/wix-ui-icons-common';
import type { IReviewFormInstance } from './use-review-form';
import { useEssentials } from '../../../contexts/essentials-context';

interface IReviewModalFormProps {
  appName: string;
  reviewForm: IReviewFormInstance;
}

export function ReviewModalForm({
  appName,
  reviewForm,
}: IReviewModalFormProps) {
  const { t, experiments } = useEssentials();
  const enableOptionalReviewTitle = experiments?.enabled(
    'specs.marketplace.OptionalTitleReviewModal',
  );

  const rateStarsDescription: [string, string, string, string, string] = [
    t('review-app.rate-one-star-description'),
    t('review-app.rate-two-stars-description'),
    t('review-app.rate-three-stars-description'),
    t('review-app.rate-four-stars-description'),
    t('review-app.rate-five-stars-description'),
  ];

  const reviewTitleStatusMessage = reviewForm.titleStatus
    ? t('review-app.review-title-error-tooltip')
    : undefined;

  const reviewDescriptionInputHeight = !reviewForm.errorKey ? '149px' : '101px';

  return (
    <Box direction="vertical">
      {Boolean(reviewForm.errorKey) && (
        <Box direction="vertical" marginBottom="12px">
          <FloatingNotification
            type="destructive"
            prefixIcon={<StatusAlert />}
            text={t(reviewForm.errorKey)}
            showCloseButton={false}
          />
        </Box>
      )}

      <Box direction="vertical" marginBottom="24px">
        <FormField label={t('review-app.rate-label')} required>
          <StarsRatingBar
            dataHook="review-rating-stars"
            value={reviewForm.rate as starRatingBarValue}
            descriptionValues={rateStarsDescription}
            onChange={(rate) => reviewForm.setRate(rate as starRatingBarValue)}
          />
        </FormField>
      </Box>

      <Box direction="vertical" marginBottom="24px">
        <FormField
          label={
            enableOptionalReviewTitle
              ? t('review.app.review.title.label.optional')
              : t('review-app.review-title-label')
          }
          required={!enableOptionalReviewTitle}
          charCount={100 - reviewForm.title.length}
        >
          <Input
            dataHook="review-title-input"
            value={reviewForm.title}
            placeholder={t('review-app.review-title-placeholder')}
            onChange={(event) => reviewForm.setTitle(event.target.value)}
            maxLength={100}
            {...(enableOptionalReviewTitle
              ? {}
              : {
                  status: reviewForm.titleStatus,
                  statusMessage: reviewTitleStatusMessage,
                  onBlur: () => reviewForm.updateTitleStatus(reviewForm.title),
                })}
          />
        </FormField>
      </Box>

      <Box direction="vertical" marginBottom="2px">
        <FormField
          label={t('review-app.review-description-label')}
          charCount={1000 - reviewForm.description.length}
        >
          <InputArea
            dataHook="review-description-input-area"
            value={reviewForm.description}
            placeholder={t('review-app.review-description-placeholder', {
              appName,
            })}
            onChange={(event) => reviewForm.setDescription(event.target.value)}
            maxLength={1000}
            minHeight={reviewDescriptionInputHeight}
          />
        </FormField>
      </Box>
    </Box>
  );
}
