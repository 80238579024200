import React, { Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';
import { i18nInit } from './i18nInit-browser';
import { getI18nSSRInitator } from './i18nInit-server';
import type { i18n as i18nType } from 'i18next';

const isBrowser = () => typeof window !== 'undefined';

const i18nConfig: { i18n?: i18nType } = {};

export const getI18nInstance = () => i18nConfig.i18n;

const setI18nInstance = (i18n: i18nType) => {
  i18nConfig.i18n = i18n;
};

export const TranslationsProvider = ({
  locale,
  children,
  i18n,
}: {
  locale: string;
  children: any;
  i18n?: i18nType;
}) => {
  if (i18n) {
    setI18nInstance(i18n);
    return children;
  }
  if (isBrowser()) {
    return (
      <I18nextProvider i18n={i18nInit(locale)}>{children}</I18nextProvider>
    );
  }
  const i18nInitSSR = getI18nSSRInitator();
  return (
    <Suspense fallback={null}>
      <SSRI18nextProvider locale={locale} i18nInitSSR={i18nInitSSR}>
        {children}
      </SSRI18nextProvider>
    </Suspense>
  );
};

const SSRI18nextProvider = ({ locale, children, i18nInitSSR }) => {
  const i18nResources = i18nInitSSR(locale);
  return <I18nextProvider i18n={i18nResources}>{children}</I18nextProvider>;
};
