import type {
  WebSolutionBase as WebSolutionBaseType,
  PagingMetadataV2,
} from '@wix/ambassador-devcenter-ams-v1-app-market-search/types';
import { WebSolutionsBase } from '../web-solutions';
import { PagingMetadata } from '../common/paging-metadata';

export class SearchApps {
  private readonly webSolutions: WebSolutionsBase;
  private readonly pagingMetadata: PagingMetadata;

  constructor(
    _webSolutions: WebSolutionBaseType[],
    _pagingMetadata?: PagingMetadataV2,
  ) {
    this.webSolutions = new WebSolutionsBase(_webSolutions);
    this.pagingMetadata = new PagingMetadata(_pagingMetadata);
  }

  get appIds(): string[] {
    return this.webSolutions.appIds;
  }

  get totalApps(): number {
    return this.pagingMetadata.total;
  }

  get hasMoreApps(): boolean {
    return this.pagingMetadata.hasNext;
  }
}
