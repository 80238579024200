import React from 'react';
import type { IRouterContext } from '../models';
import type { FunctionComponent, ReactNode } from 'react';

const RouterContext = React.createContext<IRouterContext | null>(null);

export const RouterContextProvider: FunctionComponent<{
  router: IRouterContext;
  children: ReactNode;
}> = ({ router, children }) => (
  <RouterContext.Provider value={router}>{children}</RouterContext.Provider>
);

export const useRouterContext = (): IRouterContext => {
  const context = React.useContext(RouterContext);
  if (context === null) {
    throw new Error(
      'useRouterContext must be used within a RouterContextProvider',
    );
  }
  return context;
};
