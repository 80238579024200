import type { AppReviewsSummary as ReviewsSummaryType } from '@wix/ambassador-appreviewssummaries-v1-app-reviews-summary/types';

export type AppReviewsSummaryType = {
  totalReviews: number;
  averageRating: number;
  ratingHistogram: {
    rating1?: number;
    rating2?: number;
    rating3?: number;
    rating4?: number;
    rating5?: number;
  };
};
export class AppReviewsSummary {
  constructor(private readonly appsReviewSummary: ReviewsSummaryType) {}

  toJSON(): AppReviewsSummaryType {
    return {
      totalReviews: this.appsReviewSummary.totalReviews ?? 0,
      averageRating: this.appsReviewSummary.averageRating ?? 0,
      ratingHistogram: this.appsReviewSummary.ratingHistogram ?? {},
    };
  }
}
