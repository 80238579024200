import React from 'react';
import type { ISiteContext } from '../models';
import type { FunctionComponent, ReactNode } from 'react';

const SiteContext = React.createContext<ISiteContext | null>(null);

export const SiteContextProvider: FunctionComponent<{
  site: ISiteContext;
  children: ReactNode;
}> = ({ site, children }) => (
  <SiteContext.Provider value={site}>{children}</SiteContext.Provider>
);

export const useSiteContext = (): ISiteContext => {
  const siteContext = React.useContext(SiteContext);
  if (siteContext === null) {
    throw new Error('useSiteContext must be used within a SiteContextProvider');
  }
  return siteContext;
};
