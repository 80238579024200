import type {
  IServices,
  AppReviewsSummaryType,
  ReviewType as Review,
} from '@wix/app-market-services';

export type getReviewsSectionResponse = {
  reviews: Review[];
  userReview?: Review;
  reviewsSummary: AppReviewsSummaryType;
};

export async function getReviewsSectionData({
  services,
  appId,
  sortMethod = 'publishedAt',
  limit = 5,
  offset = 0,
}: {
  services: IServices;
  limit?: number;
  appId: string;
  sortMethod?: string;
  offset?: number;
}): Promise<getReviewsSectionResponse> {
  const [reviews, userReview, appReviewsSummary] = await Promise.all([
    services.queryReviews({ appId, sortMethod, limit, offset }),
    services.getUserReview({ appId }),
    services.queryAppReviewsSummary({ appId }),
  ]);

  return {
    reviews: reviews.toJSON(),
    userReview: userReview.toJSON(),
    reviewsSummary: appReviewsSummary.findByAppId(appId).toJSON(),
  };
}
