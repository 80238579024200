import * as React from 'react';

import { getAppDataById } from '../api/app';
import type { IReviewData } from '../api/review';
import { getAppReviewsById, getUserReview } from '../api/review';

interface IAppReviewData {
  appName: string;
  appIconSrc: string;
  previousReview?: IReviewData;
}

async function fetchAppReviewData(appDefId: string): Promise<IAppReviewData> {
  const [appData, userReview] = await Promise.all([
    getAppDataById(appDefId),
    getUserReview(appDefId),
  ]);

  const appName: string = appData.webSolutions[0].baseInfo.name;
  const appIconSrc: string = appData.webSolutions[0].baseInfo.icon;

  const previousReview: IReviewData = userReview
    ? {
        reviewId: userReview.id,
        rating: userReview.rating,
        title: userReview.title,
        description: userReview.description,
        revision: userReview.revision,
      }
    : undefined;

  return { appName, appIconSrc, previousReview };
}

function useReviewModalPrefetch(
  appDefId: string,
  isOpen: boolean = false,
  closeFn: () => void,
): IAppReviewData {
  const [appReviewData, setAppReviewData] =
    React.useState<IAppReviewData>(null);

  const updateData = React.useCallback(() => {
    fetchAppReviewData(appDefId).then(setAppReviewData).catch(closeFn);
  }, [appDefId]);

  const invalidateData = React.useCallback(() => {
    setAppReviewData(null);
  }, []);

  React.useEffect(() => {
    if (isOpen) {
      updateData();
    } else {
      invalidateData();
    }
  }, [isOpen, appDefId, updateData, invalidateData]);

  return appReviewData;
}

export { IAppReviewData, useReviewModalPrefetch };
