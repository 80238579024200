import * as React from 'react';
import { getSolutionPreviewBySlugOrId } from '../../api/api';
import { WithFetcher } from '../../component-decorators/with-fetcher';
import { dtoToShareAppProps, ShareApp } from './share-app';
import { CenteredLoader } from '../centered-loader';
import { useConfigContext } from '../../config-context';

export interface IShareAppContainerProps {
  appDefId: string;
  shareId: string;
  version: string;
  experiments: any;
}

export const ShareAppContainer = ({
  appDefId,
  shareId,
  version,
  experiments,
}: IShareAppContainerProps) => {
  const { locale, rpcClient } = useConfigContext();
  const dataFetcher = () =>
    getSolutionPreviewBySlugOrId(
      true,
      appDefId,
      locale,
      experiments,
      rpcClient,
    ).then((data) => data);

  return (
    <WithFetcher
      loader={<CenteredLoader />}
      initialFetch={dataFetcher}
      fetchMemoizationId={`share-app-${locale}`}
    >
      {({ fetchData }) => (
        <ShareApp {...dtoToShareAppProps(fetchData, shareId, version)} />
      )}
    </WithFetcher>
  );
};
