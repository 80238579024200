import * as React from 'react';
import type {
  WebSolutionExtended,
  TagExtended,
} from '@wix/app-market-collection-widget';
import {
  getCollectionDefinition,
  getWebSolutionData,
  extractTagsDefinition,
} from '@wix/app-market-collection-widget';
import s from './style.scss';
import { getWebSolutionsBiData } from '../biData';
import { biLogger } from '../../../bi';
import { appMarketAppCollectionImpressions } from '@wix/bi-logger-market/v2';

import type { MarketplaceStore } from '../../../component-decorators/with-marketplace-store';
import { withMarketplaceStore } from '../../../component-decorators/with-marketplace-store';
import { SolutionGridWrapper } from '../../solution-grid-wrapper/solution-grid-wrapper';

import { Heading, Layout, Cell } from '@wix/design-system';

import {
  getBundleAppSavedPrice,
  isBundleAppAndInstalled,
  isBundleAppAndNotInstalled,
  isBundleAppWithVoucher,
} from '../../../common/utils';
import {
  getPrivateAppsData,
  getRandomRecommendations,
  getRecommendationsFromML,
  getRecommendationsWithoutBS,
  getTagsDataBySlugs,
} from '../../../api/api';
import type { InjectedExperimentsProps } from '@wix/wix-experiments-react';
import { withExperiments } from '../../../component-decorators/with-experiments';
import { SeeMoreApps } from './see-more-apps';
import type {
  GetWebSolutionsByTagsResponse,
  SectionData,
  WebSolution,
} from '@wix/ambassador-marketplace/types';
import type { AppMarketRpcClient } from '../../../types/app-market-rpc-client';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import { withTranslation } from '../../../component-decorators/with-translation';

interface ISolutionsGridProps
  extends Partial<InjectedExperimentsProps>,
    Partial<WithTranslationProps> {
  marketplaceStore?: MarketplaceStore;
  rootTagDefinition: TagExtended;
  webSolutions: WebSolutionExtended[];
  innerTagsDefinitions: TagExtended[];
  locale?: string;
  signedInstance?: string;
  collimp_id?: string;
  countryCode?: string;
  rpcClient?: AppMarketRpcClient;
}

interface ISolutionsGridState {
  webSolutions: WebSolutionExtended[];
  showImpression: boolean;
}

type ISolutionsMapper = (
  sectionDTO: any,
  marketplaceStore: MarketplaceStore,
) => ISolutionsGridProps;

export const solutionsSectionDTOtoProps: ISolutionsMapper = (
  sectionDTO: SectionData,
  marketplaceStore: MarketplaceStore,
  locale?: string,
  signedInstance?: string,
  impressionId?: any,
  countryCode?: any,
  rpcClient?: any,
) => {
  const { tagData } = sectionDTO;
  const { tagsToWebSolutions } = tagData;
  const { tagsAndSubCategories, webSolutions } =
    tagsToWebSolutions[0].webSolutions;
  const rootTagDefinition = getCollectionDefinition(
    tagsAndSubCategories[0].tag,
  );
  const innerTagsDefinitions = extractTagsDefinition(tagsToWebSolutions);
  const webSolutionsData: WebSolutionExtended[] = webSolutions.map(
    (solution: WebSolution, appIndex: number) => ({
      ...getWebSolutionData(solution),
      appIndex,
      showPremiumLabel:
        !marketplaceStore.siteIsPremium &&
        solution.baseInfo.pricing.isRequiredWixPremium,
      isInstalled: !!(
        marketplaceStore.installedApps &&
        marketplaceStore.installedApps?.findIndex(
          (installedApp) => installedApp.appId === solution.baseInfo.id,
        ) > -1
      ),
      isBundleAppNotInstalled: isBundleAppAndNotInstalled(
        solution.baseInfo.id,
        marketplaceStore.bundleApps,
      ),
      isBundleAppInstalled: isBundleAppAndInstalled(
        solution.baseInfo.id,
        marketplaceStore.bundleApps,
      ),
      savedPrice: getBundleAppSavedPrice(
        solution.baseInfo.id,
        marketplaceStore.bundleApps,
      ),
      isVoucherApp: isBundleAppWithVoucher(
        solution.baseInfo.id,
        marketplaceStore.bundleApps,
      ),
    }),
  );
  return {
    rootTagDefinition,
    webSolutions: webSolutionsData,
    innerTagsDefinitions,
    locale,
    signedInstance,
    collimp_id: impressionId,
    countryCode,
    rpcClient,
  };
};

@withMarketplaceStore
@withTranslation
@withExperiments
export class Solution extends React.Component<
  ISolutionsGridProps,
  ISolutionsGridState
> {
  constructor(props) {
    super(props);
    this.state = {
      webSolutions: this.props.webSolutions,
      showImpression: true,
    };
  }

  fetchRecommendedApps = async () => {
    const apps = await getRecommendationsWithoutBS(
      this.props?.signedInstance,
      this.props?.locale,
      6,
    );
    return apps;
  };

  async componentDidMount() {
    const {
      rootTagDefinition,
      marketplaceStore,
      locale,
      signedInstance,
      experiments,
      collimp_id,
      countryCode,
    } = this.props;
    const { webSolutions } = this.state;

    if (
      rootTagDefinition.slug === marketplaceStore.privateAppsTagSlug &&
      marketplaceStore?.privateAppsTagId
    ) {
      const tagsData: GetWebSolutionsByTagsResponse =
        await getPrivateAppsData();

      const apps = tagsData?.webSolutions;
      if (apps?.length) {
        const parsedWebSolutions = apps.map(
          (solution: WebSolution, appIndex: number): WebSolutionExtended => ({
            ...getWebSolutionData(solution),
            appIndex,
            showPremiumLabel:
              !marketplaceStore.siteIsPremium &&
              solution.baseInfo.pricing.isRequiredWixPremium,
          }),
        );
        marketplaceStore.setPrivateAppsTagName(
          tagsData?.tagsAndSubCategories?.[0]?.tag?.name,
        );
        marketplaceStore.setPrivateAppsTagSlug(
          tagsData?.tagsAndSubCategories?.[0]?.tag?.slug,
        );
        this.setState({ webSolutions: parsedWebSolutions?.slice(0, 8) });
      }
    }

    if (rootTagDefinition.slug === 'newest-apps') {
      const tagsData: GetWebSolutionsByTagsResponse = await getTagsDataBySlugs(
        ['newest-apps-v2'],
        locale,
        countryCode,
        true,
        true,
      );
      const apps = tagsData?.webSolutions;
      if (apps?.length) {
        const parsedWebSolutions = apps.map(
          (solution: WebSolution, appIndex: number): WebSolutionExtended => ({
            ...getWebSolutionData(solution),
            appIndex,
            showPremiumLabel:
              !marketplaceStore.siteIsPremium &&
              solution.baseInfo.pricing.isRequiredWixPremium,
          }),
        );
        this.setState({ webSolutions: parsedWebSolutions?.slice(0, 8) });
      }
    } else if (
      rootTagDefinition.slug === 'user-recommendations' &&
      webSolutions.length === 0
    ) {
      const fetchRandomApps = experiments?.enabled(
        'specs.marketplace.ml-random-apps',
      );
      const randomApps = fetchRandomApps
        ? await getRandomRecommendations('4', locale, signedInstance)
        : [];
      const recommendedApps = !fetchRandomApps
        ? await getRecommendationsFromML('4', locale, signedInstance)
        : { data: [] };
      const dynamicWebSolutions = fetchRandomApps
        ? randomApps
        : recommendedApps.webSolutions;
      if (dynamicWebSolutions?.length) {
        const parsedWebSolutions = dynamicWebSolutions.map(
          (solution: WebSolution, appIndex: number): WebSolutionExtended => ({
            ...getWebSolutionData(solution),
            appIndex,
            showPremiumLabel:
              !marketplaceStore.siteIsPremium &&
              solution.baseInfo.pricing.isRequiredWixPremium,
          }),
        );

        if (this.state.showImpression) {
          const appIds = parsedWebSolutions.map(
            (webSolution) => webSolution.id,
          );
          const appIdsList = appIds?.join(',');
          biLogger.report(
            appMarketAppCollectionImpressions({
              apps_list: appIdsList,
              collimp_id,
              number_of_apps: 4, // check
              tag_name: 'user-recommendations',
              tag_type: 'homepage',
              collection_index: 1,
              prediction_type: fetchRandomApps
                ? 'random_apps'
                : recommendedApps.predictionType,
            }),
          );
          this.setState({ showImpression: false });
        }
        this.setState({ webSolutions: parsedWebSolutions });
      }
    }
  }

  render() {
    const { rootTagDefinition, marketplaceStore, collimp_id, t } = this.props;
    const { webSolutions } = this.state;
    const biData = getWebSolutionsBiData(
      webSolutions,
      'box',
      rootTagDefinition.slug,
      marketplaceStore.metaSiteId,
      marketplaceStore.route,
      marketplaceStore.prevRoute,
      marketplaceStore.bundleApps,
      collimp_id,
    );
    const shouldShowMoreAppsButton =
      rootTagDefinition.slug !== 'user-recommendations';

    const showPrivateApps = rootTagDefinition.slug === 'private-apps';

    const title = showPrivateApps
      ? t('home.privateApps.title', {
          accountName: marketplaceStore?.privateAppsTagName,
        })
      : rootTagDefinition.name;

    if (webSolutions?.length) {
      return (
        <div data-hook={rootTagDefinition.slug} className={s.section}>
          <Layout>
            <Cell span={9}>
              <Heading
                appearance="H2"
                dataHook="widget-title"
                className={s.widgetTitle}
              >
                {title}
              </Heading>
            </Cell>
            <Cell span={1} />
            <Cell span={2}>
              {shouldShowMoreAppsButton && (
                <SeeMoreApps
                  slug={rootTagDefinition.slug}
                  gridType={rootTagDefinition.type}
                />
              )}
            </Cell>
          </Layout>
          <Layout>
            <Cell>
              <span className={s.widgetWrapper}>
                <SolutionGridWrapper
                  solutions={webSolutions.map((solution, appIndex) => {
                    return { appIndex, ...solution };
                  })}
                  solutionsBiData={biData}
                  isCompany
                  isFull={true}
                  collimp_id={collimp_id}
                  referralSectionName={rootTagDefinition.slug}
                  numberOfLines={
                    rootTagDefinition.slug === 'user-recommendations' ? 1 : 2
                  }
                />
                {shouldShowMoreAppsButton && (
                  <SeeMoreApps
                    slug={rootTagDefinition.slug}
                    gridType={rootTagDefinition.type}
                    positionBottom={true}
                  />
                )}
              </span>
            </Cell>
          </Layout>
        </div>
      );
    }
    return <div />;
  }
}
