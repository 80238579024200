import { ListItemAction, Popover } from '@wix/design-system';
import * as React from 'react';
import s from './header-desktop-languages-popover.scss';
import { Languages } from '@wix/wix-ui-icons-common';
import { useConfigContext } from '../../../../../config-context';
import { getCurrentLanguageText } from './header-desktop-languages-popover-content/languages';
import { HeaderDesktopLanguagesPopoverContent } from './header-desktop-languages-popover-content/header-desktop-languages-popover-content';

export const HeaderDesktopLanguagesPopover = () => {
  const { locale } = useConfigContext();
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const openPopover = () => {
    setPopoverOpen(true);
  };
  const closePopover = () => {
    setPopoverOpen(false);
  };
  return (
    <Popover
      animate
      appendTo="scrollParent"
      onMouseEnter={openPopover}
      onMouseLeave={closePopover}
      placement="left-start"
      showArrow={false}
      shown={popoverOpen}
      theme="light"
      moveBy={{ y: -102, x: 17 }}
      dataHook="languages-popover"
      hideDelay={150}
    >
      <Popover.Element>
        <ListItemAction
          dataHook="languages-popover-element"
          as="button"
          prefixIcon={<Languages />}
          title={getCurrentLanguageText(locale)}
          skin="dark"
          className={s.languagesPopoverButton}
        />
      </Popover.Element>
      <Popover.Content>
        <HeaderDesktopLanguagesPopoverContent isLoggedIn={true} />
      </Popover.Content>
    </Popover>
  );
};
