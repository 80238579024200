const getRedirectTo = (languageCode: string): string =>
  `https://${languageCode === 'en' ? '' : `${languageCode}.`}wix.com${
    window.location.pathname
  }${window.location.search}`;

export const getLanguageLinkHref = (
  isLoggedIn: boolean,
  languageCode: string,
): string => {
  const redirectTo = getRedirectTo(languageCode);
  return isLoggedIn
    ? `https://users.wix.com/wix-users/auth/setLanguage?language=${languageCode}&redirectTo=${encodeURIComponent(
        redirectTo,
      )}`
    : redirectTo;
};
