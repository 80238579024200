import React from 'react';
import { Box } from '@wix/design-system';
import type { LayoutComponentProps } from '../../app-box.types';

export function NarrowAppBox({
  appName = null,
  appIcon = null,
  appTeaser = null,
  companyName = null,
  topBadge = null,
  ctaButton = null,
  reviewsInfo = null,
  bottomBadge = null,
  className,
}: LayoutComponentProps) {
  return (
    <Box
      className={className}
      width="100%"
      height="100%"
      direction="vertical"
      align="space-between"
    >
      <Box direction="vertical">
        <Box marginBottom="6px" align="space-between">
          {appIcon}
          {topBadge}
        </Box>
        <Box direction="vertical">
          <Box direction="vertical">
            {appName}
            {companyName}
          </Box>
          <Box marginTop="6px" marginBottom="24px">
            {appTeaser}
          </Box>
        </Box>
      </Box>
      <Box
        align="space-between"
        verticalAlign={ctaButton ? 'bottom' : 'middle'}
      >
        <Box direction="vertical" gap="6px">
          {ctaButton ? reviewsInfo : null}
          {bottomBadge}
        </Box>

        {ctaButton ?? reviewsInfo}
      </Box>
    </Box>
  );
}
