import React from 'react';
import s from './app-market-link.scss';
import { useConfigContext } from '../../config-context';

export interface IAppMarketLink {
  to?: string;
  className?: string;
  onClick?(e: any): void;
  [s: string]: any;
}

export const FakeLink = ({
  to,
  className,
  children,
  onClick,
}: IAppMarketLink) => {
  return (
    <a
      href={to}
      className={className}
      onClick={(e) => {
        e.preventDefault();
        onClick && onClick(e);
      }}
    >
      {children}
    </a>
  );
};

export const AppMarketLink = ({
  to,
  className = '',
  children,
  onClick = () => {},
  ...restOfTheProps
}: IAppMarketLink) => {
  const { baseUrl } = useConfigContext();
  const _to = to
    ? `${baseUrl}${to}`
    : baseUrl && baseUrl.substr(0, baseUrl.length - 1);
  return (
    <FakeLink
      className={`${s.link} ${className}`}
      to={_to}
      onClick={onClick}
      {...restOfTheProps}
    >
      {children}
    </FakeLink>
  );
};
