import * as React from 'react';
import { Box, Heading, Image, Text, TextButton } from '@wix/design-system';
import { useEssentials } from '../../../contexts/essentials-context';

interface IReviewModalHeaderProps {
  appName: string;
  appIcon: string;
  onContactSupport: (location: string) => void;
}

export function ReviewModalHeader({
  appName,
  appIcon,
  onContactSupport,
}: IReviewModalHeaderProps) {
  const { t } = useEssentials();

  return (
    <Box>
      <Box minWidth="60px" width="60px" height="60px">
        <Image src={appIcon} alt={`${appName} icon`} />
      </Box>
      <Box maxWidth="450px" marginLeft="18px" direction="vertical">
        <Heading size="large">{t('review-app.header-title')}</Heading>
        <Text secondary>
          {t('review-app.header-description', {
            appName,
            interpolation: { escapeValue: false },
          })}
          <TextButton
            dataHook="report-bug-button"
            onClick={() => onContactSupport('review_modal')}
            style={{ paddingLeft: '6px' }}
          >
            {t('review-app.header-description.report-bug')}
          </TextButton>
        </Text>
      </Box>
    </Box>
  );
}
