import type {
  MarketApiSection,
  Pricing,
  ApiTag,
  TagToWebSolutions,
  WebSolution,
  WSTag,
} from '@wix/ambassador-marketplace/types';
import type {
  ISolutionBiData,
  TagExtended,
  WebSolutionExtended,
} from '../../types';

export const extractWebSolutions = (
  rawCollection: MarketApiSection,
): WebSolution[] => {
  return rawCollection?.sectionData?.tagData?.tagsToWebSolutions?.[0]
    ?.webSolutions?.webSolutions;
};

export const getWebSolutionData = (
  webSolution: WebSolution,
): WebSolutionExtended => {
  const { baseInfo } = webSolution;
  return {
    id: baseInfo.id,
    slug: baseInfo.slug,
    name: baseInfo.name,
    description: baseInfo.teaser,
    teaser: baseInfo.teaser,
    icon: baseInfo.icon,
    appIcon: baseInfo.icon,
    pricing: baseInfo.pricing,
    numberOfReviews: baseInfo.numberOfReviews || 0,
    reviewStars: baseInfo.reviewStars || 0,
    // badge: baseInfo.badge,
    // isInstalled: baseInfo.isInstalled,
    company: baseInfo.company,
    openConsentIn: baseInfo.openConsentIn,
    Tags: baseInfo.Tags,
    type: baseInfo.type,
    // showPremiumLabel: baseInfo.showPremiumLabel,
    // isBundleAppNotInstalled: baseInfo.isBundleAppNotInstalled
  };
};

export const getFakeWebSolutionBiData = (
  solutions: WebSolutionExtended[],
  widgetName: string,
): ISolutionBiData[] => {
  return solutions.map((solution: WebSolutionExtended, index: number) => {
    return {
      impressionType: widgetName,
      index,
      market: 'fake market',
      tagName: 'fake tagName',
      tagType: 'fake tagType',
      subCategory: 'fake subCategory',
    };
  });
};

export const isWixApp = (companyId) =>
  companyId === 'ad147a73-a40e-4e50-8344-2ad33592790d' ||
  companyId === 'a60ac714-5c5a-4c38-a285-573752f04876';

export function getPricingLabel(pricing: Pricing, companyId: string): string {
  const isInAppPurchase = pricing?.isInAppPurchase;
  const freeTrialDays = pricing?.freeTrialDays;
  const isMeteredWithCustomPrice = pricing?.plans?.some(
    (plan) => plan.meteredBilling && plan.isCustom,
  );

  if (isWixApp(companyId)) {
    return 'app.pricing.free-to-install';
  }
  if (pricing?.businessModel === 'DYNAMIC' && freeTrialDays > 0) {
    return 'app.pricing.trial-days-in-app';
  }
  if (
    (pricing?.businessModel === 'DYNAMIC' && freeTrialDays <= 0) ||
    isMeteredWithCustomPrice
  ) {
    return 'app.pricing.freemium-free-to-install';
  }
  if (pricing?.businessModel === 'FREE') {
    return isInAppPurchase ? 'app.pricing.free-to-install' : 'app.pricing.free';
  }
  if (pricing?.businessModel === 'FREEMIUM') {
    return isInAppPurchase
      ? 'app.pricing.freemium-free-to-install'
      : 'app.pricing.free-plan-available';
  }
  if (
    pricing?.businessModel === 'PREMIUM' &&
    freeTrialDays > 0 &&
    isInAppPurchase
  ) {
    return 'app.pricing.trial-days-in-app';
  }
  if (
    pricing?.businessModel === 'PREMIUM' &&
    freeTrialDays > 0 &&
    !isInAppPurchase
  ) {
    return 'app.pricing.trial-days-premium';
  }
  if (pricing?.businessModel === 'PREMIUM' && freeTrialDays <= 0) {
    return 'app.pricing.from-in-app-full';
  }

  return pricing?.pricingLabel;
}

export const getLowestPrice = (pricing: Pricing) => {
  const pricesWithoutTax = pricing?.plans?.[0]?.premiumPrices?.fullPrices.map(
    (price) => Number(price.monthlyPriceBeforeTax),
  );
  const pricesWithTax = pricing?.plans?.[0]?.premiumPrices?.fullPrices.map(
    (price) => Number(price.monthlyPriceWithTax),
  );
  const lowestPrice = pricing?.taxSettings?.showPricesWithTax
    ? Math.min(...pricesWithTax)
    : Math.min(...pricesWithoutTax);
  return lowestPrice;
};

export const getCollectionDefinition = (
  rawTagDefinition: ApiTag,
): TagExtended => ({
  name: rawTagDefinition?.name,
  slug: rawTagDefinition?.slug,
  description: rawTagDefinition?.description,
  type: rawTagDefinition?.type,
  // teaser: rawTagDefinition.teaser,
  color: rawTagDefinition?.colors[0],
});

export const extractTagsDefinition = (
  tagsToWebSolutions: TagToWebSolutions[],
): TagExtended[] =>
  tagsToWebSolutions.map((tagData: TagToWebSolutions): TagExtended => {
    const { name, slug, description, colors, data, assetsMap } =
      tagData?.webSolutions?.tagsAndSubCategories?.[0]?.tag;

    // todo: validate teaser
    return {
      name,
      description,
      color: (colors && colors[0]) || '',
      icon: assetsMap?.collectionImage?.assets?.[0]?.url || '',
      teaser: data?.collectionDate?.teaser,
      slug,
    };
  });

export const getAppBadgeTitle = (
  isBundleAppInstalled: boolean,
  isBundleAppNotInstalled: boolean,
  tags: WSTag[],
  experiments: any,
  hideTagsBadge: boolean,
  appId: string,
  isWixOffering?: boolean,
) => {
  const HOTEL_RUNNER_APP_ID = '826670ba-3a6b-4157-ac72-7c4fca9ce220';
  const HOTEL_RUNNER_STAGING_APP_ID = 'ee4f5349-bf6f-46a8-aaf6-8cba4f9726ff';

  const isHotels =
    appId === HOTEL_RUNNER_APP_ID || appId === HOTEL_RUNNER_STAGING_APP_ID;
  if (isWixOffering) {
    return 'solutionBox.badge.wix-offering';
  }
  if (isBundleAppInstalled || isBundleAppNotInstalled) {
    return 'solutionBox.badge.bundle';
  }

  if (experiments.enabled('specs.marketplace.hotelsBetaBadge') && isHotels) {
    return 'solutionBox.badge.beta';
  }

  return '';
};
export const getAppBadgeTheme = (badgeKey: string) => {
  if (badgeKey === 'solutionBox.badge.beta') {
    return {
      skin: 'neutralStandard',
      type: 'solid',
      size: 'small',
      uppercase: true,
    };
  }

  if (badgeKey === 'solutionBox.badge.wix-offering') {
    return {
      skin: 'neutral',
      type: 'solid',
      size: 'small',
      uppercase: true,
    };
  }

  if (badgeKey === 'solutionBox.badge.bundle') {
    return {
      skin: 'success',
      type: 'solid',
      size: 'small',
      uppercase: false,
    };
  }

  if (
    badgeKey === 'solutionBox.badge.holidaySale' ||
    badgeKey === 'solutionBox.holidayBadge.fortyOff' ||
    badgeKey === 'solutionBox.holidayBadge.thirtyOff'
  ) {
    return {
      type: 'solid',
      size: 'tiny',
      uppercase: true,
    };
  }
};
export const getAppBadgeTooltipContent = (
  isBundleAppInstalled: boolean,
  isBundleAppNotInstalled: boolean,
  isVoucherApp: boolean,
  showHotelsToolTip?: boolean,
) => {
  if (showHotelsToolTip) {
    return 'solutionBox.badge.beta-tooltip';
  }
  if (isBundleAppNotInstalled && isVoucherApp) {
    return 'solutionBox.badge.bundle-tooltip-voucher-not-installed';
  }
  if (isBundleAppNotInstalled) {
    return 'solutionBox.badge.bundle-tooltip-bundle-not-installed';
  }
  if (isBundleAppInstalled && isVoucherApp) {
    return 'solutionBox.badge.bundle-tooltip-installed-voucher';
  }
  if (isBundleAppInstalled) {
    return 'solutionBox.badge.bundle-tooltip-not-installed';
  }
};
