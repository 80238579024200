import * as React from 'react';
import { TranslationsProvider } from '../../i18next';
import { Translation } from 'react-i18next';
import type { IReviewAppModalProps } from './review-app-modal';
import { ReviewAppModal } from './review-app-modal';

interface IReviewAppModalExportProps extends Omit<IReviewAppModalProps, 't'> {
  locale: string;
}

function ReviewAppModalExport({
  locale,
  ...reviewAppModalProps
}: IReviewAppModalExportProps) {
  return (
    <TranslationsProvider locale={locale}>
      <Translation>
        {(t) => <ReviewAppModal {...reviewAppModalProps} t={t} />}
      </Translation>
    </TranslationsProvider>
  );
}

export { IReviewAppModalExportProps, ReviewAppModalExport };
