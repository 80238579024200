import { SuggestionType } from '../../../../types/common/marketplace';

export const defaultSuggestions = [
  {
    suggestionType: SuggestionType.NONE_TYPE,
    value: 'search-box.default-suggestions.1',
    suggestionKey: 'default-1',
  },
  {
    suggestionType: SuggestionType.NONE_TYPE,
    value: 'search-box.default-suggestions.2',
    suggestionKey: 'default-2',
  },
  {
    suggestionType: SuggestionType.NONE_TYPE,
    value: 'search-box.default-suggestions.3',
    suggestionKey: 'default-3',
  },
  {
    suggestionType: SuggestionType.NONE_TYPE,
    value: 'search-box.default-suggestions.4',
    suggestionKey: 'default-4',
  },
];
