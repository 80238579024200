import React from 'react';
import s from './centered-loader.scss';
import { Loader } from '@wix/design-system';

export const isBrowser = () => typeof window !== 'undefined';

export const CenteredLoader = () => {
  isBrowser() && window.scrollTo(0, 0);
  return (
    <div className={s.loaderPositionGrey}>
      <Loader status="loading" />
    </div>
  );
};
