import type { MeterdBilling } from '../apps-plans';

export type IPricingModelPlan = {
  vendorId: string;
  name: string;
  title?: string;
  isVisible: boolean;
  isFree: boolean;
  description: PlanDescription;
  monthlyPrice?: string;
  yearlyPrice?: string;
  oneTimePrice?: string;
  meterdBilling?: MeterdBilling;
};

export type IPricingModel = {
  appId: string;
  freeTrialDays: number;
  isExternalPricing: boolean;
  externalPricingPageUrl?: string;
  plans: IPricingModelPlan[];
  hasDynamicPlan: boolean;
};

export type getPricingModel = (payload: {
  appId: string;
  isPublished: boolean;
}) => Promise<IPricingModel>;

export type PlanDescription = {
  descriptionType: EPlanDescription | null;
  costumeText?: string;
  benefits: string[];
};

export enum EPlanDescription {
  /** benefits array description */
  BENEFITS = 'BENEFITS',
  /** custom paragraph description */
  CUSTOM_TEXT = 'CUSTOM_TEXT',
}
