import * as React from 'react';
import { Path } from '../..';
import s from './menu-item.scss';
import {
  Box,
  CounterBadge,
  SidebarDivider,
  SidebarSectionItem,
} from '@wix/design-system';
import { AppMarketLink } from '../app-market-link';
import { composer } from '../../component-decorators/composer';
import type { InjectedExperimentsProps } from '@wix/wix-experiments-react';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import { biLogger } from '../../bi';
import { config } from '../../config';
import { SettingsSmall } from '@wix/wix-ui-icons-common';
import {
  getCompleteSetupApps,
  filterBusinessSolutionApps,
} from '@wix/manage-installed-apps';
import type { MarketplaceStore } from '../../component-decorators/with-marketplace-store';
import { appMarketMenuNavigation } from '@wix/bi-logger-market/v2';

export interface IManageAppsMenuItemProps
  extends Partial<WithTranslationProps>,
    Partial<InjectedExperimentsProps> {
  marketplaceStore?: MarketplaceStore;
}

export const ManageAppsMenuItem = composer()
  .withMarketplaceStore()
  .withTranslation()
  .withExperiments()
  .compose(({ marketplaceStore, t, experiments }: IManageAppsMenuItemProps) => {
    const path = Path.MANAGE_APPS;
    const manageAppsLabel = experiments.enabled(
      'specs.appMarket.manageAppsTextTest',
    )
      ? t?.('menu.manageApps.test')
      : t?.('menu.manageApps');
    const navigateTo = () => {
      biLogger.report(
        appMarketMenuNavigation({
          menu_item_label: 'manage_apps',
        }),
      );
      config.goToManageApps();
    };
    const calcPendingAppsBadge = () => {
      const hideBusinessSolutions =
        marketplaceStore.hideBusinessSolutionsInManageApps || false;
      const installedApps = hideBusinessSolutions
        ? filterBusinessSolutionApps(marketplaceStore.installedApps || [])
        : marketplaceStore.installedApps || [];
      const currentBundleApps = marketplaceStore.currentBundleApps || [];
      const completeSetupApps = getCompleteSetupApps(
        installedApps,
        currentBundleApps,
        marketplaceStore.siteIsPremium,
        experiments,
        false,
      );
      const completeSetupCount = completeSetupApps && completeSetupApps.length;
      return completeSetupCount || 0;
    };
    const pendingAppsCount = calcPendingAppsBadge();

    const pendingAppsCounterBadge =
      pendingAppsCount > 0 ? (
        <CounterBadge skin="danger">{pendingAppsCount}</CounterBadge>
      ) : null;
    const settingIcon = React.cloneElement(<SettingsSmall />, {
      className: s.menuItemIcon,
    });

    return (
      <Box
        dataHook="manage-apps-menu-item"
        direction="vertical"
        alignContent="space-between"
      >
        <SidebarDivider fullWidth />
        <SidebarSectionItem
          suffix={pendingAppsCounterBadge}
          dataHook="manage-apps-section-item"
          onClick={navigateTo}
          className={s.menuItem}
        >
          <AppMarketLink to={path} className={s.menuItemLink}>
            <Box verticalAlign="middle" gap="10px">
              {settingIcon}
              {manageAppsLabel}
            </Box>
          </AppMarketLink>
        </SidebarSectionItem>
        <SidebarDivider fullWidth />
      </Box>
    );
  });
