import { httpClient } from '../../http-client';
import type { AppMarketRpcClient } from '../../types/app-market-rpc-client';
import { config } from '../../config';
import { EditorType, Origin, Path } from '../../enums/marketplace-enums';
import type { IRoute } from '../../types/common/marketplace';
import type {
  ContainerResponse,
  GetCategoriesResponse,
  MarketApiSection,
  ApiTag,
  TagToWebSolutions,
} from '@wix/ambassador-marketplace/types';
import { getDynamicSections } from '@wix/ambassador-appmarket-v1-app-market-client-aggregator/http';
import type { DynamicSection } from '@wix/ambassador-appmarket-v1-app-market-client-aggregator/types';
import {
  Placement,
  SectionType,
} from '@wix/ambassador-appmarket-v1-app-market-client-aggregator/types';

export type SidebarItem =
  | SidebarDivider
  | SidebarTitle
  | SidebarLink
  | SidebarSubMenu;

export type SidebarDivider = {
  type: 'divider';
};

export type SidebarTitle = {
  type: 'title';
  text: string;
};

export type SidebarLink = {
  type: 'link';
  text: string;
  slug: string;
  parentSlug?: string;
  pageType: 'category' | 'sub-category' | 'collection' | 'home';
};

export type SidebarSubMenu = {
  type: 'sub-menu';
  title: string;
  slug: string;
  links: SidebarLink[];
};

export async function getContainer(
  platformName: string,
  containerName: string,
  lang: string,
  rpcClient?: AppMarketRpcClient,
): Promise<ContainerResponse> {
  const shouldUseRpcClient =
    typeof window === 'undefined' && rpcClient !== undefined;

  const getContainerResponse = shouldUseRpcClient
    ? await rpcClient.getContainer({
        platformName,
        containerName,
        lang,
      })
    : await httpClient
        .get<ContainerResponse>(
          `${config.baseApiUrl}/_api/marketplace-api/v1/container?platformName=${platformName}&containerName=${containerName}&lang=${lang}`,
        )
        .then(({ data }) => data);

  return getContainerResponse;
}

export async function getCategories(
  lang: string,
  rpcClient?: AppMarketRpcClient,
): Promise<GetCategoriesResponse> {
  const shouldUseRpcClient =
    typeof window === 'undefined' && rpcClient !== undefined;

  const getCategoriesResponse = shouldUseRpcClient
    ? await rpcClient.getCategories({ lang })
    : await httpClient
        .get<GetCategoriesResponse>(
          `${config.baseApiUrl}/_api/marketplace-api/v1/categories?lang=${lang}`,
        )
        .then(({ data }) => data);

  return getCategoriesResponse;
}

export function reduceSectionsToSidebarItems(
  accumulatedItems: SidebarItem[],
  section: MarketApiSection,
  getCategoriesResponse: GetCategoriesResponse,
): SidebarItem[] {
  let sectionLinks: SidebarItem[] = [];
  if (section.type === 'collectionsList') {
    sectionLinks = section.sectionData.tagData.tagsToWebSolutions.map(
      generateCollectionSidebarLink,
    );
  }
  if (section.type === 'categoriesList') {
    sectionLinks = section.sectionData.tagData.tagsToWebSolutions.map(
      (tagsToWebSolution) =>
        generateCategorySidebarSubMenu(
          tagsToWebSolution,
          getCategoriesResponse,
        ),
    );
  }

  const sectionDivider: SidebarDivider = {
    type: 'divider',
  };
  const sectionTitle: SidebarTitle = {
    type: 'title',
    text: section.sectionData.tagData.displayName,
  };

  return accumulatedItems
    .concat(sectionDivider)
    .concat(sectionTitle)
    .concat(sectionLinks);
}

function generateCategorySidebarSubMenu(
  tagsToWebSolution: TagToWebSolutions,
  getCategoriesResponse: GetCategoriesResponse,
): SidebarSubMenu {
  const currentCategory = getCategoriesResponse.categoryToSubCategory?.find(
    (category) => category.category?.slug === tagsToWebSolution.tag?.slug,
  );
  const subCategories = currentCategory?.subCategories ?? [];

  return {
    type: 'sub-menu',
    title: tagsToWebSolution.tag.name,
    slug: tagsToWebSolution.tag.slug,
    links: subCategories.map(generateSubCategorySidebarLink),
  };
}

function generateSubCategorySidebarLink(subCategory: ApiTag): SidebarLink {
  return {
    type: 'link',
    text: subCategory.name,
    parentSlug: subCategory.data?.categoryData?.parentSlug,
    slug: subCategory.slug,
    pageType: 'sub-category',
  };
}

function generateCollectionSidebarLink(
  tagsToWebSolution: TagToWebSolutions,
): SidebarLink {
  return {
    type: 'link',
    text: tagsToWebSolution.tag.name,
    slug: tagsToWebSolution.tag.slug,
    pageType: 'collection',
  };
}

export function getPlatform(route: IRoute): string {
  if (config.editorType === EditorType.ADI) {
    return 'adi%20in%20bm';
  }

  if (route.platformName) {
    return route.platformName;
  }

  if (config.origin === Origin.BIZMGR) {
    return 'business_manager_platform';
  }

  if (config.origin === Origin.EDITOR) {
    return 'editor_platform';
  }

  if (config.origin === Origin.STANDALONE) {
    return 'stand_alone_platform';
  }

  return '';
}

export function getRoute(sidebarLink: SidebarLink | SidebarSubMenu): IRoute {
  if (sidebarLink.type === 'sub-menu') {
    return {
      path: Path.CATEGORY,
      slug: sidebarLink.slug,
      referral: 'menu_navigation',
    };
  }

  if (sidebarLink.pageType === 'category') {
    return {
      path: Path.CATEGORY,
      slug: sidebarLink.slug,
      referral: 'menu_navigation',
    };
  }

  if (sidebarLink.pageType === 'sub-category') {
    return {
      path: Path.CATEGORY,
      slug: sidebarLink.parentSlug,
      subCategories: [sidebarLink.slug],
      referral: 'menu_navigation',
    };
  }

  if (sidebarLink.pageType === 'collection') {
    return {
      path: Path.COLLECTION,
      slug: sidebarLink.slug,
      referral: 'menu_navigation',
    };
  }

  if (sidebarLink.pageType === 'home') {
    return {
      path: Path.HOME,
      referral: 'menu_navigation',
    };
  }
}

export function getHref(sidebarLink: SidebarLink | SidebarSubMenu): string {
  if (sidebarLink.type === 'sub-menu') {
    return `/app-market/${Path.CATEGORY}/${sidebarLink.slug}`;
  }

  if (sidebarLink.pageType === 'category') {
    return `/app-market/${Path.CATEGORY}/${sidebarLink.slug}`;
  }

  if (sidebarLink.pageType === 'sub-category') {
    return `/app-market/${Path.CATEGORY}/${sidebarLink.parentSlug}/${sidebarLink.slug}`;
  }

  if (sidebarLink.pageType === 'collection') {
    return `/app-market/${Path.COLLECTION}/${sidebarLink.slug}`;
  }

  if (sidebarLink.pageType === 'home') {
    return '/app-market';
  }
}

export async function getSidebarDealerSections({
  rpcClient,
  getAppInstance,
}: {
  rpcClient?: AppMarketRpcClient;
  getAppInstance?: (appDefID: string) => string;
}): Promise<DynamicSection[]> {
  const signedInstance = getAppInstance
    ? getAppInstance('22bef345-3c5b-4c18-b782-74d4085112ff')
    : undefined;

  const shouldUseRpcClient =
    typeof window === 'undefined' && rpcClient !== undefined;

  return shouldUseRpcClient
    ? rpcClient
        .getDynamicSections({
          placement: Placement.SIDEBAR,
        })
        .then((response) => response?.sections ?? [])
    : httpClient
        .request(
          getDynamicSections({ placement: Placement.SIDEBAR }),
          signedInstance ? { signedInstance } : undefined,
        )
        .then((response) => {
          return response.data?.sections ?? [];
        });
}

export function mapSidebarSectionToSidebarItem(
  section: DynamicSection,
): SidebarItem {
  if (section.type === SectionType.SIDEBAR_TITLE) {
    return {
      type: 'title',
      text: section.sidebarTitle.text,
    };
  }
  if (section.type === SectionType.COLLECTION_LINK) {
    return {
      type: 'link',
      text: section.collectionLink.label,
      slug: section.collectionLink.slug,
      pageType: 'collection',
    };
  }
  if (section.type === SectionType.SIDEBAR_DIVIDER) {
    return {
      type: 'divider',
    };
  }
  if (section.type === SectionType.CATEGORY_LINK) {
    return {
      type: 'sub-menu',
      title: section.categoryLink.label,
      slug: section.categoryLink.slug,
      links: section.categoryLink.subCategoryLinks.map((subCategoryLink) => {
        return {
          type: 'link',
          text: subCategoryLink.label,
          parentSlug: subCategoryLink.parentCategorySlug,
          slug: subCategoryLink.slug,
          pageType: 'sub-category',
        };
      }),
    };
  }
}

export function determineSelectedItemKey(route: IRoute) {
  if (route.path === Path.HOME || route.path === Path.SEARCH) {
    return 'home';
  }

  if (route.subCategories?.[0]) {
    return route.subCategories?.[0];
  }

  return route.slug;
}
