import React from 'react';
import { Notification } from '@wix/design-system';
import type { INotificationsOptions } from '../../types/common/marketplace';

function StandaloneNotification({
  message,
  type = 'success',
}: INotificationsOptions) {
  return (
    <Notification
      show={true}
      type="sticky"
      theme={type.toLowerCase()}
      autoHideTimeout={5000}
    >
      <Notification.TextLabel>{message}</Notification.TextLabel>
      <Notification.CloseButton />
    </Notification>
  );
}

export { StandaloneNotification };
