import type { ReactElement } from 'react';
import React, { useMemo } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { isSSR } from '@wix/app-market-services';

type QueryFn<T> = () => Promise<T>;
type Children<T> = <Props extends { data: T }>(props: Props) => ReactElement;

// TODO: update interface once fetcher decorator is removed (memoId & initialFetch)
interface QueryDecoratorProps<T> {
  initialFetch: QueryFn<T>;
  loader: ReactElement;
  children: Children<T>;
  memoId: string;
}

function createSSRQuery<T>(
  queryKey: string,
): ({ queryFn }: { queryFn: QueryFn<T> }) => T {
  let error: Error;
  let data: T | undefined;
  let promise: Promise<T> | undefined;

  return ({ queryFn }) => {
    const queryClient = useQueryClient();

    if (data) {
      return data;
    }

    if (error) {
      throw new Error(
        `Query with memo id ${queryKey} failed with error: ${error}`,
      );
    }

    if (!promise) {
      promise = queryClient
        .prefetchQuery([queryKey], queryFn)
        .then(() => {
          data = queryClient.getQueryData<T>([queryKey]);
          if (!data) {
            throw new Error(`Query with memo id ${queryKey} returned no data`);
          }
          return data;
        })
        .catch((e: Error) => {
          error = e;
          throw e;
        });
    }

    throw promise;
  };
}

function SSRRenderer<T>({
  query,
  queryFn,
  children: Children,
}: {
  query: ({ queryFn }: { queryFn: QueryFn<T> }) => T;
  queryFn: QueryFn<T>;
  children: Children<T>;
}) {
  const data = query({ queryFn });

  if (!data) {
    throw new Promise(() => {}); // Suspend rendering if no data
  }

  return <Children data={data} />;
}

function createClientQuery<T>(
  queryKey: string,
): ({ queryFn }: { queryFn: QueryFn<T> }) => T {
  return ({ queryFn }) => {
    const { data, error, isLoading } = useQuery<T, Error>([queryKey], queryFn, {
      staleTime: 100000,
    });

    if (isLoading) {
      throw new Promise(() => {}); // Suspend rendering during loading
    }

    if (error) {
      throw new Error(`Query with memo id ${queryKey} returned no data`);
    }

    return data;
  };
}

function CSRRenderer<T>({
  query,
  queryFn,
  children: Children,
  loader,
}: {
  query: ({ queryFn }: { queryFn: QueryFn<T> }) => T;
  queryFn: QueryFn<T>;
  children: Children<T>;
  loader: ReactElement;
}) {
  try {
    const data = query({ queryFn });
    return <Children data={data} />;
  } catch (errorOrPromise) {
    if (errorOrPromise instanceof Promise) {
      return loader;
    }

    throw errorOrPromise;
  }
}

/**
 * QueryDecorator for fetching data on SSR & CSR.
 * Suspense is used for CSR data fetching.
 */
export function QueryDecorator<T>({
  initialFetch: queryFn,
  loader,
  children,
  memoId: queryKey,
}: QueryDecoratorProps<T>) {
  const ssrQuery = useMemo(() => createSSRQuery<T>(queryKey), [queryKey]);
  const clientQuery = useMemo(() => createClientQuery<T>(queryKey), [queryKey]);

  if (isSSR()) {
    return (
      <SSRRenderer<T> query={ssrQuery} queryFn={queryFn}>
        {children}
      </SSRRenderer>
    );
  }

  return (
    <React.Suspense fallback={loader}>
      <CSRRenderer<T> query={clientQuery} queryFn={queryFn} loader={loader}>
        {children}
      </CSRRenderer>
    </React.Suspense>
  );
}
