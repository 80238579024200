import * as React from 'react';
import type { IFinishUpdateProps } from './finish-setup';
import { FinishUpdate } from './finish-setup';
import { FetchStatus } from '../view-rapper';
import { WithFetcher } from '../../component-decorators/with-fetcher';
import { CenteredLoader } from '../centered-loader';
import type { MarketplaceStore } from '../../component-decorators/with-marketplace-store';
import { withMarketplaceStore } from '../../component-decorators/with-marketplace-store';
import type { IConfigContext } from '../../config-context';
import { useConfigContext } from '../../config-context';
import { withExperiments } from '../../component-decorators/with-experiments';
import type { InjectedExperimentsProps } from '@wix/wix-experiments-react';
import { getInstalledApps } from '../../common/utils';

export interface IFinishUpdateContainerProps
  extends Partial<InjectedExperimentsProps> {
  marketplaceStore?: MarketplaceStore;
  slug: string;
  appVersion: string;
}

interface IFinishUpdateConnectedContainerState {
  isFetchingData: FetchStatus;
  FinishUpdateProps: IFinishUpdateProps;
}

interface IFinishUpdateConnectedContainerProps
  extends IFinishUpdateContainerProps {
  configContext: IConfigContext;
}

export const FinishUpdateContainer = (props: IFinishUpdateContainerProps) => {
  const configContext = useConfigContext();
  return (
    <FinishUpdateConnectedContainer {...props} configContext={configContext} />
  );
};

@withMarketplaceStore
@withExperiments
export class FinishUpdateConnectedContainer extends React.Component<
  IFinishUpdateConnectedContainerProps,
  IFinishUpdateConnectedContainerState
> {
  constructor(props: IFinishUpdateConnectedContainerProps) {
    super(props);
    this.state = {
      isFetchingData: FetchStatus.FETCHING,
      FinishUpdateProps: null,
    };
  }

  dataFetcher = () => {
    const { getAppInstance, locale } = this.props.configContext;
    const signedInstance = getAppInstance
      ? getAppInstance('22bef345-3c5b-4c18-b782-74d4085112ff')
      : undefined;

    return getInstalledApps(signedInstance, locale).then((apps) => {
      const appFromInstalled = apps?.find(
        (installedApp) => installedApp.appId === this.props.slug,
      );

      return appFromInstalled;
    });
  };

  render() {
    return (
      <WithFetcher
        loader={<CenteredLoader />}
        initialFetch={this.dataFetcher}
        fetchMemoizationId="finish-update"
      >
        {({ fetchData }) => <FinishUpdate app={fetchData} />}
      </WithFetcher>
    );
  }
}
