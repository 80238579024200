import type { Tag as TagEntityType } from '@wix/ambassador-prime-auto-v1-tag/types';
import type { TagType } from './types';

export class Tag {
  constructor(private readonly tag: TagEntityType) {}
  get id(): string {
    return this.tag?.id ?? '';
  }

  get type(): TagType {
    return this.tag?.tagType!;
  }

  get name(): string {
    return this.tag?.content?.name ?? this.tag?.name ?? '';
  }

  get slug(): string {
    return this.tag?.slug ?? '';
  }

  get description(): string {
    return this.tag?.content?.description ?? this.tag?.description ?? '';
  }

  get collectionTeaser(): string {
    return (
      this.tag?.content?.collectionTeaser ?? this.tag?.collectionTeaser ?? ''
    );
  }

  get isHidden(): boolean {
    return this.tag?.isHidden ?? true;
  }

  get image(): string {
    return this.tag?.assetsMap?.collectionImage?.assets?.[0].url ?? '';
  }

  get subCategoriesIds(): string[] {
    return this.tag?.subcategoryIds || [];
  }

  get parentId(): string {
    return this.tag?.parentIds?.[0] || '';
  }

  toJSON(): TagEntityType {
    return this.tag;
  }
}
