import React from 'react';
import { Box, Text } from '@wix/design-system';
import { AppGeoAvailability } from './app-geo-availability';
import { AppCollections } from './app-collections';
import { AppLanguages } from './app-languages';
import { AppRequirements } from './app-requirements';
import type { MarketListing } from '@wix/app-market-services';
import { useTranslation } from '../../../../contexts/translations-context';
import { CategoriesTags } from '../categories-tags';
import type { Subcategory } from '../categories-tags';

export interface AppPropertiesProps {
  appId: string;
  appName: string;
  isWixApp: boolean;
  supportedLanguages: string[];
  isAppContentTranslatable: boolean;
  appDependencies: {
    id: string;
    slug: string;
    name: string;
  }[];
  appCollections: {
    slug: string;
    name: string;
  }[];
  geoAvailability: MarketListing['geoAvailability'];
  subCategories: Subcategory[];
  onTagClick(
    categorySlug: string,
    subCategory: string,
    label: 'category' | 'sub_category',
    location: 'top_page' | 'side_category_page',
  ): void;
}

export const AppProperties = ({
  appId,
  isWixApp,
  appName,
  supportedLanguages,
  isAppContentTranslatable,
  appDependencies,
  geoAvailability,
  appCollections,
  subCategories,
  onTagClick,
}: AppPropertiesProps) => {
  const { t } = useTranslation();

  return (
    <Box
      border="1px solid #dcdcdc"
      borderRadius="8px"
      padding="18px 24px"
      minWidth="272px"
      boxSizing="border-box"
      direction="vertical"
      gap="12px"
    >
      {Boolean(subCategories?.length) && (
        <>
          <Box direction="vertical">
            <Text dataHook="categories-tags" size="small" weight="bold">
              {t('app.page.categories.title')}
            </Text>
            <Box marginTop="6px">
              <CategoriesTags
                subCategories={subCategories}
                onTagClick={onTagClick}
              />
            </Box>
          </Box>
        </>
      )}
      <AppGeoAvailability
        appId={appId}
        appName={appName}
        isWixApp={isWixApp}
        geoAvailability={geoAvailability}
      />
      {supportedLanguages.length > 0 && (
        <AppLanguages
          appId={appId}
          languages={supportedLanguages}
          isAppContentTranslatable={isAppContentTranslatable}
        />
      )}
      {appDependencies.length > 0 && (
        <AppRequirements appId={appId} appDependencies={appDependencies} />
      )}
      {appCollections.length > 0 && (
        <AppCollections
          appId={appId}
          collections={appCollections}
          subCategories={Boolean(subCategories.length)}
        />
      )}
    </Box>
  );
};
