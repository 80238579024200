import { AppMarketPage } from '../../types/common/marketplace';
import type {
  appMarketAppCollectionImpressionsParams,
  appMarketPageViewParams,
  appModalOpenAppModalClickParams,
  categoryClickParams,
  appMarketAppImpressionParams,
  appMarketWidgetsNavigationClickParams,
  appMarketWidgetsClickActionParams,
} from '@wix/bi-logger-market/v2/types';
import {
  getPathForBi,
  getRouteForBi,
  getWidgetReferral,
} from '../../common/bi-events';
import type { MarketplaceStore } from '../../component-decorators/with-marketplace-store';
import type { GetDynamicSectionsResponseNonNullableFields } from '@wix/ambassador-appmarket-v1-app-market-client-aggregator/types';
import { v4 as generateUuidV4 } from 'uuid';

const convertStringToBi = (value: string) => {
  return value.replace(/-/g, '_');
};

export const getAppCollectionImpressionsBiData = (
  section: GetDynamicSectionsResponseNonNullableFields['sections'][number],
  sectionIndex: number,
  collimpId: string,
  pageType: AppMarketPage,
): appMarketAppCollectionImpressionsParams => {
  const appIds = section.appGroup.apps.map((app) => app.id);
  const appIdsList = appIds?.join(',');
  return {
    apps_list: appIdsList,
    number_of_apps: appIds.length,
    collimp_id: collimpId,
    tag_name: section.appGroup.tagSlug,
    tag_type: pageType,
    collection_index: sectionIndex,
  };
};

export const getPageViewBiData = (
  marketplaceStore: MarketplaceStore,
  urlParams: string,
): appMarketPageViewParams => {
  return {
    navigation_source: marketplaceStore?.route?.referral || 'first_load',
    tag_type: AppMarketPage.HOMEPAGE,
    tag_name: AppMarketPage.HOMEPAGE,
    referral_info:
      getPathForBi(marketplaceStore?.prevRoute?.referral) ??
      marketplaceStore?.route?.referral ??
      urlParams === 'app-market'
        ? 'side_bar'
        : urlParams,
    widget_referral: getWidgetReferral(),
    slug_index_list: '',
    section: '',
    referral_sub_tag_name:
      marketplaceStore?.prevRoute?.subCategories?.join(','),
  };
};

export const getAppMarketAppImpressionBiData = (
  section: GetDynamicSectionsResponseNonNullableFields['sections'][number],
  sectionIndex: number,
  collimpId: string,
  pageType: AppMarketPage,
  appId: string,
  isBundleApp: boolean,
  appIndex: number,
): appMarketAppImpressionParams => {
  const impressionId = generateUuidV4();
  const { tagSlug, type } = section.appGroup;
  return {
    badge_name: isBundleApp ? 'premium_benefit' : '',
    impression_id: impressionId,
    collimp_id: collimpId,
    impression_type: type === 'PROMO' ? 'promo' : 'box',
    section_index: sectionIndex,
    section: tagSlug,
    tag_type: pageType,
    tag_name: tagSlug,
    app_id: appId,
    index: appIndex,
  };
};

export const getAppModalOpenAppModalClickBiData = (
  tagSlug: string,
  appId: string,
  appIndex: number,
  collimpId: string,
  pageType: AppMarketPage,
  marketplaceStore: MarketplaceStore,
): appModalOpenAppModalClickParams => {
  const { route, prevRoute } = marketplaceStore;
  const { subCategory, referralInfo, searchTerm } = getRouteForBi(
    route,
    prevRoute,
  );
  const tagSlugBi = convertStringToBi(tagSlug);
  const pageTypeBi = convertStringToBi(pageType);
  return {
    app_id: appId,
    index: appIndex,
    tag_name: tagSlugBi,
    sub_tag_name: subCategory,
    tag_type: pageTypeBi,
    section: tagSlugBi,
    referral_info: referralInfo,
    search_term: searchTerm,
    collimp_id: collimpId,
    slug_id: tagSlugBi,
  };
};

export const getWidgetClickBiData = (
  pageName: string,
  tagName: string,
  tagType: string,
  tagTypeClicked: string,
  tagSlag: string,
): appMarketWidgetsClickActionParams => {
  return {
    tag_name: tagName,
    tag_name_clicked: convertStringToBi(tagSlag),
    tag_type: tagType,
    tag_type_clicked: tagTypeClicked,
    page_name: pageName,
  };
};

export const getCategoryClickBiData = (
  slug: string,
  tagTypeClicked: string,
): categoryClickParams => {
  const slugBi = convertStringToBi(slug);
  return {
    category: slugBi,
    origin: 'categories_carousel',
    tag_name: slugBi,
    tag_type: tagTypeClicked,
  };
};

export const getAppMarketWidgetsNavigationClickBiData = (
  currentSlide: number,
  nextSlide: number,
  tagName: string,
  tagType: string,
): appMarketWidgetsNavigationClickParams => {
  const direction = currentSlide < nextSlide ? 'right' : 'left';
  return {
    tag_name: tagName,
    tag_type: tagType,
    direction,
  };
};
