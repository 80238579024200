import React from 'react';
import Helmet from 'react-helmet';
import { languages } from './seo-page.utils';

export interface PagesSEOProps {
  getHref: (languageCode: string) => string;
  metaTagsAggregator: string[];
  title: string;
  description: string;
}

export function SEOPage({
  getHref,
  title,
  description,
  metaTagsAggregator,
}: PagesSEOProps) {
  if (metaTagsAggregator) {
    metaTagsAggregator.push(
      `<title data-meta-tags-aggregator="true">${title}</title>`,
    );
    metaTagsAggregator.push(
      `<meta data-meta-tags-aggregator="true" property="og:title" content="${title}" />`,
    );

    metaTagsAggregator.push(
      `<meta data-meta-tags-aggregator="true" name="description" content="${description}" />`,
    );
    metaTagsAggregator.push(
      `<meta data-meta-tags-aggregator="true" property="og:description" content="${description}" />`,
    );
    metaTagsAggregator.push(
      `<link rel="alternate" hreflang="x-default" href="${getHref('en')}" />`,
    );
    languages.map((language) =>
      metaTagsAggregator.push(
        `<link rel="alternate" hreflang="${language}" href="${getHref(
          language,
        )}" />`,
      ),
    );
  }
  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
    </Helmet>
  );
}
