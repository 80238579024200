import { Path } from '../../../enums/marketplace-enums';
import { config } from '../../../config';
import type { DynamicSectionComponentProps } from '../dynamic-section';
import type { AppsSectionProps } from '@wix/app-market-components';
import { v4 as generateUuidV4 } from 'uuid';
import type { AppBoxData } from '@wix/ambassador-appmarket-v1-app-market-client-aggregator/types';
import {
  appMarketAppCollectionImpressions,
  appMarketAppImpression,
  appModalOpenAppModalClick,
} from '@wix/bi-logger-market/v2';
import {
  getAppCollectionImpressionsBiData,
  getAppMarketAppImpressionBiData,
  getAppModalOpenAppModalClickBiData,
} from '../bi-events-data-parser';

export const appsSectionPropsMapper = (
  dynamicSectionComponentProps: DynamicSectionComponentProps,
): AppsSectionProps => {
  const {
    section,
    i18n,
    index,
    marketplaceStore,
    page,
    biLogger,
    isPremiumSite,
  } = dynamicSectionComponentProps;
  const { type, title, apps, tagSlug, totalAppsCount, rows } =
    section?.appGroup;
  const collimpId = generateUuidV4();

  const navigate = (navigationData: { slug: string; appIndex: number }) => {
    config.goto({
      path: Path.WEB_SOLUTION,
      referralSectionName: tagSlug,
      collimp_id: collimpId,
      ...navigationData,
    });
  };
  const onAppClick = (app: AppBoxData, appIndex: number) => {
    biLogger.report(
      appModalOpenAppModalClick(
        getAppModalOpenAppModalClickBiData(
          tagSlug,
          app.id,
          appIndex,
          collimpId,
          page,
          marketplaceStore,
        ),
      ),
    );
    navigate({ slug: app.slug, appIndex });
  };

  return {
    apps,
    onButtonClick: () => config.goto({ path: Path.COLLECTION, slug: tagSlug }),
    onAppClick,
    title,
    type,
    tagSlug,
    totalAppsCount,
    i18n,
    columns: 4,
    minColumns: 3,
    dataHook: section.appGroup?.tagSlug,
    rows,
    isPremiumSite,
    onSectionLoad: () =>
      apps.length &&
      biLogger.report(
        appMarketAppCollectionImpressions(
          getAppCollectionImpressionsBiData(section, index, collimpId, page),
        ),
      ),
    onAppLoad: (app: AppBoxData, appIndex: number) =>
      biLogger.report(
        appMarketAppImpression(
          getAppMarketAppImpressionBiData(
            section,
            index,
            collimpId,
            page,
            app.id,
            !!app.bundle?.isBundleApp,
            appIndex,
          ),
        ),
      ),
  };
};
