import type { GetTagSectionsResponse as TagsSections } from '@wix/ambassador-appmarket-v1-app-market-client-aggregator/types';
import { TagSection } from './tag-section';
import type { AppGroupSection } from '../../types/sections';

export class TagSections {
  constructor(private readonly tagsSections: TagsSections) {}

  findByTagId(tagId: string): TagSection {
    const tagsSection = this.tagsSections?.sections?.find(
      (section) => section.id === tagId,
    );
    return new TagSection(tagsSection);
  }
  getAppGroupSections(): AppGroupSection[] {
    const tagsSection = this.tagsSections?.sections?.map(
      (section) => new TagSection(section).appGroup,
    );
    return tagsSection ?? [];
  }
}
