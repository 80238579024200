import { composer } from '../../component-decorators/composer';
import { MenuSection } from './menu-section';
import { HomeMenuItem, MenuItem } from '../menu-item';
import { Path } from '../../enums/marketplace-enums';
import * as React from 'react';
import type { MarketApiSection } from '@wix/ambassador-marketplace/types';
import type { MarketplaceStore } from '../../component-decorators/with-marketplace-store';
import type { InjectedExperimentsProps } from '@wix/wix-experiments-react';
import { useState, useEffect } from 'react';

interface IMenuSectionsProps extends Partial<InjectedExperimentsProps> {
  marketplaceStore?: MarketplaceStore;
  sections: MarketApiSection[];
}
export const MenuSections = composer()
  .withMarketplaceStore()
  .withExperiments()
  .compose(
    ({ sections, marketplaceStore, experiments }: IMenuSectionsProps) => {
      const sectionsSizes = [];
      sections?.map(({ sectionData }, index) => {
        sectionsSizes[index] =
          index === 0
            ? sectionData?.tagData?.tagsToWebSolutions?.length
            : sectionData?.tagData?.tagsToWebSolutions?.length +
              sectionsSizes[index - 1];
      });
      const [showPrivateApps, setShowPrivateApps] = useState<boolean>(false);
      const getMenuIndex = (index) =>
        index === 0 ? 0 : sectionsSizes[index - 1];
      const isHomeSelected =
        marketplaceStore.route.path === Path.HOME ||
        marketplaceStore.route.path === Path.SEARCH;

      useEffect(() => {
        if (
          marketplaceStore?.privateAppsTagId &&
          marketplaceStore?.privateAppsTagName &&
          marketplaceStore?.privateAppsTagSlug
        ) {
          const showApps =
            marketplaceStore?.privateAppsTagId &&
            marketplaceStore?.privateAppsTagName &&
            marketplaceStore?.privateAppsTagSlug;
          setShowPrivateApps(!!showApps);
        }
      }, [marketplaceStore?.privateAppsTagSlug]);

      return (
        <>
          <HomeMenuItem isSelected={isHomeSelected} />
          {showPrivateApps && <PrivateApps />}
          {sections?.map(({ id, type, sectionData }, index) => {
            return (
              <MenuSection
                key={id}
                id={id}
                type={type}
                sectionData={sectionData}
                menuIndex={getMenuIndex(index)}
              />
            );
          })}
        </>
      );
    },
  );

// todo: move to mnm
const PrivateApps = composer()
  .withMarketplaceStore()
  .withTranslation()
  .compose(({ marketplaceStore, t }) => (
    <MenuItem
      path={Path.COLLECTION}
      name={t('home.privateApps.title', {
        accountName: marketplaceStore?.privateAppsTagName,
      })} // todo: change
      slug={marketplaceStore?.privateAppsTagSlug}
      index=""
    />
  ));
