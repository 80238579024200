import type { TFunction } from '@wix/wix-i18n-config';
import type { Plan } from '../../../exported-components/pricing-plans-section';
import type { PriceData } from '../plan-box';

const getPriceByPeriod = ({
  plan,
  toggleValue,
  yearlyTitle,
}: {
  plan: Plan;
  toggleValue: string | null;
  yearlyTitle: string;
}): string | undefined => {
  const hasMeterdBilling = !!plan.meterdBilling;
  return hasMeterdBilling
    ? plan.meterdBilling?.baseFee
    : toggleValue === yearlyTitle
    ? plan.yearlyPrice
    : plan.monthlyPrice;
};

const getPriceTitle = ({
  isFreePlan,
  planPriceTitle,
  isMeterdBilling,
  t,
}: {
  isFreePlan: boolean;
  planPriceTitle?: string;
  isMeterdBilling: boolean;
  t: TFunction;
}) => {
  let priceTitle;
  if (planPriceTitle) {
    priceTitle = planPriceTitle;
  } else if (isFreePlan) {
    priceTitle = getFreePriceTitle({ t, isMeterdBilling });
  }
  return priceTitle;
};

const getFreePriceTitle = ({
  t,
  isMeterdBilling,
}: {
  isMeterdBilling: boolean;
  t: TFunction;
}) => {
  return isMeterdBilling
    ? t('solution.page.metered.billing.freePlanTitle')
    : t('solution.page.freePlanTitle');
};

export const getDecodedSign = (symbol: string): string => {
  const decodedSign = symbol?.replace(/&#(\d+);/g, function (_, dec) {
    return String.fromCharCode(dec);
  });
  return decodedSign.includes('$') ? '$' : decodedSign;
};

export const parsePricePlansData = ({
  plan,
  toggleValue,
  yearlyTitle,
  symbol,
  t,
}: {
  plan: Plan;
  toggleValue: string | null;
  yearlyTitle: string;
  symbol: string;
  t: TFunction;
}): {
  priceTitle: string | undefined;
  priceSubTitle: string | undefined;
  priceData: PriceData | undefined;
} => {
  const price = getPriceByPeriod({ plan, toggleValue, yearlyTitle });
  const priceTitle = getPriceTitle({
    isFreePlan: plan.isFree || Number(plan.meterdBilling?.baseFee) === 0,
    planPriceTitle: plan.title,
    isMeterdBilling: !!plan.meterdBilling,
    t,
  });

  return {
    priceTitle,
    priceSubTitle: plan.meterdBilling?.outline,
    priceData: price
      ? {
          decodedSign: getDecodedSign(symbol),
          price,
        }
      : undefined,
  };
};
