import React, { useEffect, useState } from 'react';
import type { ButtonSize } from '@wix/design-system';
import { Button, Tooltip } from '@wix/design-system';
import {
  addAppClick,
  appMarketAppInstallationFailedClient,
  appMarketAppInstallationSuccess,
} from '@wix/bi-logger-market/v2';

import { useTranslation } from '../../../../contexts/translations-context';
import { InstallAppButtonDataHooks } from './data-hooks';
import {
  useEssentials,
  useInstaller,
  useJunkYard,
  useServices,
  useUserContext,
} from '../../../../contexts';
import {
  getAddAppClickBiData,
  getAppMarketAppInstallationFailedClientBiData,
  getAppMarketAppInstallationSuccessBiData,
} from '../../../../bi-services/events-data-parser';
import { useQueryClient } from '@tanstack/react-query';
import type { OpenConsentMethod, BadgeType } from '@wix/app-market-services';

export interface InstallAppButtonProps {
  isDisabled: boolean;
  tooltipContent: string;
  app: {
    id: string;
    isBundle: boolean;
    badges?: BadgeType[];
  };
  size: ButtonSize;
  openMobileInstallationBlockedModal: () => void;
  onInstallationSuccess?: () => void;
}

export function InstallAppButton({
  isDisabled,
  tooltipContent,
  app,
  size,
  openMobileInstallationBlockedModal,
  onInstallationSuccess,
}: InstallAppButtonProps) {
  const { t } = useTranslation();
  const { isMobile } = useUserContext();
  const installer = useInstaller();
  const services = useServices();
  const { biLogger } = useEssentials();
  const queryClient = useQueryClient();
  const { routerData, goBackToReferrer } = useJunkYard();
  const buttonProps = {
    disabled: isDisabled,
    size,
    dataHook: InstallAppButtonDataHooks.button,
  };
  const [openConsentMethod, setOpenConsentMethod] =
    useState<OpenConsentMethod>('NEW_WINDOW');

  useEffect(() => {
    async function getOpenConsentMethod() {
      const webSolutionsBase = await services.getWebSolutionsBase({
        appIds: [app.id],
      });
      const webSolutionBase = webSolutionsBase.findByAppId(app.id);
      return webSolutionBase?.openConsentMethod;
    }
    getOpenConsentMethod().then((ocm) => setOpenConsentMethod(ocm));
  }, []);

  return (
    <Tooltip
      dataHook={InstallAppButtonDataHooks.tooltip}
      inline
      disabled={!tooltipContent}
      content={tooltipContent}
    >
      <Button
        {...buttonProps}
        onClick={() => {
          biLogger.report(
            addAppClick(
              getAddAppClickBiData({
                appId: app.id,
                isBundleApp: app.isBundle,
                tagType: 'app_page',
                tagName: app.id,
                routerData,
                referrerNameForBI: goBackToReferrer?.referrerNameForBI,
                badges: app.badges!,
              }),
            ),
          );

          if (isMobile) {
            return openMobileInstallationBlockedModal();
          }

          installer.installApp({
            appId: app.id,
            consentOpeningMethod: openConsentMethod,
            onSuccess: (instanceId) => {
              biLogger.report(
                appMarketAppInstallationSuccess(
                  getAppMarketAppInstallationSuccessBiData({
                    appId: app.id,
                    tagType: 'app_page',
                    tagName: app.id,
                    instanceId,
                    routerData,
                    referrerNameForBI: goBackToReferrer?.referrerNameForBI,
                    isBundleApp: app.isBundle,
                    badges: app.badges!,
                  }),
                ),
              );
              onInstallationSuccess?.();
            },
            onFailure: () => {
              biLogger.report(
                appMarketAppInstallationFailedClient(
                  getAppMarketAppInstallationFailedClientBiData({
                    appId: app.id,
                    tagType: 'app_page',
                    tagName: app.id,
                  }),
                ),
              );
            },
          });
          queryClient.invalidateQueries();
        }}
      >
        {t('solution.page.addToSite')}
      </Button>
    </Tooltip>
  );
}
