import React from 'react';
import type { IInstaller } from '../models/installer';

const InstallerContext = React.createContext<IInstaller | null>(null);

export const InstallerContextProvider: React.FunctionComponent<{
  installer: IInstaller;
  children: React.ReactNode;
}> = ({ installer, children }) => (
  <InstallerContext.Provider value={installer}>
    {children}
  </InstallerContext.Provider>
);

export function useInstaller() {
  const installer = React.useContext(InstallerContext);

  if (installer === null) {
    throw new Error(
      'useInstaller must be used within a InstallerContextProvider',
    );
  }

  return installer;
}
