import * as React from 'react';
import { SectionType, SupportedComponents } from './SupportedComponents';
import { composer } from '../../component-decorators/composer';
import { config } from '../../config';
import s from './style.scss';
import type { IFetcherInjected } from '../../component-decorators/with-fetcher';
import { biLogger } from '../../bi';
import { FluidContainer } from '../FluidContainer';
import { HomeHeader } from './partials/home-header/home-header';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import { VisibilityDetector } from '@wix/app-market-components';
import { v4 as generateUuidV4 } from 'uuid';
import type { InjectedExperimentsProps } from '@wix/wix-experiments-react';
import { Origin } from '../../enums/marketplace-enums';
import type { MarketplaceStore } from '../../component-decorators/with-marketplace-store';
import { appMarketAppCollectionImpressions } from '@wix/bi-logger-market/v2';
import { useConfigContext } from '../../config-context';
import { TextButton } from '@wix/design-system';
import { ChevronLeft } from '@wix/wix-ui-icons-common';

export interface IHomeComponentProps
  extends Partial<WithTranslationProps>,
    Partial<InjectedExperimentsProps>,
    IFetcherInjected {
  marketplaceStore?: MarketplaceStore;
  fetchData: any;
  locale?: string;
  signedInstance?: string;
  notInStandalone?: boolean;
  isMobile?: boolean;
}

interface IContainerSectionDTO {
  id: string;
  type: string;
  sectionData: any;
}

interface IContainerDTO {
  container: { sections: IContainerSectionDTO[] };
}

const getImpressionIds = (sections) => {
  return sections.map((section) => generateUuidV4());
};

const sendSectionImpressionsBi = (type, sectionProps, index, impressionIds) => {
  if (
    (type === SectionType.COLLECTION || type === SectionType.PROMO) &&
    sectionProps?.webSolutions?.length
  ) {
    const appIds = sectionProps?.webSolutions.map(
      (webSolution) => webSolution.id,
    );
    const appIdsList = appIds?.join(',');
    biLogger.report(
      appMarketAppCollectionImpressions({
        apps_list: appIdsList,
        number_of_apps: appIds.length,
        collimp_id: impressionIds[index],
        tag_name: sectionProps?.rootTagDefinition.slug,
        tag_type: 'homepage',
        collection_index: index,
      }),
    );
  }
};

export const HomeComponentWrapper = composer()
  .withTranslation()
  .withExperiments()
  .compose((props: IHomeComponentProps) => {
    const { locale, signedInstance, fetchData } = props;

    return (
      <HomeComponent
        fetchData={fetchData}
        locale={locale}
        signedInstance={signedInstance}
      />
    );
  });

export const HomeComponent = composer()
  .withMarketplaceStore()
  .withTranslation()
  .withExperiments()
  .compose((props: IHomeComponentProps) => {
    const { marketplaceStore, t, locale, signedInstance } = props;
    const { rpcClient, countryCode } = useConfigContext();
    const fetchData: IContainerDTO = props.fetchData;
    const { sections } = fetchData[0].container;
    const ids = getImpressionIds(sections);

    const filteredSections = () => {
      //  can't be removed since marketplace api returns discovery
      const sectionsToShow = sections.filter(
        (section) => section?.type !== SectionType.DISCOVERY,
      );
      // todo - add in mnm
      return [
        {
          id: 'private-apps',
          type: 'COLLECTION',
          sectionData: {
            tagData: {
              displayName: 'apps for',
              tagsToWebSolutions: [
                {
                  tag: {
                    name: 'private-apps',
                    slug: marketplaceStore?.privateAppsTagSlug,
                  },
                  webSolutions: {
                    tagsAndSubCategories: [
                      {
                        tag: {
                          name: marketplaceStore?.privateAppsTagName,
                          slug: marketplaceStore?.privateAppsTagSlug,
                          description:
                            'Dynamic private apps - please do not add apps ',
                          type: 'COLLECTION',
                          colors: [],
                        },
                      },
                    ],
                    webSolutions: [],
                    total: 0,
                  },
                },
              ],
            },
          },
        },
      ].concat(sectionsToShow);
    };

    const SectionsWrapper = ({ homePageSections, impressionIds }) => {
      const [sectionsWithBi, setSectionsWithBi] = React.useState([]);
      const onSectionImpression = (type, sectionProps, index) => {
        if (!sectionsWithBi.includes(index)) {
          setSectionsWithBi((sectionsWithBi) => [...sectionsWithBi, index]);
          sendSectionImpressionsBi(type, sectionProps, index, impressionIds);
        }
      };

      return (
        <div className={s.sectionsWrapper}>
          {homePageSections
            ?.filter((section) => SupportedComponents[section.type])
            ?.map((section, i) => {
              const type = section.type;
              const SectionComponent = SupportedComponents[type].component;
              const sectionMapper = SupportedComponents[type].propsMapper;
              const sectionProps = sectionMapper
                ? sectionMapper(
                    section.sectionData,
                    marketplaceStore,
                    locale,
                    signedInstance,
                    impressionIds[i],
                    countryCode,
                    rpcClient,
                  )
                : {};
              return (
                <div className={s.sectionWrapper}>
                  <VisibilityDetector
                    onVisible={() => onSectionImpression(type, sectionProps, i)}
                  >
                    <SectionComponent key={i} {...sectionProps} />
                  </VisibilityDetector>
                </div>
              );
            })}
        </div>
      );
    };

    const onBackToReferrerClick = () => {
      config.goBackToReferrer.navigation();
    };

    return (
      <div
        data-hook="homepage"
        data-testit="homepage"
        style={{ position: 'relative', top: '0' }}
      >
        {config.origin === Origin.BIZMGR && config.goBackToReferrer && (
          <FluidContainer className={s.backToReferrer}>
            <TextButton
              onClick={onBackToReferrerClick}
              underline="always"
              dataHook="back-to-referrer-button"
              size="medium"
              prefixIcon={<ChevronLeft />}
            >
              {t('homepage.back.to.referrer', {
                translatedPageName: t(`${config.goBackToReferrer.labelKey}`),
              })}
            </TextButton>
          </FluidContainer>
        )}
        <FluidContainer>
          <HomeHeader />
        </FluidContainer>
        <FluidContainer>
          <SectionsWrapper
            homePageSections={filteredSections()}
            impressionIds={ids}
          />
        </FluidContainer>
      </div>
    );
  });
