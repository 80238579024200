import React from 'react';
import ReactDOM from 'react-dom';
import { StandaloneNotification } from '@wix/marketplace-component';

function showToast(toastOptions: any): void {
  const notificationsContainer: Element =
    document.getElementById('notifications-root');
  ReactDOM.render(
    <StandaloneNotification {...toastOptions} />,
    notificationsContainer,
  );
}

export { showToast };
