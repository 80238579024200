import * as React from 'react';
import style from './solution-top-section.scss';
import { Badge, Box, Tooltip } from '@wix/design-system';
import { composer } from '../../../../../component-decorators/composer';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';

interface ISolutionTopSectionProps extends Partial<WithTranslationProps> {
  solutionIcon: string;
  badgeTitle?: string;
  alt?: string;
  badgeTheme?: any;
  showTooltip?: boolean;
  tooltipContent?: string;
  solutionName?: string;
}

export const SolutionTopSection = composer()
  .withTranslation()
  .compose((props: ISolutionTopSectionProps) => (
    <Box dataHook="solution-top-section" className={style.topSection}>
      <img
        alt={props.alt || 'solution-icon'}
        src={props.solutionIcon}
        className={style.appIcon}
      />
      {props.showTooltip && props.tooltipContent ? (
        <Tooltip
          placement="top"
          content={props.t(props.tooltipContent, {
            appName: props.solutionName,
          })}
        >
          <SolutionTopSectionBadgeBase
            {...props}
            badgeTitle={props.t(props.badgeTitle)}
          />
        </Tooltip>
      ) : (
        <SolutionTopSectionBadgeBase
          {...props}
          badgeTitle={props.t(props.badgeTitle)}
        />
      )}
    </Box>
  ));

export const SolutionTopSectionBadgeBase = ({
  solutionIcon,
  badgeTitle,
  alt,
  badgeTheme,
}: any) => (
  <>
    {badgeTitle && (
      <Badge dataHook="solution-badge" {...badgeTheme}>
        {badgeTitle}
      </Badge>
    )}
  </>
);

//      <Tooltip placement='top' content={props.t(props.tooltipContent, {appName: props.solutionName})}>
