import { HttpClient } from '@wix/http-client';
import type { starRatingBarValue } from '@wix/design-system';
import { calculateApiHostname } from './api.utils';

interface IReviewData {
  reviewId: string;
  rating: starRatingBarValue;
  title: string;
  description: string;
  revision: string;
}

const httpClient = new HttpClient();

// function getAppReviewsById(appDefId: string) {
//   return httpClient
//     .get(
//       `https://${calculateApiHostname()}/_api/reviews-server/v1/products/${appDefId}/reviews`,
//       {
//         withCredentials: true,
//       },
//     )
//     .then(({ data }) => data);
// }

function getAppReviewsById(appDefId: string) {
  return httpClient
    .get(
      `https://${calculateApiHostname()}/app-reviews/v1/reviews/app?appId=${appDefId}`,
      {
        withCredentials: true,
      },
    )
    .then(({ data }) => data);
}

function getUserReview(appDefId: string) {
  return httpClient
    .get(
      `https://${calculateApiHostname()}/app-reviews/v1/reviews/user-review?appId=${appDefId}`,
      {
        withCredentials: true,
      },
    )
    .then(({ data }) => data.userReview);
}

// function submitReview(appDefId: string, reviewData: IReviewData) {
//   const { reviewId, etag, rating, title, description } = reviewData;
//   const appWasPreviouslyReviewed = Boolean(reviewId);

//   if (appWasPreviouslyReviewed) {
//     return httpClient.put(
//       `https://${calculateApiHostname()}/_api/reviews-server/v1/products/${appDefId}/reviews/${reviewId}`,
//       { rating, title, description },
//       {
//         withCredentials: true,
//         headers: { 'If-Match': etag },
//       },
//     );
//   }

//   return httpClient.post(
//     `https://${calculateApiHostname()}/_api/reviews-server/v1/products/${appDefId}/reviews`,
//     {
//       rating,
//       title,
//       description,
//     },
//   );
// }

function submitReview(appDefId: string, reviewData: IReviewData) {
  const { reviewId, rating, title, description, revision } = reviewData;
  const appWasPreviouslyReviewed = Boolean(reviewId);

  if (appWasPreviouslyReviewed) {
    return httpClient.put(
      `https://${calculateApiHostname()}/app-reviews/v1/reviews`,
      { appId: appDefId, reviewId, rating, title, description, revision },
      {
        withCredentials: true,
      },
    );
  }

  return httpClient.post(
    `https://${calculateApiHostname()}/app-reviews/v1/reviews`,
    {
      appId: appDefId,
      rating,
      title,
      description,
    },
  );
}

export { IReviewData, getAppReviewsById, getUserReview, submitReview };
