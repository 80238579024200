import type { PlanDescription } from '../pricing-model';
import type {
  TaxDetails,
  CurrencySettings,
  MeterdBilling,
} from '../apps-plans';

export enum AppBoxPlanType {
  FREE = 'FREE',
  RECURRING = 'RECURRING',
  ONE_TIME = 'ONE_TIME',
  DYNAMIC = 'DYNAMIC',
  METERED = 'METERED',
}

/**
 * Represents a unified type for managing app pricing.
 * @type
 */
export type IAppsPricingPlans = {
  taxSettings: TaxDetails;
  currency: CurrencySettings;
  appId: string;
  isExternalPricing: boolean;
  externalPricingPageUrl?: string;
  plans: IAppPricingPlan[];
  isFreeApp: boolean;
  hasFreePlan: boolean;
  hasDynamicPlan: boolean;
  hasMeteredPlanWithNoBaseFee: boolean;
  cheapestPlan:
    | {
        planType: AppBoxPlanType;
        price: number;
      }
    | undefined;
};

/**
 * Represents a unified type for managing app pricing plan.
 * @type
 */
export type IAppPricingPlan = {
  vendorId: string;
  name: string;
  title?: string;
  isVisible: boolean;
  isFree: boolean;
  description: PlanDescription;
  meterdBilling?: MeterdBilling;
  yearlyPrice?: string;
  monthlyPrice?: string;
  oneTimePrice?: string;
  type: AppBoxPlanType;
  appBoxPrice?: number;
};
