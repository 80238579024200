import * as React from 'react';
import { SearchInput } from './search-input';
import { SearchSuggestionsContainer } from './search-suggestions-container';
import { config } from '../../config';
import { Path } from '../../enums/marketplace-enums';
import type { IWithFocusIndicationInjectedProps } from '../../component-decorators/with-focus-indication';
import { WithFocusIndication } from '../../component-decorators/with-focus-indication';
import type { MarketplaceStore } from '../../component-decorators/with-marketplace-store';
import { withMarketplaceStore } from '../../component-decorators/with-marketplace-store';
import { withBiLogger } from '../../component-decorators/with-bi-logger';
import type { BiInjectedProps as InjectedBiLoggerProps } from '../../bi';
import { biLogger } from '../../bi';
import ReactDOM from 'react-dom';
import {
  getAutocompleteAppsByQuery,
  getSolutionsBaseBySearchQueryInVespa,
  getWixOfferingBySearchQueryInVespa,
} from '../../api/api';
import { getCategoriesByQuery } from './search-suggestions-container/static-data/categories-searches';
import { defaultSuggestions as defaultSuggestionsData } from './search-suggestions-container/static-data/default-suggestions';
import { withTranslation } from '../../component-decorators/with-translation';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import { getPathForBi } from '../../common/bi-events';
import { SuggestionType } from '../../types/common/marketplace';
import { withExperiments } from '../../component-decorators/with-experiments';
import type { InjectedExperimentsProps } from '@wix/wix-experiments-react';
import {
  appMarketAppImpression,
  searchSearchFocus,
  searchSearchClick,
  appModalOpenAppModalClick,
  wixOfferingBoxAction,
} from '@wix/bi-logger-market/v2';
import type { AppSuggestion } from './search-suggestions-container/apps-suggestions';

export interface ISearchBoxContainerProps
  extends Partial<IWithFocusIndicationInjectedProps>,
    Partial<InjectedBiLoggerProps>,
    Partial<WithTranslationProps>,
    Partial<InjectedExperimentsProps> {
  marketplaceStore?: MarketplaceStore;
  isInMenu: boolean;
  isInHeader?: boolean;
  isExpandable?: boolean;
  isInSearchPage?: boolean;
  isInSearchPageWide?: boolean;
  locale: string;
  value?: string;
  searchLocation?: string;
  isBig?: boolean;
}

interface ISearchBoxContainerState {
  value: string;
  isLoading: boolean;
  suggestionsList: any;
  fetchedData: boolean;
  clearedSearchAtDate: number;
}

@withBiLogger
@withExperiments
@withMarketplaceStore
@WithFocusIndication({ init: false })
@withTranslation
export class SearchBoxContainer extends React.Component<
  ISearchBoxContainerProps,
  ISearchBoxContainerState
> {
  constructor(props) {
    super(props);
    this.state = {
      fetchedData: false,
      value: props.value,
      isLoading: false,
      suggestionsList: this.fetchDefaultSuggestions(),
      clearedSearchAtDate: Date.now(),
    };
    this.onSearchInputEnter = this.onSearchInputEnter.bind(this);
    this.onSearchInputValueChange = this.onSearchInputValueChange.bind(this);
    this.addAndRemoveSelectedNode = this.addAndRemoveSelectedNode.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.searchInputOnclickHandler = this.searchInputOnclickHandler.bind(this);
    this.sendSearchSearchClickBi = this.sendSearchSearchClickBi.bind(this);

    // autorun(() => {
    //   if (
    //     props.marketplaceStore.clearSearchBeforeDate >
    //     this.state.clearedSearchAtDate
    //   ) {
    //     this.clearSearchInput();
    //   }
    // });
  }

  fetchDefaultSuggestions() {
    return defaultSuggestionsData.map((item, index) => {
      return { ...item, selected: false, index };
    });
  }

  setScrollPositions(suggestionsList) {
    const node = ReactDOM.findDOMNode(this) as HTMLElement;
    const suggestionsElements = node.querySelectorAll('.suggestion');
    suggestionsElements.forEach((el: HTMLElement, index) => {
      const offset =
        el.offsetParent.clientHeight - (el.offsetTop + el.clientHeight);
      const scrollPosition = offset >= 0 ? 0 : offset * -1;
      suggestionsList[index] = { ...suggestionsList[index], scrollPosition };
    });
    return suggestionsList;
  }

  async onSearchInputValueChange(value) {
    const { marketplaceStore, setFocus, experiments } = this.props;
    setFocus(true);
    this.setState({ isLoading: true, value });
    let suggestionsList;
    if (value === '') {
      suggestionsList = this.fetchDefaultSuggestions();
    } else {
      suggestionsList = await this.fetchData(this.props.t(value), experiments);
      suggestionsList.forEach((item, index) => {
        if (item.type === SuggestionType.APP) {
          biLogger.report(
            appMarketAppImpression({
              app_id: item.id,
              market: config.originForBI,
              msid: marketplaceStore.metaSiteId,
              impression_type: 'search',
              section: 'app_suggestion',
              tag_type: 'search_bar',
              search_term: value,
              section_index: index,
            }),
          );
        } else if (item.type === SuggestionType.WIX_OFFERING) {
          biLogger.report(
            wixOfferingBoxAction({
              app_id: item.id,
              market: config.originForBI,
              action_type: 'impression',
              msid: marketplaceStore.metaSiteId,
              offering_name: item.name,
              search_term: value,
              referral_info: 'search_bar',
            }),
          );
        }
      });
    }
    this.setState({
      suggestionsList,
      fetchedData: true,
      value,
      isLoading: false,
    });
  }

  onSearchInputEnter(value) {
    const { setFocus } = this.props;
    const node = ReactDOM.findDOMNode(this) as HTMLElement;
    const selectedSuggestion = node.querySelector('.selected') as HTMLElement;
    if (selectedSuggestion) {
      selectedSuggestion.click();
    } else {
      this.sendSearchSearchClickBi(value, 'direct');
      setFocus(false);
      config.goto({ path: Path.SEARCH, query: value });
    }
  }

  addAndRemoveSelectedNode(newIndex) {
    const { suggestionsList } = this.state;
    const updatedList = suggestionsList.map((item, index) => {
      if (item.selected) {
        return { ...item, selected: false };
      }

      return item;
    });
    if (newIndex !== null && newIndex !== undefined) {
      updatedList[newIndex] = { ...updatedList[newIndex], selected: true };
    }
    this.setState({ suggestionsList: updatedList });
  }

  setScrollPosition(suggestionsList, index) {
    const node = ReactDOM.findDOMNode(this) as HTMLElement;
    const suggestionsContainer = node.querySelector('.suggestionsContainer');
    if (suggestionsContainer) {
      suggestionsContainer.scrollTop = suggestionsList[index].scrollPosition;
    }
  }

  onKeyDown(e) {
    const { suggestionsList } = this.state;
    const prevIndex: number = suggestionsList.findIndex(
      (item) => item.selected,
    );
    const suggestionsLength: number = suggestionsList.length;
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      let newIndex = 0;
      if (e.key === 'ArrowDown' && prevIndex >= 0) {
        newIndex = (prevIndex + 1) % suggestionsLength;
      }
      if (e.key === 'ArrowUp') {
        newIndex = suggestionsLength - 1;
        if (prevIndex >= 0) {
          newIndex = (prevIndex + suggestionsLength - 1) % suggestionsLength;
        }
      }
      this.addAndRemoveSelectedNode(newIndex);
      this.setScrollPosition(suggestionsList, newIndex);
    }
  }

  // setLoading(value) {
  //   this.setState({ isLoading: value });
  // }

  async fetchData(value, experiments) {
    const showMoreAppResults = experiments?.enabled(
      'specs.marketplace.showMoreAppsInSearch',
    );
    const autocompletePromise = getAutocompleteAppsByQuery(
      value,
      this.props.locale,
    );
    const searchPromise = getSolutionsBaseBySearchQueryInVespa(
      value,
      this.props.locale,
      showMoreAppResults ? 5 : 3,
      0,
      undefined,
    );
    const wixOfferingPromise = getWixOfferingBySearchQueryInVespa(
      value,
      this.props.locale,
      1,
      0,
      undefined,
    );
    const [autocompleteResult, searchResult, wixOfferingResult] =
      await Promise.all([
        autocompletePromise,
        searchPromise,
        wixOfferingPromise,
      ]);
    const searchSuggestions = searchResult?.suggestions;

    const allSuggestions = searchSuggestions.concat(
      wixOfferingResult.suggestions,
    );
    const lastOfferingSuggestionType = wixOfferingResult.suggestions.length
      ? SuggestionType.WIX_OFFERING
      : SuggestionType.APP;
    const apps =
      allSuggestions?.map((app, index) => {
        return {
          ...app,
          suggestionType:
            index !== allSuggestions.length - 1
              ? SuggestionType.APP
              : lastOfferingSuggestionType,
          selected: false,
        };
      }) || [];

    const popularSearchesResponse = autocompleteResult.autocomplete;
    const popularSearches =
      popularSearchesResponse?.map((popular) => ({
        name: popular,
        suggestionType: SuggestionType.SEARCH,
        selected: false,
      })) || [];

    let categories = getCategoriesByQuery(value);
    categories = categories.map((category) => {
      return {
        ...category,
        suggestionType: SuggestionType.CATEGORY,
        selected: false,
      };
    });
    return [...popularSearches, ...apps, ...categories].map(
      (suggestion, index) => {
        return { ...suggestion, index };
      },
    );
  }

  searchInputOnclickHandler() {
    const { setFocus, biLogger, marketplaceStore } = this.props;
    biLogger.report(
      searchSearchFocus({
        market: config.originForBI,
        msid: marketplaceStore.metaSiteId,
        search_location: this.props.searchLocation
          ? this.props.searchLocation
          : this.props.isInMenu
          ? 'sidebar_search'
          : 'main_search',
      }),
    );
    setFocus(true);
  }

  componentDidUpdate(
    prevProps: Readonly<ISearchBoxContainerProps>,
    prevState: Readonly<ISearchBoxContainerState>,
    snapshot?: any,
  ): void {
    const { suggestionsList } = this.state;
    if (prevState.suggestionsList !== suggestionsList) {
      this.setScrollPositions(suggestionsList);
    }
  }

  sendSearchSearchClickBi(
    search_term: string,
    search_type: string,
    index?: number,
  ) {
    const { marketplaceStore, isInMenu } = this.props;
    biLogger.report(
      searchSearchClick({
        market: config.originForBI,
        search_term,
        msid: marketplaceStore.metaSiteId,
        search_type,
        search_location: this.props.searchLocation
          ? this.props.searchLocation
          : isInMenu
          ? 'sidebar_search'
          : 'main_search',
        index,
        tag_type: getPathForBi(this.props.marketplaceStore.route.path),
      }),
    );
  }

  sendAppBoxClickBi = (
    app: AppSuggestion,
    searchLocation: string,
    searchValue: string,
    sectionIndex?: number,
  ) => {
    biLogger.report(
      appModalOpenAppModalClick({
        app_id: app.id,
        index: app.index,
        section_index: sectionIndex,
        market: config.originForBI,
        navigation_source: searchLocation,
        tag_type: 'search_bar',
        section: 'app_suggestion',
        search_term: searchValue,
        slug_id: app.slug,
      }),
    );
  };

  render() {
    const { isFocused, setFocus, isInMenu } = this.props;
    const { suggestionsList, fetchedData, value } = this.state;
    return (
      <div
        data-testid="search-box-container"
        onKeyDownCapture={this.onKeyDown}
        style={{ width: 'fit-content' }}
      >
        <div onClick={this.searchInputOnclickHandler}>
          <SearchInput
            onSearchInputEnter={this.onSearchInputEnter}
            searchValue={value}
            onSearchValueChange={this.onSearchInputValueChange}
            isLoading={this.state.isLoading}
            isInMenu={this.props.isInMenu}
            isInHeader={this.props.isInHeader}
            isExpandable={this.props.isExpandable}
            isInSearchPage={this.props.isInSearchPage}
            isInSearchPageWide={this.props.isInSearchPageWide}
          />
        </div>
        {isFocused && (
          <div onClick={() => setFocus(false)}>
            <SearchSuggestionsContainer
              sendAppBoxClickBi={this.sendAppBoxClickBi}
              onSearchValueChange={this.onSearchInputValueChange}
              searchValue={value}
              suggestions={suggestionsList}
              addSelectedNode={this.addAndRemoveSelectedNode}
              fetchedData={fetchedData}
              sendSearchSearchClickBi={this.sendSearchSearchClickBi}
              isInHeader={this.props.isInHeader}
              searchLocation={this.props.searchLocation}
              isInNewSidebar={
                this.props.experiments.enabled(
                  'specs.app-market.NewCategoriesUX',
                ) && isInMenu
              }
              isBig={this.props.isBig}
              isInSearchPage={this.props.isInSearchPage}
            />
          </div>
        )}
      </div>
    );
  }
}
