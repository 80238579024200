import type { AppGroupSection, IServices } from '@wix/app-market-services';
import { APPS_LIMIT, AppGroupType } from '@wix/app-market-services';

export interface SearchResultsPageData {
  searchTerm: string;
  appGroup: AppGroupSection;
  paging: {
    total: number;
    hasNext: boolean;
  };
}

export async function getApps({
  services,
  searchTerm,
  offset,
}: {
  services: IServices;
  searchTerm: string;
  offset: number;
}) {
  const { appIds, hasMoreApps } = await services.searchApps({
    searchTerm,
    paging: {
      limit: APPS_LIMIT,
      offset,
    },
  });
  const appsData = await services.getAppsByAppIds({
    appIds,
    status: 'PUBLISHED',
  });

  return {
    apps: appsData.toJSON(),
    hasNext: hasMoreApps,
  };
}

export async function getSearchResultsPageData({
  searchTerm,
  services,
}: {
  services: IServices;
  searchTerm: string;
}): Promise<SearchResultsPageData> {
  const { appIds, hasMoreApps, totalApps } = await services.searchApps({
    searchTerm,
    paging: {
      limit: APPS_LIMIT,
      offset: 0,
    },
  });
  const appsData = await services.getAppsByAppIds({
    appIds,
    status: 'PUBLISHED',
  });

  const apps = appsData.toJSON();

  return {
    searchTerm,
    appGroup: {
      type: AppGroupType.COLLECTION,
      tagSlug: searchTerm,
      apps: apps as AppGroupSection['apps'],
      title: '',
      totalAppsCount: 0,
    },
    paging: {
      total: totalApps,
      hasNext: hasMoreApps,
    },
  };
}
