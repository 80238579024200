import React from 'react';
import { Box } from '@wix/design-system';
import { ReviewsInfo } from '../reviews-info';
import { AppIcon } from '../app-icon';
import { AppName } from '../app-name';
import { AppDeveloper } from '../app-developer';
import { AppTeaser } from '../app-teaser';
import { TopBadge } from '../top-badge/TopBadge';
import { CTAButton } from '../cta-button';
import { useConsumerPreferences } from '../contexts/consumer-preferences';
import { PricingBadge } from '../pricing-badge';
import { InstalledBadge } from '../installed-badge';
import { useSolution } from '../contexts/solution';

export function WideAppBox() {
  const {
    showViewAppButton,
    showDeveloperName,
    showRating,
    showPricing,
    showTopBadge,
  } = useConsumerPreferences();

  const { isBundleAppInstalled, isInstalled } = useSolution();

  return (
    <Box width="100%" gap="12px">
      <AppIcon />
      <Box width="100%" align="space-between">
        <Box direction="vertical">
          <Box gap="12px">
            <AppName />
            {showTopBadge ? <TopBadge /> : null}
          </Box>
          {showDeveloperName && (
            <Box marginBottom="6px">
              <AppDeveloper />
            </Box>
          )}
          <AppTeaser />
        </Box>
        <Box verticalAlign="middle">
          <Box marginRight="12px">
            {isBundleAppInstalled || isInstalled ? (
              <InstalledBadge />
            ) : (
              showPricing && <PricingBadge />
            )}
          </Box>
          {showRating && <ReviewsInfo />}
          {showViewAppButton && (
            <Box marginLeft="24px">
              <CTAButton />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
