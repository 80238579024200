import type { InjectedExperimentsProps } from '@wix/wix-experiments-react';
import { composer } from '../../../component-decorators/composer';
import s from './promo-box-compact.scss';
import * as React from 'react';
import { sendOpenSolutionPageBi } from '../../../bi/events';
import {
  Box,
  Card,
  Divider,
  Proportion,
  Text,
  Tooltip,
} from '@wix/design-system';
import { ConditionalLinkRenderer } from '../../solution/solution-grid/solution-box';
import { getClientImageURL } from '../../common/getClientImageURL';
import { PricingPlanOrInstalledBadge } from '../../common/PricingPlanOrInstalledBadge';
import { SolutionTopSectionBadgeBase } from '../../solution/solution-grid/solution-box-compact/solution-top-section';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import type { ISolutionBiData, WebSolutionExtended } from '../../../types';

export interface IPromoBoxProps
  extends Partial<InjectedExperimentsProps>,
    Partial<WithTranslationProps> {
  solution: WebSolutionExtended;
  solutionBiData?: ISolutionBiData;

  onPromoClickHandler(solution: WebSolutionExtended): Promise<void>;

  onBoxClick?(solution: WebSolutionExtended): void;

  onBoxRender?(): void;

  as?: 'link';
  hideCTA?: boolean;
  collimp_id?: any;
  tooltipContent?: string;
  badgeTitle?: string;
  badgeTheme?: any;
  showTooltip?: boolean;
  isMobile?: boolean;
}

export const PromoBoxCompact = composer()
  .withExperiments()
  .withTranslation()
  .compose(
    ({
      solution,
      solutionBiData,
      onBoxClick,
      as,
      tooltipContent,
      badgeTitle,
      badgeTheme,
      showTooltip,
      isMobile,
      t,
    }: IPromoBoxProps) => {
      const showSaleToolTip = false;

      return (
        <div
          data-testid="promo-box"
          className={s.promoBox}
          onClick={(e: React.MouseEvent) => {
            onBoxClick(solution);
            sendOpenSolutionPageBi(solutionBiData);
          }}
        >
          <Card dataHook="promo-box-card" className={s.promoBoxCard}>
            <ConditionalLinkRenderer as={as} to={solution.to}>
              {showTooltip && (
                <Box
                  width="100%"
                  position="absolute"
                  zIndex={1}
                  top="12px"
                  paddingRight="12px"
                  boxSizing="border-box"
                  display="flex"
                  align="right"
                >
                  <Box>
                    <Tooltip
                      placement="top"
                      content={t(tooltipContent, {
                        appName: solution.name,
                      })}
                    >
                      <SolutionTopSectionBadgeBase
                        badgeTheme={badgeTheme}
                        badgeTitle={t(badgeTitle)}
                        showSaleToolTip={showSaleToolTip}
                      />
                    </Tooltip>
                  </Box>
                </Box>
              )}
              <div className={s.imagesStack}>
                <div className={s.bottomImage}>
                  <Proportion aspectRatio="1.5">
                    <img
                      alt={`${solution.name} poster`}
                      src={solution.poster}
                      className={s.poster}
                    />
                  </Proportion>
                </div>
                <div className={s.topImage}>
                  <Box
                    position="absolute"
                    bottom={isMobile ? '-15px' : '-21px'}
                    left={isMobile ? '15px' : '27px'}
                    width="42"
                    height="42"
                    borderRadius="6px"
                    background="#ffffff"
                    className={s.iconOutline}
                  >
                    <img
                      alt={`${solution.name} icon`}
                      src={getClientImageURL(solution.icon, {
                        toHeight: 36,
                        toWidth: 36,
                      })}
                      className={s.icon}
                    />
                  </Box>
                </div>
              </div>
              <Divider />
            </ConditionalLinkRenderer>
            <Box
              className={s.solutionDetails}
              direction="vertical"
              verticalAlign="space-between"
              dataHook="solution-details-box"
              paddingRight={isMobile ? '12px' : '24px'}
              paddingLeft={isMobile ? '18px' : '24px'}
              paddingBottom="18px"
              paddingTop={isMobile ? '12px' : '18px'}
            >
              <Box direction="vertical" paddingTop="6px" height="84px">
                <Text weight="bold" dataHook="solution-name">
                  {solution.name}
                </Text>
                <Text
                  size="small"
                  weight="thin"
                  className={s.solutionDescription}
                >
                  {solution.description}
                </Text>
              </Box>
              <Box className={s.actionBar} verticalAlign="middle">
                <PricingPlanOrInstalledBadge
                  isInstalled={solution.isInstalled}
                  pricing={solution.pricing}
                  showPremiumLabel={solution.showPremiumLabel}
                  isBundleAppInstalled={solution.isBundleAppInstalled}
                  isBundleAppNotInstalled={solution.isBundleAppNotInstalled}
                  savedPrice={solution.savedPrice}
                />
              </Box>
            </Box>
          </Card>
        </div>
      );
    },
  );
