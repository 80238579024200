import * as React from 'react';
import { MarketplaceRouter } from '../marketplace-router';
import { MarketplaceMenu } from '../marketplace-menu';
import s from './marketplace-layout.scss';
import { composer } from '../../component-decorators/composer';
import type { InjectedExperimentsProps } from '@wix/wix-experiments-react';
import { config } from '../../config';
import { Origin } from '../..';
import type { MarketplaceStore } from '../../component-decorators/with-marketplace-store';
import { Sidebar } from '../sidebar';

export interface IMarketplaceLayoutProps
  extends Partial<InjectedExperimentsProps> {
  marketplaceStore?: MarketplaceStore;
  hideSearchInMenu: boolean;
  t: any;
}

export const MarketplaceLayout = composer()
  .withMarketplaceStore()
  .withExperiments()
  .withTranslation()
  .compose(
    ({
      hideSearchInMenu,
      marketplaceStore,
      t,
      experiments,
    }: IMarketplaceLayoutProps) => {
      const isInStandaloneMarket = config.origin === Origin.STANDALONE;
      const minHeight = isInStandaloneMarket ? 'calc(100vh - 60px)' : '100%';
      const shouldShowRegularSidebar =
        !experiments.enabled('specs.app-market.NewCategoriesUX') &&
        !marketplaceStore.route.hideMenu;
      const shouldShowNewSidebar =
        experiments.enabled('specs.app-market.NewCategoriesUX') &&
        !marketplaceStore.route.hideMenu &&
        marketplaceStore.route.path !== 'share-app';

      return (
        <div
          data-testid="marketplace-layout"
          className={s.marketplaceLayout}
          style={{ minHeight }}
        >
          {shouldShowRegularSidebar ? (
            <MarketplaceMenu
              hideSearchInMenu={hideSearchInMenu}
              experiments={experiments}
            />
          ) : null}
          {shouldShowNewSidebar ? (
            <Sidebar experiments={experiments} t={t} />
          ) : null}
          <MarketplaceRouter />
        </div>
      );
    },
  );
