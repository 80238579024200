import jwtDecode from 'jwt-decode';

interface AppMarketParamsProps {
  route?: string;
  appDefId?: string;
  shareId?: string;
  version?: string;
  appId?: string;
  modal?: string;
  customParams?: any;
}

export function appMarketParamsParser(
  jwtToken: string,
): AppMarketParamsProps | undefined {
  try {
    const jsonString = jwtDecode<{ data?: string } & AppMarketParamsProps>(
      jwtToken,
    );

    let params: AppMarketParamsProps;

    if (jsonString.data) {
      params = JSON.parse(jsonString.data);
    } else {
      params = jsonString;
    }

    return params;
  } catch (e) {
    console.log('error in appMarketParamsParser:', e);
  }

  return undefined;
}
