import React from 'react';
import { Text } from '@wix/design-system';
import { useSolution } from '../contexts/solution';

export function AppTeaser() {
  const solution = useSolution();
  const { teaser } = solution;

  return (
    <Text size="small" weight="thin">
      {teaser}
    </Text>
  );
}
