import * as React from 'react';
import { Box, Heading, Text, Image } from '@wix/design-system';
import { pageHeaderDataHooks } from './data-hooks';

export interface PageHeaderProps {
  secondaryTitle?: string;
  primaryTitle?: string;
  description?: string;
  imageUrl?: string;
}

export function PageHeader({
  secondaryTitle,
  primaryTitle,
  description,
  imageUrl,
}: PageHeaderProps) {
  return (
    <Box dataHook={pageHeaderDataHooks.container} align="space-between">
      <Box direction="vertical">
        {secondaryTitle && (
          <Box marginTop="6px">
            <Heading
              dataHook={pageHeaderDataHooks.secondaryTitle}
              size="small"
              as="h1"
            >
              {secondaryTitle}
            </Heading>
          </Box>
        )}
        <Heading
          dataHook={pageHeaderDataHooks.primaryTitle}
          size="extraLarge"
          as="h2"
        >
          {primaryTitle}
        </Heading>
        <Box marginTop="6px">
          {description && (
            <Text
              dataHook={pageHeaderDataHooks.description}
              size="medium"
              weight="thin"
            >
              {description}
            </Text>
          )}
        </Box>
      </Box>
      {imageUrl && (
        <Box marginTop="-16px" marginLeft="6px" flexShrink={0}>
          <Image
            dataHook={pageHeaderDataHooks.image}
            width="96px"
            height="96px"
            transparent={true}
            src={imageUrl}
          />
        </Box>
      )}
    </Box>
  );
}
