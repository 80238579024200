export const AppPropertiesDataHooks = {
  appLanguage: 'app-properties-app-language',
  appCollection: 'app-properties-app-collection',
  appDependency: 'app-properties-app-dependency',
  appAvailabilityTooltip: 'app-properties-app-availability-tooltip',
  appLanguagesTooltip: 'app-properties-app-languages-tooltip',
  appTooltip: 'app-properties-app-languages-tooltip',
  appRequirementsTooltip: 'app-properties-app-requirements-tooltip',
  appAvailableWorldwideMessage:
    'app-properties-app-available-worldwide-message',
  appAvailableInOneCountryMessage:
    'app-properties-app-available-in-one-country-message',
  appAvailableInGeoMessage: 'app-properties-app-available-in-geo-message',
  appNotAvailableInGeoMessage:
    'app-properties-app-not-available-in-geo-message',
  appContentTranslatableMessage:
    'app-properties-app-content-translatable-message',
  siteRequirementsContainer: 'app-properties-featured-in-container',
  featuredInContainer: 'app-properties-featured-in-container',
  featuredInTitle: 'app-properties-featured-in-title',
};
