import React from 'react';
import { Box, Heading, TextButton, Text } from '@wix/design-system';
import { ExternalLink } from '@wix/wix-ui-icons-common';
import { useEssentials } from '../../contexts/essentials-context';
import { pricingPlansSectionDataHooks } from '../../exported-components/pricing-plans-section/data-hooks';

export interface PricingHeaderProps {
  externalPricingPageUrl?: string;
  trialDays?: number;
  onLinkClick?(): void;
}

export function PricingHeader({
  externalPricingPageUrl,
  trialDays,
  onLinkClick,
}: PricingHeaderProps) {
  const { t } = useEssentials();

  return (
    <Box
      direction="horizontal"
      align="space-between"
      verticalAlign="bottom"
      paddingBottom="18px"
    >
      <Box direction="vertical">
        <Heading
          size="large"
          dataHook={pricingPlansSectionDataHooks.sectionTitle}
        >
          {t('solution.page.pricingAndPlans')}
        </Heading>
        {!!trialDays && (
          <Text dataHook={pricingPlansSectionDataHooks.sectionSubTitle}>
            {t('solution.page.pricing.trialDays', { trialDays })}
          </Text>
        )}
      </Box>
      {externalPricingPageUrl && (
        <TextButton
          dataHook={pricingPlansSectionDataHooks.externalPricingLinkButton}
          suffixIcon={<ExternalLink size="24" />}
          as="a"
          href={externalPricingPageUrl}
          target="_blank"
          onClick={() => onLinkClick?.()}
        >
          {t('solution.page.comparePlans')}
        </TextButton>
      )}
    </Box>
  );
}
