import type { TagApp as TagAppType } from '@wix/ambassador-devcenter-appmarket-v1-tag-app/types';

export class TagApp {
  constructor(private readonly tagApp: TagAppType | undefined) {}

  get tagId(): string {
    return this.tagApp?.tagId || '';
  }
  get appId(): string {
    return this.tagApp?.appId || '';
  }
}
