import i18next from 'i18next';

let instance;

export const i18nInit = (locale: string) => {
  if (instance) {
    return instance;
  }
  instance = i18next.createInstance();
  instance.use({
    type: 'backend',
    read: (
      language: string,
      _namespace: string,
      callback: (
        err: any | null,
        translations?: Record<string, string>,
      ) => void,
    ) => {
      return import(`../locales/messages_${language}.json`)
        .then((translation: Record<string, string>) =>
          callback(null, translation),
        )
        .catch((error) => callback(error));
    },
  });
  instance
    .init({
      lng: locale,
      fallbackLng: 'en',
      keySeparator: false,
      react: {
        wait: true,
      },
      interpolation: { escapeValue: false },
    })
    .catch(console.error);
  return instance;
};
