/**
 * RateAndReviewsSectionProps represents the props for the RateAndReviewsSection component.
 *
 * @typedef {Object} RateAndReviewsSectionProps
 * @property {ReviewsSummary} reviewsSummary - The summary of reviews- totalReviewNumber, average rate, how much vote to all sort of rate..
 * @property {boolean} isEligibleForReview - Indicates whether the user is eligible for writing a review-if the app installed in any of his sites.
 * @property {App} app - The details of the app being reviewed.
 * @property {boolean} shouldShowUserActionButton - Specifies whether to show the user action button.
 * @property {Review} userReview - the review that the user wrote on the app(if not will be undefined).
 * @property {(sortMethod: string) => Promise<void>} onSortChange - Callback for sorting reviews('publishedAt'/'totalLikes'/'rating').
 * @property {Review[]} reviews - The array of reviews.
 * @property {(reviewId: string, sort: string) => Promise<void>} onLike - Callback for liking a review.
 * @property {(selectedSort: string, readMoreClicks: number) => Promise<void>} onReviewsLoad - Callback for loading more reviews.
 * @property {(appId: string, review: Review, index: number) => void} onReviewLoad - Callback for loading a specific review.
 * @property {(reviewId: string, opinionId: string, sort: string) => Promise<void>} onUnlike - Callback for unliking a review.
 * @property {(review: Review) => Promise<void>} onSubmit - Callback for submitting a review.
 * @property {(appId: string) => void} onClose - Callback for closing the modal.
 * @property {Object} i18n - The internationalization object.
 */

/**
 */

import * as React from 'react';
import { withEssentialsContext } from '../../contexts/essentials-context';
import { RatingSection } from '../../components/reviews/rating-section';
import type { ExternalComponentProps } from '../types';
import { ReviewsEmptyState } from '../../components/reviews/reviews-empty-state';
import { Box } from '@wix/design-system';
import { ReviewsSection } from '../../components/reviews/reviews-section';
import type { App, ReviewsSummary } from './types';
import type { ReviewType as Review } from '@wix/app-market-services';
import { RateAndReviewSectionContextProvider } from './rate-and-reviews-section-context';
import { useState } from 'react';
import { ReviewModal } from '../review-modal';

export interface RateAndReviewsSectionProps extends ExternalComponentProps {
  reviewsSummary: ReviewsSummary;
  isEligibleForReview: boolean;
  isMobile?: boolean;
  app: App;
  shouldShowUserActionButton: boolean;
  userReview?: Review;
  onSortChange: (sortMethod: string) => Promise<void>;
  reviews: Review[];
  onLike: (reviewId: string, sort: string, limit: number) => Promise<void>;
  onReviewsLoad: (
    selectedSort: string,
    readMoreClicks: number,
  ) => Promise<void>;
  onReviewLoad: (appId: string, review: Review, index: number) => void;
  onUnlike: (
    reviewId: string,
    opinionId: string,
    sort: string,
    limit: number,
  ) => Promise<void>;
  onSubmit: (review: Review) => Promise<void>;
  onClose: (appId: string) => void;
  onUserAction: (appId: string, isEligibleForReview: boolean) => void;
  onReadMoreOrLessClick: (clickType: string) => void;
  onContactSupport(location: string): void;
}

function RateAndReviewsSectionComponent({
  reviewsSummary,
  isEligibleForReview,
  isMobile,
  app,
  shouldShowUserActionButton,
  userReview,
  onSortChange,
  reviews,
  onLike,
  onReviewsLoad,
  onReviewLoad,
  onUnlike,
  onSubmit,
  onClose,
  onUserAction,
  onReadMoreOrLessClick,
  onContactSupport,
  i18n,
  experiments,
}: RateAndReviewsSectionProps) {
  const [isReviewModalOpen, setReviewModalOpen] = useState<boolean>(false);

  return (
    <>
      <ReviewModal
        app={app}
        onContactSupport={onContactSupport}
        onSubmit={async (reviewData: Review) => {
          setReviewModalOpen(false);
          await onSubmit(reviewData);
        }}
        onClose={() => {
          setReviewModalOpen(false);
          onClose(app.id);
        }}
        isOpen={isReviewModalOpen}
        i18n={i18n}
        experiments={experiments}
        review={userReview}
        isMobile={isMobile}
      />
      <RateAndReviewSectionContextProvider
        setReviewModalOpen={setReviewModalOpen}
        isEligibleForReview={isEligibleForReview}
        app={app}
        shouldShowUserActionButton={shouldShowUserActionButton}
        onSortChange={onSortChange}
        onLike={onLike}
        onReviewsLoad={onReviewsLoad}
        onReviewLoad={onReviewLoad}
        onUnlike={onUnlike}
        onSubmit={onSubmit}
        onClose={onClose}
        onUserAction={onUserAction}
        onReadMoreOrLessClick={onReadMoreOrLessClick}
        onContactSupport={onContactSupport}
        isMobile={isMobile}
      >
        {!reviewsSummary.totalReviews ? (
          <ReviewsEmptyState />
        ) : (
          <Box direction="vertical" gap="30px">
            <RatingSection reviewsSummary={reviewsSummary} />
            <ReviewsSection
              userReview={userReview}
              reviews={reviews}
              totalReviews={reviewsSummary.totalReviews}
            />
          </Box>
        )}
      </RateAndReviewSectionContextProvider>
    </>
  );
}

export const RateAndReviewsSection = withEssentialsContext(
  RateAndReviewsSectionComponent,
);
