import type { CategoryCarouselProps } from '@wix/app-market-components';
import type { DynamicSectionComponentProps } from '../dynamic-section';
import { Path } from '../../../enums/marketplace-enums';
import { config } from '../../../config';
import {
  getAppMarketWidgetsNavigationClickBiData,
  getCategoryClickBiData,
  getWidgetClickBiData,
} from '../bi-events-data-parser';
import {
  appMarketWidgetsClickAction,
  appMarketWidgetsNavigationClick,
  categoryClick,
} from '@wix/bi-logger-market/v2';

const navigate = (slug: string) => {
  config.goto({ path: Path.CATEGORY, slug });
};

export const categoryCarouselPropsMapper = (
  dynamicSectionComponentProps: DynamicSectionComponentProps,
): CategoryCarouselProps => {
  const { section, page, biLogger } = dynamicSectionComponentProps;
  const categories = section?.categoryCarousel?.categories ?? [];
  const tagTypeClicked = 'category';
  // TODO: take it from serverless after BEDs will add it to market-sections service
  const tagName = 'explore_by_category';
  const title = 'Explore by Category';

  return {
    categories,
    onCategoryClick: ({ slug }) => {
      biLogger.report(
        categoryClick(getCategoryClickBiData(slug, tagTypeClicked)),
      );
      biLogger.report(
        appMarketWidgetsClickAction(
          getWidgetClickBiData(page, tagName, page, tagTypeClicked, slug),
        ),
      );
      navigate(slug);
    },
    title,
    onChange: (currentSlide: number, nextSlide: number) =>
      biLogger.report(
        appMarketWidgetsNavigationClick(
          getAppMarketWidgetsNavigationClickBiData(
            currentSlide,
            nextSlide,
            tagName,
            page,
          ),
        ),
      ),
    getHrefByCategory: ({ slug }: { slug: string }) => {
      return undefined;
    },
  };
};
