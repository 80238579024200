import React from 'react';
import { Layout, Cell } from '@wix/design-system';
import { AppBox } from '../app-box';
import { SolutionProvider } from '../contexts/solution';
import type { IConsumerPreferences } from '../contexts/consumer-preferences';
import { ConsumerPreferencesProvider } from '../contexts/consumer-preferences';
import type { IConsumerCallbacks } from '../contexts/consumer-callbacks';
import { ConsumerCallbacksProvider } from '../contexts/consumer-callbacks';
import type { WebSolutionExtended } from '../../../../types';

export interface AppsGridBoxProps
  extends IConsumerPreferences,
    IConsumerCallbacks {
  solutions: WebSolutionExtended[];
  finalColumns: 1 | 2 | 3 | 4;
}

export function AppsGridBox({
  solutions,
  finalColumns,
  onClick,
  onInstalledAppClick,
  showViewAppButton,
  showDeveloperName,
  showRating,
  showPricing,
  showTopBadge,
  skin,
}: AppsGridBoxProps) {
  return (
    <Layout gap="24px" rowHeight="1fr">
      {solutions.map((solution, index) => (
        <Cell span={12 / finalColumns} key={solution?.id || index}>
          <ConsumerCallbacksProvider
            callbacks={{
              onClick,
              onInstalledAppClick,
            }}
          >
            <ConsumerPreferencesProvider
              preferences={{
                showViewAppButton,
                showDeveloperName,
                showRating,
                showPricing,
                showTopBadge,
                skin,
              }}
            >
              <SolutionProvider solution={solution}>
                <AppBox />
              </SolutionProvider>
            </ConsumerPreferencesProvider>
          </ConsumerCallbacksProvider>
        </Cell>
      ))}
    </Layout>
  );
}
