import React from 'react';
import type {
  TextButtonSize,
  TextButtonWeight,
  TextButtonSkin,
} from '@wix/design-system';
import { TextButton } from '@wix/design-system';
import { ArrowLeftSmall } from '@wix/wix-ui-icons-common';
import { useTranslation } from '../../contexts/translations-context';

interface BackButtonProps {
  onBack: () => void;
  size: TextButtonSize;
  weight: TextButtonWeight;
  skin: TextButtonSkin;
}

export function BackButton({ onBack, size, weight, skin }: BackButtonProps) {
  const { t } = useTranslation();

  return (
    <TextButton
      dataHook="back-button"
      size={size}
      weight={weight}
      skin={skin}
      onClick={onBack}
      prefixIcon={<ArrowLeftSmall />}
    >
      {t('marketplace.go-back.button')}
    </TextButton>
  );
}
