import * as React from 'react';
import type { IReviewData } from '../api/review';
import { submitReview } from '../api/review';
import type { InputStatusError, starRatingBarValue } from '@wix/design-system';

const EMPTY_REVIEW_DATA: IReviewData = {
  reviewId: '',
  rating: 5,
  title: '',
  description: '',
  revision: '',
};

interface IReviewFormInstance {
  errorKey: string;
  rating: starRatingBarValue;
  setRating(rate: starRatingBarValue): void;
  title: string;
  setTitle(title: string): void;
  titleStatus: InputStatusError | undefined;
  updateTitleStatus(inputValue: string): void;
  description: string;
  setDescription(description: string): void;
  submit(): void;
}

function useReviewForm(
  appDefId: string,
  previousReview: IReviewData = EMPTY_REVIEW_DATA,
  onSuccessfulSubmission?: (reviewData: IReviewData) => void,
): IReviewFormInstance {
  const {
    reviewId,
    rating: initialRating,
    title: initialTitle,
    description: initialDescription,
    revision,
  } = previousReview;

  const [rating, setRating] = React.useState<starRatingBarValue>(initialRating);
  const [title, setTitle] = React.useState(initialTitle);
  const [description, setDescription] = React.useState(initialDescription);

  const [errorKey, setErrorKey] = React.useState('');
  const [titleStatus, setTitleStatus] = React.useState(undefined);

  function updateTitleStatus(inputValue: string) {
    if (inputValue) {
      setTitleStatus(undefined);
    } else {
      setTitleStatus('error');
    }
  }

  async function submit() {
    const reviewData: IReviewData = {
      reviewId,
      rating,
      title,
      description,
      revision,
    };

    if (!reviewData.title) {
      setErrorKey('review-app.error-notification-missing-fields');
      setTitleStatus('error');
      return;
    }

    setErrorKey('');
    setTitleStatus(undefined);

    try {
      await submitReview(appDefId, reviewData);
      onSuccessfulSubmission?.(reviewData);
    } catch (error) {
      if (error.response.status === 412) {
        setErrorKey(
          'review-app.error-notification-user-is-ineligible-to-review',
        );
      } else {
        setErrorKey('review-app.error-notification-server-error');
      }
    }
  }

  return {
    errorKey,
    rating,
    setRating,
    title,
    setTitle,
    titleStatus,
    updateTitleStatus,
    description,
    setDescription,
    submit,
  };
}

export { IReviewFormInstance, useReviewForm };
