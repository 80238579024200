import { httpClient } from '../../http-client';
import { config } from '../../config';
import type { AppMarketRpcClient } from '../../types/app-market-rpc-client';

export const fetchDeveloperPageData =
  (
    companySlug: string,
    locale: string = 'en',
    rpcClient?: AppMarketRpcClient,
  ) =>
  () => {
    const isSSR = typeof window === 'undefined';
    if (isSSR && rpcClient !== undefined) {
      return Promise.all([
        rpcClient.getCategories({ lang: locale }),
        rpcClient.getCompanies({ idsOrSlugs: companySlug, lang: locale }),
      ]).then(([categoryResponse, companiesResponse]) => {
        return {
          company: companiesResponse.companies[0],
          categories: categoryResponse.categoryToSubCategory,
        };
      });
    }

    return Promise.all([
      httpClient.get(
        `${config.baseApiUrl}/_api/marketplace-api/v1/categories?lang=${locale}`,
      ),
      httpClient.get(
        `${config.baseApiUrl}/_api/marketplace-api/v1/company/${companySlug}?lang=${locale}`,
      ),
    ]).then(([categoryResponse, companiesResponse]) => {
      return {
        company: companiesResponse?.data?.companies?.[0],
        categories: categoryResponse?.data?.categoryToSubCategory,
      };
    });
  };
