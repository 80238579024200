import React, { useEffect } from 'react';
import { Box, Page } from '@wix/design-system';
import { PageHeader, AppsSection } from '@wix/app-market-components';
import {
  appMarketSubCategoryClick,
  appMarketPageView,
  appMarketBackButtonClick,
} from '@wix/bi-logger-market/v2';
import { appMarketPageStartLoadedSrc24Evid163 } from '@wix/bi-logger-app-market-data/v2';
import s from '../pages.scss';
import { useAppsSectionSharedPropsMapper } from '../../hooks/apps-section-props-mapper';
import { Path } from '../../models/junk-yard';
import {
  useJunkYard,
  useEssentials,
  useServices,
  useRouterContext,
} from '../../contexts';
import { AppMarketPage } from '../../enums';
import { convertStringToBi } from '../../bi-services/utils';
import { getPageViewBiData } from '../../bi-services/events-data-parser';
import { QueryDecorator } from '../../decorators';
import type { CategoryPageData } from './api/category-page-data';
import { getCategoryPageData } from './api/category-page-data';
import { CategorySkeleton } from './category-skeleton';
import { RoutePath } from '../../models/router';
import { BackButton } from '../../components/back-button';

export interface CategoryPageProps {
  slug: string;
}

function CategoryPageComponent({
  slug,
  category,
  appGroupSections,
}: CategoryPageProps & CategoryPageData) {
  const appsSectionSharedPropsMapper = useAppsSectionSharedPropsMapper();
  const { goto, routerData } = useJunkYard();
  const { biLogger, experiments } = useEssentials();
  const { router } = useRouterContext();
  const page = AppMarketPage.CATEGORY;

  useEffect(() => {
    const urlParams = location.pathname.split('/')[3];
    biLogger.report(
      appMarketPageView(
        getPageViewBiData({
          routerData,
          urlParams,
          pageType: page,
          pageName: slug,
          subCategories: appGroupSections.map((section) => section.tagSlug),
        }),
      ),
    );
  }, []);

  const navigateToSubCategoryPage = (subCategorySlug: string) => {
    goto({
      path: Path.CATEGORY,
      slug: category.slug,
      subCategories: [subCategorySlug],
      referral: 'view_more_apps',
    });
  };

  function onBack() {
    biLogger.report(
      appMarketBackButtonClick({
        tag_type: page,
        tag_name: slug,
        location: 'collection_page',
      }),
    );
    router.navigateTo({
      path: RoutePath.BACK,
      payload: undefined,
    });
  }

  function onButtonClick(subCategorySlug: string, categorySlug: string) {
    biLogger.report(
      appMarketSubCategoryClick({
        type: 'view_more_apps',
        subcategory_name: convertStringToBi(subCategorySlug),
        category_name: convertStringToBi(category.slug),
      }),
    );
    if (experiments.enabled('specs.marketplace.routerOnNewInfra')) {
      router.navigateTo({
        path: RoutePath.SUB_CATEGORY,
        payload: {
          parentSlug: categorySlug,
          slug: subCategorySlug,
          subCat: subCategorySlug,
          referral: 'view_more_apps',
        },
      });
    } else {
      navigateToSubCategoryPage(subCategorySlug);
    }
  }

  return (
    <Page className={`${s.appMarketPage} ${s.appMarketPageBackgroundColor}`}>
      <Page.Content>
        {router.hasHistory() && (
          <Box marginTop="18px">
            <BackButton
              size="small"
              weight="thin"
              skin="dark"
              onBack={onBack}
            />
          </Box>
        )}
        <Box
          dataHook="collection-page-header"
          marginTop={router.hasHistory() ? '6px' : '18px'}
          direction="vertical"
        >
          <PageHeader
            primaryTitle={category.description}
            secondaryTitle={category.name}
            imageUrl={category.image}
          />
        </Box>
        {appGroupSections.map((appGroupSection, index) => {
          return (
            <Box direction="vertical" marginTop="36px">
              <AppsSection
                {...appsSectionSharedPropsMapper(
                  appGroupSection,
                  page,
                  slug,
                  index,
                  undefined,
                  undefined,
                  {
                    path: RoutePath.SUB_CATEGORY,
                    payload: {
                      parentSlug: category.slug,
                      slug: appGroupSection.tagSlug,
                      subCat: appGroupSection.tagSlug,
                      referral: 'view_more_apps',
                    },
                  },
                )}
                onButtonClick={() =>
                  onButtonClick(appGroupSection.tagSlug, category.slug)
                }
                titleSize="medium"
              />
            </Box>
          );
        })}
      </Page.Content>
    </Page>
  );
}

export function CategoryPage({ slug }: CategoryPageProps) {
  const { biLogger, i18n } = useEssentials();
  const services = useServices();

  useEffect(() => {
    biLogger.report(
      appMarketPageStartLoadedSrc24Evid163({
        tag_type: AppMarketPage.CATEGORY,
      }),
    );
  }, [slug]);

  return (
    <QueryDecorator
      initialFetch={() =>
        getCategoryPageData({
          services,
          slug,
          languageCode: i18n.language,
        })
      }
      loader={<CategorySkeleton />}
      memoId={`category-page-${slug}-${i18n.language}`}
    >
      {({ data: categoryPageData }) => (
        <CategoryPageComponent {...categoryPageData} slug={slug} />
      )}
    </QueryDecorator>
  );
}
