import * as React from 'react';
import { composer } from '../../../component-decorators/composer';
import {
  Heading,
  Box,
  Button,
  Divider,
  ModalMobileLayout,
  TextButton,
  Modal,
  RadioGroup,
} from '@wix/design-system';
import { useConfigContext } from '../../../config-context';
import {
  getCurrentLanguageText,
  getLanguagesForMenu,
} from '../../standalone-header/header-user-details/header-desktop-user-details/header-desktop-languages-popover/header-desktop-languages-popover-content/languages';
import { Languages } from '@wix/wix-ui-icons-common';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import { getLanguageLinkHref } from '../../standalone-header/header-user-details/header-desktop-user-details/header-desktop-languages-popover/header-desktop-languages-popover-content/utils';
import type { InjectedUserDetailsProps } from '../../../component-decorators/with-user-details';
import type { InjectedExperimentsProps } from '@wix/wix-experiments-react';
import type { MarketplaceStore } from '../../../component-decorators/with-marketplace-store';

export const MobileMenuLanguages = () => {
  const { locale } = useConfigContext();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <Divider />
      <Box paddingTop="24px" paddingBottom="18px">
        <TextButton
          as="button"
          dataHook="languages-mobile-menu"
          skin="dark"
          prefixIcon={<Languages />}
          onClick={openModal}
        >
          {getCurrentLanguageText(locale)}
        </TextButton>
      </Box>
      <MobileLanguageSelector
        isModalOpen={isModalOpen}
        updateCloseModalState={closeModal}
      />
    </>
  );
};

interface IMobileLanguageSelectorProps
  extends Partial<WithTranslationProps>,
    Partial<InjectedUserDetailsProps>,
    Partial<InjectedExperimentsProps> {
  marketplaceStore?: MarketplaceStore;
  isModalOpen: boolean;
  updateCloseModalState(): void;
}

const MobileLanguageSelector = composer()
  .withMarketplaceStore()
  .withTranslation()
  .withExperiments()
  .compose(
    ({
      isModalOpen,
      updateCloseModalState,
      t,
      marketplaceStore,
      experiments,
    }: IMobileLanguageSelectorProps) => {
      const { locale } = useConfigContext();
      const [selectedLanguage, setSelectedLanguage] = React.useState(locale);
      const onChooseLanguageClick = () => {
        if (selectedLanguage === locale) {
          updateCloseModalState();
        } else if (selectedLanguage) {
          window.location.href = getLanguageLinkHref(
            !!marketplaceStore.userName,
            selectedLanguage,
          );
        }
      };

      const closeModal = () => {
        updateCloseModalState();
        setSelectedLanguage(locale);
      };

      return (
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Language selector"
          scrollableContent
        >
          <ModalMobileLayout
            fullscreen
            onCloseButtonClick={closeModal}
            stickyFooter
            stickyTitle
            title={
              <Heading appearance="H2">
                {t('mobile.menu.languages.selector.title')}
              </Heading>
            }
            content={
              <Box direction="vertical">
                <RadioGroup
                  display="vertical"
                  onChange={(value: string) => {
                    setSelectedLanguage(value);
                  }}
                  vAlign="center"
                  value={selectedLanguage}
                >
                  {getLanguagesForMenu(locale).map((language) => (
                    <RadioGroup.Radio
                      value={language.languageCode}
                      key={language.languageCode}
                    >
                      {language.text}
                    </RadioGroup.Radio>
                  ))}
                </RadioGroup>
              </Box>
            }
            footer={
              <Box align="right">
                <Box marginRight="12px">
                  <Button
                    priority="secondary"
                    onClick={closeModal}
                    dataHook="language-selector-cancel-button"
                  >
                    {t('mobile.menu.languages.selector.cancel.button')}
                  </Button>
                </Box>
                <Button
                  dataHook="language-selector-choose-button"
                  onClick={onChooseLanguageClick}
                >
                  {t('mobile.menu.languages.selector.choose.button')}
                </Button>
              </Box>
            }
          />
        </Modal>
      );
    },
  );
