import * as React from 'react';
import { StarFilled } from '@wix/wix-ui-icons-common';
import { Text, Box } from '@wix/design-system';
import style from './reviews-info.scss';
import { useSolution } from '../contexts/solution';

export function ReviewsInfo() {
  const solution = useSolution();
  const { numberOfReviews, reviewStars } = solution;
  const noReviews = !numberOfReviews || numberOfReviews === 0;

  return (
    <Box dataHook="reviews-info" verticalAlign="middle" height="18px">
      <StarFilled
        color={noReviews ? 'rgba(22, 45, 61, 0.2)' : '#FDB10C'}
        className={style.starIcon}
      />
      <Box>
        <Text
          data-hook="average-rating"
          className={style.rating}
          size="tiny"
          weight="bold"
        >
          {Number(reviewStars).toFixed(1)}
        </Text>
        <Text
          data-hook="total-reviews"
          size="tiny"
          weight="thin"
          secondary
        >{`(${numberOfReviews})`}</Text>
      </Box>
    </Box>
  );
}
