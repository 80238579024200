import React from 'react';
import { Box } from '@wix/design-system';
import type { LayoutComponentProps } from '../../app-box.types';

export function RegularAppBox({
  appName = null,
  appIcon = null,
  appTeaser = null,
  companyName = null,
  topBadge = null,
  ctaButton = null,
  reviewsInfo = null,
  bottomBadge = null,
  className,
}: LayoutComponentProps) {
  return (
    <Box className={className} gap="12px" width="100%" height="100%">
      {appIcon}
      <Box width="100%" direction="vertical" align="space-between">
        <Box direction="vertical">
          <Box align="space-between">
            <Box direction="vertical">
              {appName}
              {companyName ? <Box marginBottom="6px">{companyName}</Box> : null}
            </Box>
            {topBadge}
          </Box>
          {appTeaser}
        </Box>
        <Box marginTop="12px" align="space-between" verticalAlign="middle">
          <Box gap="12px" verticalAlign="middle">
            {reviewsInfo}
            {bottomBadge}
          </Box>
          {ctaButton}
        </Box>
      </Box>
    </Box>
  );
}
