import type { GetPricingModelResponse as PricingModelType } from '@wix/ambassador-devcenter-pricing-v1-pricing-entity/types';
import { PricingModelPlan } from './plan';
import type { IPricingModelPlan, IPricingModel } from './types';

/**
 * Represents a facade for GetPricingModel type on the pricing-proxy service.
 *
 * @class PricingModel
 */

export class PricingModel {
  constructor(private readonly pricingModel: PricingModelType) {}

  get appId(): string {
    const appId = this.pricingModel.appId;
    if (appId) {
      return appId;
    }

    throw new Error(`[pricing-model] (appId) appId is missing in pricingModel`);
  }

  get freeTrialDays(): number {
    return this.pricingModel.model?.freeTrialDays ?? 0;
  }
  get isExternalPricing(): boolean {
    return this.pricingModel.model?.isExternalPricing ?? false;
  }

  get externalPricingPageUrl(): string | undefined {
    return this.pricingModel.model?.externalPricingPageUrl ?? undefined;
  }

  get hasDynamicPlan(): boolean {
    return this.pricingModel.model?.businessModel === 'DYNAMIC' ?? false;
  }

  get plans(): IPricingModelPlan[] {
    const plans = this.pricingModel.model?.plans?.reduce(
      (visiblePlans: IPricingModelPlan[], _plan) => {
        const plan = new PricingModelPlan(_plan);
        if (plan.isVisible) {
          visiblePlans.push(plan.toJSON());
        }
        return visiblePlans;
      },
      [],
    );
    return plans ?? [];
  }
  getAllPlans(): IPricingModelPlan[] {
    return (
      this.pricingModel.model?.plans?.map((plan) =>
        new PricingModelPlan(plan).toJSON(),
      ) || []
    );
  }

  toJSON(): IPricingModel {
    return {
      appId: this.appId,
      freeTrialDays: this.freeTrialDays,
      isExternalPricing: this.isExternalPricing,
      externalPricingPageUrl: this.externalPricingPageUrl,
      plans: this.plans,
      hasDynamicPlan: this.hasDynamicPlan,
    };
  }
}
