import * as React from 'react';
import type { ISolutionGridProps } from './index';
import { SolutionGrid } from './index';
import { composer } from '../../../component-decorators/composer';

export const SolutionGridFull = composer().compose(
  (props: ISolutionGridProps) => {
    const numOfSolutions = props.solutions.length;
    const numberOfLines =
      !!props.numberOfLines && props.numberOfLines > 0
        ? props.numberOfLines
        : Math.min(
            Math.floor(numOfSolutions / 3),
            Math.floor(numOfSolutions / 4),
          );
    return <SolutionGrid {...props} numberOfLines={numberOfLines} />;
  },
);
