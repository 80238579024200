import * as React from 'react';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import { sendFinanceAddMoreAppsClickBi } from '../../../bi/events';
import { Page, Button } from '@wix/design-system';
import type { InjectedExperimentsProps } from '@wix/wix-experiments-react';
import { composer } from '../../../component-decorators/composer';
import type { WebSolutionBase } from '@wix/ambassador-marketplace/types';
import type { IAppsCollectionPageWrapperMetadata } from '../../../types';

export interface IAppsCollectionPageWrapperProps
  extends Partial<WithTranslationProps>,
    Partial<InjectedExperimentsProps> {
  isCompany?: boolean;
  locale?: string;
  onBoxClick?(solution: WebSolutionBase): void;
  onButtonClick?(solution: WebSolutionBase): void;
  onBoxRender?(): void;
  onGoToMarketClick?(): void;
  as?: 'link';
  metadata?: IAppsCollectionPageWrapperMetadata;
  children: React.ReactNode;
  metaSiteId?: string;
}

export const AppsCollectionPageWrapper = composer()
  .withTranslation()
  .compose(
    ({
      metadata,
      t,
      onGoToMarketClick,
      children,
      metaSiteId,
    }: IAppsCollectionPageWrapperProps) => {
      function getSubtitle() {
        return metadata?.subtitle || t('collection.subtitle');
      }

      function getTitle() {
        return metadata?.title || t('collection.title');
      }

      function getOnClick() {
        if (metadata?.cta?.onClick) {
          return metadata?.cta?.onClick;
        }
        return () => {
          sendFinanceAddMoreAppsClickBi(metaSiteId);
          onGoToMarketClick();
        };
      }

      function getCtaMetadata() {
        return metadata?.cta?.ctaMetadata || t('collection.market.cta');
      }

      return (
        <Page height="100vh">
          <Page.Header
            subtitle={getSubtitle()}
            title={getTitle()}
            actionsBar={
              <Button onClick={getOnClick()}>{getCtaMetadata()}</Button>
            }
          />
          <Page.Content>
            <div>{children}</div>
          </Page.Content>
        </Page>
      );
    },
  );
