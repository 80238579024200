import type { GridProps } from './grid';

export const calculateFinalColumns = ({
  requestedColumns,
  noSpaceForThreeColumns,
  noSpaceForTwoColumns,
  noSpaceForFourColumns,
  minColumns = 1,
}: {
  requestedColumns: GridProps['columns'];
  noSpaceForThreeColumns: boolean;
  noSpaceForTwoColumns: boolean;
  noSpaceForFourColumns: boolean;
  minColumns?: number;
}) => {
  let finalColumns: number;
  if (noSpaceForTwoColumns) {
    finalColumns = 1;
  } else if (noSpaceForThreeColumns && requestedColumns >= 3) {
    finalColumns = 2;
  } else if (noSpaceForFourColumns && requestedColumns === 4) {
    finalColumns = 3;
  } else {
    finalColumns = requestedColumns;
  }

  return Math.max(finalColumns, minColumns);
};

export function getGridColumnsCount(gridElement: HTMLDivElement): number {
  const computedStyle = window.getComputedStyle(gridElement);
  const gridTemplateColumns = computedStyle.getPropertyValue(
    'grid-template-columns',
  );
  const columns = gridTemplateColumns.split(' ').length;
  return columns;
}
