export const reviewBodySlicer = (body: string): string =>
  body.split(' ').slice(0, 33).join(' ').replace(/\s*$/, '') + '...';

export const nameToAvatarFormatter = (name: string): string => {
  const formattedName = name.trim();
  return formattedName?.length > 1
    ? `${formattedName.charAt(0)} ${formattedName.charAt(1)}`
    : `${formattedName.charAt(0)} ${formattedName.charAt(0)}`;
};

export const isBodyTooLong = (body: string) => {
  if (body) {
    return body.split(' ').length > 34;
  }
  return false;
};
