import type { WebSolutionBase as WebSolutionBaseType } from '@wix/ambassador-devcenter-marketplace-v1-web-solution/types';
import { OpenConsentIn } from '@wix/ambassador-devcenter-marketplace-v1-web-solution/types';

export class WebSolutionBase {
  constructor(
    private readonly webSolutionBase: WebSolutionBaseType | undefined,
  ) {}

  get appId(): string {
    return this.webSolutionBase?.id ?? '';
  }

  get openConsentMethod(): 'NEW_WINDOW' | 'NEW_TAB' {
    switch (this.webSolutionBase?.openConsentIn) {
      case OpenConsentIn.NEW_WINDOW:
        return 'NEW_WINDOW';
      case OpenConsentIn.NEW_TAB:
        return 'NEW_TAB';
      default:
        return 'NEW_WINDOW';
    }
  }
}
