import type {
  WebSolutionBase,
  ApiTag,
} from '@wix/ambassador-marketplace/types';
import type { GUID } from '@wix/bi-logger-market/v2/types';

export interface ISolutionBiData {
  appId?: string;
  impressionType?: string;
  index?: number;
  market?: string;
  msid?: GUID;
  tagName?: string;
  tagType?: string;
  subCategory?: string;
  section?: string;
  referralInfo?: string;
  searchTerm?: string;
  widgetReferral?: string;
  sectionReferral?: string;
  isBundleAppInstalled?: boolean;
  isBundleAppNotInstalled?: boolean;
  collimp_id?: GUID;
  referralSectionName?: string;
  referralTag?: string;
  referralTagType?: string;
  referralSubTag?: string;
}

export interface ITagBiData {
  widgetType?: string;
  index?: number;
  market?: string;
  msid?: GUID;
  tagName?: string;
  tagNameClicked?: string;
  tagType?: string;
  tagTypeClicked?: string;
  section?: string;
}

export interface WebSolutionExtended extends WebSolutionBase {
  appIndex?: number;
  appIcon?: string;
  poster?: string;
  badge?: string;
  isInstalled?: boolean;
  showPremiumLabel?: boolean;
  isBundleAppInstalled?: boolean;
  isBundleAppNotInstalled?: boolean;
  savedPrice?: number;
  isVoucherApp?: boolean;
  to?: string;
  isAppSetupIncomplete?: boolean;
  isPreInstalled?: boolean;
}

export interface TagExtended extends ApiTag {
  teaser?: string;
  color?: string;
  icon?: string;
  to?: string;
}

export enum SolutionGridLayout {
  MARKETPLACE = 'marketplace',
  INTEGRATIONS = 'integrations',
}

export enum AppMarketWidget {
  FINANCE_INTEGRATIONS = 'finance-integrations',
  MARKETING_INTEGRATIONS = 'marketing-integrations',
  APPS_RECOMMENDATIONS_WIDGETS = 'app-recommendations-widget',
  FULLFILLMENT_SERVICES = 'stores-fulfillment-services',
  MARKETING_HOME = 'marketing-home',
}

export interface IAppsCollectionPageWrapperMetadata {
  title?: string;
  subtitle?: string;
  cta?: {
    onClick?(): void;
    ctaMetadata: string;
  };
}
