import React, { useContext } from 'react';
import type { WebSolutionExtended } from '../../../../types';

const SolutionContext = React.createContext<WebSolutionExtended>(
  {} as WebSolutionExtended,
);

export function SolutionProvider({
  children,
  solution,
}: {
  children: React.ReactNode;
  solution: WebSolutionExtended;
}) {
  return (
    <SolutionContext.Provider value={solution}>
      {children}
    </SolutionContext.Provider>
  );
}

export const useSolution = () => useContext(SolutionContext);
