import type { GetDynamicSectionsResponseNonNullableFields } from '@wix/ambassador-appmarket-v1-app-market-client-aggregator/types';
import { Placement } from '@wix/ambassador-appmarket-v1-app-market-client-aggregator/types';
import { getDynamicSections } from '@wix/ambassador-appmarket-v1-app-market-client-aggregator/http';
import type { Essentials } from '@wix/app-market-core';

const isSSR = () => typeof window === 'undefined';

export const getHomePageDynamicSections = async ({
  httpClient,
  rpcClient,
}: {
  httpClient: Essentials['httpClient'];
  rpcClient: Essentials['rpcClient'];
}): Promise<GetDynamicSectionsResponseNonNullableFields['sections']> => {
  let homePageSections: GetDynamicSectionsResponseNonNullableFields['sections'];
  if (isSSR() && rpcClient !== undefined) {
    const getDynamicSectionsRpc = (
      await import(
        '@wix/ambassador-appmarket-v1-app-market-client-aggregator/rpc'
      )
    ).getDynamicSections;
    const rpcResult = await rpcClient.request(
      getDynamicSectionsRpc({
        placement: Placement.HOME_PAGE,
      }),
    );
    homePageSections = rpcResult?.sections ?? [];
  } else {
    const httpResult = await httpClient.request(
      getDynamicSections({ placement: Placement.HOME_PAGE }),
    );
    homePageSections = httpResult.data?.sections ?? [];
  }

  return homePageSections;
};
