import { sdk } from '@wix/image-kit';

const getRelativeUrl = (iconURL: string) => iconURL && iconURL.split('/').pop();

export interface IClientImageURLOptions {
  originalWidth?: number;
  originalHeight?: number;
  toWidth?: number;
  toHeight?: number;
}

const defaultOptions = {
  originalWidth: 1000,
  originalHeight: 1000,
  toWidth: 54,
  toHeight: 54,
};
export const getClientImageURL = (
  ImageUrl: string,
  options: IClientImageURLOptions = defaultOptions,
): string => {
  const _options = { ...defaultOptions, ...options };
  const { originalWidth, originalHeight, toWidth, toHeight } = _options;
  const relativeUrl = getRelativeUrl(ImageUrl);
  return relativeUrl
    ? sdk.getScaleToFillImageURL(
        relativeUrl,
        originalWidth,
        originalHeight,
        toWidth,
        toHeight,
      )
    : '';
};
