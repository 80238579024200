import React from 'react';
import { Box, Text, SegmentedToggle } from '@wix/design-system';
import { pricingPlansSectionDataHooks } from '../../exported-components/pricing-plans-section/data-hooks';

interface ITogglePricingProps {
  selectedValue: string;
  toggleValues: string[];
  onClick(value: string): void;
}

export function PricingToggle({
  selectedValue,
  toggleValues,
  onClick,
}: ITogglePricingProps) {
  return (
    <Box
      marginBottom={4}
      dataHook={pricingPlansSectionDataHooks.pricingPlansToggleContainer}
    >
      <SegmentedToggle
        size="small"
        selected={selectedValue}
        onClick={(_e, value: string) => onClick(value)}
      >
        {toggleValues.map((value) => {
          const key = `pricing-toggle-${value.toLowerCase()}`;
          return (
            <SegmentedToggle.Button
              value={value}
              dataHook={key}
              key={key}
              style={{ width: '110px' }}
            >
              <Text>{value}</Text>
            </SegmentedToggle.Button>
          );
        })}
      </SegmentedToggle>
    </Box>
  );
}
