import * as React from 'react';
import { composer } from '../../component-decorators/composer';
import { Box, Text, EmptyState, Button } from '@wix/design-system';
import s from './open-app-with-params.scss';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import { config } from '../../config';
import { Path } from '../..';
import { useConfigContext } from '../../config-context';
import { postDeepLinkEditorCache } from '../../api/api';
import type { ManagedApp } from '@wix/ambassador-devcenter-managedapps-v1-managed-app/types';
import type { MarketplaceStore } from '../../component-decorators/with-marketplace-store';
import { useState } from 'react';

interface IOpenAppWithParamsProps extends Partial<WithTranslationProps> {
  marketplaceStore?: MarketplaceStore;
  appId: string;
  options?: any;
}

export const OpenAppWithParams = composer()
  .withMarketplaceStore()
  .withTranslation()
  .compose(
    ({ appId, t, marketplaceStore, options }: IOpenAppWithParamsProps) => {
      const [isLoading, setIsLoading] = useState(true);
      const [appFromInstalled, setAppFromInstalled] = useState(null);
      const { finishAppInstall } = useConfigContext();

      const sendOptionsAndOpenApp = async () => {
        const optionsObj = {
          key: marketplaceStore?.metaSiteId,
          data: JSON.parse(options),
        };
        await postDeepLinkEditorCache(marketplaceStore?.metaSiteId, optionsObj);
        finishAppInstall(
          appFromInstalled?.appId,
          appFromInstalled?.installationDetails?.installedVersion,
          options,
        );
      };

      React.useEffect(() => {
        const app: ManagedApp = marketplaceStore?.installedApps?.find(
          (installedApp) => installedApp.appId === appId,
        );

        if (app) {
          setAppFromInstalled(app);
          sendOptionsAndOpenApp();
          setIsLoading(false);
        }
        if (marketplaceStore?.installedApps?.length >= 0) {
          setIsLoading(false);
        }
      });

      if (isLoading) {
        return <Box align="center"></Box>;
      }

      if (appFromInstalled) {
        return (
          <Box className={s.openApp} marginTop="90px" marginLeft="538px">
            <Box verticalAlign="middle" direction="vertical" align="center">
              <Box className={s.loader} />
              <Text skin="standard" weight="normal" className={s.text}>
                {t('marketplace.adding.element1', {
                  appName: appFromInstalled?.appInfo.name,
                })}
                <br />
                {t('marketplace.adding.element2')}
              </Text>
            </Box>
          </Box>
        );
      }

      return (
        <EmptyState
          theme="page-no-border"
          image={
            <img
              style={{
                width: '120px',
                height: '120px',
              }}
              src={`${config.marketplaceStaticsUrl}/assets/images/empty-state-generic-nothing-here-desert.svg`}
            />
          }
          title={t('marketplace.open-app.title')}
          subtitle={t('marketplace.open-app.subtitle')}
        >
          <Button
            size="medium"
            onClick={() => {
              config.goto({
                path: Path.WEB_SOLUTION,
                slug: appId,
                hideMenu: false,
              });
            }}
          >
            {t('marketplace.goto.appPage')}
          </Button>
        </EmptyState>
      );
    },
  );
