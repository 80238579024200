export class AutoComplete {
  private readonly autoComplete: string[];

  constructor(autoComplete: string[]) {
    this.autoComplete = autoComplete ?? [];
  }

  get autoCompleteResults(): string[] {
    return this.autoComplete;
  }
}
