import React from 'react';
import Helmet from 'react-helmet';
import { useConfigContext } from '../../../config-context';

export const MarketplaceRouterSeo = () => {
  const { route, metaTagsAggregator } = useConfigContext();
  const cleanUrl = encodeURI(route.currentUrl);

  const url = removeParametersFromUrl(cleanUrl);

  if (metaTagsAggregator) {
    metaTagsAggregator.push(
      `<meta data-meta-tags-aggregator="true" property="og:url" content="${cleanUrl}" />`,
    );
    metaTagsAggregator.push(
      `<link data-meta-tags-aggregator="true" rel="canonical" href="${url}" />`,
    );
  }
  return (
    <Helmet>
      <meta property="og:url" content={cleanUrl} />
      <link rel="canonical" href={url} />
    </Helmet>
  );
};

export const removeParametersFromUrl = (url: string) => {
  if (!url) {
    return undefined;
  }

  let cleanedURL = url.split('?')[0];
  if (cleanedURL.endsWith('/')) {
    cleanedURL = cleanedURL.substring(0, cleanedURL.length - 1);
  }
  return cleanedURL;
};
