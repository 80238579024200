import { ListItemAction } from '@wix/design-system';
import * as React from 'react';
import s from './header-desktop-languages-popover-content.scss';
import { getLanguagesForMenu } from './languages';
import { useConfigContext } from '../../../../../../config-context';
import { getLanguageLinkHref } from './utils';

interface IHeaderDesktopLanguagesPopoverContentProps {
  isLoggedIn: boolean;
}

export const HeaderDesktopLanguagesPopoverContent = ({
  isLoggedIn,
}: IHeaderDesktopLanguagesPopoverContentProps) => {
  const { locale } = useConfigContext();

  return (
    <div className={s.popoverContentContainer}>
      {getLanguagesForMenu(locale).map((language) => {
        const { languageCode, text } = language;
        const linkHref = getLanguageLinkHref(isLoggedIn, languageCode);
        return (
          <ListItemAction
            title={text}
            as="a"
            key={languageCode}
            size="small"
            skin="dark"
            href={linkHref}
            className={s.language}
            dataHook={`language-link-${languageCode}`}
          />
        );
      })}
    </div>
  );
};
