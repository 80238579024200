import { SectionHelper, Text } from '@wix/design-system';
import React from 'react';
import { useTranslation } from '../../../../../contexts/translations-context';
import { EditorNotSupportedNotificationDataHooks } from './data-hooks';

export interface EditorNotSupportedNotificationProps {
  appName: string;
  editorType: 'EDITORX' | 'STUDIO';
}

export function EditorNotSupportedNotification({
  appName,
  editorType,
}: EditorNotSupportedNotificationProps) {
  const { t } = useTranslation();

  const getEditorName = () =>
    editorType === 'EDITORX'
      ? t('editor.x.brand.name')
      : t('wix.studio.brand.name');

  return (
    <SectionHelper
      dataHook={EditorNotSupportedNotificationDataHooks.container}
      appearance="danger"
      title={t('solution.page.isNotCompatible.title', {
        appName,
        editorType: getEditorName(),
      })}
      size="small"
    >
      <Text
        dataHook={EditorNotSupportedNotificationDataHooks.description}
        size="small"
        weight="thin"
      >
        {t('solution.page.isNotCompatible.subtitle', {
          editorType: getEditorName(),
        })}
      </Text>
    </SectionHelper>
  );
}
