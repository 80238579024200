import React from 'react';
import { Text, Box } from '@wix/design-system';
import { PremiumSmall } from '@wix/wix-ui-icons-common';
import { useEssentials } from '../../contexts/essentials-context';

export interface PremiumSiteRequiredBadgeProps {
  size?: 'tiny' | 'small';
}

export function PremiumSiteRequiredBadge({
  size = 'tiny',
}: PremiumSiteRequiredBadgeProps) {
  const { t } = useEssentials();

  return (
    <Box gap="2px" verticalAlign="middle">
      <PremiumSmall />
      <Text size={size} weight="thin" skin="standard" secondary>
        {t('app.pricing.Premium')}
      </Text>
    </Box>
  );
}
