import s from '../pages.scss';
import {
  SkeletonLine,
  SkeletonRectangle,
  Box,
  SkeletonGroup,
  Page,
} from '@wix/design-system';
import React from 'react';
import { AppGridSkeleton } from '@wix/app-market-components';
import { useUserContext } from '../../contexts';

export function SubCategorySkeleton() {
  const { isMobile } = useUserContext();

  return (
    <Page className={`${s.appMarketPage} ${s.appMarketPageBackgroundColor}`}>
      <Page.Content>
        <SkeletonGroup skin="light">
          <Box dataHook="skeleton-header" marginTop="30px">
            <Box marginTop="4px" marginLeft="4px">
              <SkeletonLine width="350px" />
            </Box>
          </Box>
          <Box dataHook="skeleton-title" marginTop="18px">
            <SkeletonRectangle width="300px" height="36px" />
          </Box>
          <Box dataHook="skeleton-apps-count" marginTop="24px">
            <SkeletonLine width="48px" />
          </Box>
          <Box marginTop="18px">
            <AppGridSkeleton boxCount={24} minColumns={isMobile ? 1 : 3} />
          </Box>
        </SkeletonGroup>
      </Page.Content>
    </Page>
  );
}
