import React from 'react';
import { AppsGridBox } from '../apps-grid-box';
import { SubHeader } from '../sub-header';
import type { IConsumerPreferences } from '../contexts/consumer-preferences';
import type { IConsumerCallbacks } from '../contexts/consumer-callbacks';
import type { WebSolutionExtended } from '../../../../types';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import { composer } from '../../../../component-decorators/composer';

export interface UninstalledAppsGridProps
  extends IConsumerPreferences,
    IConsumerCallbacks,
    WithTranslationProps {
  solutions: WebSolutionExtended[];
  finalColumns: 1 | 2 | 3 | 4;
}

export const UninstalledAppsGrid = composer()
  .withTranslation()
  .compose(
    ({
      t,
      solutions,
      finalColumns,
      onClick,
      onInstalledAppClick,
      showViewAppButton,
      showDeveloperName,
      showRating,
      showPricing,
      showTopBadge,
      skin,
    }: UninstalledAppsGridProps) => {
      return (
        <>
          <SubHeader title={t('solutionBox.gridBoxUninstalledApps.title')} />
          <AppsGridBox
            showTopBadge={showTopBadge}
            skin={skin}
            finalColumns={finalColumns}
            showDeveloperName={showDeveloperName}
            showRating={showRating}
            showPricing={showPricing}
            showViewAppButton={showViewAppButton}
            solutions={solutions}
            onClick={onClick}
            onInstalledAppClick={onInstalledAppClick}
          />
        </>
      );
    },
  );
