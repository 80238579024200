import React, { useEffect, useState } from 'react';
import { Box, Card, TextButton } from '@wix/design-system';
import { ReviewsSectionHeader } from './reviews-section-header';
import { Review } from '../review';
import { useRateAndReviewSectionContext } from '../../../exported-components/rate-and-reviews-section/rate-and-reviews-section-context';
import type { ReviewType } from '@wix/app-market-services';
import { useEssentials } from '../../../contexts/essentials-context';

interface ReviewsSectionProps {
  reviews: ReviewType[];
  totalReviews: number;
  userReview?: ReviewType;
}

export function ReviewsSection({
  reviews,
  totalReviews,
  userReview,
}: ReviewsSectionProps) {
  const { onReviewsLoad, onSortChange, app } = useRateAndReviewSectionContext();
  const { t } = useEssentials();

  const [selectedSort, setSelectedSort] = useState<string>('publishedAt');
  const [readMoreClicks, setReadMoreClicks] = useState<number>(5);

  useEffect(() => {
    setReadMoreClicks(5);
  }, [selectedSort]);

  async function onClick() {
    setReadMoreClicks(readMoreClicks + 5);
    await onReviewsLoad(selectedSort, readMoreClicks);
  }

  async function onChangeSort(sort: string) {
    setSelectedSort(sort);
    await onSortChange(sort);
  }
  return (
    <Box
      border="1px solid #dcdcdc"
      borderRadius="8px"
      direction="vertical"
      dataHook="reviews-element"
    >
      <Card>
        <ReviewsSectionHeader
          onChangeSort={onChangeSort}
          selectedSort={selectedSort}
          totalReviews={totalReviews}
          userReview={userReview}
        />
        <Card.Divider />
        <Box direction="vertical" padding="24px 24px 0">
          {reviews.map((review, index) => (
            <Review
              index={index}
              review={review}
              selectedSort={selectedSort}
              limit={readMoreClicks}
              company={app.company}
            />
          ))}
        </Box>
      </Card>
      {totalReviews > 5 && readMoreClicks < totalReviews && (
        <>
          <Card.Divider />
          <Box padding="15px" align="center">
            <TextButton onClick={onClick} dataHook="load-more-reviews-button">
              {t('solution.page.reviewsViewMore')}
            </TextButton>
          </Box>
        </>
      )}
    </Box>
  );
}
