import * as React from 'react';
import type { WithTranslationProps } from 'react-i18next';
import { Translation } from 'react-i18next';
import type { TFunction } from 'i18next';

import { ConfigContextConsumer } from '../config-context';

export const withTranslation: any = (
  component: React.ComponentType<WithTranslationProps>,
) => {
  return (props) => (
    <ConfigContextConsumer>
      {({ i18n: i18nConfig }) => {
        const i18n = i18nConfig || props.i18n;
        if (i18n) {
          return React.createElement(component, {
            ...props,
            t: (k, o) => i18n.t(k, o),
          });
        }
        return (
          <Translation>
            {(t) => React.createElement(component, { ...props, t })}
          </Translation>
        );
      }}
    </ConfigContextConsumer>
  );
};

export type TranslateFunction = TFunction;
