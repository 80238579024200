import React from 'react';
import { Box } from '@wix/design-system';
import { ReviewsInfo } from '../reviews-info';
import { AppIcon } from '../app-icon';
import { AppName } from '../app-name';
import { AppDeveloper } from '../app-developer';
import { AppTeaser } from '../app-teaser';
import { TopBadge } from '../top-badge';
import { CTAButton } from '../cta-button';
import { useConsumerPreferences } from '../contexts/consumer-preferences';
import { PricingBadge } from '../pricing-badge';
import { useSolution } from '../contexts/solution';
import { InstalledBadge } from '../installed-badge';

export function RegularAppBox() {
  const {
    showViewAppButton,
    showDeveloperName,
    showRating,
    showPricing,
    showTopBadge,
  } = useConsumerPreferences();

  const { isBundleAppInstalled, isInstalled } = useSolution();

  return (
    <Box gap="12px" width="100%">
      <AppIcon />
      <Box width="100%" direction="vertical" align="space-between">
        <Box direction="vertical">
          <Box align="space-between">
            <Box direction="vertical">
              <AppName />
              {showDeveloperName && (
                <Box marginBottom="6px">
                  <AppDeveloper />
                </Box>
              )}
            </Box>
            {showTopBadge ? <TopBadge /> : null}
          </Box>
          <AppTeaser />
        </Box>
        <Box marginTop="12px" align="space-between" verticalAlign="middle">
          <Box gap="12px" verticalAlign="middle">
            {showRating && <ReviewsInfo />}
            {isBundleAppInstalled || isInstalled ? (
              <InstalledBadge />
            ) : (
              showPricing && <PricingBadge />
            )}
          </Box>
          {showViewAppButton && <CTAButton />}
        </Box>
      </Box>
    </Box>
  );
}
