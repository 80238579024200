import * as React from 'react';
import { Suggestion } from '../suggestion';
import { config } from '../../../../config';
import { Path } from '../../../..';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import { composer } from '../../../../component-decorators/composer';
import type { BiInjectedProps as InjectedBiLoggerProps } from '../../../../bi';
import { SuggestionType } from '../../../../types/common/marketplace';

export interface IPopularSuggestionsProps
  extends Partial<WithTranslationProps>,
    Partial<InjectedBiLoggerProps> {
  onSearchValueChange(value: string): Promise<void>;
  searchTerms: any;
  searchValue: string;
  addSelectedNode(index): void;
  sendSearchSearchClickBi(value: string, type: string, index?: number): void;
}

export const PopularSuggestions = composer()
  .withTranslation()
  .withBiLogger()
  .compose(
    ({
      searchTerms,
      searchValue,
      addSelectedNode,
      onSearchValueChange,
      sendSearchSearchClickBi,
    }: IPopularSuggestionsProps) => {
      const removeSpaces = (str: string) => str && str.replace(/\s/g, '');
      return (
        <React.Fragment>
          {searchTerms.map((term) => (
            <Suggestion
              key={removeSpaces(term.name)}
              suggestionKey={removeSpaces(term.name)}
              value={term.name}
              suggestionType={SuggestionType.SEARCH}
              onClick={() => {
                onSearchValueChange(term.name);
                sendSearchSearchClickBi(
                  searchValue,
                  'autocomplete',
                  term.index,
                );
                config.goto({ path: Path.SEARCH, query: term.name });
              }}
              selected={term.selected}
              index={term.index}
              addSelected={addSelectedNode}
            />
          ))}
        </React.Fragment>
      );
    },
  );
