import * as React from 'react';
import { HeaderDesktopUserDetails } from './header-desktop-user-details/header-desktop-user-details';
import { HeaderNotLoggedInView } from './header-not-logged-in-view/header-not-logged-in-view';
import { composer } from '../../../component-decorators/composer';
import { useConfigContext } from '../../../config-context';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import type { MarketplaceStore } from '../../../component-decorators/with-marketplace-store';

interface IStandaloneHeaderProps extends Partial<WithTranslationProps> {
  marketplaceStore?: MarketplaceStore;
}

export const HeaderUserDetails = composer()
  .withMarketplaceStore()
  .withExperiments()
  .compose(({ marketplaceStore }: IStandaloneHeaderProps) => {
    const base = marketplaceStore.route.currentUrl
      ? marketplaceStore.route.currentUrl
      : 'www.wix.com/app-market';
    const { locale } = useConfigContext();
    return marketplaceStore.userName && marketplaceStore.userImage ? (
      <HeaderDesktopUserDetails
        username={marketplaceStore.userName}
        logoutLink={`https://www.wix.com/signout?redirectTo=${base
          .split('/app-market')[0]
          .concat('/app-market')}`}
      />
    ) : (
      <HeaderNotLoggedInView
        signinLink={`https://users.wix.com/signin?loginDialogContext=login&referralInfo=HEADER&overrideLocale=${locale}&postLogin=${base
          .split('/app-market')[0]
          .concat('/app-market')}&postSignUp=${base
          .split('/app-market')[0]
          .concat('/app-market')}`}
      />
    );
  });
