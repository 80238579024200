export enum OptionTypes {
  APP = 'app',
  SEARCH = 'search',
}

export enum OptionIdKeys {
  APP_ID = 'appId',
  INDEX = 'index',
  TYPE = 'type',
  SLUG = 'slug',
}

export enum SearchTypes {
  APPS = 'apps',
  POPULAR = 'popular',
  MORE_RESULTS = 'more_results',
  DIRECT = 'direct',
}
