import React from 'react';
import s from './review-modal-mobile-layout.scss';
import { ModalMobileLayout } from '@wix/design-system';
import { ReviewModalForm } from '../review-modal-form/review-modal-form';
import { useReviewForm } from '../review-modal-form/use-review-form';
import type { ReviewType as Review } from '@wix/app-market-services';
import { ReviewModalHeaderMobile } from '../review-modal-header-mobile';
import { ReviewModalFooterMobile } from '../review-modal-footer-mobile';

export interface ReviewModalMobileLayoutProps {
  app: {
    id: string;
    name: string;
    icon: string;
  };
  onContactSupport: (location: string) => void;
  review?: Review;
  onSubmit(reviewData: Review): Promise<void>;
  onClose: () => void;
}

export function ReviewModalMobileLayout({
  app,
  onContactSupport,
  review,
  onSubmit,
  onClose,
}: ReviewModalMobileLayoutProps) {
  const reviewForm = useReviewForm(review, onSubmit);

  function onCloseAction() {
    reviewForm.updateError('', undefined);
    onClose();
  }

  return (
    <ModalMobileLayout
      className={s.reviewModalMobile}
      dataHook="review-modal-layout"
      stickyFooter
      title={
        <ReviewModalHeaderMobile
          app={app}
          onContactSupport={onContactSupport}
        />
      }
      footer={
        <ReviewModalFooterMobile
          onCancel={onCloseAction}
          onSubmit={reviewForm.submit}
        />
      }
      onCloseButtonClick={onCloseAction}
      content={<ReviewModalForm appName={app.name} reviewForm={reviewForm} />}
    />
  );
}
