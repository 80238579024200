import type { ListAppPlansByAppIdResponse as AppsPlansType } from '@wix/ambassador-appmarket-v1-app-plans/types';
import { AppPlans } from './app-plans';
import type { IAppPlans } from './types';

/**
 * Represents facade entity for ListAppPlansByAppIdResponse type on the app-plans service.
 *
 * @class AppsPlans
 */
export class AppsPlans {
  constructor(private readonly appsPlans: AppsPlansType) {}
  findByAppId(appId: string): IAppPlans {
    const appPlanPrices = this.appsPlans?.appPlans?.find(
      (_plans) => _plans.id === appId,
    );
    const appPlans = {
      appPlans: appPlanPrices ? [appPlanPrices] : [],
      taxSettings: this.appsPlans.taxSettings,
      currency: this.appsPlans.currency,
      currencySymbol: this.appsPlans.currencySymbol,
    };
    return new AppPlans(appPlans).toJSON();
  }
}
