import React from 'react';
import type { LayoutComponentProps, Options } from './app-box.types';
import { Box, Text } from '@wix/design-system';
import style from './app-box.scss';
import { AppIcon } from '../app-icon';
import { useEssentials } from '../../contexts/essentials-context';
import { AppReviewsInfo } from './app-box-elements/app-reviews-Info';
import { CTAButton } from './app-box-elements/cta-button';
import { NarrowAppBox } from './app-box-layouts/narrow-app-box';
import { RegularAppBox } from './app-box-layouts/regular-app-box';
import { WideAppBox } from './app-box-layouts/wide-app-box';
import type { AppBoxData } from '@wix/ambassador-appmarket-v1-app-market-client-aggregator/types';
import { AppInfoBadge } from '../app-info-badge';
import type { PricingBadgeProps } from '../pricing-badge';
import { appBoxDataHooks } from './data-hooks';
import { useSiteContext } from '../../contexts/site-context';
import { shouldShowPremiumRequired } from '../app-info-badge/app-info-badge.util';
import { Badges } from '../badges/badges';

export interface AppBoxProps {
  appData: AppBoxData;
  onClick?(): void;
  options?: Options;
  href?: string;
}

export function AppBox({ appData, onClick, options = {}, href }: AppBoxProps) {
  const {
    slug,
    icon,
    name,
    company,
    shortDescription,
    reviews,
    installation,
    pricing,
    bundle,
    badges,
  } = appData;
  const {
    showCTA = false,
    showCompanyName = false,
    showRating = true,
    showPricing = true,
    showBorder = true,
    showBadgesVertical = true,
  } = options;

  const { t } = useEssentials();
  const { isPremiumSite } = useSiteContext();

  const renderTopBadge = () => {
    return (
      <Box
        direction={showBadgesVertical ? 'vertical' : 'horizontal'}
        align="right"
        gap={showBadgesVertical ? '3px' : '6px'}
      >
        <Badges
          badges={badges}
          bundle={bundle}
          isInstalled={!!installation?.isInstalled}
          appName={name ?? ''}
        />
      </Box>
    );
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onClick?.();
    }
  };

  const appBoxLayoutProps: LayoutComponentProps = {
    appIcon: (
      <AppIcon
        iconUrl={icon ?? ''}
        appName={name ?? ''}
        className={style.icon}
      />
    ),
    appName: (
      <Text size="medium" weight="bold">
        {name}
      </Text>
    ),
    companyName: showCompanyName ? (
      <Text secondary size="tiny" weight="thin">
        {t('solutionBox.solutionDetails.companyName', {
          company: company?.name,
        })}
      </Text>
    ) : null,
    appTeaser: (
      <Text size="small" weight="thin">
        {shortDescription}
      </Text>
    ),
    ctaButton: showCTA ? (
      <CTAButton onClick={() => onClick?.()}>
        {installation?.isInstalled
          ? t('apps.grid.cta.button.installed')
          : t('apps.grid.cta.button')}
      </CTAButton>
    ) : null,
    reviewsInfo:
      showRating && reviews ? <AppReviewsInfo reviews={reviews} /> : null,
    bottomBadge: (
      <AppInfoBadge
        isAppInstalled={!!installation?.isInstalled}
        isAppBuiltIn={!!installation?.isBuiltIn}
        isAppSetupIncomplete={!!installation?.isSetupIncomplete}
        isBundleApp={!!bundle?.isBundleApp}
        pricing={pricing as PricingBadgeProps}
        options={{
          showPricing,
          showPremiumRequired: shouldShowPremiumRequired(
            !!installation?.requirements?.premiumSite,
            isPremiumSite,
          ),
        }}
      />
    ),
    topBadge: renderTopBadge(),
  };

  return (
    <div
      data-hook={appBoxDataHooks.container}
      className={style.appBoxContainer}
    >
      <div
        data-hook={slug}
        className={`${style.appBox} ${!showBorder ? style.noBorder : ''}`}
        onClick={(e) => {
          onClick?.();
          e.preventDefault();
        }}
        tabIndex={0}
        onKeyDown={handleKeyDown}
      >
        <a className={style.link} href={href}>
          <NarrowAppBox {...appBoxLayoutProps} className={style.narrowLayout} />
          <RegularAppBox
            {...appBoxLayoutProps}
            className={style.regularLayout}
          />
          <WideAppBox {...appBoxLayoutProps} className={style.wideLayout} />
        </a>
      </div>
    </div>
  );
}
