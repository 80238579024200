import {
  PremiumStatus,
  type SitePremiumStatus as SitePremiumStatusType,
} from '@wix/ambassador-premium-v1-asset/types';

export class SitePremiumStatus {
  constructor(
    private readonly sitePremiumStatus: SitePremiumStatusType | undefined,
  ) {}

  get isPremiumSite(): boolean {
    return this?.sitePremiumStatus?.premiumStatus === PremiumStatus.PREMIUM;
  }
}
