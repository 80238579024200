import React from 'react';
import { CustomModalLayout, Modal } from '@wix/design-system';
import type { ExternalComponentProps } from '../types';
import {
  useEssentials,
  withEssentialsContext,
} from '../../contexts/essentials-context';
import { ReviewModalHeader } from '../../components/reviews/review-modal-header';
import { ReviewModalForm } from '../../components/reviews/review-modal-form/review-modal-form';
import { useReviewForm } from '../../components/reviews/review-modal-form/use-review-form';
import type { ReviewType as Review } from '@wix/app-market-services';
import { ReviewModalMobileLayout } from '../../components/reviews/review-modal-mobile-layout';

export interface ReviewAppModalProps extends ExternalComponentProps {
  app: {
    id: string;
    name: string;
    icon: string;
  };
  onContactSupport: (location: string) => void;
  review?: Review;
  onSubmit(reviewData: Review): Promise<void>;
  onClose: () => void;
  isOpen: boolean;
  isMobile?: boolean;
}

function ReviewModalComponent({
  app,
  onContactSupport,
  review,
  onSubmit,
  onClose,
  isOpen,
  isMobile,
}: ReviewAppModalProps) {
  const { t } = useEssentials();

  const reviewForm = useReviewForm(review, onSubmit);

  function onCloseAction() {
    reviewForm.updateError('', undefined);
    onClose();
  }

  return (
    <Modal
      screen={isMobile ? 'mobile' : 'desktop'}
      zIndex={100000}
      isOpen={isOpen}
      dataHook="review-modal"
    >
      {isMobile ? (
        <ReviewModalMobileLayout
          app={app}
          onContactSupport={onContactSupport}
          review={review}
          onSubmit={onSubmit}
          onClose={onClose}
        />
      ) : (
        <CustomModalLayout
          dataHook="review-modal-layout"
          width="600px"
          height="544px"
          title={
            <ReviewModalHeader
              appName={app.name}
              appIcon={app.icon}
              onContactSupport={onContactSupport}
            />
          }
          primaryButtonText={t('review-app.submit-review')}
          primaryButtonOnClick={reviewForm.submit}
          secondaryButtonText={t('review-app.cancel-review')}
          secondaryButtonOnClick={onCloseAction}
          onCloseButtonClick={onCloseAction}
        >
          <ReviewModalForm appName={app.name} reviewForm={reviewForm} />
        </CustomModalLayout>
      )}
    </Modal>
  );
}

export const ReviewModal = withEssentialsContext(ReviewModalComponent);
