import React, { useContext } from 'react';
import type { i18n } from 'i18next';
import type { IRoute } from '../types/common/marketplace';
import type { StatusCode } from '../enums/marketplace-enums';
import type { AppMarketRpcClient } from '../types/app-market-rpc-client';
import type { ManagedApp } from '@wix/ambassador-devcenter-managedapps-v1-managed-app/types';

export interface IConfigContext {
  locale?: string;
  i18n?: i18n;
  ssrMemo?: any;
  baseUrl?: string;
  route?: IRoute;
  metaTagsAggregator?: string[];
  isMobile?: boolean;
  isBusinessFirstSite?: boolean;
  isAppOnStage?(appDefinitionId: string): Promise<boolean>;
  closeMarket?(): {} | void;
  finishAppInstall?(appDefId: string, appVersion: string, options?: any): void;
  openApp?(app: any, options?: any): Promise<{} | void>;
  getAppInstance?(appDefID: string): string;
  goToWixOffering?(
    pageComponentId: string,
    appState: string,
    text: string,
    buttonText: string,
  ): void;
  openBMInEditor?(path: string): void;
  openHelpCenter?(): void;
  countryCode?: string;
  showUserActionNotification?(INotificationsOptions): void;
  shouldUseSupportModal?: boolean;
  unInstallApp?(appDefID: string): Promise<any>;
  responseCode?(code: StatusCode): void;
  openPackagePicker?(
    app: ManagedApp,
    ppOrigin?: string,
    isAppInBundle?: boolean,
  ): void;
  rpcClient?: AppMarketRpcClient;
  showSidebar?(): void;
  hideSidebar?(): void;
}

const _configContext = React.createContext<IConfigContext>({});

export const ConfigContextProvider = _configContext.Provider;

export const ConfigContextConsumer = _configContext.Consumer;

export const useConfigContext = (): IConfigContext =>
  useContext(_configContext);
