import type { MarketApp as MarketAppType } from '@wix/ambassador-devcenter-market-apps-v1-market-app/types';

export class MarketApp {
  constructor(private readonly marketApp: MarketAppType | undefined) {}

  get supportEmails(): string[] {
    return this.marketApp?.contactEmails?.supportEmails || [];
  }

  get status(): string {
    return this.marketApp?.versionStatus ?? '';
  }
}
