import * as React from 'react';
import { Suggestion } from '../suggestion';
import { config } from '../../../../config';
import { Path } from '../../../..';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import { composer } from '../../../../component-decorators/composer';
import s from './apps-suggestions.scss';
import type { BiInjectedProps as InjectedBiLoggerProps } from '../../../../bi';
import { Text, ListItemSelect } from '@wix/design-system';
import { SuggestionType } from '../../../../types/common/marketplace';
import type { InjectedExperimentsProps } from '@wix/wix-experiments-react';
import { Origin } from '../../../../enums/marketplace-enums';

export interface IAppsSuggestionsProps
  extends Partial<WithTranslationProps>,
    Partial<InjectedExperimentsProps>,
    Partial<InjectedBiLoggerProps> {
  apps: any[];
  searchValue: string;

  addSelectedNode(index): void;

  onSearchValueChange(value: string): Promise<void>;

  sendSearchSearchClickBi(value: string, type: string, index?: number): void;

  sendAppBoxClickBi(
    app: AppSuggestion,
    searchLocation: string,
    searchValue: string,
    index: number,
  ): void;
  searchLocation?: string;
}

export interface AppSuggestion {
  id: string;
  icon: string;
  name: string;
  teaser: string;
  slug: string;
  selected: boolean;
  index: number;
}

export const AppsSuggestions = composer()
  .withTranslation()
  .withExperiments()
  .withBiLogger()
  .compose(
    ({
      t,
      apps,
      searchValue,
      addSelectedNode,
      onSearchValueChange,
      sendSearchSearchClickBi,
      sendAppBoxClickBi,
      searchLocation,
    }: IAppsSuggestionsProps) => {
      const removeSpaces = (str: string) => str && str.replace(/\s/g, '');
      const IsEditorX =
        config.editorType === 'EDITORX' && config.origin === Origin.EDITOR;
      const IsStudio =
        config.editorType === 'STUDIO' && config.origin === Origin.EDITOR;
      const isWixOfferingApp = (app) => {
        return (
          (app.type === 'APP' && app.showRegularAppPage) ||
          app.type === 'WIX_OFFERING'
        );
      };
      const sugesstions =
        IsEditorX || IsStudio
          ? apps.filter((app) => !isWixOfferingApp(app))
          : apps;
      return (
        <React.Fragment>
          {sugesstions.length > 0 && (
            <div
              className={s.appsSuggestionsTitle}
              data-testid="apps-suggestions-title"
            >
              <div className={s.titleText}>
                <Text size="small">{t('search.box.apps.title')}</Text>
              </div>
            </div>
          )}
          {sugesstions.map((app, index) => (
            <Suggestion
              key={removeSpaces(app.slug)}
              suggestionKey={removeSpaces(app.slug)}
              value={app.name}
              suggestionType={SuggestionType.APP}
              onClick={() => {
                onSearchValueChange(app.name);
                sendSearchSearchClickBi(searchValue, 'apps', app.index);
                sendAppBoxClickBi(app, searchLocation, searchValue, index);
                config.goto({
                  path: Path.WEB_SOLUTION,
                  slug: app.slug,
                  searchLocation,
                });
              }}
              icon={app.icon}
              teaser={app.teaser}
              selected={app.selected}
              index={app.index}
              addSelected={addSelectedNode}
            />
          ))}
          {sugesstions.length > 0 && (
            <ListItemSelect
              title={
                <Text
                  size="small"
                  weight="thin"
                  skin="primary"
                  dataHook="see-more-results"
                >
                  {t('search.box.more')}
                </Text>
              }
              onClick={() => {
                sendSearchSearchClickBi(searchValue, 'more_results', 4);
                config.goto({ path: Path.SEARCH, query: searchValue });
              }}
            />
          )}
        </React.Fragment>
      );
    },
  );
