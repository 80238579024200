import React from 'react';
import type { Essentials } from '../models';
import type { FC, ReactChild } from 'react';
import { TranslationContextProvider } from './translations-context';

export const EssentialsContext = React.createContext<Essentials | undefined>(
  undefined,
);

interface EssentialsContextProps {
  essentials: Essentials;
  children: ReactChild;
}

export const EssentialsContextProvider: FC<EssentialsContextProps> = ({
  essentials,
  children,
}) => (
  <EssentialsContext.Provider value={essentials}>
    <TranslationContextProvider i18n={essentials.i18n}>
      {children}
    </TranslationContextProvider>
  </EssentialsContext.Provider>
);

export const useEssentials = () => {
  const context = React.useContext(EssentialsContext);
  if (context === undefined) {
    throw new Error(
      'useEssentials must be used within a EssentialsContextProvider',
    );
  }
  return context;
};
