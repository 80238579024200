import * as React from 'react';
import type { IModalProps } from '../modals';
import {
  Modal,
  ModalMobileLayout,
  Heading,
  Text,
  Box,
  Button,
  TextButton,
} from '@wix/design-system';
import type { TFunction } from 'i18next';

interface BlockAddReviewProps extends IModalProps {
  t?: TFunction;
}
import { composer } from '../../../component-decorators/composer';
import { isWixApp, wixChatBotLink } from '../../../common/utils';

function BlockAddReviewMobileBase({
  t,
  app,
  marketplaceStore,
}: BlockAddReviewProps) {
  function handleClose() {
    return marketplaceStore.closeAllModals();
  }

  function getContent() {
    const getActionText = () => {
      return isWixApp(app?.company?.companyBase?.id)
        ? t('solution.page.reviews.block-helper-cta2')
        : t('solution.page.reviews.block-helper-cta1');
    };
    const getSubtitleText = () => {
      return isWixApp(app?.company?.companyBase?.id)
        ? t('solution.page.reviews.block-helper-description-wix', {
            appName: app?.baseInfo.name,
          })
        : t('solution.page.reviews.block-helper-description', {
            appName: app?.baseInfo.name,
          });
    };

    const onActionClick = () => {
      if (isWixApp(app?.company?.companyBase?.id) && window) {
        window.open(wixChatBotLink);
      } else if (window) {
        const supportMails = app?.supportEmails.join(';');
        window.location.href = 'mailto:' + supportMails;
      }
    };
    const subtitleText = getSubtitleText();
    const actionText = getActionText();
    return (
      <Box
        direction="vertical"
        align="center"
        textAlign="center"
        marginTop="18px"
        marginLeft="36px"
        marginRight="36px"
      >
        <Heading appearance="H2">
          {t('solution.page.reviews.block-helper-title')}
        </Heading>
        <Box marginTop="18px" marginBottom="42px">
          <Text skin="standard" secondary weight="thin">
            {subtitleText}
          </Text>
        </Box>
        <Button priority="secondary" onClick={onActionClick}>
          {actionText}
        </Button>
        <Box marginBottom="18px" />
        <TextButton onClick={handleClose}>
          {t('modals-installationNotSupportedMobile-cta')}
        </TextButton>
      </Box>
    );
  }
  return (
    <Modal isOpen={true} zIndex={100000}>
      <ModalMobileLayout
        onCloseButtonClick={handleClose}
        content={getContent()}
        fullscreen
      />
    </Modal>
  );
}

const BlockAddReviewMobile = composer()
  .withTranslation()
  .withMarketplaceStore()
  .compose((props: BlockAddReviewProps) => (
    <BlockAddReviewMobileBase {...props} />
  ));

export { BlockAddReviewProps, BlockAddReviewMobile, BlockAddReviewMobileBase };
