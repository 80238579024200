import type { V1App as V1AppType } from '@wix/ambassador-devcenter-apps-v1-app/types';

export class App {
  constructor(private readonly app: V1AppType | undefined) {}

  get id(): string {
    return this.app?.id ?? '';
  }
  get slug(): string {
    return this.app?.slug ?? '';
  }

  get isWixApp(): boolean {
    return this.app?.wixApp ?? false;
  }
}
