import React from 'react';
import { Box, Button } from '@wix/design-system';
import {
  useEssentials,
  useRouterContext,
  useSiteContext,
} from '../../../contexts';
import { Route, RoutePath, type RoutePayloadMap } from '../../../models';

export type Subcategory = {
  id: string;
  name: string;
  slug: string;
  parentSlug: string;
  parentName: string;
};
interface SubCategoriesProps {
  subCategories: Subcategory[];
  onTagClick(
    categorySlug?: string,
    subCategory?: string,
    label?: 'category' | 'sub_category',
    location?: 'top_page' | 'side_category_page',
  ): void;
}

export function CategoriesTags({
  subCategories,
  onTagClick,
}: SubCategoriesProps) {
  const { router } = useRouterContext();
  const { experiments } = useEssentials();
  const { baseURL } = useSiteContext();
  function organizeSubCategories(subCategoriesList: Subcategory[]) {
    const organizedCategories: {
      parentName: string;
      parentSlug: string;
      subCategories: { name: string; slug: string }[];
    }[] = [];

    subCategoriesList.forEach((subcategory) => {
      const parentIndex = organizedCategories.findIndex(
        (category) => category.parentName === subcategory.parentName,
      );

      if (parentIndex !== -1) {
        organizedCategories[parentIndex].subCategories.push({
          name: subcategory.name,
          slug: subcategory.slug,
        });
      } else {
        organizedCategories.push({
          parentName: subcategory.parentName,
          parentSlug: subcategory.parentSlug,
          subCategories: [{ name: subcategory.name, slug: subcategory.slug }],
        });
      }
    });

    return organizedCategories;
  }
  function getButtonHref(path: RoutePath, payload: RoutePayloadMap[RoutePath]) {
    const hasHref = router.hasHref();
    if (
      !experiments.enabled('specs.marketplace.routerOnNewInfra') ||
      !hasHref
    ) {
      return undefined;
    }
    const route = new Route({
      path,
      payload,
      baseURL,
    });
    return route.toHref();
  }

  const tags = organizeSubCategories(subCategories);

  return (
    <Box dataHook="categories-tags" gap="6px" flexWrap="wrap">
      {tags.map((category) => {
        return (
          <Box columnGap="6px" rowGap="8px" flexWrap="wrap">
            <Button
              as="a"
              href={getButtonHref(RoutePath.CATEGORY, {
                slug: category.parentSlug,
              })}
              onClick={(e) => {
                onTagClick(
                  category.parentSlug,
                  '',
                  'category',
                  'side_category_page',
                );
                e.preventDefault();
              }}
              key={category.parentName}
              size="tiny"
              priority="secondary"
              style={{ outline: '1px solid #116DFF' }}
            >
              {`${category.parentName
                .charAt(0)
                .toUpperCase()}${category.parentName.slice(1)}`}
            </Button>

            {category.subCategories.map((subCategory, subIndex) => (
              <Button
                as="a"
                href={getButtonHref(RoutePath.SUB_CATEGORY, {
                  parentSlug: category.parentSlug,
                  slug: subCategory.slug,
                  subCat: subCategory.slug,
                })}
                onClick={(e) => {
                  onTagClick(
                    category.parentSlug,
                    subCategory.slug,
                    'sub_category',
                    'side_category_page',
                  );
                  e.preventDefault();
                }}
                dataHook={`tag-${category.parentName}-${subCategory.slug}`}
                key={`${category.parentName}-${subCategory.slug}-${subIndex}`}
                size="tiny"
                priority="secondary"
                style={{
                  outline: '1px solid  #116DFF',
                }}
              >
                {`${subCategory.name
                  .charAt(0)
                  .toUpperCase()}${subCategory.name.slice(1)}`}
              </Button>
            ))}
          </Box>
        );
      })}
    </Box>
  );
}
