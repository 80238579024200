import type { GetDynamicSectionsResponseNonNullableFields } from '@wix/ambassador-appmarket-v1-app-market-client-aggregator/types';

import type { DeepRequired, Optional } from './general';

export type Section =
  GetDynamicSectionsResponseNonNullableFields['sections'][number];

export enum AppGroupType {
  UNKNOWN = 'UNKNOWN',
  COLLECTION = 'COLLECTION',
  PROMO = 'PROMO',
}

export type AppGroupSection = Optional<
  DeepRequired<NonNullable<Section['appGroup']>>,
  'rows'
>;

export type CategoryCarouselSection = DeepRequired<
  NonNullable<Section['categoryCarousel']>
>;
