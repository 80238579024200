import React, { useContext } from 'react';

export interface IConsumerCallbacks {
  onClick?(appId: string): void;
  onInstalledAppClick?(appId: string): void;
}

const consumerCallbacksContext = React.createContext<IConsumerCallbacks>(
  {} as IConsumerCallbacks,
);

export function ConsumerCallbacksProvider({
  children,
  callbacks,
}: {
  children: React.ReactNode;
  callbacks: IConsumerCallbacks;
}) {
  return (
    <consumerCallbacksContext.Provider value={callbacks}>
      {children}
    </consumerCallbacksContext.Provider>
  );
}

export const useConsumerCallbacks = () => useContext(consumerCallbacksContext);
