import type { HTMLAttributes } from 'react';
import React, { useEffect, useRef, useState } from 'react';

export interface VisibilityDetectorProps
  extends HTMLAttributes<HTMLDivElement> {
  onVisible?(): void;
  onHidden?(): void;
  onFirstVisible?(): void;
}

export function VisibilityDetector({
  onVisible,
  onHidden,
  onFirstVisible,
  style,
  children,
}: VisibilityDetectorProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [wasVisible, setWasVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.intersectionRatio > 0) {
          if (!wasVisible) {
            setWasVisible(true);
            onFirstVisible?.();
          }
          onVisible?.();
        } else {
          onHidden?.();
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: [0.0],
      },
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [onFirstVisible, onVisible, onHidden, wasVisible]);

  return (
    <div style={style} ref={ref}>
      {children}
    </div>
  );
}
