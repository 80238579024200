import React from 'react';
import { Box, InfoIcon, Text } from '@wix/design-system';
import { appMarketAppPageHover } from '@wix/bi-logger-market/v2';
import { useTranslation } from '../../../../contexts/translations-context';
import { AppAvailableCountries } from './app-available-countries';
import { useEssentials, useSiteContext } from '../../../../contexts';
import type { MarketListing } from '@wix/app-market-services';
import { AppPropertiesDataHooks } from './data-hooks';

export interface AppGeoAvailabilityProps {
  appId: string;
  appName: string;
  isWixApp: boolean;
  geoAvailability: MarketListing['geoAvailability'];
}

export const AppGeoAvailability = ({
  appId,
  isWixApp,
  appName,
  geoAvailability,
}: AppGeoAvailabilityProps) => {
  const { t } = useTranslation();
  const { biLogger } = useEssentials();
  const siteContext = useSiteContext();
  const countryCode = siteContext?.countryCode;

  const renderAvailableInMessage = () => {
    if (geoAvailability.isAvailableWorldwide) {
      return (
        <Text
          dataHook={AppPropertiesDataHooks.appAvailableWorldwideMessage}
          size="small"
          weight="thin"
        >
          {t('solution.page.tagsBoxAvailabilityWorldwide')}
        </Text>
      );
    }

    const availableCountries = geoAvailability.availableCountries ?? [];

    if (availableCountries.length === 1) {
      return (
        <Text
          dataHook={AppPropertiesDataHooks.appAvailableInOneCountryMessage}
          size="small"
          weight="thin"
        >
          {t('solution.page.tagsBoxAvailabilityText6', {
            countryName: t(`marketplace.country.${availableCountries[0]}`),
          })}
        </Text>
      );
    }

    if (countryCode && availableCountries.includes(countryCode)) {
      return (
        <Text
          dataHook={AppPropertiesDataHooks.appAvailableInGeoMessage}
          size="small"
          weight="thin"
        >
          {t('solution.page.tagsBoxAvailabilityText1', {
            countryName: t(`marketplace.country.${countryCode}`),
          })}
          <Box inline paddingLeft="3px">
            <Text size="small" weight="thin">
              {t('solution.page.tagsBoxAvailabilityText2')}
            </Text>
          </Box>
          <Box inline paddingLeft="3px">
            <AppAvailableCountries
              label={t('solution.page.tagsBoxAvailabilityText3')}
              appId={appId}
              appName={appName}
              isWixApp={isWixApp}
              availableCountries={availableCountries}
            />
          </Box>
        </Text>
      );
    }

    if (countryCode && !availableCountries.includes(countryCode)) {
      return (
        <Text
          dataHook={AppPropertiesDataHooks.appNotAvailableInGeoMessage}
          size="small"
          weight="thin"
        >
          {t('solution.page.tagsBoxAvailabilityText4', {
            countryName: t(`marketplace.country.${countryCode}`),
          })}
          <Box inline>
            <Text size="small" weight="thin">
              {t('solution.page.tagsBoxAvailabilityText5')}
            </Text>
          </Box>
          <Box inline paddingLeft="3px">
            <AppAvailableCountries
              label={t('solution.page.tagsBoxAvailabilityText3')}
              appId={appId}
              appName={appName}
              isWixApp={isWixApp}
              availableCountries={availableCountries}
            />
          </Box>
        </Text>
      );
    }

    return (
      <Text size="small" weight="thin">
        {t('solution.page.tagsBoxAvailabilityText8')}
        <Box inline>
          <AppAvailableCountries
            label={t('solution.page.tagsBoxAvailabilityText7')}
            appId={appId}
            appName={appName}
            isWixApp={isWixApp}
            availableCountries={availableCountries}
          />
        </Box>
      </Text>
    );
  };

  return (
    <Box direction="vertical">
      <Box gap={1}>
        <Text size="small" weight="bold">
          {t('solution.page.tagsBoxAvailabilityTitle')}
        </Text>
        {!geoAvailability.isAvailableWorldwide && (
          <InfoIcon
            dataHook={AppPropertiesDataHooks.appAvailabilityTooltip}
            tooltipProps={{
              onShow: () => {
                biLogger.report(
                  appMarketAppPageHover({
                    app_id: appId,
                    location: 'properties',
                    hover_type: 'availability',
                  }),
                );
              },
            }}
            size="small"
            content={t('solution.page.tagsBoxAvailabilityTooltip')}
          />
        )}
      </Box>
      {renderAvailableInMessage()}
    </Box>
  );
};
