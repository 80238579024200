import { withTranslation } from './with-translation';
import { withExperiments } from './with-experiments';
import { withPermissions } from './with-market-permissions';
import { withMarketplaceStore } from './with-marketplace-store';
import { WithBi } from '../bi';
import { withSuspenseFetcherHOC } from './with-suspense-fetcher';
import { withUserDetails } from './with-user-details';

class Composer {
  private readonly funcs = [];

  withTranslation() {
    this.funcs.push(withTranslation);
    return this;
  }

  withExperiments() {
    this.funcs.push(withExperiments);
    return this;
  }

  withPermissions() {
    this.funcs.push(withPermissions);
    return this;
  }

  withMarketplaceStore() {
    this.funcs.push(withMarketplaceStore);
    return this;
  }

  withBiLogger() {
    this.funcs.push(WithBi);
    return this;
  }

  withUserDetails() {
    this.funcs.push(withUserDetails);
    return this;
  }

  withSuspenseFetcher(fetchBuilder: (props: any) => () => Promise<any>) {
    this.funcs.push((component) =>
      withSuspenseFetcherHOC(fetchBuilder, component),
    );
    return this;
  }

  compose(val) {
    return this.funcs.reduceRight(
      (prevComposition, func) => func?.(prevComposition) ?? prevComposition,
      val,
    );
  }
}

export const composer = () => new Composer();
