import { withTranslation } from './with-translation';
import { withExperiments } from './with-experiments';

class Composer {
  private readonly funcs = [];

  withTranslation() {
    this.funcs.push(withTranslation);
    return this;
  }

  withExperiments() {
    this.funcs.push(withExperiments);
    return this;
  }

  compose(val) {
    return this.funcs.reduceRight(
      (prevComposition, func) => func(prevComposition),
      val,
    );
  }
}
export const composer = () => new Composer();
