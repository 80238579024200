import type { TFunction } from '@wix/wix-i18n-config';
import type { Plan } from '../../../exported-components/pricing-plans-section';
import { EDisplayTaxType } from '@wix/app-market-services';

export function getPricingDisclaimerText({
  tax,
  currency,
  companyName,
  hasMeteredBillingPlan,
  isExternalPricing,
  showPriceWithTax,
  t,
}: {
  tax: EDisplayTaxType;
  currency: string;
  companyName: string;
  hasMeteredBillingPlan: boolean;
  isExternalPricing: boolean;
  showPriceWithTax: boolean;
  t: TFunction;
}): string {
  const TAXES: Record<EDisplayTaxType, string> = {
    [EDisplayTaxType.GST]: 'solution.page.pricing.gst',
    [EDisplayTaxType.VAT]: 'solution.page.pricing.vat',
    [EDisplayTaxType.NOT_APPLICABLE]: 'solution.page.pricing.tax',
  };
  const taxName = t(TAXES[tax]);
  const isCurrencyUSD = currency === 'USD';
  let disclaimerText;
  if (hasMeteredBillingPlan) {
    disclaimerText = t('solution.page.metered.pricingCurrency');
  } else if (isExternalPricing && showPriceWithTax) {
    const key = isCurrencyUSD
      ? 'solution.page.external.pricingCurrencyTaxUSA'
      : 'solution.page.external.pricingCurrencyTaxAll';
    disclaimerText = t(key, {
      currency,
      taxName,
      companyName,
    });
  } else if (isExternalPricing && !showPriceWithTax) {
    const key = isCurrencyUSD
      ? 'solution.page.external.pricingCurrencyUSA'
      : 'solution.page.external.pricingCurrencyAll';
    disclaimerText = t(key, {
      currency,
      companyName,
    });
  } else if (!isExternalPricing && showPriceWithTax) {
    disclaimerText = t('solution.page.pricingCurrencyTaxAll', {
      currency,
      taxName,
    });
  } else {
    disclaimerText = t('solution.page.pricingCurrencyAll', { currency });
  }

  return disclaimerText;
}

export function shouldShowPricingDisclaimer({
  plans,
}: {
  plans: Plan[];
}): boolean {
  return plans.some((plan) => isPlanWithPrices({ plan }));
}

function isPlanWithPrices({ plan }: { plan: Plan }): boolean {
  return !!plan.monthlyPrice || !!plan.yearlyPrice || !!plan.meterdBilling;
}
