import * as React from 'react';
import { Suggestion } from './suggestion';
import type { AppSuggestion } from './apps-suggestions';
import { AppsSuggestions } from './apps-suggestions';
import { config } from '../../../config';
import { Origin, Path } from '../../..';
import { CategoriesSuggestions } from './categories-suggestions';
import { PopularSuggestions } from './popular-suggestions';
import s from './search-suggestions-container.scss';
import type { BiInjectedProps as InjectedBiLoggerProps } from '../../../bi';
import { composer } from '../../../component-decorators/composer';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import { Text } from '@wix/design-system';
import { SuggestionType } from '../../../types/common/marketplace';
import type { InjectedExperimentsProps } from '@wix/wix-experiments-react';
import { WixOfferingSuggestion } from './wix-offering-suggestion/wix-offering-suggestion';

export interface ISearchSuggestionsContainerProps
  extends Partial<InjectedExperimentsProps> {
  onSearchValueChange(value: string): Promise<void>;

  searchValue: string;
  suggestions: any;

  addSelectedNode(index): void;

  fetchedData: boolean;
  isBig?: boolean;
  sendSearchSearchClickBi(value: string, type: string, index?: number): void;
  sendAppBoxClickBi(
    app: AppSuggestion,
    searchLocation: string,
    searchValue: string,
  ): void;
  isInHeader?: boolean;
  searchLocation?: string;
  isInNewSidebar?: boolean;
  isInSearchPage?: boolean;
}

export const SearchSuggestionsContainer = composer()
  .withExperiments()
  .compose(
    ({
      onSearchValueChange,
      searchValue,
      suggestions,
      addSelectedNode,
      fetchedData,
      sendAppBoxClickBi,
      sendSearchSearchClickBi,
      isInHeader,
      experiments,
      searchLocation,
      isInNewSidebar = false,
      isBig = false,
      isInSearchPage,
    }: ISearchSuggestionsContainerProps) => {
      const showWixOffering =
        config.editorType !== 'EDITORX' && config.editorType !== 'STUDIO';
      const wixOfferingExists = suggestions.some(
        (item) => item.suggestionType === SuggestionType.WIX_OFFERING,
      );
      return (
        <div
          data-testid="search-suggestions"
          className={`${s.suggestionsWrapper} ${
            isInHeader ? s.isInHeader : ''
          } ${isInSearchPage ? s.isInSearchPage : ''}`}
          style={isInNewSidebar ? { position: 'fixed' } : undefined}
        >
          {!searchValue ? (
            <div data-testid="default-suggestions">
              <DefaultSuggestions
                onSearchValueChange={onSearchValueChange}
                addSelectedNode={addSelectedNode}
                suggestions={suggestions}
                sendSearchSearchClickBi={sendSearchSearchClickBi}
                isBig={isBig}
              />
            </div>
          ) : (
            fetchedData && (
              <div
                style={{
                  maxHeight:
                    config.origin === Origin.EDITOR && !isInNewSidebar
                      ? '333px'
                      : undefined,
                }}
                className={`${
                  isBig ? s.suggestionsContainerBig : s.suggestionsContainer
                } suggestionsContainer`}
              >
                <div>
                  <PopularSuggestions
                    onSearchValueChange={onSearchValueChange}
                    searchTerms={suggestions.filter(
                      (item) => item.suggestionType === SuggestionType.SEARCH,
                    )}
                    searchValue={searchValue}
                    addSelectedNode={addSelectedNode}
                    sendSearchSearchClickBi={sendSearchSearchClickBi}
                  />
                </div>
                <div>
                  <AppsSuggestions
                    sendAppBoxClickBi={sendAppBoxClickBi}
                    onSearchValueChange={onSearchValueChange}
                    searchValue={searchValue}
                    apps={suggestions.filter(
                      (item) => item.suggestionType === SuggestionType.APP,
                    )}
                    addSelectedNode={addSelectedNode}
                    sendSearchSearchClickBi={sendSearchSearchClickBi}
                    searchLocation={searchLocation}
                  />
                </div>
                <div>
                  <CategoriesSuggestions
                    onSearchValueChange={onSearchValueChange}
                    searchValue={searchValue}
                    categories={suggestions.filter(
                      (item) => item.suggestionType === SuggestionType.CATEGORY,
                    )}
                    addSelectedNode={addSelectedNode}
                    sendSearchSearchClickBi={sendSearchSearchClickBi}
                  />
                </div>
                {showWixOffering && wixOfferingExists && (
                  <WixOfferingSuggestion
                    searchValue={searchValue}
                    sendSearchSearchClickBi={sendSearchSearchClickBi}
                    wixOffering={suggestions.find(
                      (item) =>
                        item.suggestionType === SuggestionType.WIX_OFFERING,
                    )}
                  />
                )}
              </div>
            )
          )}
        </div>
      );
    },
  );

interface IDefaultSuggestionProps
  extends Partial<InjectedBiLoggerProps>,
    Partial<WithTranslationProps> {
  onSearchValueChange(value: string): Promise<void>;
  suggestions: any;
  isBig: boolean;
  addSelectedNode(index): void;

  sendSearchSearchClickBi(value: string, type: string, index?: number): void;
}

const DefaultSuggestions = composer()
  .withBiLogger()
  .withTranslation()
  .compose(
    ({
      suggestions,
      addSelectedNode,
      onSearchValueChange,
      sendSearchSearchClickBi,
      t,
      isBig,
    }: IDefaultSuggestionProps) => {
      return (
        <React.Fragment>
          <div
            className={
              isBig
                ? s.defaultSuggestionsBigContainer
                : s.defaultSuggestionsContainer
            }
          >
            <div className={s.defaultSuggestionsTitle}>
              <Text size="small">
                {t('search.box.default.suggestions.title')}
              </Text>
            </div>
            {suggestions.map(
              ({ suggestionKey, value, suggestionType, index, selected }) => (
                <Suggestion
                  key={suggestionKey}
                  suggestionKey={suggestionKey}
                  value={value}
                  suggestionType={suggestionType}
                  onClick={async () => {
                    onSearchValueChange(value);
                    sendSearchSearchClickBi(t(value), 'popular', index);
                    config.goto({ path: Path.SEARCH, query: t(value) });
                  }}
                  index={index}
                  selected={selected}
                  addSelected={addSelectedNode}
                />
              ),
            )}
          </div>
        </React.Fragment>
      );
    },
  );
