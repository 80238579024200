import type {
  IAppPricingPlan,
  IAppsPricingPlans,
  AppBoxPlanType,
} from './types';
import type { CurrencySettings, TaxDetails, IAppPlans } from '../apps-plans';
import { AppPricingPlan } from './app-pricing-plan';
import type { IPricingModel } from '../pricing-model';
import { emptyAppPlansMock } from './pricing-plans-section-api.mock';
import type { SupportedAppStatuses } from '../../types';

/**
 * Represents a unified class for managing app plans prices.
 * Retrieves app plans from the app-plans service and pricing models from the pricing-proxy service.
 * @class
 */

export class AppPricingPlans {
  constructor(
    private readonly appPlans: IAppPlans | undefined,
    private readonly pricingModel: IPricingModel | undefined,
    private readonly status: SupportedAppStatuses,
  ) {}

  get taxSettings(): TaxDetails {
    return this.appPlans?.taxSettings ?? emptyAppPlansMock.taxSettings;
  }
  get currency(): CurrencySettings {
    return this.appPlans?.currency ?? emptyAppPlansMock.currency;
  }
  get appId(): string {
    return this.pricingModel?.appId ?? emptyAppPlansMock.appId;
  }

  get isExternalPricing(): boolean {
    return this.pricingModel?.isExternalPricing ?? false;
  }

  get externalPricingPageUrl(): string | undefined {
    return this.pricingModel?.externalPricingPageUrl ?? undefined;
  }

  get isFreeApp(): boolean {
    return (
      this.plans.length === 0 ||
      (this.plans.length === 1 && this.plans[0].isFree)
    );
  }

  get hasFreePlan(): boolean {
    return this.plans.some((plan) => plan.isFree);
  }

  get hasDynamicPlan(): boolean {
    return this.pricingModel?.hasDynamicPlan ?? false;
  }

  get hasMeteredPlanWithNoBaseFee(): boolean {
    return (
      (this.status === 'DRAFT'
        ? this.pricingModel?.plans.some(
            (plan) =>
              plan.meterdBilling && Number(plan.meterdBilling?.baseFee) === 0,
          )
        : this.appPlans?.plans.some(
            (plan) => Number(plan.meterdBilling?.baseFee) === 0,
          )) || false
    );
  }

  get cheapestPlan():
    | {
        planType: AppBoxPlanType;
        price: number;
      }
    | undefined {
    const validPlans = this.plans.filter(
      (plan) => plan.appBoxPrice !== undefined,
    );

    if (validPlans.length === 0) {
      return undefined;
    }

    const cheapestPlan = validPlans.reduce(
      (_cheapestPlan, plan) =>
        plan.appBoxPrice! < _cheapestPlan.appBoxPrice! ? plan : _cheapestPlan,
      validPlans[0],
    );

    return {
      planType: cheapestPlan.type,
      price: cheapestPlan.appBoxPrice!,
    };
  }

  get plans(): IAppPricingPlan[] {
    let appPricingPlans: IAppPricingPlan[] = [];

    if (this.status === 'DRAFT') {
      appPricingPlans = this.pricingModel?.plans
        ? this.pricingModel.plans.map((pricingModelPlan) =>
            new AppPricingPlan('DRAFT', pricingModelPlan).toJSON(),
          )
        : [];
    } else {
      appPricingPlans = this.pricingModel?.plans
        ? this.pricingModel.plans.reduce(
            (publishedPlans: IAppPricingPlan[], pricingModelPlan) => {
              const appPlan = this.appPlans?.plans.find(
                (plan) => plan.vendorId === pricingModelPlan.vendorId,
              );
              if (!appPlan) {
                console.error(
                  `[app-pricing-plans] (plans) failed for appId:${this.appId}, vendorId: ${pricingModelPlan.vendorId}`,
                );
              } else {
                publishedPlans.push(
                  new AppPricingPlan(
                    'PUBLISHED',
                    pricingModelPlan,
                    appPlan,
                  ).toJSON(),
                );
              }
              return publishedPlans;
            },
            [],
          )
        : [];
    }
    return appPricingPlans;
  }

  toJSON(): IAppsPricingPlans {
    return {
      taxSettings: this.taxSettings,
      currency: this.currency,
      appId: this.appId,
      isExternalPricing: this.isExternalPricing,
      externalPricingPageUrl: this.externalPricingPageUrl,
      plans: this.plans,
      isFreeApp: this.isFreeApp,
      hasFreePlan: this.hasFreePlan,
      hasDynamicPlan: this.hasDynamicPlan,
      hasMeteredPlanWithNoBaseFee: this.hasMeteredPlanWithNoBaseFee,
      cheapestPlan: this.cheapestPlan,
    };
  }
}
