import type { PagingMetadata as pagingData } from '@wix/ambassador-devcenter-appmarket-v1-tag-app/types';

export class PagingMetadata {
  constructor(private readonly pagingMetadata: pagingData | undefined) {}

  get count(): number {
    return this.pagingMetadata?.count ?? 0;
  }
  get offset(): number {
    return this.pagingMetadata?.offset ?? 0;
  }

  get total(): number {
    return this.pagingMetadata?.total ?? 0;
  }

  get hasNext(): boolean {
    return this.pagingMetadata?.hasNext ?? false;
  }
}
