export type { ISolutionPageContainerProps } from './solution-page-container';
export * from './solution-page-views/solution-page';
export * from './solution-page-context';

import loadable from '@loadable/component';

const SolutionPageContainer = loadable(
  () => import('./solution-page-container'),
);

export { SolutionPageContainer };
