import React from 'react';
import {
  Box,
  Divider,
  Text,
  TextButton,
  Modal,
  ModalMobileLayout,
  Heading,
} from '@wix/design-system';
import { useTranslation } from '../../../../contexts/translations-context';

interface AvailableCountriesMobileModalProps {
  isOpen: boolean;
  onClose: () => void;
  appName: string;
  availableCountries: string[];
  contactSupportLink: string;
}

export function AvailableCountriesMobileModal({
  isOpen,
  onClose,
  appName,
  availableCountries,
  contactSupportLink,
}: AvailableCountriesMobileModalProps) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} zIndex={100000} screen="mobile">
      <ModalMobileLayout
        onOverlayClick={onClose}
        onCloseButtonClick={onClose}
        stickyTitle={true}
        stickyFooter={true}
        title={
          <Heading size="large">
            {t('solution.page.tagsBoxAvailabilityTitle')}
          </Heading>
        }
        content={
          <Box direction="vertical">
            <Text size="small" weight="bold">
              {t('solution.page.tagsBoxAvailabilityPopoverTitle', {
                appName,
                interpolation: { escapeValue: false },
              })}
            </Text>
            <Box
              direction="vertical"
              gap="18px"
              marginBottom="18px"
              marginTop="12px"
            >
              {availableCountries.map((country) => (
                <div>
                  -{' '}
                  <Text size="small" weight="thin">
                    {t(`marketplace.country.${country}`)}
                  </Text>
                </div>
              ))}
            </Box>
          </Box>
        }
        footer={
          <Box gap="12px" direction="vertical">
            <Divider />
            <Text size="tiny" weight="thin">
              {t('solution.page.tagsBoxAvailabilityPopoverFooter1')}
              <Box inline>
                <TextButton
                  as="a"
                  target="_blank"
                  href={contactSupportLink}
                  size="tiny"
                  weight="thin"
                >
                  {t('solution.page.tagsBoxAvailabilityPopoverFooter2')}
                </TextButton>
              </Box>
            </Text>
          </Box>
        }
      />
    </Modal>
  );
}
