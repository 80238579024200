import React, { useState } from 'react';
import { Box, Button } from '@wix/design-system';
import type { ButtonSize } from '@wix/design-system';
import { useRateAndReviewSectionContext } from '../../../exported-components/rate-and-reviews-section/rate-and-reviews-section-context';
import { useEssentials } from '../../../contexts/essentials-context';
import { ReviewBlockedNotification } from './review-blocked-notification';

interface WriteReviewButtonProps {
  size?: ButtonSize;
}
export function WriteReviewButton({ size = 'medium' }: WriteReviewButtonProps) {
  const { app, isEligibleForReview, setReviewModalOpen, onUserAction } =
    useRateAndReviewSectionContext();
  const { t } = useEssentials();

  const [isReviewBlockedNotificationOpen, setIsReviewBlockedNotificationOpen] =
    useState(false);

  function onButtonClick() {
    onUserAction(app.id, isEligibleForReview);
    if (isEligibleForReview) {
      setReviewModalOpen(true);
    } else {
      setIsReviewBlockedNotificationOpen(!isReviewBlockedNotificationOpen);
    }
  }

  return (
    <Box>
      <ReviewBlockedNotification
        isOpen={isReviewBlockedNotificationOpen}
        onClose={() => setIsReviewBlockedNotificationOpen(false)}
        triggerElement={
          <Button
            size={size}
            dataHook="write-review-button"
            style={{ verticalAlign: 'middle' }}
            onClick={onButtonClick}
          >
            {t('solution.page.reviews.shareYourFeedBack')}
          </Button>
        }
      />
    </Box>
  );
}
