import React from 'react';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import { composer } from '../../../component-decorators/composer';
import s from './finish-update.scss';
import { ChevronLeftLarge } from '@wix/wix-ui-icons-common';
import { config } from '../../../config';
import type { MarketplaceStore } from '../../../component-decorators/with-marketplace-store';
import { Button, Heading, Text, TextButton } from '@wix/design-system';
import type { IConfigContext } from '../../../config-context';
import { useConfigContext } from '../../../config-context';
import type { ManagedApp } from '@wix/ambassador-devcenter-managedapps-v1-managed-app/types';

export interface IFinishUpdateProps {
  app: ManagedApp;
}

export const FinishUpdate = composer()
  .withMarketplaceStore()
  .compose(({ app }: IFinishUpdateProps) => {
    const configContext = useConfigContext();
    const appName = app.appInfo?.name;

    return (
      <div className={s.finishSetup} data-testid="finish-update">
        <FinishUpdateHeader appName={appName} />
        <AppCard app={app} appName={appName} configContext={configContext} />
      </div>
    );
  });

interface IFinishUpdateHeaderProps extends Partial<WithTranslationProps> {
  appName: string;
}

const FinishUpdateHeader = composer()
  .withTranslation()
  .compose(({ appName, t }: IFinishUpdateHeaderProps) => (
    <>
      <div className={s.manageAppsLink}>
        <TextButton
          size="medium"
          prefixIcon={<ChevronLeftLarge />}
          onClick={config.goToManageApps}
        >
          {t('finishUpdate.goto')}
        </TextButton>
      </div>
      <div className={s.header}>
        <Heading
          appearance="H1"
          children={t('finishUpdate.title')}
          dataHook="finish-update-title"
        />
        <Text
          children={t('finishUpdate.subtitle', { appName })}
          dataHook="finish-update-subtitle"
        />
      </div>
    </>
  ));

interface IAppCardProps extends Partial<WithTranslationProps> {
  marketplaceStore?: MarketplaceStore;
  app: ManagedApp;
  appName: string;
  configContext: IConfigContext;
}

const AppCard = composer()
  .withTranslation()
  .withMarketplaceStore()
  .compose(({ t, app, appName, marketplaceStore }: IAppCardProps) => {
    const appTeaser = app.appInfo?.teaser;
    const appIconUrl = app.appInfo?.icon;

    return (
      <div className={s.appCard}>
        <img
          src={
            appIconUrl
              ? appIconUrl
              : `${config.marketplaceStaticsUrl}/assets/images/logo-placeholder.svg`
          }
          data-testid="finish-update-app-card-icon"
        />
        <div className={s.column}>
          <Heading
            appearance="H3"
            children={appName}
            dataHook="finish-update-app-card-title"
          />
          <Text
            children={appTeaser}
            dataHook="finish-update-app-card-subtitle"
          />
        </div>
        <Button
          className={s.addToSite}
          children={t('finishUpdate.CTA')}
          dataHook="finish-update-cta"
          onClick={() => {
            config.manageAppsMainButtonAction(
              app,
              marketplaceStore.bundleApps,
              () => {},
            );
          }}
        />
      </div>
    );
  });
