import React from 'react';
import { Box, Text } from '@wix/design-system';
import { Check } from '@wix/wix-ui-icons-common';

interface BenefitProps {
  title: string;
}

export type BenefitType = { title?: string };

export const Benefit = ({ title }: BenefitProps) => {
  return (
    <Box
      align="center"
      direction="horizontal"
      verticalAlign="top"
      marginBottom="8px"
      gap="6px"
      inline
    >
      <Box marginBottom="2px">
        <Check />
      </Box>
      <Text dataHook="benefit">{title}</Text>
    </Box>
  );
};
