export type App = {
  id: string;
  name: string;
  icon: string;
  type: AppType;
  company: Company;
};

export type Company = {
  name: string;
  icon: string;
};

export type Opinion = {
  id: string;
};

export enum AppType {
  WIX_APP = 'wix app',
  TPA = 'tpa',
}

export type ReviewsSummary = {
  totalReviews: number;
  averageRating: number;
  ratingHistogram: {
    rating1?: number;
    rating2?: number;
    rating3?: number;
    rating4?: number;
    rating5?: number;
  };
};
