import type { Tag as TagEntityType } from '@wix/ambassador-prime-auto-v1-tag/types';
import { Tag } from './tag';
import type { TagType } from './types';

export class Tags {
  private readonly tags: Tag[];

  constructor(tags: TagEntityType[]) {
    this.tags = tags.map((t) => new Tag(t));
  }

  get ids(): string[] {
    return this.tags.map((t) => t.id);
  }
  get names(): string[] {
    return this.tags.map((t) => t.name);
  }
  get slugs(): string[] {
    return this.tags.map((t) => t.slug);
  }
  get parentIds(): string[] {
    return this.tags.map((t) => t.parentId);
  }

  findBySlug(slug: string): Tag {
    return this.tags.find((t) => t.slug === slug) ?? new Tag({});
  }

  findByTagId(tagId: string): Tag {
    return this.tags.find((t) => t.id === tagId) ?? new Tag({});
  }

  findByIndex(index: number): Tag {
    return this.tags[index] ?? new Tag({});
  }

  filterByType(type: TagType): Tags {
    const tags = this.tags
      .filter((t) => t.type === type)
      .map((t) => t.toJSON());
    return new Tags(tags);
  }

  map<T>(callbackfn: (value: Tag, index: number, array: Tag[]) => T): T[] {
    return this.tags.map<T>(callbackfn);
  }
}
