import * as React from 'react';
import style from './create-website-banner.scss';
import bannerStyle from './banner.scss';
import { composer } from '../../component-decorators/composer';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import { Button, Box, Heading } from '@wix/design-system';
import type { TFunction } from 'i18next';
import { useConfigContext } from '../../config-context';

interface IDevCenterBannerProps extends Partial<WithTranslationProps> {}

export const DevCenterBanner = composer()
  .withTranslation()
  .compose(({ t }: IDevCenterBannerProps) => {
    const { isMobile } = useConfigContext();
    return (
      <Box
        direction="vertical"
        align="center"
        backgroundColor="#162d3d"
        maxHeight="312px"
        className={bannerStyle.banner}
      >
        <Heading appearance="H2" light className={style.titleBanner}>
          {t('standalone.devCenterBanner.title1')}&nbsp;
          {t('standalone.devCenterBanner.title2')}
        </Heading>
        <Box marginTop="12px" className={style.buttonWrapper}>
          <Button
            skin="light"
            priority="secondary"
            size={isMobile ? 'small' : 'large'}
            as="a"
            href="https://dev.wix.com"
            target="_blank"
            dataHook="dev-center-banner-cta"
          >
            {t('standalone.devCenterBanner.button')}
          </Button>
        </Box>
      </Box>
    );
  });

export const PublicDevCenterBanner = ({
  t,
  experiments,
}: {
  t: TFunction;
  experiments;
}) => {
  return (
    <Box
      direction="vertical"
      align="center"
      backgroundColor="#162d3d"
      maxHeight="312px"
      className={bannerStyle.banner}
    >
      <Heading appearance="H2" light className={style.titleBanner}>
        {t('standalone.devCenterBanner.new-title')}
      </Heading>
      <Box marginTop="14px" className={style.buttonWrapper}>
        <Button
          skin="light"
          priority="secondary"
          size="large"
          as="a"
          href="https://dev.wix.com"
          target="_blank"
          dataHook="dev-center-banner-cta"
        >
          {t('standalone.devCenterBanner.button')}
        </Button>
      </Box>
    </Box>
  );
};
