import * as React from 'react';
import { composer } from '../../component-decorators/composer';
import { Box, SidebarSectionItem } from '@wix/design-system';
import { AppMarketLink } from '../app-market-link';
import { config } from '../../config';
import { Origin, Path } from '../../enums/marketplace-enums';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import { biLogger } from '../../bi';
import { appMarketMenuNavigation } from '@wix/bi-logger-market/v2';
import s from './menu-item.scss';

export interface IHomeMenuItemProps extends Partial<WithTranslationProps> {
  isSelected?: boolean;
}

export const HomeMenuItem = composer()
  .withTranslation()
  .compose(({ t, isSelected }: IHomeMenuItemProps) => {
    const isPlatformSA = config.origin === Origin.STANDALONE;
    const marginTop = isPlatformSA ? '18px' : '0';
    const homeLabel = t('menu.home');
    const navigateTo = () => {
      biLogger.report(
        appMarketMenuNavigation({
          market: config.originForBI,
          menu_item_label: homeLabel,
        }),
      );
      config.goto({ path: Path.HOME, referral: 'menu_navigation' });
    };

    return (
      <Box
        dataHook="home-menu-item"
        className={s.menuItem}
        marginTop={marginTop}
      >
        <SidebarSectionItem
          dataHook="home-section-item"
          onClick={navigateTo}
          selected={isSelected}
        >
          <AppMarketLink to={Path.HOME} className={s.menuItemLink}>
            {homeLabel}
          </AppMarketLink>
        </SidebarSectionItem>
      </Box>
    );
  });
