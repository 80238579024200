import React from 'react';
import { Avatar, Text, Box, StarsRatingBar } from '@wix/design-system';
import type { starRatingBarValue } from '@wix/design-system';
import { dateFormatter, nameFormatter } from './review-title.utils';
import { nameToAvatarFormatter } from '../review.utils';

interface IReviewTitleProps {
  name: string;
  rate: number;
  publishedDate?: Date;
}
export const ReviewTitle = ({
  name,
  rate,
  publishedDate,
}: IReviewTitleProps) => {
  const formattedPublishedDate = `/ ${dateFormatter(
    publishedDate?.toString() ?? '',
  )}`;
  return (
    <Box verticalAlign="top">
      <Avatar name={nameToAvatarFormatter(name)} size="size48" />
      <Box direction="vertical" marginLeft="16px" verticalAlign="top">
        <Box
          direction="horizontal"
          verticalAlign="middle"
          gap="8px"
          paddingBottom="3px"
        >
          <Text size="small" weight="normal" property="name">
            {`${nameFormatter(name)}`}
          </Text>
          <Text size="small" weight="thin" secondary>
            {formattedPublishedDate}
          </Text>
        </Box>
        {rate && (
          <StarsRatingBar
            readOnly
            size="small"
            value={rate as starRatingBarValue}
          />
        )}
      </Box>
    </Box>
  );
};
