import type { AppsSectionProps } from '@wix/app-market-components';
import type { AppBoxData as AppBoxDataOptional } from '@wix/ambassador-appmarket-v1-app-market-client-aggregator/types';
import { Path } from '../models/junk-yard';
import type { IRoute, RoutePayloadMap } from '../models';
import { Route, RoutePath } from '../models';
import { v4 as generateUUIDv4 } from 'uuid';
import {
  useEssentials,
  useJunkYard,
  useRouterContext,
  useSiteContext,
  useUserContext,
} from '../contexts';
import type { AppGroupSection, AppBoxData } from '@wix/app-market-services';
import {
  appMarketAppCollectionImpressions,
  appMarketAppImpression,
  appModalOpenAppModalClick,
} from '@wix/bi-logger-market/v2';
import {
  getAppCollectionImpressionsBiData,
  getAppModalOpenAppModalClickBiData,
  getAppMarketAppImpressionBiData,
} from '../bi-services/events-data-parser';
import type { AppMarketPage } from '../enums';

/**
 * This hook is responsible for mapping the app group section data to a shared props of the AppsSection component.
 * Different pages can use this hook to easily render the AppsSection component by using the shared props and add
 * their own specific props.
 * @returns A function that maps the app group section data to a shared props of the AppsSection component.
 */
export function useAppsSectionSharedPropsMapper(): (
  appGroup: AppGroupSection,
  page: AppMarketPage,
  pageName: string,
  index: number,
  tagName?: string,
  titleSize?: 'large' | 'medium',
  route?: IRoute,
) => AppsSectionProps {
  const { isMobile } = useUserContext();
  const { i18n, biLogger, experiments } = useEssentials();
  const { isPremium, baseURL } = useSiteContext();
  const { goto, routerData, goBackToReferrer } = useJunkYard();
  const { router } = useRouterContext();

  return (
    appGroup: AppGroupSection,
    page: AppMarketPage,
    pageName: string,
    index: number,
    tagName?: string,
    titleSize?: 'large' | 'medium',
    route?: IRoute,
  ): AppsSectionProps => {
    const { tagSlug } = appGroup;
    const collimpId = generateUUIDv4();

    const navigateToAppPage = (appSlug: string, appIndex: number) => {
      if (experiments.enabled('specs.marketplace.routerOnNewInfra')) {
        router.navigateTo({
          path: RoutePath.APP_PAGE,
          payload: {
            slug: appSlug,
            referralSectionName: tagSlug,
            collimp_id: collimpId,
            appIndex: appIndex.toString(),
          },
        });
      } else {
        goto({
          path: Path.WEB_SOLUTION,
          referralSectionName: tagSlug,
          collimp_id: collimpId,
          slug: appSlug,
          appIndex,
        });
      }
    };

    const onAppClick = (
      app: AppBoxData,
      appIndex: number,
      impressionId: string,
    ) => {
      biLogger.report(
        appModalOpenAppModalClick(
          getAppModalOpenAppModalClickBiData({
            tagSlug,
            tagName: tagName ?? pageName,
            appId: app.id,
            appIndex,
            impressionId,
            collimpId,
            pageType: page,
            pageName,
            routerData,
            referrerNameForBI: goBackToReferrer?.referrerNameForBI,
          }),
        ),
      );
      navigateToAppPage(app.slug, appIndex);
    };

    const onSectionLoad = () => {
      appGroup.apps.length &&
        biLogger.report(
          appMarketAppCollectionImpressions(
            getAppCollectionImpressionsBiData({
              appGroup,
              sectionIndex: index,
              collimpId,
              pageType: page,
              pageName,
            }),
          ),
        );
    };

    function getButtonHref(
      path: RoutePath,
      payload: RoutePayloadMap[RoutePath],
    ) {
      const hasHref = router.hasHref();
      if (
        !experiments.enabled('specs.marketplace.routerOnNewInfra') ||
        !hasHref
      ) {
        return undefined;
      }
      const route = new Route({
        path,
        payload,
        baseURL,
      });
      return route.toHref();
    }

    const onAppLoad = (
      app: AppBoxDataOptional,
      appIndex: number,
      impressionId: string,
    ) => {
      biLogger.report(
        appMarketAppImpression(
          getAppMarketAppImpressionBiData({
            impressionType: appGroup.type,
            tagSlug: appGroup.tagSlug,
            sectionIndex: index,
            impressionId,
            collimpId,
            pageType: page,
            pageName,
            appId: app?.id ?? '',
            isBundleApp: Boolean(app.bundle?.isBundleApp),
            badges: app?.badges!,
            appIndex,
            appReviewCount: app.reviews?.totalCount,
            appReviewScore: app.reviews?.averageRating,
          }),
        ),
      );
    };
    return {
      ...appGroup,
      rows: isMobile ? appGroup.totalAppsCount : appGroup.rows,
      onAppClick,
      i18n,
      columns: 4,
      minColumns: isMobile ? 1 : 3,
      dataHook: appGroup.tagSlug,
      onSectionLoad,
      onAppLoad,
      isPremiumSite: isPremium,
      getHref: (appSlug: string, appIndex: number) => {
        const hasHref = router.hasHref();
        if (
          !experiments.enabled('specs.marketplace.routerOnNewInfra') ||
          !hasHref
        ) {
          return undefined;
        }
        const route = new Route({
          path: RoutePath.APP_PAGE,
          payload: {
            referralSectionName: tagSlug,
            collimp_id: collimpId,
            appIndex: appIndex.toString(),
            slug: appSlug,
          },
          baseURL,
        });
        return route.toHref();
      },
      titleSize,
      ...(route?.path && {
        buttonHref: getButtonHref(route?.path, route?.payload),
      }),
    };
  };
}
