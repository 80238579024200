import React from 'react';
import s from './view-wrapper.scss';
import { Loader } from '@wix/design-system';

export enum FetchStatus {
  FETCHING = 'fetching',
  ERROR = 'error',
  COMPLETE = 'pending',
}
export interface IViewWrapperProps {
  fetchStatus?: FetchStatus;
  ErrorPlaceholder?: React.FunctionComponent;
  FetchPlaceholder?: React.FunctionComponent;
}
export class ViewWrapper extends React.Component<IViewWrapperProps, any> {
  render() {
    return (
      <div className={s.viewWrapperContainer}>
        {this.props.fetchStatus === FetchStatus.ERROR ? (
          <Error PlaceHolder={this.props.ErrorPlaceholder} />
        ) : this.props.fetchStatus === FetchStatus.FETCHING ? (
          <FetchingMode PlaceHolder={this.props.FetchPlaceholder} />
        ) : (
          this.props.children
        )}
      </div>
    );
  }
}

interface IWrapperPlaceHolderProps {
  PlaceHolder?: React.FunctionComponent;
}
const Error = (props: IWrapperPlaceHolderProps) => {
  const PlaceHolder = props.PlaceHolder;
  return (
    <div data-testid="view-wrapper-container" className={s.errorLoader}>
      {PlaceHolder ? (
        <PlaceHolder />
      ) : (
        <Loader
          dataHook="error-loader"
          status="error"
          statusMessage="Content From Our Amazing Product(s)"
          text="This is an Error Handling"
        />
      )}
    </div>
  );
};

const FetchingMode = (props: IWrapperPlaceHolderProps) => {
  const PlaceHolder = props.PlaceHolder;
  return (
    <div data-testid="view-wrapper-container" className={s.fetchLoader}>
      {PlaceHolder ? (
        <PlaceHolder />
      ) : (
        <Loader
          dataHook="fetching-loader"
          status="loading"
          statusMessage="Content From Our Amazing Product(s)"
          text="Fetching some stunning data"
        />
      )}
    </div>
  );
};
