import * as React from 'react';
import { Loader } from '@wix/design-system';

const isSSR = () => typeof window === 'undefined';

const getSuspenseFetcher = () => {
  let data;
  let promise;
  let ssrCount = 0;

  return ({ fetch, setRefetchedData }: IFetcherAgrs) => {
    if (!isSSR()) {
    } else if (ssrCount++ > 10) {
      return {};
    }

    if (setRefetchedData) {
      fetch().then((res) => {
        setRefetchedData(res);
      });
    }

    if (data) {
      return data;
    }

    if (!promise) {
      promise = fetch().then((res) => {
        data = res;
      });
    }

    throw promise;
  };
};

interface IFetcherAgrs {
  fetch(): Promise<any>;
  setRefetchedData?(data: any): void;
}

interface IFetcherHOCProps {
  initialFetch(): Promise<any>;
  WrappedComponent: React.ComponentType<any>;
  fetcher(args: IFetcherAgrs): any;
  additionalProps: any;
}

interface IFetcherHOCState {
  fetchData: any;
}

class FetcherHOC extends React.Component<IFetcherHOCProps, IFetcherHOCState> {
  constructor(props: IFetcherHOCProps) {
    super(props);
    this.reFetch = this.reFetch.bind(this);
    const { fetcher, initialFetch } = this.props;

    if (!isSSR()) {
      this.state = {
        fetchData: fetcher({ fetch: initialFetch }),
      };
    }
  }

  reFetch(fetch: () => Promise<any>) {
    const setRefetchedData = (fetchData: any) => this.setState({ fetchData });
    this.props.fetcher({ fetch, setRefetchedData });
  }

  render() {
    const { fetcher, initialFetch, additionalProps } = this.props;
    return React.createElement(this.props.WrappedComponent, {
      fetchData: !isSSR()
        ? this.state.fetchData
        : fetcher({ fetch: initialFetch }),
      reFetch: this.reFetch,
      ...additionalProps,
    });
  }
}

export interface IWithSuspenseFetcherInjectedProps<T> {
  fetchData: T;
  reFetch(fetch: () => Promise<T>): void;
}

export const withSuspenseFetcherHOC = (
  fetchBuilder: (p: any) => () => Promise<any>,
  WrappedComponent: React.ComponentType<any>,
) =>
  class SuspenseFetcher extends React.Component<any> {
    fetcher: any;

    constructor(props) {
      super(props);
      this.fetcher = getSuspenseFetcher();
    }

    render() {
      return (
        <React.Suspense fallback={<Loader status="loading" />}>
          <FetcherHOC
            fetcher={this.fetcher}
            initialFetch={fetchBuilder(this.props)}
            WrappedComponent={WrappedComponent}
            additionalProps={this.props}
          />
        </React.Suspense>
      );
    }
  };

export const WithSuspenseFetcher: any =
  (fetchBuilder: (props: any) => () => Promise<any>) =>
  (component: React.ComponentType<any>) =>
    withSuspenseFetcherHOC(fetchBuilder, component);
