import React from 'react';
import ReactDOM from 'react-dom';
import { create as createFedopsLogger } from '@wix/fedops-logger';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './components/App';
import { ExperimentsProvider } from '@wix/wix-experiments-react';
import { parse } from 'flatted';
import { unescape } from 'html-escaper';
import { getI18nInstance } from './i18next/i18next-client';
import { ResourcesCTX } from '@wix/marketplace-component';
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';

const deserialize = (str) => (!str ? str : JSON.parse(unescape(str)));

const baseURL = window.__BASEURL__;
const locale = window.__LOCALE__;
const experiments = deserialize(window.__STORE_EXPERIMENTS__);
const marketplaceStaticsUrl = window.__MARKET_PLACE_STATIC_URL__;
const manageAppsStaticsUrl = window.__MANAGE_APPS_STATIC_URL__;
const ssrMemo = parse(unescape(window.__SSR_MEMO__), (_key, value) =>
  typeof value === 'string' ? decodeURIComponent(value) : value,
);
const resources = deserialize(window.__RESOURCES__);

window.consentPolicyManager.init({
  baseUrl: 'https://www.wix.com/market',
  consentPolicy: deserialize(window.__CONSENT_POLICY__),
});

if (window.wixTagManager) {
  const hostNames = window.wixTagManager.HOST_NAMES;
  window.wixTagManager &&
    window.wixTagManager.init(hostNames.APP_MARKET_STANDALONE, locale);
}

const fedopsLogger = createFedopsLogger('marketplace-standalone');

const i18nResources = window.__I18N_RESOURCES__;
const isMobile = window.__IS_MOBILE__;
const baseApiUrl = window.__BASE_API_URL__;
const countryCode = window.__COUNTRY_CODE__;
const userName = window.__USER_NAME__;
const userImage = window.__USER_IMAGE__;
const dehydratedState = window.__REACT_QUERY_STATE__;

const queryClient = new QueryClient();

getI18nInstance(i18nResources, locale).then(({ i18n }) => {
  ReactDOM.hydrate(
    <QueryClientProvider client={queryClient}>
      <Hydrate state={dehydratedState}>
        <Router basename={baseURL}>
          <ExperimentsProvider options={{ experiments }}>
            <ResourcesCTX.Provider value={resources}>
              <App
                locale={locale}
                baseURL={baseURL}
                manageAppsStaticsUrl={manageAppsStaticsUrl}
                marketplaceStaticsUrl={marketplaceStaticsUrl}
                ssrMemo={ssrMemo}
                routeBaseName={baseURL}
                i18n={i18n}
                isMobile={isMobile}
                baseApiUrl={baseApiUrl}
                countryCode={countryCode}
                userName={userName}
                userImage={userImage}
                sendAppLoadedEvent={() => fedopsLogger.appLoaded()}
              />
            </ResourcesCTX.Provider>
          </ExperimentsProvider>
        </Router>
      </Hydrate>
    </QueryClientProvider>,
    document.getElementById('root'),
  );
});
