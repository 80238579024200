import * as React from 'react';
import type { IModalProps } from '../modals';
import { config } from '../../../config';
import { biLogger } from '../../../bi';
import {
  appMarketCloseDialogBoxOfConnectDomainX,
  appMarketConnectDomainClick,
  appMarketUpgradeSiteConnectDomainDialogBox,
} from '@wix/bi-logger-market/v2';

import { Modal, MessageBoxFunctionalLayout } from '@wix/design-system';
import type { TFunction } from 'i18next';
import { composer } from '../../../component-decorators/composer';

interface IConnectDomainModalProps extends IModalProps {
  t?: TFunction;
}

export const ConnectDomainModal = composer()
  .withTranslation()
  .withMarketplaceStore()
  .compose(
    ({
      t,
      appId,
      msid,
      marketplaceStore,
      appName,
    }: IConnectDomainModalProps) => {
      const handleClose = () => {
        biLogger.report(
          appMarketCloseDialogBoxOfConnectDomainX({
            app_id: appId,
            msid,
          }),
        );
        marketplaceStore.closeAllModals();
      };

      const handleConnect = () => {
        biLogger.report(
          appMarketConnectDomainClick({
            app_id: appId,
            msid,
          }),
        );
        window.open(
          `https://www.wix.com/dashboard/${marketplaceStore.metaSiteId}/manage-website/domains?referralInfo=marketplace${config.origin}',`,
        );
      };

      biLogger.report(
        appMarketUpgradeSiteConnectDomainDialogBox({
          appId,
          msid,
          stepName: 'connect_domain',
        }),
      );

      return (
        <Modal isOpen={true}>
          <MessageBoxFunctionalLayout
            title={t('modals-connectDomain-header', { appName })}
            confirmText={t('modals-connectDomain-cta')}
            cancelText={t('modals-connectDomain-cancel')}
            theme="blue"
            closeButton
            onClose={() => handleClose()}
            onCancel={() => handleClose()}
            onOk={() => handleConnect()}
            image={
              <img
                src={`${config.marketplaceStaticsUrl}assets/images/connect-domain.png`}
              />
            }
          >
            {t('modals-connectDomain-content', { appName })}
          </MessageBoxFunctionalLayout>
        </Modal>
      );
    },
  );
