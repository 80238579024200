import type { AmbassadorBootstrap } from '@wix/ambassador-bootstrap-plugin';
import type { IHttpClient } from '@wix/http-client';
import { isSSR } from '../utils/is-ssr';
import type { IServices } from '../types';
import { HttpServices } from './http-services';
import { RpcServices } from './rpc-services';
import type { queryTag } from './tags';
import { Tags } from './tags';
import { ManagedApps, type getManagedApps } from './managed-apps';
import { MarketApp, MarketApps } from './market-app';
import type { queryMarketApp, getMarketApp } from './market-app';
import { MarketListings, type queryMarketListing } from './market-listing';
import { Apps, type queryApp } from './apps-service';
import type { queryTagApp, getAppsByTag, getAppsDataByTagId } from './tag-apps';
import type { queryAppsPlans } from './apps-plans';
import { AppsPlans } from './apps-plans';
import { APPS_LIMIT, TagApps } from './tag-apps';
import type {
  getAppsByAppIds,
  getDynamicSections,
  Status,
  Placement,
} from './app-market-client-aggregator/types';
import { AppsData } from './app-market-client-aggregator';
import type { getWebSolutionsBase } from './web-solutions';
import { WebSolutionsBase } from './web-solutions';
import { type completePendingInstallation } from './pending-installations';
import type { PendingReason } from '@wix/ambassador-devcenter-pendingapps-v1-pending-installation/types';
import type { ComponentType } from '@wix/ambassador-devcenter-app-components-v1-app-components/types';
import type { getAppComponentsByAppIds } from './apps-components';
import type { queryAppReviewsSummary } from './app-review-summary';
import { AppReviewsSummaries } from './app-review-summary';
import type {
  createReview,
  getUserReview,
  queryReviews,
  updateReview,
} from './review';
import { SortOrder } from '@wix/ambassador-devcenter-ams-v1-app-market-search/types';
import { Review, Reviews } from './review';
import type { getTagSections } from './tag-sections';
import { TagSections } from './tag-sections';
import type { getSiteProperties } from './site-properties';
import { SiteProperties } from './site-properties';
import type { getPricingModel } from './pricing-model';
import type { GetPricingModelRequest } from '@wix/ambassador-devcenter-pricing-v1-pricing-entity/types';
import { PricingModel } from './pricing-model';
import {
  SitesPremiumStatus,
  type getSitesPremiumStatus,
} from './premium-data-view-retriever';
import { AppsComponents } from './apps-components';
import type {
  getAutoCompleteSuggestions,
  searchApps,
} from './app-market-search';
import {
  AutoComplete as AutoCompleteProxy,
  type getAutoCompleteProxySuggestions,
} from './app-market-search-proxy';
import { SearchApps, AutoComplete } from './app-market-search';

/**
 * Implements IServices.
 ** abstracts SSR/CSR api calls.
 ** api response returns the abstracted domain model.
 For example, getManagedApps returns ManagedApps model.
 * @class Services
 */
export class Services implements IServices {
  private httpServices: HttpServices;
  private rpcServices?: RpcServices | undefined;

  constructor({
    httpClient,
    rpcClient,
  }: {
    httpClient?: IHttpClient;
    rpcClient?: AmbassadorBootstrap;
  }) {
    if (!isSSR() && !httpClient) {
      throw new Error('httpServices is not defined');
    }
    this.httpServices = new HttpServices(httpClient!);
    this.rpcServices = isSSR() ? new RpcServices(rpcClient!) : undefined;
  }

  getManagedApps: getManagedApps = async (payload) => {
    const response = this.rpcServices
      ? await this.rpcServices?.getManagedApps(payload)
      : (await this.httpServices.getManagedApps(payload)).data;

    return new ManagedApps(response.managedApps || []);
  };

  getMarketApp: getMarketApp = async (payload) => {
    const response = this.rpcServices
      ? await this.rpcServices?.getMarketApp(payload)
      : (await this.httpServices.getMarketApp(payload)).data;

    return new MarketApp(response.marketApp || {});
  };

  queryMarketApp: queryMarketApp = async (payload) => {
    if (!payload.appIds.length) {
      throw new Error('appIds empty in queryMarketApp');
    }
    const req = {
      query: {
        filter: {
          appId: payload.appIds,
        },
      },
    };
    const response = this.rpcServices
      ? await this.rpcServices?.queryMarketApp(req)
      : (await this.httpServices.queryMarketApp(req)).data;

    return new MarketApps(response.marketApps ?? []);
  };

  queryMarketListing: queryMarketListing = async (payload) => {
    if (!payload.appIds.length) {
      throw new Error('appIds empty in queryMarketListing');
    }
    const req = {
      query: {
        filter: {
          appId: payload.appIds,
          languageCode: [...new Set(['en', ...payload.languageCodes])],
          status: payload.status,
        },
      },
    };

    const response = this.rpcServices
      ? await this.rpcServices?.queryMarketListing(req)
      : (await this.httpServices.queryMarketListing(req)).data;

    return new MarketListings(response.marketListing || []);
  };

  queryApp: queryApp = async (payload) => {
    const { appIds: id, slugs: slug } = payload;
    const req = {
      query: {
        filter: {
          ...(id ? { id } : {}),
          ...(slug ? { slug } : {}),
        },
      },
    };

    const response = this.rpcServices
      ? await this.rpcServices?.queryApp(req)
      : (await this.httpServices.queryApp(req)).data;

    return new Apps(response.apps || []);
  };

  queryTag: queryTag = async (payload) => {
    const {
      tagIds: id,
      slugs: slug,
      languageCode,
      isHidden,
      tagType,
    } = payload;

    const req = {
      query: {
        filter: {
          ...(id ? { id } : {}),
          ...(slug ? { slug } : {}),
          ...(isHidden !== undefined ? { isHidden } : {}),
          ...(tagType ? { tagType } : {}),
        },
      },
      languageCode,
    };
    const response = this.rpcServices
      ? await this.rpcServices?.queryTag(req)
      : (await this.httpServices.queryTag(req)).data;

    return new Tags(response.tags || []);
  };

  queryTagApp: queryTagApp = async (payload) => {
    const { appIds: appId, tagIds: tagId } = payload;
    const req = {
      query: {
        filter: {
          ...(appId ? { appId } : {}),
          ...(tagId ? { tagId } : {}),
        },
      },
    };

    const response = this.rpcServices
      ? await this.rpcServices?.queryTagApp(req)
      : (await this.httpServices.queryTagApp(req)).data;

    return new TagApps(response.tagApps || []);
  };

  getAppsByTag: getAppsByTag = async (payload) => {
    const { tagId, sortTypes, paging } = payload;

    const req = {
      tagId,
      ...(sortTypes ? { sortTypes } : {}),
      ...(paging ? { paging } : {}),
    };
    const response = this.rpcServices
      ? await this.rpcServices?.getAppsByTag(req)
      : (await this.httpServices.getAppsByTag(req)).data;

    return new TagApps(
      response.tagApps || [],
      response.pagingMetadata || undefined,
    );
  };

  getAppsByAppIds: getAppsByAppIds = async (payload) => {
    if (payload.appIds.length === 0) {
      return new AppsData([]);
    }
    const { appIds, status } = payload;
    const req = {
      appIds,
      status: status as Status,
    };

    const response = this.rpcServices
      ? await this.rpcServices?.getAppsByAppIds(req)
      : (await this.httpServices.getAppsByAppIds(req)).data;

    return new AppsData(response.apps || []);
  };

  getDynamicSections: getDynamicSections = async (payload) => {
    const { placement } = payload;
    const req = {
      placement: placement as Placement,
    };

    const response = this.rpcServices
      ? await this.rpcServices?.getDynamicSections(req)
      : (await this.httpServices.getDynamicSections(req)).data;

    return response;
  };

  getWebSolutionsBase: getWebSolutionsBase = async (payload) => {
    const req = {
      idsOrSlugs: payload.appIds.join(','),
    };
    const response = this.rpcServices
      ? await this.rpcServices?.getWebSolutionsBase(req)
      : (await this.httpServices.getWebSolutionsBase(req)).data;

    return new WebSolutionsBase(response.webSolutionsBase || []);
  };

  queryAppsPlans: queryAppsPlans = async (payload) => {
    const response = this.rpcServices
      ? await this.rpcServices?.queryAppPlans(payload)
      : (await this.httpServices.queryAppPlans(payload)).data;

    return new AppsPlans(response || []);
  };

  completePendingInstallation: completePendingInstallation = async (
    payload,
  ) => {
    const req = {
      appId: payload.appId,
      pendingReason: payload.pendingReason as PendingReason,
    };

    this.rpcServices
      ? this.rpcServices?.completePendingInstallation(req)
      : this.httpServices.completePendingInstallation(req);
  };

  queryReviews: queryReviews = async (payload) => {
    const { appId, sortMethod, limit, offset } = payload;
    const queryReviewsReq = {
      query: {
        filter: {
          appId,
          publishedAt: {
            $exists: true,
          },
        },
        sort: [{ fieldName: sortMethod, order: SortOrder.DESC }],
        paging: {
          limit,
          offset,
        },
      },
    };
    const response = this.rpcServices
      ? await this.rpcServices?.queryReviews(queryReviewsReq)
      : (await this.httpServices.queryReviews(queryReviewsReq)).data;

    return new Reviews(response.reviews || []);
  };

  getUserReview: getUserReview = async (payload) => {
    const response = this.rpcServices
      ? await this.rpcServices?.getUserReview(payload)
      : (await this.httpServices.getUserReview(payload)).data;

    return new Review(response.userReview ?? {});
  };

  queryAppReviewsSummary: queryAppReviewsSummary = async (payload) => {
    const req = {
      query: {
        filter: {
          appId: payload.appId,
        },
      },
    };
    const response = this.rpcServices
      ? await this.rpcServices?.queryAppReviewsSummary(req)
      : (await this.httpServices.queryAppReviewsSummary(req)).data;

    return new AppReviewsSummaries(response.appReviewsSummaries || []);
  };

  updateReview: updateReview = async (payload) => {
    const response = this.rpcServices
      ? await this.rpcServices?.updateReview(payload)
      : (await this.httpServices.updateReview(payload)).data;

    return new Review(response.review || {});
  };

  createReview: createReview = async (payload) => {
    const response = this.rpcServices
      ? await this.rpcServices?.createReview(payload)
      : (await this.httpServices.createReview(payload)).data;

    return new Review(response.review || {});
  };

  getTagSections: getTagSections = async (payload) => {
    if (!payload.tagIds.length) {
      throw new Error('tagIds empty in getTagSections req');
    }
    const response = this.rpcServices
      ? await this.rpcServices?.getTagSections(payload)
      : (await this.httpServices.getTagSections(payload)).data;

    return new TagSections(response || []);
  };

  getSiteProperties: getSiteProperties = async () => {
    if (this.rpcServices) {
      throw new Error("getSiteProperties doesn't support RPC.");
    }
    const response = await this.httpServices.getSiteProperties();
    return new SiteProperties(response?.properties);
  };

  getPricingModel: getPricingModel = async (payload) => {
    const { appId, isPublished } = payload;

    const req: GetPricingModelRequest = {
      appId,
      isPublished,
    };
    const response = this.rpcServices
      ? await this.rpcServices?.getPricingModel(req)
      : (await this.httpServices.getPricingModel(req)).data;

    return new PricingModel(response).toJSON();
  };

  getSitesPremiumStatus: getSitesPremiumStatus = async (payload) => {
    const response = this.rpcServices
      ? await this.rpcServices?.getSitesPremiumStatus(payload)
      : (await this.httpServices.getSitesPremiumStatus(payload)).data;

    return new SitesPremiumStatus(response.sitesPremiumStatus || []);
  };

  searchApps: searchApps = async (payload) => {
    const response = this.rpcServices
      ? await this.rpcServices?.searchApps(payload)
      : (await this.httpServices.searchApps(payload)).data;

    return new SearchApps(response.webSolutions || [], response.paging);
  };

  getAppsDataByTagId: getAppsDataByTagId = async (payload) => {
    const { tagId, sortTypes, limit, offset = 0, status } = payload;
    const tagApps = await this.getAppsByTag({
      tagId,
      sortTypes,
      paging: {
        limit: limit && limit < APPS_LIMIT ? limit : APPS_LIMIT,
        offset,
      },
    });
    const appsData = await this.getAppsByAppIds({
      appIds: tagApps.appIds,
      status,
    });
    return {
      apps: appsData.toJSON(),
      hasNext: tagApps.paging.hasNext,
      total: tagApps.paging.total,
    };
  };

  getAppComponentsByAppIds: getAppComponentsByAppIds = async (payload) => {
    const { appId, componentTypes } = payload;
    const getAppComponentsByAppIdsRequest = {
      apps: [
        {
          appId,
        },
      ],
      componentTypes: componentTypes as ComponentType[],
    };
    const response = this.rpcServices
      ? await this.rpcServices?.getAppComponentsByAppIds(
          getAppComponentsByAppIdsRequest,
        )
      : (
          await this.httpServices.getAppComponentsByAppIds(
            getAppComponentsByAppIdsRequest,
          )
        ).data;

    return new AppsComponents(response.appComponents || []);
  };

  getAutoCompleteProxySuggestions: getAutoCompleteProxySuggestions = async (
    payload,
  ) => {
    if (this.rpcServices) {
      throw new Error("getAutoCompleteProxySuggestions doesn't support RPC.");
    }
    const { searchTerm, languageCode } = payload;

    const response = await this.httpServices.getAutoCompleteProxySuggestions({
      searchTerm,
      languageCode: languageCode ?? 'en',
    });
    return new AutoCompleteProxy(response);
  };

  getAutoCompleteSuggestions: getAutoCompleteSuggestions = async (payload) => {
    const { searchTerm, paging } = payload;
    const getAutoCompleteSuggestionsRequest = {
      searchTerm,
      paging,
    };
    const response = this.rpcServices
      ? await this.rpcServices?.getAutoCompleteSuggestions(
          getAutoCompleteSuggestionsRequest,
        )
      : (
          await this.httpServices.getAutoCompleteSuggestions(
            getAutoCompleteSuggestionsRequest,
          )
        ).data;

    return new AutoComplete(response.suggestions || []);
  };
}
