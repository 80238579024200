function calculateApiHostname() {
  const isLocalEnv = window?.location?.hostname?.includes('local');
  const isBrandWix = window?.location?.hostname?.includes('wix') && !isLocalEnv;
  const isBrandEditorX =
    window?.location?.hostname?.includes('editorx') && !isLocalEnv;

  if (isBrandWix || isBrandEditorX) {
    return window.location.hostname;
  }

  return 'www.wix.com';
}

export { calculateApiHostname };
