import React from 'react';
import { getClientImageURL } from '../../../common/getClientImageURL';
import { useSolution } from '../contexts/solution';
import style from './app-icon.scss';

export function AppIcon() {
  const solution = useSolution();
  const { icon, name } = solution;

  return (
    <img
      alt={`${name} icon`}
      src={getClientImageURL(icon as string, {
        toHeight: 42,
        toWidth: 42,
      })}
      className={style.icon}
    />
  );
}
