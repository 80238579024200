import React from 'react';
import { SkeletonLine, SkeletonCircle, Box } from '@wix/design-system';

interface NarrowAppBoxSkeletonProps {
  className?: string;
}

export function NarrowAppBoxSkeleton({ className }: NarrowAppBoxSkeletonProps) {
  return (
    <Box className={className} direction="vertical">
      <SkeletonCircle diameter="42px" />
      <SkeletonLine width="168px" marginTop="6px" />
      <SkeletonLine width="236px" marginTop="18px" />
      <SkeletonLine width="117px" marginTop="6px" />
      <Box marginTop="50px" verticalAlign="space-between">
        <SkeletonLine width="80px" />
        <SkeletonLine width="60px" />
      </Box>
    </Box>
  );
}
