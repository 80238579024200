import * as React from 'react';
import { composer } from '../../component-decorators/composer';
import { config } from '../../config';
import { HomeSEO } from './homeSEO';
import { WithFetcher } from '../../component-decorators/with-fetcher';
import { httpClient } from '../../http-client';
import { AppMarketPage, EditorType, Origin } from '../..';
import { mapOriginToQueryParam } from '../../common/utils';
import type { InjectedExperimentsProps } from '@wix/wix-experiments-react';
import { CenteredLoader } from '../centered-loader';
import { useConfigContext } from '../../config-context';
import { HomeComponentWrapper } from './homeComponent';
import type { MarketplaceStore } from '../../component-decorators/with-marketplace-store';
import type { AppMarketRpcClient } from '../../types/app-market-rpc-client';
import type { appMarketPageViewParams } from '@wix/bi-logger-market/v2/types';
import { getPathForBi, getWidgetReferral } from '../../common/bi-events';
import { biLogger } from '../../bi';
import { appMarketPageView } from '@wix/bi-logger-market/v2';

const getHomeLayout = (
  route,
  locale,
  experiments?,
  rpcClient?: AppMarketRpcClient,
) => {
  const baseURl = 'marketplace-api/v1';
  const platformBTest =
    experiments?.enabled('specs.marketplace.platform-b') &&
    !(config.editorType === EditorType.ADI);
  const platform = mapOriginToQueryParam(route);
  const platformParamName = platformBTest ? `${platform}-b` : platform;

  const isSSR = typeof window === 'undefined';
  if (isSSR && rpcClient !== undefined) {
    return rpcClient.getContainer({
      platformName: platformParamName,
      containerName: 'home',
      withWebSolutions: true,
      lang: locale,
    });
  }

  return httpClient
    .get(
      `${config.baseApiUrl}/_api/${baseURl}/container?platformName=${platformParamName}&containerName=home&withWebSolutions=true&lang=${locale}`,
    )
    .then(({ data }) => data);
};

const initialFetch = (
  route,
  locale,
  experiments,
  rpcClient?: AppMarketRpcClient,
) => {
  return () =>
    Promise.all([getHomeLayout(route, locale, experiments, rpcClient)]);
};

interface IHomeProps extends Partial<InjectedExperimentsProps> {
  marketplaceStore?: MarketplaceStore;
}

export const Home = composer()
  .withMarketplaceStore()
  .withExperiments()
  .compose(({ experiments, marketplaceStore }: IHomeProps) => {
    const { locale, getAppInstance, isMobile, rpcClient } = useConfigContext();
    const signedInstance = getAppInstance
      ? getAppInstance('22bef345-3c5b-4c18-b782-74d4085112ff')
      : undefined;

    React.useEffect(() => {
      const urlParams = location.pathname.split('/')[3];
      const pageViewParams: appMarketPageViewParams = {
        market: config.originForBI,
        navigation_source: marketplaceStore?.route?.referral || 'first_load',
        tag_type: AppMarketPage.HOMEPAGE,
        tag_name: AppMarketPage.HOMEPAGE,
        referral_info:
          getPathForBi(marketplaceStore?.prevRoute?.referral) ??
          marketplaceStore?.route?.referral ??
          urlParams === 'app-market'
            ? 'side_bar'
            : urlParams,
        widget_referral: getWidgetReferral(),
        slug_index_list: '',
        section: '',
        utm_id: marketplaceStore?.route?.utm_id,
        referral_sub_tag_name:
          marketplaceStore?.prevRoute?.subCategories?.join(','),
        is_external:
          marketplaceStore?.route?.referral &&
          marketplaceStore?.route?.referral !== 'manage_apps',
      };
      biLogger.report(appMarketPageView(pageViewParams));
    }, []);

    return (
      <WithFetcher
        initialFetch={initialFetch(
          marketplaceStore.route,
          locale,
          experiments,
          rpcClient,
        )}
        fetchMemoizationId={`home-${locale}`}
        loader={<CenteredLoader />}
      >
        {({ fetchData }) => (
          <span>
            {config.origin === Origin.STANDALONE.toString() && (
              <HomeSEO data={fetchData[0]} url={config.baseApiUrl} />
            )}
            <HomeComponentWrapper
              fetchData={fetchData}
              locale={locale}
              signedInstance={signedInstance}
              isMobile={isMobile}
            />
          </span>
        )}
      </WithFetcher>
    );
  });
