import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import { composer } from '../../component-decorators/composer';
import { useConfigContext } from '../../config-context';
import { WithFetcher } from '../../component-decorators/with-fetcher';
import { CenteredLoader } from '../centered-loader';
import { config } from '../../config';
import { AppErrorPageSeo } from './app-error-page-seo';
import { httpClient } from '../../http-client';
import React from 'react';
import { ErrorPage } from '.';
import { ErrorPageType, Origin } from '../..';

export interface IErrorPageContainerProps
  extends Partial<WithTranslationProps> {
  slug?: string;
  errorPageType?: ErrorPageType;
}

export const ErrorPageContainer = composer()
  .withTranslation()
  .compose(({ slug, errorPageType, t }: IErrorPageContainerProps) => {
    const { locale } = useConfigContext();
    const getErrorPageType = (appName) => {
      return errorPageType
        ? errorPageType
        : appName
        ? ErrorPageType.NOT_AVAILABLE_APP
        : ErrorPageType.NOT_FOUND;
    };

    return (
      <WithFetcher
        loader={<CenteredLoader />}
        initialFetch={fetchErrorPageData(slug, locale)}
        fetchMemoizationId={`error-page-container-${slug}-${locale}`}
      >
        {({ fetchData }) => (
          <div data-testid="error-page-container">
            {config.origin === Origin.STANDALONE && fetchData.appName && (
              <AppErrorPageSeo appName={fetchData.appName} appSlug={slug} />
            )}
            <ErrorPage
              appName={fetchData.appName}
              categories={fetchData.categories}
              errorPageType={getErrorPageType(fetchData.appName)}
              t={t}
            />
          </div>
        )}
      </WithFetcher>
    );
  });

export const fetchErrorPageData =
  (slug: string, locale: string = 'en') =>
  () => {
    return slug
      ? fetchAppErrorPageData(slug, locale)
      : fetchDefaultErrorPageData(locale);
  };

export const fetchAppErrorPageData = (slug: string, locale: string = 'en') => {
  return Promise.all([
    httpClient.get(
      `${config.baseApiUrl}/_api/marketplace-api/v1/categories?lang=${locale}`,
    ),
    httpClient.post(
      `${config.baseApiUrl}/_api/app-service/v2/market-versions/${slug}`,
      { status: ['APPROVED', 'DECLINED'], languageCode: locale },
      {
        headers: {
          Host: config.baseApiUrl.replace(/(^\w+:|^)\/\//, ''), // to remove the https have the host only
        },
      },
    ),
  ])
    .then(([categoryResponse, appResponse]) => {
      return {
        appName:
          appResponse.data &&
          appResponse.data.appsVersions[0] &&
          appResponse.data.appsVersions[0].marketListing &&
          appResponse.data.appsVersions[0].marketListing.basicInfo.name,
        categories: categoryResponse.data.categoryToSubCategory,
      };
    })
    .catch((error) => console.log(error));
};

const fetchDefaultErrorPageData = (locale: string = 'en') => {
  return httpClient
    .get(
      `${config.baseApiUrl}/_api/marketplace-api/v1/categories?lang=${locale}`,
    )
    .then(({ data }) => {
      return {
        categories: data.categoryToSubCategory,
      };
    })
    .catch((error) => console.log(error));
};
