import * as React from 'react';
import style from './top-badge.scss';
import { Badge, Box, Tooltip } from '@wix/design-system';
import { composer } from '../../../../component-decorators/composer';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import { useSolution } from '../contexts/solution';
import {
  getAppBadgeTheme,
  getAppBadgeTitle,
  getAppBadgeTooltipContent,
} from '../../../common';
import type { InjectedExperimentsProps } from '@wix/wix-experiments-react';

interface ITopBadgeProps
  extends WithTranslationProps,
    InjectedExperimentsProps {}

export const TopBadge = composer()
  .withExperiments()
  .withTranslation()
  .compose((props: ITopBadgeProps) => {
    const solution = useSolution();
    const {
      isBundleAppInstalled,
      isBundleAppNotInstalled,
      name,
      isVoucherApp,
    } = solution;

    const { t, experiments } = props;

    const badgeTitle = getAppBadgeTitle(
      isBundleAppInstalled,
      isBundleAppNotInstalled,
      undefined,
      experiments,
      undefined,
      solution?.id,
    );

    const badgeTheme = getAppBadgeTheme(badgeTitle);

    const showHotelsToolTip = badgeTitle === 'solutionBox.badge.beta';

    const showTooltip =
      isBundleAppInstalled || isBundleAppNotInstalled || showHotelsToolTip;

    const tooltipContent = getAppBadgeTooltipContent(
      isBundleAppInstalled,
      isBundleAppNotInstalled,
      isVoucherApp,
      showHotelsToolTip,
    );

    return (
      <Box dataHook="solution-top-section" className={style.topSection}>
        {showTooltip && tooltipContent ? (
          <Box marginTop="-2px">
            <Tooltip
              placement="top"
              content={t(tooltipContent, {
                appName: name,
              })}
            >
              <TopBadgeBase
                badgeTitle={t(badgeTitle)}
                badgeTheme={badgeTheme}
              />
            </Tooltip>
          </Box>
        ) : (
          <TopBadgeBase badgeTitle={t(badgeTitle)} badgeTheme={badgeTheme} />
        )}
      </Box>
    );
  });

export const TopBadgeBase = ({ badgeTitle, badgeTheme }: any) => (
  <>
    {badgeTitle && (
      <Badge dataHook="solution-badge" {...badgeTheme}>
        {badgeTitle}
      </Badge>
    )}
  </>
);
