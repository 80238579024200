import React, { useEffect, useState } from 'react';
import style from './pricing-badge.scss';
import {
  PremiumSmall,
  StatusWarningFilledSmall,
} from '@wix/wix-ui-icons-common';
import { Box, Popover, Text } from '@wix/design-system';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import type { InjectedExperimentsProps } from '@wix/wix-experiments-react';
import { usePremiumCurrencyFormatter } from '@wix/premium-react-text';
import { composer } from '../../../../component-decorators/composer';
import { getLowestPrice, getPricingLabel } from '../../../common';
import { useSolution } from '../contexts/solution';

export interface IPricingBadgeProps
  extends InjectedExperimentsProps,
    WithTranslationProps {
  shouldShowIncompleteSetupPopover?: boolean;
}

export const PricingBadge = composer()
  .withExperiments()
  .withTranslation()
  .compose(
    ({
      t,
      shouldShowIncompleteSetupPopover,
      experiments,
    }: IPricingBadgeProps) => {
      const solution = useSolution();
      const pricing: any = solution.pricing;
      const {
        isAppSetupIncomplete,
        isBundleAppNotInstalled,
        savedPrice,
        company,
        showPremiumLabel,
      } = solution;
      const textWeight = 'thin';
      const textSize = 'tiny';
      const premiumTheme = 'standard';

      const shouldShowFullPrices = experiments.enabled(
        'specs.marketplace.fullPrices',
      );
      const [isPopoverShown, setIsPopoverShown] = useState(false);
      const [seeFullPrices, setSeeFullPrices] = useState<boolean>(false);

      useEffect(() => {
        if (
          shouldShowFullPrices &&
          !pricing.isExternalPricing &&
          pricing?.businessModel === 'PREMIUM' &&
          pricing?.freeTrialDays <= 0 &&
          pricing?.plans?.[0]?.premiumPrices?.fullPrices
        ) {
          setSeeFullPrices(true);
        }
      }, [pricing?.plans?.[0]?.premiumPrices?.fullPrices]);

      const priceLabel = getPricingLabel(pricing, company?.id ?? '');

      const [currencyToString] = usePremiumCurrencyFormatter({
        currencySettings: {
          [pricing?.currencySettings?.code]: {
            symbol: pricing?.currencySettings?.symbol,
            decimalSep: pricing?.currencySettings?.decimalSeparator,
            groupSep: pricing?.currencySettings?.groupSeparator,
            negPrefix: pricing?.currencySettings?.negativePrefix,
            negSuffix: pricing?.currencySettings?.negativeSuffix,
            posPrefix: '¤',
            posSuffix: '',
            fractionSize: 2,
          },
        },
        currencyCode: pricing?.currencySettings?.code,
      });
      const fixedPrice =
        savedPrice !== undefined && savedPrice !== 0
          ? Number(savedPrice).toFixed(2)
          : '';
      const fixedPriceWithTaxAndCurrency = shouldShowFullPrices
        ? currencyToString(Number(fixedPrice))
        : `$${fixedPrice}`;

      if (isBundleAppNotInstalled) {
        return (
          <span className={style.flexView}>
            {fixedPrice !== '' && (
              <span
                style={{
                  textDecoration: 'line-through',
                }}
              >
                <Text size={textSize} weight={textWeight}>
                  {fixedPriceWithTaxAndCurrency}
                </Text>
              </span>
            )}
            <span style={{ marginLeft: '6px' }}>
              <Text size={textSize} weight={textWeight} skin="success">
                {t('solutionBox.solutionBottomActionBar.BundleNotInstalled')}
              </Text>
            </span>
          </span>
        );
      }
      if (showPremiumLabel) {
        return (
          <div className={style.premiumBadge}>
            {premiumTheme ? (
              <Box color="P10">
                <PremiumSmall className={style.premiumSmallIcon} />
              </Box>
            ) : (
              <PremiumSmall className={style.premiumSmallIcon} />
            )}
            <span>
              <Text
                size={textSize}
                weight={textWeight}
                skin={premiumTheme}
                secondary
                className={style.premiumText}
              >
                {t('app.pricing.Premium')}
              </Text>
            </span>
          </div>
        );
      }
      if (isAppSetupIncomplete) {
        return (
          <Popover
            showArrow={true}
            appendTo="window"
            placement="right"
            theme="dark"
            shown={isPopoverShown}
            onMouseEnter={() => {
              if (shouldShowIncompleteSetupPopover) {
                setIsPopoverShown(true);
              }
            }}
            onMouseLeave={() => {
              if (shouldShowIncompleteSetupPopover) {
                setIsPopoverShown(false);
              }
            }}
          >
            <Popover.Element>
              <Box gap="4px">
                <StatusWarningFilledSmall
                  color="#fdb10c"
                  className={style.warningIcon}
                />
                <span>
                  <Text
                    skin="standard"
                    secondary={true}
                    weight={textWeight}
                    size={textSize}
                  >
                    {t('solutionBox.solutionBottomActionBar.IncompleteSetup')}
                  </Text>
                </span>
              </Box>
            </Popover.Element>
            <Popover.Content>
              <Box padding="12px 24px" maxWidth="102px">
                <Text
                  skin="standard"
                  light
                  weight={textWeight}
                  size={textSize || 'small'}
                >
                  {t(
                    'solutionBox.solutionBottomActionBar.IncompleteSetupTooltip',
                  )}
                </Text>
              </Box>
            </Popover.Content>
          </Popover>
        );
      }

      return (
        <span>
          <Text
            size={textSize}
            weight={textWeight}
            skin="standard"
            secondary
            dataHook="business-model-text"
          >
            {t(priceLabel, {
              trialDays: pricing?.freeTrialDays,
              price: pricing?.lowestMonthlyPrice,
              fullPrice: seeFullPrices
                ? currencyToString(getLowestPrice(pricing))
                : '',
            })}
          </Text>
        </span>
      );
    },
  );
