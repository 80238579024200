import React, { useEffect } from 'react';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import { composer } from '../../../component-decorators/composer';
import s from './finish-setup.scss';
import { ChevronLeftLarge } from '@wix/wix-ui-icons-common';

import { config } from '../../../config';
import { finishInstallCTA } from '../../../common/utils';
import type { MarketplaceStore } from '../../../component-decorators/with-marketplace-store';
import { useMarketplaceStore } from '../../../component-decorators/with-marketplace-store';

import { Button, Heading, Text, TextButton } from '@wix/design-system';
import type { IConfigContext } from '../../../config-context';
import { useConfigContext } from '../../../config-context';
import { biLogger } from '../../../bi';
import type { WebSolution } from '@wix/ambassador-marketplace/types';
import type { ManagedApp } from '@wix/ambassador-devcenter-managedapps-v1-managed-app/types';

import { deprecated } from '@wix/bi-logger-market/v2';

export interface IFinishSetupProps {
  appName: string;
  appDefId: string;
  appTeaser: string;
  appIconUrl: string;
  appVersion: string;
}

export const dtoToFinishSetupProps = (
  app: ManagedApp | WebSolution,
  version?: string,
): IFinishSetupProps => {
  if ((app as ManagedApp)?.appInfo) {
    return {
      appName: (app as ManagedApp).appInfo?.name,
      appDefId: (app as ManagedApp).appId,
      appTeaser: (app as ManagedApp).appInfo?.teaser,
      appIconUrl: (app as ManagedApp).appInfo?.icon,
      appVersion: (app as ManagedApp).installationDetails?.installedVersion,
    };
  }
  return {
    appName: (app as WebSolution)?.baseInfo?.name,
    appDefId: (app as WebSolution)?.baseInfo?.id,
    appTeaser: (app as WebSolution)?.baseInfo?.teaser,
    appIconUrl: (app as WebSolution)?.baseInfo?.icon,
    appVersion: version,
  };
};

export const FinishSetup = composer()
  .withMarketplaceStore()
  .compose(
    ({
      appName,
      appDefId,
      appTeaser,
      appIconUrl,
      appVersion,
    }: IFinishSetupProps) => {
      const marketplaceStore = useMarketplaceStore();
      const configContext = useConfigContext();

      useEffect(() => {
        finishInstallCTA(
          appDefId,
          appVersion,
          marketplaceStore.installedApps,
          configContext,
          false,
        );
      }, []);

      return (
        <div className={s.finishSetup} data-testid="finish-setup">
          <FinishSetupHeader appName={appName} />
          <AppCard
            appName={appName}
            appDefId={appDefId}
            appTeaser={appTeaser}
            appIconUrl={appIconUrl}
            appVersion={appVersion}
            configContext={configContext}
          />
        </div>
      );
    },
  );

interface IFinishSetupHeaderProps extends Partial<WithTranslationProps> {
  appName: string;
}

const sendAddPendingAppBi = (appId, msid: string) => {
  biLogger
    .report(
      deprecated({
        app_id: appId,
        msid,
        pending_source: 'manage_apps',
      }),
    )
    .catch((e) => console.log(e));
};

const FinishSetupHeader = composer()
  .withTranslation()
  .compose(({ appName, t }: IFinishSetupHeaderProps) => (
    <>
      <div className={s.manageAppsLink}>
        <TextButton
          size="medium"
          prefixIcon={<ChevronLeftLarge />}
          onClick={config.goToManageApps}
        >
          {t('finishSetup.goto')}
        </TextButton>
      </div>
      <div className={s.header}>
        <Heading
          appearance="H1"
          children={t('finishSetup.title')}
          dataHook="finish-setup-title"
        />
        <Text
          children={t('finishSetup.subtitle', { appName })}
          dataHook="finish-setup-subtitle"
        />
      </div>
    </>
  ));

interface IAppCardProps extends Partial<WithTranslationProps> {
  marketplaceStore?: MarketplaceStore;
  appName: string;
  appDefId: string;
  appTeaser: string;
  appIconUrl: string;
  appVersion: string;
  configContext: IConfigContext;
}

const AppCard = composer()
  .withTranslation()
  .withMarketplaceStore()
  .compose(
    ({
      appName,
      appDefId,
      appTeaser,
      appIconUrl,
      t,
      appVersion,
      marketplaceStore,
      configContext,
    }: IAppCardProps) => {
      return (
        <div className={s.appCard}>
          <img
            src={
              appIconUrl
                ? appIconUrl
                : `${config.marketplaceStaticsUrl}/assets/images/logo-placeholder.svg`
            }
            data-testid="finish-setup-app-card-icon"
          />
          <div className={s.column}>
            <Heading
              appearance="H3"
              children={appName}
              dataHook="finish-setup-app-card-title"
            />
            <Text
              children={appTeaser}
              dataHook="finish-setup-app-card-subtitle"
            />
          </div>
          <Button
            className={s.addToSite}
            children={t('finishSetup.addToSite')}
            dataHook="finish-setup-app-add-to-site"
            onClick={executeOnce(() => {
              sendAddPendingAppBi(appDefId, marketplaceStore.metaSiteId);
              finishInstallCTA(
                appDefId,
                appVersion,
                marketplaceStore.installedApps,
                configContext,
                true,
              );
            })}
          />
        </div>
      );
    },
  );

function executeOnce(callback: () => void): () => void {
  let executed = false;

  return () => {
    if (!executed) {
      executed = true;
      callback();
    }
  };
}
