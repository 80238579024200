import * as React from 'react';
// loadable
import { SolutionPageContainer } from '../solution-page';
import { Home } from '../home';
import type { IRoute } from '../../types/common/marketplace';
import { AppPageMode, Origin, Path } from '../../enums/marketplace-enums';
import s from './marketplace-router.scss';
import { config } from '../../config';
import { ShareAppContainer } from '../share-app';
import { FinishSetupContainer } from '../finish-setup';
import { FinishUpdateContainer } from '../finish-update';
// loadable
import { ManageApps } from '../manage-apps';
import { ErrorBoundry } from '../error-boundry';
import { MarketplaceRouterSeo } from './marketplace-router-seo';
import { DeveloperPageContainer } from '../developer-page-container';
import { OpenAppWithParams } from '../open-app-with-params';
import { composer } from '../../component-decorators/composer';
import type { MarketplaceStore } from '../../component-decorators/with-marketplace-store';
import { usePageViewEvent } from '../../hooks/use-page-view-event';
import { useMediaQuery } from 'react-responsive';
import { HomePage } from '../home-page';
import type { TranslateFunction } from '../../component-decorators/with-translation';
import {
  CollectionPage as CollectionPageOnSectionsInfra,
  CategoryPage as CategoryPageOnSectionsInfra,
  SubCategoryPage as SubCategoryPageOnSectionsInfra,
  SearchResultsPage,
  HomePage as HomePageOnSectionsInfra,
  AppPage,
  useRouterContext,
  RoutePath,
} from '@wix/app-market-core';

function uuidValidate(uuid) {
  const uuidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return uuidRegex.test(uuid);
}

const ShowComponent = (
  route: IRoute,
  marketplaceStore: MarketplaceStore,
  experiments: any,
  isMobile: boolean,
  t: TranslateFunction,
) => {
  marketplaceStore.resetClearSearchBeforeDate();
  const { route: newRoute } = useRouterContext();

  const shouldShowNewHomePageOverDealerStandalone =
    experiments.enabled('specs.marketplace.newHomePageOverDealerStandalone') &&
    config.origin === Origin.STANDALONE;

  const shouldShowAppPageOnNewInfra = config.origin !== Origin.STANDALONE;

  const shouldShowAppPageOnNewInfraStandalone =
    experiments.enabled('specs.marketplace.AppPageOnNewInfraStandalone') &&
    config.origin === Origin.STANDALONE;

  if (
    newRoute.path &&
    experiments.enabled('specs.marketplace.routerOnNewInfra')
  ) {
    switch (newRoute.path) {
      case RoutePath.HOME:
        if (
          config.origin !== Origin.STANDALONE ||
          shouldShowNewHomePageOverDealerStandalone
        ) {
          if (
            experiments.enabled('specs.app-market.HomePageOnNewInfra') &&
            (!isMobile ||
              experiments.enabled('specs.marketplace.standaloneMobileNewInfra'))
          ) {
            return <HomePageOnSectionsInfra />;
          }
          return <HomePage t={t} marketplaceStore={marketplaceStore} />;
        }
        return <Home />;

      case RoutePath.APP_PAGE:
        if (
          config.origin !== Origin.STANDALONE ||
          shouldShowAppPageOnNewInfraStandalone
        ) {
          const { slug, appId, status } = newRoute.payload;
          const isSlug = !uuidValidate(appId);

          return (
            <AppPage {...(isSlug ? { slug, status } : { id: appId, status })} />
          );
        }
        // TODO: remove this after AppPage is open to users in standalone For desktop & mobile
        marketplaceStore.resetClearSearchBeforeDate();
        return (
          <SolutionPageContainer
            slug={route.slug}
            mode={route.appPageMode}
            previewLangCode={route.languageCode}
            version={route.version}
          />
        );

      case RoutePath.CATEGORY:
        return <CategoryPageOnSectionsInfra slug={newRoute.payload.slug} />;

      case RoutePath.SUB_CATEGORY:
        return (
          <SubCategoryPageOnSectionsInfra
            slug={newRoute.payload.slug}
            parentSlug={newRoute.payload.parentSlug}
          />
        );

      case RoutePath.COLLECTION:
        return <CollectionPageOnSectionsInfra slug={newRoute.payload.slug} />;

      case RoutePath.SEARCH_RESULTS:
        return <SearchResultsPage searchTerm={newRoute.payload.query.trim()} />;

      case RoutePath.SHARE:
        return (
          <ShareAppContainer
            appDefId={newRoute.payload.appId}
            shareId={newRoute.payload.shareId}
            version={newRoute.payload.version}
            experiments={experiments}
          />
        );

      case RoutePath.DEVELOPER:
        return <DeveloperPageContainer slug={newRoute.payload.slug} />;

      case RoutePath.FINISH_SETUP:
        return (
          <FinishSetupContainer
            slug={newRoute.payload.appId}
            appVersion={newRoute.payload.version}
          />
        );
      case RoutePath.FINISH_UPDATE:
        return (
          <FinishUpdateContainer
            slug={newRoute.payload.appId}
            appVersion={newRoute.payload.version}
          />
        );
      case RoutePath.MANAGE_APPS:
        return (
          <ManageApps
            manageAppsDeepLinkData={newRoute.payload?.manageAppsDeepLinkData}
          />
        );
      case RoutePath.OPEN_APP_WITH_PARAMS:
        return (
          <OpenAppWithParams
            appId={newRoute.payload.appId}
            options={newRoute.payload.openAppOptions}
          />
        );
    }
  }

  switch (route.path) {
    case Path.CATEGORY:
      marketplaceStore.resetClearSearchBeforeDate();

      if (route.subCategories?.length === 1) {
        return (
          <SubCategoryPageOnSectionsInfra
            slug={route.subCategories?.[0]!}
            parentSlug={route.slug}
          />
        );
      }

      return <CategoryPageOnSectionsInfra slug={route.slug} />;

    case Path.COLLECTION:
      return <CollectionPageOnSectionsInfra slug={route.slug} />;

    case Path.SEARCH:
      return <SearchResultsPage searchTerm={route.query.trim()} />;

    case Path.WEB_SOLUTION:
      if (
        shouldShowAppPageOnNewInfra ||
        shouldShowAppPageOnNewInfraStandalone
      ) {
        const isSlug = !uuidValidate(route.slug);
        const isTestMode = [AppPageMode.PREVIEW, AppPageMode.TEST].includes(
          route.appPageMode,
        );
        const status = isTestMode ? 'DRAFT' : 'PUBLISHED';
        return (
          <AppPage
            {...(isSlug
              ? { slug: route.slug, status }
              : { id: route.slug, status })}
          />
        );
      }

      marketplaceStore.resetClearSearchBeforeDate();
      return (
        <SolutionPageContainer
          slug={route.slug}
          mode={route.appPageMode}
          previewLangCode={route.languageCode}
          version={route.version}
        />
      );
    case Path.SHARE:
      return (
        <ShareAppContainer
          appDefId={route.shareAppData.appDefId}
          shareId={route.shareAppData.shareId}
          version={route.shareAppData.version}
          experiments={experiments}
        />
      );
    case Path.FINISH_SETUP:
      return (
        <FinishSetupContainer slug={route.slug} appVersion={route.version} />
      );
    case Path.FINISH_UPDATE:
      return (
        <FinishUpdateContainer slug={route.slug} appVersion={route.version} />
      );
    case Path.DEVELOPER:
      return <DeveloperPageContainer slug={route.slug} />;
    case Path.MANAGE_APPS:
      return (
        <ManageApps manageAppsDeepLinkData={route.manageAppsDeepLinkData} />
      );
    case Path.OPEN_APP_WITH_PARAMS:
      return (
        <OpenAppWithParams appId={route.slug} options={route.openAppOptions} />
      );
    default:
      if (
        config.origin !== Origin.STANDALONE ||
        shouldShowNewHomePageOverDealerStandalone
      ) {
        if (
          experiments.enabled('specs.app-market.HomePageOnNewInfra') &&
          (!isMobile ||
            experiments.enabled('specs.marketplace.standaloneMobileNewInfra'))
        ) {
          return <HomePageOnSectionsInfra />;
        }
        return <HomePage t={t} marketplaceStore={marketplaceStore} />;
      }
      return <Home />;
  }
};

export const MarketplaceRouter = composer()
  .withMarketplaceStore()
  .withTranslation()
  .withExperiments()
  .compose(({ marketplaceStore, experiments, t }) => {
    const marketPlaceRouterStyles =
      config.origin === Origin.STANDALONE
        ? s.marketplaceRouterSa
        : config.origin === Origin.EDITOR
        ? s.marketplaceRouterEditor
        : s.marketplaceRouter;

    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    usePageViewEvent();

    return (
      <div data-testid="marketplace-router" className={marketPlaceRouterStyles}>
        <ErrorBoundry>
          {config.origin === Origin.STANDALONE && <MarketplaceRouterSeo />}
          {ShowComponent(
            marketplaceStore.route,
            marketplaceStore,
            experiments,
            isMobile,
            t,
          )}
        </ErrorBoundry>
      </div>
    );
  });
