import React, { useState } from 'react';
import { Box, Cell, Heading, Layout, TextButton } from '@wix/design-system';
import { ArrowRightSmall } from '@wix/wix-ui-icons-common';
import {
  useEssentials,
  withEssentialsContext,
} from '../../contexts/essentials-context';
import { AppGrid } from '../app-grid';
import type {
  AppBoxData,
  AppGroup,
} from '@wix/ambassador-appmarket-v1-app-market-client-aggregator/types';
import { AppPromoGrid } from '../app-promo-grid';
import type {
  ExternalComponentProps,
  ComponentsWithSiteContextProps,
} from '../types';
import { VisibilityDetector } from '../visibility-detector';
import { appsSectionDataHooks } from './data-hooks';
import { useSiteContext, withSiteContext } from '../../contexts/site-context';

export interface AppsSectionProps
  extends Required<Omit<AppGroup, 'rows'>>,
    ExternalComponentProps,
    ComponentsWithSiteContextProps {
  titleSize?: 'large' | 'medium';
  onButtonClick?: () => void;
  columns?: 1 | 2 | 3 | 4;
  minColumns?: 1 | 2 | 3 | 4;
  rows?: number;
  dataHook?: string;
  onAppClick?(app: AppBoxData, appIndex: number, impressionId: string): void;
  onAppLoad?(app: AppBoxData, appIndex: number, impressionId: string): void;
  onSectionLoad?(): void;
  showBorder?: boolean;
  getHref?(appSlug: string, appIndex: number): string | undefined;
  buttonHref?: string;
}

function AppsSectionComponent({
  i18n,
  onButtonClick,
  onAppClick,
  columns = 3,
  minColumns,
  titleSize = 'large',
  rows,
  dataHook,
  onAppLoad,
  onSectionLoad,
  showBorder,
  getHref,
  buttonHref,
  ...appGroupProps
}: AppsSectionProps) {
  const { title, apps, type, totalAppsCount } = appGroupProps;
  const { t } = useEssentials();
  const { isPremiumSite } = useSiteContext();
  const [finalColumnsCount, setFinalColumnsCount] = useState<number>(columns);

  const showMoreButton =
    (finalColumnsCount === 3 && totalAppsCount > 6) ||
    ((finalColumnsCount === 4 || finalColumnsCount === 1) &&
      totalAppsCount > 8);

  return (
    <div data-hook={appsSectionDataHooks.container}>
      <Box
        direction="vertical"
        gap={titleSize === 'large' ? '18px' : '12px'}
        dataHook={dataHook}
      >
        <Box direction="vertical">
          <Layout>
            <Cell span={10}>
              <Heading size={titleSize} dataHook={appsSectionDataHooks.title}>
                {title}
              </Heading>
            </Cell>
            <Cell span={2}>
              {onButtonClick && showMoreButton && (
                <Box align="right" verticalAlign="bottom" height="100%">
                  <TextButton
                    href={buttonHref}
                    as="a"
                    size="small"
                    weight="normal"
                    dataHook={appsSectionDataHooks.button}
                    onClick={(e) => {
                      onButtonClick();
                      e.preventDefault();
                    }}
                    suffixIcon={<ArrowRightSmall />}
                  >
                    {t('apps.section.view.all.apps', {
                      count: totalAppsCount,
                    })}
                  </TextButton>
                </Box>
              )}
            </Cell>
          </Layout>
        </Box>
        <VisibilityDetector
          onFirstVisible={onSectionLoad}
          style={{ height: '100%' }}
        >
          <Layout>
            <Cell>
              {type === 'PROMO' ? (
                <AppPromoGrid
                  dataHook={`${dataHook}-promo-grid`}
                  apps={apps}
                  i18n={i18n}
                  onAppClick={onAppClick}
                  onAppLoad={onAppLoad}
                  getHref={getHref}
                  isPremiumSite={isPremiumSite}
                />
              ) : (
                <AppGrid
                  dataHook={`${dataHook}-app-grid`}
                  apps={apps}
                  i18n={i18n}
                  onAppClick={onAppClick}
                  columns={columns}
                  rows={rows}
                  minColumns={minColumns}
                  onAppLoad={onAppLoad}
                  getHref={getHref}
                  isPremiumSite={isPremiumSite}
                  showBorder={showBorder}
                  finalColumnsCount={(finalColumns: number) =>
                    setFinalColumnsCount(finalColumns)
                  }
                />
              )}
            </Cell>
          </Layout>
        </VisibilityDetector>
      </Box>
    </div>
  );
}

export const AppsSection = withSiteContext(
  withEssentialsContext(AppsSectionComponent),
);
