import * as React from 'react';
import { config } from '../../../config';
import type { IAppInstallParams } from '@wix/installation-manager-client-sdk';
import { composer } from '../../../component-decorators/composer';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';

import { Box, Button, Heading, Text } from '@wix/design-system';
import type { WebSolution } from '@wix/ambassador-marketplace/types';

export interface IShareAppProps extends Partial<WithTranslationProps> {
  appName: string;
  ctaClickHandler(): void;
}

export const dtoToShareAppProps = (
  res: WebSolution,
  shareId: string,
  versionToInstall: string,
): IShareAppProps => {
  const appInstallParams: IAppInstallParams = {
    appDefId: res.baseInfo.id,
    openMethod: res.baseInfo.openConsentIn,
    shareId,
    ...(versionToInstall && { version: versionToInstall }),
  };

  return {
    appName: res.baseInfo && res.baseInfo.name,
    ctaClickHandler: () => config.installApp(appInstallParams),
  };
};

export const ShareApp = composer()
  .withTranslation()
  .compose(({ appName, ctaClickHandler, t }: IShareAppProps) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100vw',
          height: '100vh',
        }}
        data-testid="share-app"
      >
        <Box padding="90px 0 100px 90px">
          <img
            src={`${config.marketplaceStaticsUrl}assets/images/wixlogo.svg`}
            alt="wix"
          />
        </Box>
        <Box direction="vertical" align="center">
          <Heading appearance="H2">{t('share-app.title')}</Heading>
          <Box marginBottom="12px">
            <Heading appearance="H2" dataHook="app-name">
              {appName}
            </Heading>
          </Box>
          <Box marginBottom="36px">
            <Text>{t('share-app.sub-title')}</Text>
          </Box>
          <Button size="large" onClick={ctaClickHandler} dataHook="cta-button">
            {t('share-app.add-to-site')}
          </Button>
        </Box>
      </div>
    );
  });
