import * as React from 'react';
import type { IModalProps } from '../modals';
import { biLogger } from '../../../bi';
import {
  appMarketCloseDialogBoxOfUpgradeWixX,
  appMarketUpgradeSiteConnectDomainDialogBox,
} from '@wix/bi-logger-market/v2';
import s from '../modals.scss';

import { Modal, MessageBoxMarketerialLayout } from '@wix/design-system';
import type { TFunction } from 'i18next';
import { composer } from '../../../component-decorators/composer';

interface ISwitchToEditorModalProps extends IModalProps {
  t?: TFunction;
}

export const SwitchToEditorModal = composer()
  .withTranslation()
  .withMarketplaceStore()
  .compose(
    ({
      t,
      appId,
      msid,
      marketplaceStore,
      appName,
      appIcon,
    }: ISwitchToEditorModalProps) => {
      const handleClose = () => {
        biLogger.report(
          appMarketCloseDialogBoxOfUpgradeWixX({
            app_id: appId,
            msid,
          }),
        );
        marketplaceStore.closeAllModals();
      };

      const handleSwitch = () => {
        window.open(
          `https://support.wix.com/en/article/adi-moving-from-adi-to-the-editor`,
        );
      };

      biLogger.report(
        appMarketUpgradeSiteConnectDomainDialogBox({
          appId,
          msid,
          stepName: 'switch_to_editor',
        }),
      );

      return (
        <Modal isOpen={true}>
          <MessageBoxMarketerialLayout
            title={
              <>
                <img className={s.appIcon} src={appIcon} />
                <div>{t('modals-switchToEditor-header', { appName })}</div>
              </>
            }
            content={t('modals-switchToEditor-content')}
            theme="white"
            primaryButtonLabel={t('modals-switchToEditor-cta')}
            onClose={() => handleClose()}
            onPrimaryButtonClick={() => handleSwitch()}
            primaryButtonTheme="blue"
            secondaryButtonLabel={t('modals-switchToEditor-back')}
            onSecondaryButtonClick={() => handleClose()}
          />
        </Modal>
      );
    },
  );
