import React from 'react';
import {
  BadgeType,
  AppBundleType,
} from '@wix/ambassador-appmarket-v1-app-market-client-aggregator/types';
import type { AppBundle } from '@wix/ambassador-appmarket-v1-app-market-client-aggregator/types';
import { BundleBadge } from './bundle-badge';
import { WixChoiceBadge } from './wix-choice-badge';
import { BetaBadge } from './beta-badge';

interface BadgesProps {
  badges?: BadgeType[];
  size?: 'tiny' | 'small';
  bundle?: AppBundle;
  isInstalled: boolean;
  appName: string;
  showBorder?: boolean;
}

export function Badges({
  badges,
  size = 'tiny',
  bundle,
  isInstalled,
  appName,
  showBorder = false,
}: BadgesProps): JSX.Element {
  const isWixChoice = badges?.some((type) => type === BadgeType.WIX_CHOICE);
  const isBeta = badges?.some((type) => type === BadgeType.BETA);

  return (
    <>
      {isWixChoice && <WixChoiceBadge {...{ showBorder, size }} />}
      {bundle?.isBundleApp && (
        <BundleBadge
          isCouponTypeBundle={bundle?.type === AppBundleType.COUPON}
          {...{ isInstalled, appName, showBorder, size }}
        />
      )}
      {isBeta && !isWixChoice && (
        <BetaBadge {...{ appName, showBorder, size }} />
      )}
    </>
  );
}
