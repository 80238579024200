import { getScaleToFillImageURL } from 'image-client-api/dist/imageClientSDK';

const getURI = (iconURL: string) => iconURL && iconURL.split('/').pop();

export interface IClientImageURLOptions {
  originalWidth?: number;
  originalHeight?: number;
  toWidth?: number;
  toHeight?: number;
}

const defaultOptions = {
  originalWidth: 1000,
  originalHeight: 1000,
  toWidth: 54,
  toHeight: 54,
};
export const getClientImageURL = (
  iconURL: string,
  options: IClientImageURLOptions = defaultOptions,
): string => {
  const _options = { ...defaultOptions, ...options };
  const { originalWidth, originalHeight, toWidth, toHeight } = _options;
  return getScaleToFillImageURL(
    getURI(iconURL),
    originalWidth,
    originalHeight,
    toWidth,
    toHeight,
  );
};
