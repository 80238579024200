import type { MarketListing as MarketListingType } from '@wix/ambassador-devcenter-app-market-listing-v1-market-listing/types';
import { MarketListing } from './market-listing';

export class MarketListings {
  constructor(private readonly marketListings: MarketListingType[]) {}

  findByAppId(appId: string, languageCode: string): MarketListing {
    const marketListing = this.marketListings.find(
      (_marketListing) =>
        _marketListing.appId === appId &&
        _marketListing.languageCode === languageCode,
    );
    const ml = new MarketListing(marketListing);

    if (ml.isValid) {
      return ml;
    }

    const fallbackMarketListing = this.marketListings.find(
      (_marketListing) =>
        _marketListing.appId === appId && _marketListing.languageCode === 'en',
    );
    return new MarketListing(fallbackMarketListing);
  }
}
