import { Box, SkeletonLine } from '@wix/design-system';
import React from 'react';
import { AppGridSkeleton } from '../app-grid';

export interface AppSectionSkeletonProps {
  boxCount: number;
  minColumns?: 1 | 2 | 3 | 4;
}

export function AppSectionSkeleton({
  boxCount,
  minColumns,
}: AppSectionSkeletonProps) {
  return (
    <Box
      dataHook="skeleton-category-section"
      marginTop="36px"
      direction="vertical"
    >
      <Box align="space-between">
        <SkeletonLine width="48px" />
        <SkeletonLine width="100px" />
      </Box>
      <Box direction="vertical" marginTop="12px">
        <AppGridSkeleton
          dataHook="skeleton-apps"
          boxCount={boxCount}
          minColumns={minColumns}
        />
      </Box>
    </Box>
  );
}
