import * as React from 'react';
import style from './solution-reviews-info.scss';
import { StarFilled } from '@wix/wix-ui-icons-common';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import { composer } from '../../../../../component-decorators/composer';
import { Text, Box } from '@wix/design-system';
import type { InjectedExperimentsProps } from '@wix/wix-experiments-react';

interface ISolutionReviewsInfoProps
  extends Partial<WithTranslationProps>,
    Partial<InjectedExperimentsProps> {
  reviews: { numberOfReviews: number; reviewStars: number };
  layout?: string;
}

export const SolutionReviewsInfo = composer()
  .withTranslation()
  .withExperiments()
  .compose(({ experiments, t, reviews }: ISolutionReviewsInfoProps) => {
    const noReviews = !reviews.numberOfReviews || reviews.numberOfReviews === 0;
    let areReviews = true;
    if (
      experiments &&
      experiments.enabled('specs.marketplace.solutionBox.removeReviewRating')
    ) {
      areReviews = false;
    }
    return (
      <Box dataHook="reviews-info" verticalAlign="middle">
        <StarFilled
          color={noReviews ? 'rgba(22, 45, 61, 0.2)' : '#FDB10C'}
          className={style.starIcon}
        />
        {noReviews ? (
          <Text
            data-hook="no-reviews"
            size="small"
            weight="normal"
            secondary
            light
          >
            {t('solutionBox.solutionReviewsInfo.noReviews')}
          </Text>
        ) : (
          <Box>
            <Text
              data-hook="average-rating"
              className={style.rating}
              size="small"
              weight="bold"
            >
              {Number(reviews.reviewStars).toFixed(1)}
            </Text>
            <Text
              data-hook="total-reviews"
              size="small"
              weight="thin"
              secondary
            >{`(${reviews.numberOfReviews})`}</Text>
          </Box>
        )}
      </Box>
    );
  });
