import * as React from 'react';
import s from './standalone-header.scss';
import { config } from '../../config';
import { SearchBoxContainer } from '../search-box';
import { HeaderUserDetails } from './header-user-details';
import { Search } from '@wix/wix-ui-icons-common';
import { useConfigContext } from '../../config-context';
import { composer } from '../../component-decorators/composer';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';

interface IStandaloneHeaderBaseProps extends Partial<WithTranslationProps> {
  className?: string;
  toggleMenu(): void;
  toggleSearchBox(): void;
}

const MobileMenuIcon = ({ onClick }: any) => {
  return (
    <span className={s.mobileMenu} onClick={onClick}>
      <img src={`${config.marketplaceStaticsUrl}assets/images/menu.svg`} />
    </span>
  );
};

function StandaloneHeaderBase({
  className = '',
  toggleMenu,
  toggleSearchBox,
  t,
}: IStandaloneHeaderBaseProps) {
  const { locale } = useConfigContext();

  return (
    <div className={`${s.header} ${className}`}>
      <MobileMenuIcon onClick={toggleMenu} />
      <a className={s.logoWrapper} href="/app-market">
        <img
          className={s.logo}
          src={`${config.marketplaceStaticsUrl}assets/images/wix-app-market-new-logo.svg`}
          alt={t('app-market-logo.alt-text')}
        />
      </a>
      <div className={s.searchUserWrapper}>
        <span className={s.searchIcon}>
          <Search onClick={toggleSearchBox} />
        </span>
        <div className={s.searchWrapper}>
          <SearchBoxContainer
            isInMenu={false}
            isExpandable={false}
            isInHeader={true}
            locale={locale}
            searchLocation="standalone-header"
          />
        </div>
        <div className={s.verticalDivider} />
        <HeaderUserDetails />
      </div>
    </div>
  );
}

export const StandaloneHeader = composer()
  .withTranslation()
  .compose(StandaloneHeaderBase);
