import type { ClientSDK } from '@wix/installation-manager-client-sdk';
import type { IInstaller } from '@wix/app-market-core';

export class Installer implements IInstaller {
  private readonly clientSDK: ClientSDK;
  private readonly installedVersionOverride?: string;
  private readonly referredByAppId?: string;

  constructor({
    clientSDK,
    installedVersionOverride,
    referredByAppId,
  }: {
    clientSDK: ClientSDK;
    installedVersionOverride?: string;
    referredByAppId?: string;
  }) {
    this.clientSDK = clientSDK;
    this.installedVersionOverride = installedVersionOverride;
    this.referredByAppId = referredByAppId;
  }

  installApp: IInstaller['installApp'] = ({
    appId,
    consentOpeningMethod,
    onSuccess,
    onFailure,
  }) => {
    this.clientSDK.installApp({
      appDefId: appId,
      openMethod: consentOpeningMethod,
      version: this.installedVersionOverride,
      onInstallationSuccess: ({ instanceId }) => {
        onSuccess?.(instanceId);
      },
      onInstallationFail: ({ reason: failureReason }) => {
        onFailure?.(failureReason);
      },
      referredByAppId: this.referredByAppId,
    });
  };
}
