import type { AppGroupSection, IServices } from '@wix/app-market-services';

export async function getAppGroupSectionsBySubCategoriesIds({
  subCategoriesIds,
  services,
}: {
  subCategoriesIds: string[];
  services: IServices;
}): Promise<AppGroupSection[]> {
  const tagSections = await services.getTagSections({
    tagIds: subCategoriesIds,
  });

  return tagSections.getAppGroupSections();
}
