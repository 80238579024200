import type { PagingMetadataV2 } from '@wix/ambassador-devcenter-ams-v1-app-market-search/types';
import { PagingMetadata } from '../common/paging-metadata';

export class AutoComplete {
  private readonly autoComplete: string[];
  private readonly pagingMetadata: PagingMetadata;

  constructor(autoComplete: string[], pagingMetadata?: PagingMetadataV2) {
    this.autoComplete = autoComplete;
    this.pagingMetadata = new PagingMetadata(pagingMetadata);
  }

  get autoCompleteResults(): string[] {
    return this.autoComplete;
  }

  get totalApps(): number {
    return this.pagingMetadata.total;
  }

  get hasMoreApps(): boolean {
    return this.pagingMetadata.hasNext;
  }
}
