import * as React from 'react';
import s from './suggestions.scss';
import { Search } from '@wix/wix-ui-icons-common';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import { composer } from '../../../../component-decorators/composer';
import { Text } from '@wix/design-system';
import { SuggestionType } from '../../../../types/common/marketplace';

export interface ISuggestionProps extends Partial<WithTranslationProps> {
  suggestionKey: string;
  value: string;
  suggestionType: SuggestionType;
  onClick: any;
  icon?: string;
  teaser?: string;
  selected: boolean;
  index: number;
  addSelected(index): void;
}

export const Suggestion = composer()
  .withTranslation()
  .compose(
    ({
      suggestionKey,
      value,
      onClick,
      suggestionType,
      icon,
      teaser,
      index,
      selected,
      addSelected,
      t,
    }: ISuggestionProps) => {
      const onMouseEnter = () => {
        addSelected && addSelected(index);
      };

      const onMouseLeave = () => {
        addSelected && addSelected(null);
      };

      return (
        <>
          {(suggestionType === SuggestionType.NONE_TYPE ||
            suggestionType === SuggestionType.SEARCH) && (
            <div
              tabIndex={0}
              data-testid="suggestion-default-popular"
              className={`${
                suggestionType === SuggestionType.NONE_TYPE
                  ? s.defaultSuggestionContainer
                  : s.popularSuggestionContainer
              } suggestion ${selected ? 'selected' : ''}`}
              onClick={(e) => {
                onClick(e, {
                  suggestionKey,
                  value,
                  onClick,
                  suggestionType,
                  icon,
                  teaser,
                  index,
                  selected,
                });
              }}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              style={
                selected
                  ? suggestionType === SuggestionType.NONE_TYPE
                    ? { backgroundColor: '#eaf7ff' }
                    : { backgroundColor: '#daeffe' }
                  : { backgroundColor: 'transparent' }
              }
            >
              <Search
                style={{ color: '#3899ec', width: '24px', height: '24px' }}
              />
              <div className={s.defaultSuggestionButton}>
                <Text
                  size="small"
                  weight="bold"
                  skin="standard"
                  data-testid={`suggestion-${suggestionKey}`}
                  dataHook={`suggestion-${suggestionKey}`}
                >
                  {suggestionType === SuggestionType.NONE_TYPE
                    ? t(value)
                    : value}
                </Text>
              </div>
            </div>
          )}
          {(suggestionType === SuggestionType.APP ||
            suggestionType === SuggestionType.CATEGORY) && (
            <div
              tabIndex={0}
              data-testid="suggestion-apps-categories"
              className={`${s.suggestionContainer} suggestion ${
                selected ? 'selected' : ''
              }`}
              onClick={onClick}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              style={
                selected
                  ? { backgroundColor: '#daeffe' }
                  : { backgroundColor: 'transparent' }
              }
            >
              <img
                src={icon}
                className={s.suggestionIcon}
                data-testid="suggestion-icon"
              />
              <div className={s.textContainer}>
                <Text
                  weight="bold"
                  size="small"
                  skin="standard"
                  className={s.suggestionButton}
                  data-testid={`suggestion-${suggestionKey}`}
                >
                  {value}
                </Text>
                {suggestionType === SuggestionType.APP && (
                  <Text
                    size="small"
                    className={s.suggestionButton}
                    data-testid="suggestion-teaser"
                    skin="standard"
                    secondary
                  >
                    {!teaser || teaser.length <= 50
                      ? teaser
                      : `${teaser.substring(0, 50)}...`}
                  </Text>
                )}
              </div>
            </div>
          )}
        </>
      );
    },
  );
