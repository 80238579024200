import * as React from 'react';
import { Box, Heading, Image, Text, TextButton } from '@wix/design-system';
import { useEssentials } from '../../../contexts/essentials-context';

interface IReviewModalHeaderMobileProps {
  app: {
    name: string;
    icon: string;
  };
  onContactSupport: (location: string) => void;
}

export function ReviewModalHeaderMobile({
  app,
  onContactSupport,
}: IReviewModalHeaderMobileProps) {
  const { t } = useEssentials();

  return (
    <Box gap="12px" direction="vertical">
      <Box gap="12px">
        <Box minWidth="60px" width="60px" height="60px">
          <Image src={app.icon} alt={`${app.name} icon`} />
        </Box>
        <Heading size="large">{t('review-app.header-title')}</Heading>
      </Box>
      <Text secondary>
        {t('review-app.header-description', {
          appName: app.name,
          interpolation: { escapeValue: false },
        })}
        <TextButton
          dataHook="report-bug-button"
          onClick={() => onContactSupport('review_modal')}
          style={{ paddingLeft: '6px' }}
        >
          {t('review-app.header-description.report-bug')}
        </TextButton>
      </Text>
    </Box>
  );
}
