import * as React from 'react';
import { TranslationsProvider } from '../../i18next';
import { sendSolutionImpressionBi } from '../../bi/events';
import type { i18n as i18nType } from 'i18next';
import type { InjectedExperimentsProps } from '@wix/wix-experiments-react';
import { composer } from '../../component-decorators/composer';
import { useCallback, useState, useEffect } from 'react';
import { Cell, Layout, Carousel, Box } from '@wix/design-system';
import { PromoBoxCompact } from './promo-box-compact';
import {
  getAppBadgeTheme,
  getAppBadgeTitle,
  getAppBadgeTooltipContent,
} from '../common';
import { useMediaQuery } from 'react-responsive';
import type { ISolutionBiData, WebSolutionExtended } from '../../types';
import { VisibilityDetector } from '../../utils/visibility-detector';
import s from './style.scss';

export interface IPromoWidgetProps extends Partial<InjectedExperimentsProps> {
  solutions: WebSolutionExtended[];
  solutionsBiData?: ISolutionBiData[];
  locale?: string;

  onPromoClickHandler(solution: WebSolutionExtended): Promise<void>;

  onBoxClick?(solution: WebSolutionExtended): void;

  onBoxRender?(): void;

  as?: 'link';
  i18n?: i18nType;
  hideCTA?: boolean;
  isBundleAppInstalled?: boolean;
  isBundleAppNotInstalled?: boolean;
  isVoucherApp?: boolean;
  index: number;
  hideTagsBadge?: boolean;
}

const PromoWidgetBase = composer()
  .withExperiments()
  .compose(
    ({
      solution,
      solutionsBiData,
      locale,
      onPromoClickHandler,
      onBoxClick,
      as,
      i18n,
      hideCTA,
      isMobile,
      index,
      experiments,
      hideTagsBadge,
    }) => {
      const badgeTitle = getAppBadgeTitle(
        solution.isBundleAppInstalled,
        solution.isBundleAppNotInstalled,
        solution.Tags,
        experiments,
        hideTagsBadge,
        solution?.id,
      );

      return (
        <PromoBoxCompact
          key={index}
          solution={solution}
          solutionBiData={solutionsBiData && solutionsBiData[index]}
          onPromoClickHandler={onPromoClickHandler}
          onBoxClick={onBoxClick}
          as={as}
          hideCTA={hideCTA}
          badgeTitle={badgeTitle}
          badgeTheme={getAppBadgeTheme(badgeTitle)}
          showTooltip={
            solution.isBundleAppInstalled ||
            solution.isBundleAppNotInstalled ||
            true
          }
          tooltipContent={getAppBadgeTooltipContent(
            solution.isBundleAppInstalled,
            solution.isBundleAppNotInstalled,
            solution.isVoucherApp,
          )}
          isMobile={isMobile}
        />
      );
    },
  );

export const PromoWidget = composer()
  .withExperiments()
  .compose((props: IPromoWidgetProps) => {
    const isInMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const bigScreen = useMediaQuery({ query: '(min-width: 1484px)' });

    const [isImpression, setIsImpression] = useState(false);
    const [isBigScreen, setIsBigScreen] = useState(true);

    const onImpression = useCallback(() => {
      !isImpression &&
        props.solutionsBiData.map((solutionBiData) => {
          sendSolutionImpressionBi(solutionBiData);
          setIsImpression(true);
        });
    }, [setIsImpression, isImpression]);

    useEffect(() => {
      setIsBigScreen(bigScreen);
    }, [bigScreen]);
    return isInMobile ? (
      <div data-testid="promo-widget">
        <TranslationsProvider i18n={props.i18n} locale={props.locale}>
          <VisibilityDetector onVisible={onImpression}>
            <Carousel
              className={s.promoCarousel}
              variableWidth={true}
              dots={false}
              controlsPosition="none"
              infinite={true}
            >
              {props.solutions
                .slice(0, 4)
                .map((solution: WebSolutionExtended, index: number) => {
                  return (
                    <Box width="222px" marginRight="12px" key={index}>
                      <PromoWidgetBase
                        {...{ ...props, isMobile: true, index, solution }}
                      />
                    </Box>
                  );
                })}
            </Carousel>
          </VisibilityDetector>
        </TranslationsProvider>
      </div>
    ) : (
      <div data-testid="promo-widget">
        <TranslationsProvider i18n={props.i18n} locale={props.locale}>
          <VisibilityDetector onVisible={onImpression}>
            <Layout gap="24px" cols={12}>
              {props.solutions
                .slice(0, isBigScreen ? 4 : 3)
                .map((solution: WebSolutionExtended, index: number) => {
                  return (
                    <Cell span={isBigScreen ? 3 : 4} rows={1} key={solution.id}>
                      <PromoWidgetBase
                        {...{
                          ...props,
                          isMobile: false,
                          index,
                          solution,
                        }}
                      />
                    </Cell>
                  );
                })}
            </Layout>
          </VisibilityDetector>
        </TranslationsProvider>
      </div>
    );
  });
