import React, { useState } from 'react';
import s from './review-body.scss';
import { Text, Box, TextButton } from '@wix/design-system';
import { isBodyTooLong, reviewBodySlicer } from '../review.utils';
import { useEssentials } from '../../../../contexts/essentials-context';
import { useRateAndReviewSectionContext } from '../../../../exported-components/rate-and-reviews-section/rate-and-reviews-section-context';

interface ReviewBodyProps {
  title: string;
  body: string;
  id: string;
}

export function ReviewBody({ title, body, id }: ReviewBodyProps) {
  const { t } = useEssentials();
  const { onReadMoreOrLessClick } = useRateAndReviewSectionContext();
  const [expandedBody, setExpandedBody] = useState<boolean>(false);
  const isLongReview = isBodyTooLong(body);

  function getReviewBodyText() {
    return isLongReview && !expandedBody ? reviewBodySlicer(body) : body;
  }

  function expandToggle() {
    onReadMoreOrLessClick('user_review');
    setExpandedBody((prevExpandedBody) => !prevExpandedBody);
  }

  return (
    <Box marginBottom="5px" marginLeft="2px">
      <Box className={s.reviewBody} direction="vertical" marginLeft="67px">
        {title && (
          <Text as="h3" size="medium" weight="bold">
            {title}
          </Text>
        )}
        <Text
          property="reviewBody"
          dataHook={`review-${id && id}`}
          size="medium"
        >
          {getReviewBodyText()}
        </Text>
        {isLongReview && (
          <TextButton
            dataHook={`review-body-expander-${id}`}
            onClick={expandToggle}
          >
            {expandedBody
              ? t('solution.page.review.readLess')
              : t('solution.page.review.readMore')}
          </TextButton>
        )}
      </Box>
    </Box>
  );
}
