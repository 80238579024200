import React from 'react';
import type { GetDynamicSectionsResponseNonNullableFields } from '@wix/ambassador-appmarket-v1-app-market-client-aggregator/types';
import { SectionType } from '@wix/ambassador-appmarket-v1-app-market-client-aggregator/types';
import { categoryCarouselPropsMapper } from './sections-props-mappers/category-carousel';
import { appsSectionPropsMapper } from './sections-props-mappers/apps-section';
import { AppsSection, CategoryCarousel } from '@wix/app-market-components';
import type { i18n } from 'i18next';
import type { MarketplaceStore } from '../../component-decorators/with-marketplace-store';
import type { AppMarketPage } from '../../types/common/marketplace';
import type { BiLogger } from '@wix/app-market-core';

export interface DynamicSectionComponentProps {
  section: GetDynamicSectionsResponseNonNullableFields['sections'][number];
  i18n: i18n;
  marketplaceStore: MarketplaceStore;
  index?: number;
  page: AppMarketPage;
  biLogger: BiLogger;
  isPremiumSite: boolean;
}

export function DynamicSectionComponent(props: DynamicSectionComponentProps) {
  const { section } = props;

  const mapSectionTypeToSection = {
    [SectionType.CATEGORY_CAROUSEL]: {
      component: CategoryCarousel,
      propsMapper: categoryCarouselPropsMapper,
    },
    [SectionType.APP_GROUP]: {
      component: AppsSection,
      propsMapper: appsSectionPropsMapper,
    },
  };

  if (!section.type || !mapSectionTypeToSection[section.type]) {
    return null;
  }

  const Component = mapSectionTypeToSection[section.type].component;
  const propsMapper = mapSectionTypeToSection[section.type].propsMapper;

  return <Component {...propsMapper(props)} />;
}
