import type { V1App as V1AppType } from '@wix/ambassador-devcenter-apps-v1-app/types';
import { App } from './app';

export class Apps {
  constructor(private readonly app: V1AppType[]) {}

  findByAppId(appId: string): App {
    const app = this.app.find((_app) => _app.id === appId);
    return new App(app);
  }
  findBySlug(slug: string): App {
    const app = this.app.find((_app) => _app.slug === slug);
    return new App(app);
  }
}
