import * as React from 'react';
import { Box, Heading, Image, Text } from '@wix/design-system';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';

interface IReviewAppHeaderProps extends Partial<WithTranslationProps> {
  appName: string;
  appIconSrc: string;
}

function ReviewAppHeaderMobile({
  appName,
  appIconSrc,
  t,
}: IReviewAppHeaderProps) {
  const headerTitle = t('review-app.header-title');
  const headerDescription = t('review-app.header-description', { appName });

  return (
    <Box direction="vertical">
      <Heading appearance="H3">{headerTitle}</Heading>
      <Box
        maxWidth="450px"
        marginBottom="10px"
        marginTop="18px"
        direction="horizontal"
        verticalAlign="middle"
      >
        <Box width="48px" height="48px" marginRight="12px">
          <Image src={appIconSrc} alt={`${appName} icon`} />
        </Box>
        <Text weight="bold">{appName}</Text>
      </Box>
      <Text size="small">{headerDescription}</Text>
    </Box>
  );
}

export { IReviewAppHeaderProps, ReviewAppHeaderMobile };
