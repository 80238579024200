import * as React from 'react';
import { TranslationsProvider } from '../../i18next';
import s from './style.scss';
import { sendWidgetClick, sendWidgetNavigation } from '../../bi/events';
import { Box, Text, Carousel } from '@wix/design-system';
import { ConditionalLinkRenderer } from '../solution/solution-grid/solution-box';
import type { i18n as i18nType } from 'i18next';
import { composer } from '../../component-decorators/composer';
import type { ITagBiData, TagExtended } from '../../types';

export interface ICategoriesCarouselWidgetProps {
  categories: TagExtended[];
  locale?: string;
  onCategoryClickHandler(category: TagExtended): void;
  onBoxRender?(): void;
  biData?: ITagBiData[];
  as?: 'link';
  i18n?: i18nType;
}

export const CategoriesCarouselWidget = composer().compose(
  ({
    categories,
    locale = 'en',
    onCategoryClickHandler,
    biData,
    as,
    i18n,
  }: ICategoriesCarouselWidgetProps) => {
    const handleNavClick = (oldIndex: number, newIndex: number) => {
      if (biData) {
        const direction = oldIndex < newIndex ? 'right' : 'left';
        sendWidgetNavigation(biData[oldIndex], direction);
      }
    };

    return (
      <TranslationsProvider i18n={i18n} locale={locale}>
        <Carousel
          buttonSkin="inverted"
          dots={true}
          variableWidth={true}
          beforeChange={handleNavClick}
          className={s.carouselWrapper}
          children={categories?.map((category: TagExtended, index: number) => {
            const { name, description, slug, to } = category as any;
            return (
              <span
                onClick={() => {
                  sendWidgetClick(biData[index]);
                  onCategoryClickHandler(category);
                }}
                key={slug}
                className={s.slide}
              >
                <ConditionalLinkRenderer as={as} to={to}>
                  <Box className={s.box}>
                    <div className={s.boxContent}>
                      <Box direction="vertical" className={s.titleWrapper}>
                        <Text
                          size="medium"
                          weight="bold"
                          dataHook="solution-name"
                          className={s.solutionName}
                        >
                          {name}
                        </Text>
                        <div className={s.teaser}>
                          <Text
                            size="small"
                            weight="thin"
                            dataHook="solution-teaser"
                          >
                            {description}
                          </Text>
                        </div>
                      </Box>
                      <div className={s.imgWrapper}>
                        <img
                          className={s.image}
                          alt={`${name} category icon`}
                          src={appendProtocolToUrlIfNeeded(category.icon)}
                        />
                      </div>
                    </div>
                  </Box>
                </ConditionalLinkRenderer>
              </span>
            );
          })}
        />
      </TranslationsProvider>
    );
  },
);

function appendProtocolToUrlIfNeeded(url: string): string {
  if (url && url?.startsWith('http')) {
    return url;
  }

  return 'https://' + url;
}
