import React from 'react';
import { type ButtonSize } from '@wix/design-system';
import {
  AppNotificationType,
  type AppNotification,
} from '../app-page-sections/notifications/types';
import { InstallAppButton } from './install-app-button';
import { OpenAppButton } from './open-app-button';
import {
  useTranslation,
  type ITranslationContext,
} from '../../../contexts/translations-context';
import type { BadgeType } from '@wix/app-market-services';
import { MobileInstallationBlockedModal } from './mobile-installation-blocked-modal';

export interface AppPageCTAProps {
  app: {
    id: string;
    name: string;
    isBundle: boolean;
    isInstalled: boolean;
    isBuiltIn: boolean;
    badges: BadgeType[];
  };
  notification: AppNotification | null;
  size?: ButtonSize;
  dataHook?: string;
}

export function AppPageCTA({
  app,
  size = 'large',
  notification,
  dataHook = 'app-page-cta',
}: AppPageCTAProps) {
  const { t } = useTranslation();

  const [isAppInstalled, setIsAppInstalled] = React.useState(app.isInstalled);
  const [isMobileModalOpen, setIsMobileModalOpen] = React.useState(false);

  const sharedButtonProps = {
    isDisabled: isCtaDisabled(notification?.type),
    tooltipContent: getCtaTooltipContent(notification, t),
    size,
    openMobileInstallationBlockedModal: () => setIsMobileModalOpen(true),
  };

  return (
    <>
      {isMobileModalOpen && (
        <MobileInstallationBlockedModal
          app={{
            id: app.id,
            name: app.name,
          }}
          onClose={() => setIsMobileModalOpen(false)}
        />
      )}
      <div data-hook={dataHook}>
        {isAppInstalled || app.isBuiltIn ? (
          <OpenAppButton
            {...sharedButtonProps}
            app={{ id: app.id, isBuiltIn: app.isBuiltIn }}
          />
        ) : (
          <InstallAppButton
            {...sharedButtonProps}
            app={{
              id: app.id,
              isBundle: app.isBundle,
              badges: app.badges,
            }}
            onInstallationSuccess={() => {
              setIsAppInstalled(true);
            }}
          />
        )}
      </div>
    </>
  );
}

function getCtaTooltipContent(
  notification: AppNotification | null,
  t: ITranslationContext['t'],
): string {
  if (notification?.type === AppNotificationType.EditorNotSupported) {
    return t('solution.page.isNotAvailable.tooltip', {
      editorType:
        notification.data.editorType === 'EDITORX'
          ? t('editor.x.brand.name')
          : t('wix.studio.brand.name'),
    });
  }

  if (notification?.type === AppNotificationType.DependenciesNotInstalled) {
    return notification.data.appDependencies.length > 1
      ? t('solution.page.appDependenciesNotInstalled.tooltip')
      : t('solution.page.appDependencyNotInstalled.tooltip', {
          appRequirement: notification.data.appDependencies[0].name,
        });
  }

  if (notification?.type === AppNotificationType.DependencyPendingEditor) {
    return t('solution.page.appDependencyPending.tooltip', {
      appRequirement: notification.data.appDependency.name,
    });
  }

  if (notification?.type === AppNotificationType.PremiumSiteRequired) {
    return t('app.page.premium.required.notification.tooltip');
  }

  return '';
}

function isCtaDisabled(notificationType?: AppNotificationType): boolean {
  return Boolean(
    notificationType &&
      [
        AppNotificationType.EditorNotSupported,
        AppNotificationType.DependenciesNotInstalled,
        AppNotificationType.DependencyPendingEditor,
        AppNotificationType.PremiumSiteRequired,
      ].includes(notificationType),
  );
}
