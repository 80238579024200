import React from 'react';
import { languages } from './seo-page.utils';

export interface AppPageSEOProps {
  getHref: (languageCode: string) => string;
  metaTagsAggregator: string[];
}

export function AppPageSEOPage({
  getHref,
  metaTagsAggregator,
}: AppPageSEOProps) {
  if (metaTagsAggregator) {
    metaTagsAggregator.push(
      `<link rel="alternate" hreflang="x-default" href="${getHref('en')}" />`,
    );
    languages.map((language) =>
      metaTagsAggregator.push(
        `<link rel="alternate" hreflang="${language}" href="${getHref(
          language,
        )}" />`,
      ),
    );
  }
  return <></>;
}
