import * as React from 'react';
import { Suggestion } from '../suggestion';
import { config } from '../../../../config';
import { Path } from '../../../..';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import { composer } from '../../../../component-decorators/composer';
import s from './categories-suggestions.scss';
import type { BiInjectedProps as InjectedBiLoggerProps } from '../../../../bi';
import { Text } from '@wix/design-system';
import { SuggestionType } from '../../../../types/common/marketplace';

export interface ICategoriesSuggestionsProps
  extends Partial<WithTranslationProps>,
    Partial<InjectedBiLoggerProps> {
  categories: any;
  searchValue: string;
  addSelectedNode(index): void;
  onSearchValueChange(value: string): Promise<void>;
  sendSearchSearchClickBi(value: string, type: string, index?: number): void;
}

export const CategoriesSuggestions = composer()
  .withTranslation()
  .withBiLogger()
  .compose(
    ({
      categories,
      t,
      searchValue,
      addSelectedNode,
      onSearchValueChange,
      sendSearchSearchClickBi,
    }: ICategoriesSuggestionsProps) => {
      const removeSpaces = (str: string) => str && str.replace(/\s/g, '');
      return (
        <React.Fragment>
          {categories.length > 0 && (
            <div
              className={s.categoriesSuggestionsTitle}
              data-testid="categories-suggestions-title"
            >
              <div className={s.titleText}>
                <Text size="small">{t('search.box.categories.title')}</Text>
              </div>
            </div>
          )}
          {categories.map((category) => (
            <Suggestion
              key={removeSpaces(category.slug)}
              suggestionKey={removeSpaces(category.slug)}
              value={category.name}
              suggestionType={SuggestionType.CATEGORY}
              onClick={() => {
                onSearchValueChange(category.name);
                sendSearchSearchClickBi(
                  searchValue,
                  'categories',
                  category.index,
                );
                config.goto({ path: Path.CATEGORY, slug: category.slug });
              }}
              icon={category.svg}
              selected={category.selected}
              index={category.index}
              addSelected={addSelectedNode}
            />
          ))}
        </React.Fragment>
      );
    },
  );
