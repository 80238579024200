import React from 'react';

type Omit<T, K extends keyof any> = Pick<T, Exclude<keyof T, K>>;

const biContext: React.Context<any> = React.createContext(null);
export interface IBiInjectedProps {
  biLogger: any;
}

export const BiProvider = biContext.Provider;
export const WithBi = <P extends IBiInjectedProps>(
  WrappedComponent: React.ComponentType<P>,
): React.ComponentType<Omit<P, keyof IBiInjectedProps>> =>
  class extends React.Component<Omit<P, keyof IBiInjectedProps>> {
    static contextType = biContext;
    context!: React.ContextType<typeof biContext>;

    render() {
      const newProps = {
        ...this.props,
        biLogger: this.context,
      };
      return React.createElement(WrappedComponent, newProps as P);
    }
  };
