import type { IAppPlans } from '../apps-plans';
import { mockTaxSettings, mockCurrencySettings } from '../apps-plans';
import type { IPricingModel } from '../pricing-model';

export const emptyAppPlansMock: IAppPlans = {
  appId: '123456',
  taxSettings: mockTaxSettings,
  currency: mockCurrencySettings,
  plans: [],
};

export const emptyPricingModelMock: IPricingModel = {
  appId: '123456',
  freeTrialDays: 0,
  isExternalPricing: false,
  plans: [],
  hasDynamicPlan: false,
};
