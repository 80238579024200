import React from 'react';
import { Text, InfoIcon, TextButton, Box } from '@wix/design-system';
import { AppsGridBox } from '../apps-grid-box';
import { SubHeader } from '../sub-header';
import type { IConsumerPreferences } from '../contexts/consumer-preferences';
import type { IConsumerCallbacks } from '../contexts/consumer-callbacks';
import type { WebSolutionExtended } from '../../../../types';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import { composer } from '../../../../component-decorators/composer';

export interface InstalledAppsGridProps
  extends IConsumerPreferences,
    IConsumerCallbacks,
    WithTranslationProps {
  solutions: WebSolutionExtended[];
  finalColumns: 1 | 2 | 3 | 4;
  metaSiteId: string;
}

export const InstalledAppsGrid = composer()
  .withTranslation()
  .compose(
    ({
      t,
      solutions,
      finalColumns,
      onClick,
      onInstalledAppClick,
      showViewAppButton,
      showDeveloperName,
      showRating,
      showPricing,
      showTopBadge,
      skin,
      metaSiteId,
    }: InstalledAppsGridProps) => {
      const openManageApps = () => {
        window.open(
          `https://manage.wix.com/dashboard/${metaSiteId}/manage-installed-apps`,
        );
      };

      return (
        <>
          <SubHeader
            title={t('solutionBox.gridBoxInstalledApps.title')}
            suffix={
              <InfoIcon
                tooltipProps={{ maxWidth: '290px' }}
                size="small"
                content={
                  <Text light size="small">
                    {t('solutionBox.gridBoxInstalledApps.tooltipStart')}
                    <TextButton
                      size="small"
                      underline="always"
                      skin="light"
                      onClick={openManageApps}
                    >
                      {t('solutionBox.gridBoxInstalledApps.tooltipButtonLink')}
                    </TextButton>
                  </Text>
                }
              />
            }
          />
          <div style={{ paddingBottom: '30px' }}>
            <AppsGridBox
              showTopBadge={showTopBadge}
              skin={skin}
              finalColumns={finalColumns}
              showDeveloperName={showDeveloperName}
              showRating={showRating}
              showPricing={showPricing}
              showViewAppButton={showViewAppButton}
              solutions={solutions}
              onClick={onClick}
              onInstalledAppClick={onInstalledAppClick}
            />
          </div>
        </>
      );
    },
  );
