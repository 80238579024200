import * as React from 'react';
import { Box, Button } from '@wix/design-system';
import { useEssentials } from '../../../contexts/essentials-context';

interface IReviewModalFooterMobileProps {
  onSubmit: () => void;
  onCancel: () => void;
}

export function ReviewModalFooterMobile({
  onSubmit,
  onCancel,
}: IReviewModalFooterMobileProps) {
  const { t } = useEssentials();

  return (
    <Box align="right">
      <Box marginRight="12px">
        <Button onClick={onCancel} priority="secondary">
          {t('review-app.cancel-review')}
        </Button>
      </Box>
      <Button onClick={onSubmit}>{t('review-app.submit-review')}</Button>
    </Box>
  );
}
