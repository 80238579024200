import * as React from 'react';
import s from './mobile-search-box.scss';
import { X } from '@wix/wix-ui-icons-common';
import { MobileSearchContainer } from '../mobile-search';
import { Suggestion } from '../search-suggestions-container/suggestion';
import { PopularSuggestions } from '../search-suggestions-container/popular-suggestions';
import type { AppSuggestion } from '../search-suggestions-container/apps-suggestions';
import { AppsSuggestions } from '../search-suggestions-container/apps-suggestions';
import { CategoriesSuggestions } from '../search-suggestions-container/categories-suggestions';
import { defaultSuggestions } from '../search-suggestions-container/static-data/default-suggestions';
import { Text } from '@wix/design-system';
import { withBiLogger } from '../../../component-decorators/with-bi-logger';
import type { MarketplaceStore } from '../../../component-decorators/with-marketplace-store';
import { withMarketplaceStore } from '../../../component-decorators/with-marketplace-store';
import { config } from '../../../config';
import { Path } from '../../../enums/marketplace-enums';
import type { BiInjectedProps as InjectedBiLoggerProps } from '../../../bi';
import { biLogger } from '../../../bi';
import { getPathForBi } from '../../../common/bi-events';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import { ConfigContextConsumer } from '../../../config-context';
import { withTranslation } from '../../../component-decorators/with-translation';
import { SuggestionType } from '../../../types/common/marketplace';
import {
  appModalOpenAppModalClick,
  searchSearchClick,
} from '@wix/bi-logger-market/v2';

export interface IMobileSearchBox
  extends Partial<InjectedBiLoggerProps>,
    Partial<WithTranslationProps> {
  marketplaceStore?: MarketplaceStore;
  toggleSearchBox(): void;
  isSearchMenuOpen: boolean;
}

@withBiLogger
@withMarketplaceStore
@withTranslation
export class MobileSearchBox extends React.PureComponent<
  IMobileSearchBox,
  any
> {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: [],
      defaultSuggestions: [],
      searchValue: '',
    };
  }

  sendSearchSearchClickBi = (
    search_term: string,
    search_type: string,
    index?: number,
  ) => {
    const { marketplaceStore, toggleSearchBox } = this.props;
    biLogger.report(
      searchSearchClick({
        market: config.originForBI,
        search_term,
        msid: marketplaceStore.metaSiteId,
        search_type,
        search_location: 'mobile_menu',
        index,
        tag_type: getPathForBi(this.props.marketplaceStore.route.path),
      }),
    );
    toggleSearchBox();
  };

  sendAppBoxClickBi = (
    app: AppSuggestion,
    searchLocation: string,
    searchValue: string,
  ) => {
    biLogger.report(
      appModalOpenAppModalClick({
        app_id: app.id,
        index: app.index,
        market: config.originForBI,
        navigation_source: searchLocation,
        tag_type: 'search_bar',
        section: 'app_suggestion',
        search_term: searchValue,
        slug_id: app.slug,
      }),
    );
  };

  render() {
    const { suggestions, searchValue } = this.state;

    const { isSearchMenuOpen, toggleSearchBox, t } = this.props;
    return (
      <ConfigContextConsumer>
        {(configContext) => (
          <div
            className={`${s.mobileSearch} ${isSearchMenuOpen ? s.open : ''}`}
          >
            <div className={s.inner}>
              <span className={s.topSection}>
                <MobileSearchContainer
                  isInMenu={false}
                  upadteSearchValue={(newSearchValue) =>
                    this.setState({ searchValue: newSearchValue })
                  }
                  updateSuggestions={(newSuggestions) =>
                    this.setState({ suggestions: newSuggestions })
                  }
                  locale={configContext.locale}
                  toggleSearchBox={toggleSearchBox}
                />

                <X onClick={toggleSearchBox} className={s.xIcon} />
              </span>
              {!searchValue.length && (
                <>
                  <Text weight="thin" className={s.topicsTitle}>
                    Trending Topics
                  </Text>
                  {defaultSuggestions.map((item, index) => {
                    return (
                      <Suggestion
                        onClick={(e, { value, index: idx }) => {
                          this.sendSearchSearchClickBi(
                            t(value),
                            'popular',
                            idx,
                          );
                          config.goto({ path: Path.SEARCH, query: t(value) });
                        }}
                        key={index}
                        {...item}
                        selected={false}
                        index={index}
                      />
                    );
                  })}
                </>
              )}

              <PopularSuggestions
                onSearchValueChange={() => {}}
                searchTerms={suggestions.filter(
                  (item) => item.suggestionType === SuggestionType.SEARCH,
                )}
                searchValue={searchValue}
                sendSearchSearchClickBi={this.sendSearchSearchClickBi}
              />

              <AppsSuggestions
                sendAppBoxClickBi={this.sendAppBoxClickBi}
                onSearchValueChange={() => {}}
                searchValue={searchValue}
                sendSearchSearchClickBi={this.sendSearchSearchClickBi}
                apps={suggestions.filter(
                  (item) => item.suggestionType === SuggestionType.APP,
                )}
              />

              <CategoriesSuggestions
                onSearchValueChange={() => {}}
                searchValue={searchValue}
                sendSearchSearchClickBi={this.sendSearchSearchClickBi}
                categories={suggestions.filter(
                  (item) => item.suggestionType === SuggestionType.CATEGORY,
                )}
              />
            </div>
          </div>
        )}
      </ConfigContextConsumer>
    );
  }
}
