import type * as React from 'react';
import { CreateWebsiteBanner } from '../standalone-banners/create-website-banner';
import { DevCenterBanner } from '../standalone-banners/dev-center-banner';
import { Promo, promoSectionDTOtoProps } from './partials/Promo';
import {
  CategoriesCarousel,
  categoriesCarouselSectionDTOtoProps,
} from './partials/CategoriesCarousel';
import {
  Solution,
  solutionsSectionDTOtoProps,
} from './partials/solution-grid-homepage';
import type { MarketplaceStore } from '../../component-decorators/with-marketplace-store';

interface IMapType {
  [key: string]: {
    component: React.ComponentType<any>;
    propsMapper?(
      sectionDTO: any,
      marketplaceStore: MarketplaceStore,
      locale?: string,
      signedInstance?: string,
      impressionId?: any,
      countryCode?: string,
      rpcClient?: any,
    ): any;
  };
}

export enum SectionType {
  PROMO = 'PROMO',
  COLLECTION = 'COLLECTION',
  CATEGORY = 'CATEGORY',
  DEVCENTER_BANNER = 'DEVCENTER_BANNER',
  WEBSITE_BANNER = 'WEBSITE_BANNER',
  DISCOVERY = 'DISCOVERY',
}

export const SupportedComponents: IMapType = {
  PROMO: { component: Promo, propsMapper: promoSectionDTOtoProps },
  COLLECTION: { component: Solution, propsMapper: solutionsSectionDTOtoProps },
  CATEGORY: {
    component: CategoriesCarousel,
    propsMapper: categoriesCarouselSectionDTOtoProps,
  },

  DEVCENTER_BANNER: { component: DevCenterBanner },
  WEBSITE_BANNER: { component: CreateWebsiteBanner },
};
