import * as React from 'react';
import style from './create-website-banner.scss';
import { composer } from '../../component-decorators/composer';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import { Heading, Button, Box, Text } from '@wix/design-system';
import type { InjectedExperimentsProps } from '@wix/wix-experiments-react';

interface ICreateBannerBannerProps
  extends Partial<WithTranslationProps>,
    Partial<InjectedExperimentsProps> {}

export const CreateWebsiteBanner = composer()
  .withTranslation()
  .withExperiments()
  .compose(({ t, experiments }: ICreateBannerBannerProps) => (
    <Box
      direction="vertical"
      align="center"
      backgroundColor="#162d3d"
      padding="54px 42px"
      marginTop="48px"
      marginBottom="48px"
      maxHeight="373px"
      className={style.centerWrapper}
    >
      <Heading appearance="H3" light className={style.titleBanner}>
        {t('standalone.createWebsiteBanner.title1')}
      </Heading>
      <Heading appearance="H3" light className={style.titleBanner}>
        {t('standalone.createWebsiteBanner.title2')}
      </Heading>
      <Box maxWidth="552px" align="center" direction="vertical">
        <Text skin="standard" light weight="normal">
          {t('standalone.createWebsiteBanner.subtitle1')}
        </Text>
        <Box marginBottom="24px" align="center" className={style.centerWrapper}>
          <Text skin="standard" light weight="normal">
            {t('standalone.createWebsiteBanner.subtitle2')}
          </Text>
        </Box>
      </Box>
      <Box width="145px" className={style.buttonWrapper}>
        <Button
          size="large"
          as="a"
          href="https://www.wix.com"
          target="_blank"
          skin="light"
          priority="secondary"
        >
          {t('standalone.createWebsiteBanner.button')}
        </Button>
      </Box>
    </Box>
  ));
