import * as React from 'react';
import { Modal } from '@wix/design-system';
import { ReviewAppModalLayout } from './review-app-modal-layout';
import { useReviewModalPrefetch } from '../../hooks/useReviewModalPrefetch';
import { biLogger } from '../../bi';
import { appMarketAddEditAppReviewClick } from '@wix/bi-logger-market/v2';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';

interface IReviewAppModalProps extends Partial<WithTranslationProps> {
  appDefId: string;
  isOpen: boolean;
  closeFn(): void;
  afterCloseFn?(): void;
  showSuccessToast?(message: string): void;
  additionalBiData: {
    msid: string;
    tagType: string;
    platform: string;
  };
  isMobile?: boolean;
}

function ReviewAppModal({
  appDefId,
  isOpen,
  closeFn,
  afterCloseFn,
  showSuccessToast,
  additionalBiData,
  t,
  isMobile,
}: IReviewAppModalProps) {
  const appReviewData = useReviewModalPrefetch(appDefId, isOpen, closeFn);
  const isModalOpen = isOpen && Boolean(appReviewData);
  const { appName, appIconSrc, previousReview } = appReviewData || {};

  React.useEffect(() => {
    if (isModalOpen) {
      biLogger
        .report(
          appMarketAddEditAppReviewClick({
            appId: appDefId,
            msid: additionalBiData.msid,
            actionType: appReviewData.previousReview
              ? 'edit_review'
              : 'add_review',
            post_type: 'new_review',
            tag_type: additionalBiData.tagType,
            market: additionalBiData.platform,
          }),
        )
        .catch((error) => console.log(error));
    }
  }, [isModalOpen]);

  return (
    <Modal isOpen={isModalOpen} zIndex={100000}>
      <ReviewAppModalLayout
        appDefId={appDefId}
        appName={appName}
        appIconSrc={appIconSrc}
        previousReview={previousReview}
        closeModal={closeFn}
        afterClose={afterCloseFn}
        showSuccessToast={showSuccessToast}
        additionalBiData={additionalBiData}
        t={t}
        isMobile={isMobile}
      />
    </Modal>
  );
}

export { IReviewAppModalProps, ReviewAppModal };
